import { PluginCameraIndentifierEnum } from '@api/enum';
import { ICamera } from '@api/models';
import { disableCameraAiPlugin, enableCameraAiPlugin } from '@api/services/camera.service';
import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

const useCameraPluginUpdate = (camera: ICamera, clientId: string, projectId: string) => {
    const availablePlugins = Object.values(PluginCameraIndentifierEnum);
    const getPluginData = (camera: ICamera) => {
        return availablePlugins.map((plugin: string) => {
            return {
                name: plugin,
                isEnabled:
                    camera.enabledFeaturesList.length > 0
                        ? camera.enabledFeaturesList.find((val) => val.name === plugin)
                            ? true
                            : false
                        : false,
            };
        });
    };
    const initialValues = useMemo(() => {
        if (!camera) {
            return {
                plugins: availablePlugins.map((plugin: string) => {
                    return {
                        name: plugin,
                        isEnabled: false,
                    };
                }),
            };
        }

        return {
            plugins: getPluginData(camera),
        };
    }, [camera]);
    const enableApiMutation = useMutation(() => enableCameraAiPlugin(clientId, projectId, camera._id));
    const disableApiMutation = useMutation(() => disableCameraAiPlugin(clientId, projectId, camera._id));

    const submit = async (values: any) => {
        const data = (await values.plugins[0].isEnabled)
            ? enableApiMutation.mutateAsync()
            : disableApiMutation.mutateAsync();
        return data;
    };
    return {
        initialValues,
        submit,
        isSubmitting: enableApiMutation.isLoading,
    };
};
export default useCameraPluginUpdate;
