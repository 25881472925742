import { Field } from 'formik';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { Checkbox, CheckboxProps } from '@hyperflake/react-ui-library/forms';

interface FormikCheckboxProps extends CheckboxProps {}

const FormikCheckbox = forwardRef((props: FormikCheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { name, id, ...rest } = props;

    const idOrName = useMemo(() => {
        return id || name;
    }, [id, name]);

    return <Field type="checkbox" {...rest} ref={ref} id={idOrName} name={name} as={Checkbox} />;
});

export default FormikCheckbox;
