import http from 'httpConfig/http';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import useImageSliderState from './use-image-slider-state';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';

const useCameraImages = (projectId: string, cameraId: string, date?: string, time?: string) => {
    const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

    const {
        images,
        selectedImage,
        setSelectedImage,
        selectedDate,
        setSelectedDate,
        endDate,
        startDate,
        setState,
        resetState,
    } = useImageSliderState();

    // const imagesQuery = useCameraImagesQuery(projectId, cameraId, date);

    // interface IImageQuery {
    //     projectId: string;
    //     cameraId: string;
    //     date?: string;
    // }

    const imagesQuery = useQuery(['camerasImages', cameraId, date], async () => {
        const { data } = await http.get(
            `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/cameras/${cameraId}/images`,
            {
                params: { date: date ? date : undefined },
            }
        );
        return data;
    });

    useEffect(() => {
        resetState();
        setIsInitialLoading(true);
    }, [projectId, cameraId, resetState]);

    useEffect(() => {
        if (imagesQuery.isLoading) return;

        const data = imagesQuery.data;
        const images = data.images;

        let index = images.findIndex((img: any) => img.time === time);
        if (index < 0) {
            index = 0;
        }

        setState({
            images: images,
            startDate: data.startDate,
            endDate: data.endDate,
            index: index,
        });

        if (isInitialLoading) {
            setIsInitialLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imagesQuery.isLoading, imagesQuery.data]);

    return {
        isInitialLoading,
        isLoading: imagesQuery.isLoading,
        images,
        selectedImage,
        setSelectedImage,
        selectedDate,
        setSelectedDate,
        startDate,
        endDate,
    };
};

export default useCameraImages;
