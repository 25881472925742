import { IProject } from '@api/models/project.model';
import { Button, Drawer } from '@hyperflake/react-ui-library';
import { UploadIcon } from '@icon/index';
import { FormHelperText, Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { ErrorMessage, Form, Formik } from 'formik';
import { IAddProjectForm } from 'modules/projects/helpers/project.types';
import useProjectAddForm from 'modules/projects/hooks/useProjectAddForm';
import CustomMultiSelect from 'modules/shared/components/FormFields/CustomMultiSelect';
import CustomSwitchWithInfo from 'modules/shared/components/FormFields/CustomSwitchWithInfo';
import RequiredMark from 'modules/shared/components/FormFields/RequiredMark';
import Img from 'modules/shared/components/Img/Img';
import { FormikInput, FormikSelect } from 'modules/shared/features/formik';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FORMATTED_ASSET_LIST } from '../../helpers/projects.constants';
import { DeleteIcon } from 'modules/shared/icons';

type IProjectLean = Pick<IProject, '_id' | 'status' | 'name' | 'hasCameras'>;

interface IProjectAddDrawerFormProps {
    open: boolean;
    onClose: () => void;
    addProject: (project: IProject) => void;
    projects: IProject[];
}

const ProjectAddDrawerForm = (props: IProjectAddDrawerFormProps) => {
    const { open, onClose, addProject, projects } = props;

    const { clientId } = useParams();
    // const queryProject = useQueryClient();
    const addProjectForm = useProjectAddForm(clientId);

    const parentProjectOptions = useMemo(() => {
        if (!projects) return [];
        const temp = projects
            .filter((proj) => proj.hasCameras === false)
            .map((project) => ({
                label: project.name,
                value: project._id,
            }));

        return [
            {
                label: 'Select an option',
                value: '',
            },
            ...temp,
        ];
    }, [projects]);

    const handleSubmit = async (values: IAddProjectForm) => {
        try {
            const data = await addProjectForm.submit(values);
            toast.success('Project Add Successfully.');
            addProject(data);

            // queryClient.setQueryData<IProject[]>(['clients', clientId, 'projects'], (tempData) => {
            //     if (!tempData) return;
            //     return produce(tempData, (draft) => {
            //         draft.unshift(data);
            //         return draft;
            //     });
            // });

            onClose();

            // add to client
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    // const { hasAdminPermission } = useAuthPermission();
    // if (!hasAdminPermission) return <AdminPermission formTitle="Project" />;

    return (
        <Drawer open={open} onClose={onClose} title="Add Project">
            <div>
                <div>
                    <Formik
                        initialValues={addProjectForm.initialValues}
                        enableReinitialize
                        validationSchema={addProjectForm?.Schema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <div className="mt-4 ">
                                    {/* name */}
                                    <div className="">
                                        <Label>
                                            Name <RequiredMark />
                                        </Label>
                                        <FormikInput type="text" name="name" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="name" />
                                        </FormHelperText>
                                    </div>

                                    {/* location.name */}
                                    <div className="">
                                        <Label>
                                            Location <RequiredMark />
                                        </Label>
                                        <FormikInput type="text" name="location.name" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="location.name" />
                                        </FormHelperText>
                                    </div>

                                    {/* location.latitude */}
                                    <div className="">
                                        <Label>Latitude</Label>
                                        <FormikInput type="text" name="location.latitude" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="location.latitude" />
                                        </FormHelperText>
                                    </div>

                                    {/* location.longitude */}
                                    <div className="">
                                        <Label>Longitude</Label>
                                        <FormikInput type="text" name="location.longitude" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="location.longitude" />
                                        </FormHelperText>
                                    </div>

                                    {/* parentId */}
                                    <div>
                                        <Label>Parent</Label>
                                        <div className="flex gap-2">
                                            <FormikSelect name="parentId" options={parentProjectOptions} />
                                            {/* {addProjectForm.isLoading && <CircularProgress size={14} />} */}
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="location.longitude" />
                                        </FormHelperText>
                                    </div>

                                    {/* assets */}
                                    <div>
                                        <Label>Assets</Label>
                                        <CustomMultiSelect name="assets" options={FORMATTED_ASSET_LIST} className="" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="assets" />
                                        </FormHelperText>
                                    </div>

                                    {/* Has Assets */}
                                    <div className="">
                                        {/* <CustomSwitch name='hasCameras' label='Has Assets' /> */}
                                        <CustomSwitchWithInfo
                                            name="hasCameras"
                                            label="Has Assets"
                                            infoMessage={
                                                <div className="flex flex-col gap-2 ">
                                                    <div>
                                                        <div className="font-semibold">If Checked</div>
                                                        <ul className="text-xs mt-2 list-disc pl-3">
                                                            <li>Can create cameras under the project.</li>
                                                            <li>Cannot create sub-projects</li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <div className="font-semibold">If Unchecked</div>
                                                        <ul className="text-xs mt-2 list-disc pl-3">
                                                            <li>Can create any number of sub projects</li>
                                                            <li>Cannot create cameras under the project.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <FormHelperText variant={'error'} />
                                    </div>

                                    <div>
                                        <Label>Images</Label>
                                        <div className="grid grid-cols-3 gap-4 mb-4 empty:hidden">
                                            {values.files.map((loginImage, index) => (
                                                <div
                                                    key={index}
                                                    className="col-span-1 relative rounded-md overflow-hidden"
                                                >
                                                    <Img
                                                        src={URL.createObjectURL(values.files[index])}
                                                        className="w-full h-full aspect-square object-cover object-center"
                                                    />

                                                    <button
                                                        className="absolute right-2 top-2 w-6 h-6 grid place-items-center bg-card-color rounded-md text-grayscale-500"
                                                        onClick={() => values.files.splice(index, 1)}
                                                    >
                                                        <DeleteIcon />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="relative flex justify-center cursor-pointer bg-primary/5 py-6 rounded-lg border border-dashed">
                                            <Button variant={'shaded'} size={'xs'} className="cursor-pointer">
                                                <UploadIcon className="cursor-pointer" /> Upload Image
                                            </Button>
                                            <input
                                                type="file"
                                                className="absolute z-20 top-0 left-0 bottom-0 right-0 w-full h-full opacity-0 cursor-pointer"
                                                onChange={(event) => {
                                                    const files = event.target.files;
                                                    if (files && files.length > 0) {
                                                        setFieldValue(`files[${values.files.length}]`, files[0]);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Button
                                    disabled={addProjectForm.isSubmitting}
                                    type="submit"
                                    size={'lg'}
                                    className="mt-8 w-28"
                                >
                                    {addProjectForm.isSubmitting ? (
                                        <CircularProgress size={20} color={'inherit'} />
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Drawer>
    );
};

export default ProjectAddDrawerForm;
