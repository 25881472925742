import { VariantProps, cva } from 'class-variance-authority';
import { ChangeEvent, DetailedHTMLProps, FC, ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import styles from './Radio.module.scss';

const radioVariants = cva(
    [styles['radio'], 'bg-transparent', 'border border-grayscale-300', 'rounded-full', 'align-top cursor-pointer'],
    {
        variants: {
            size: {
                sm: ['w-4 h-4'],
                normal: ['w-5 h-5'],
                lg: ['w-6 h-6'],
            },
            color: {
                primary: [
                    'checked:bg-primary checked:border-primary checked:outline checked:outline-primary/10 checked:focus:border-primary checked:focus:outline-primary/10',
                ],
                secondary: [
                    'checked:bg-secondary checked:border-secondary checked:outline checked:outline-secondary/10 checked:focus:border-secondary checked:focus:outline-secondary/10',
                ],
                success: [
                    'checked:bg-success checked:border-success checked:outline checked:outline-success/10 checked:focus:border-success checked:focus:outline-success/10',
                ],
                danger: [
                    'checked:bg-danger checked:border-danger checked:outline checked:outline-danger/10 checked:focus:border-danger checked:focus:outline-danger/10',
                ],
                warning: [
                    'checked:bg-warning checked:border-warning checked:outline checked:outline-warning/10 checked:focus:border-warning checked:focus:outline-warning/10',
                ],
            },
            disabled: {
                true: ['bg-grayscale-100 opacity-60'],
                false: [],
            },
        },
        defaultVariants: {
            size: 'normal',
            disabled: false,
            color: 'primary',
        },
    }
);

interface RadioProps
    extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size' | 'color'>,
        VariantProps<typeof radioVariants> {
    ref?: ForwardedRef<HTMLInputElement>;
    onCheckedChange?: (checked: boolean) => void;
}

const Radio = forwardRef<HTMLInputElement, RadioProps>((props: RadioProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { size, color, disabled, className, onCheckedChange, onChange, ...rest } = props;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);

        onCheckedChange?.(e.target.checked);
    };

    return (
        <input
            {...rest}
            ref={ref}
            type="radio"
            disabled={disabled}
            onChange={handleChange}
            className={twMerge(radioVariants({ size, color, disabled, className }))}
        />
    );
});

export default Radio;
