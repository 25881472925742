import { UnitTypeEnum } from '@api/enum';
import { ICamera, ICameraCCTV } from '@api/models';
import { createCamera } from '@api/services/camera.service';
import { useMutation } from '@tanstack/react-query';
import http from 'httpConfig/http';
import capitalize from 'lodash.capitalize';
import { resolutionOption, timezoneList } from 'modules/shared/helpers/shared.constants';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import moment from 'moment';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { produce } from 'immer';
import { createCctvCamera } from '@api/services/cctv-camera.service';

const useCctvCameraAddForm = (clientId: string, projectId: string) => {
    const addCctvCameraMutation = useMutation((payload: any) => createCctvCamera(clientId, projectId, payload));

    const schema = Yup.object().shape({
        name: Yup.string().trim().required('This field is required'),
        installationDate: Yup.string().trim().required('This field is required'),
        streamingUrl: Yup.string().trim().url('Enter a valid Url').required('This field is required'),
        location: Yup.object().shape({
            latitude: Yup.string().trim(),
            longitude: Yup.string().trim(),
        }),
        qLiveUnit: Yup.string().trim().required('This field is required'),
        storage: Yup.string().required('This field is required'),
    });

    const initialValues = {
        name: '',
        installationDate: moment(),
        streamingUrl: '',
        location: {
            latitude: '',
            longitude: '',
        },
        qLiveUnit: '',
        storage: '',
    };

    const submit = async (values: ICameraCCTV) => {
        const data = await addCctvCameraMutation.mutateAsync(values);

        return data;
    };
    // const handleDataTransformation = (values: any) => {
    //     return produce(values, (draft: ICamera) => {
    //         draft.installationDate = moment(draft.installationDate).format('YYYYMMDD');
    //         draft.folder = values.isPointedTo ? (draft.folder.trim() === '' ? null : draft.folder.trim()) : null;
    //         // draft._id = draft._id.trim() === '' ? null : draft._id.trim();
    //         draft.latestImage = draft.latestImage.name === '' ? null : draft.latestImage;
    //         // draft.latestImage = {
    //         //     name: values.latestImage.name,
    //         //     resolution: resolutionOption[parseInt(values.latestImage.resolution)],
    //         // };
    //     });
    // };

    return {
        schema,
        initialValues,

        // handleDataTransformation,
        submit,
        isSubmitting: addCctvCameraMutation.isLoading,
    };
};
export default useCctvCameraAddForm;
