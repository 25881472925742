import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(minMax);

export const getDaysOnCalendar = (year: number, month: number) => {
    const firstDay = dayjs().year(year).month(month).date(1).day();

    let index = 1;
    // const rows = firstDay >= 6 ? 6 : 5;
    const rows = 6;
    const cols = 7;
    const datesOnCalendar = [];

    for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
            const date = index - firstDay;

            const dayjsDate = dayjs().year(year).month(month).date(date);

            datesOnCalendar.push(dayjsDate);

            index++;
        }
    }

    return datesOnCalendar;
};

export const getYearsOnCalendar = (selectedYear: number, count: number) => {
    const firstYear = Math.floor(selectedYear / count) * count;

    return Array.from({ length: count }, (_, i) => firstYear + i);
};

export const getInitialViewDate = (value: Dayjs, maxDate: Dayjs) => {
    if (value && value.isValid()) return value;

    if (!maxDate) return dayjs();

    return dayjs.min(dayjs(), maxDate);
};
