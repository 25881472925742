import { useCallback, useState } from 'react';

interface DialogData<T> {
    isOpen: boolean;
    data?: T;
}

const useDialog = <T = unknown>(initialValue: T = null) => {
    const [dialogData, setDialogData] = useState<DialogData<T>>({ isOpen: false, data: initialValue });

    const show = useCallback((data: T = null) => {
        setDialogData({ isOpen: true, data });
    }, []);

    const hide = useCallback(() => {
        setDialogData({ isOpen: false, data: initialValue });
    }, [initialValue]);

    const setData = useCallback((data: T = null) => {
        setDialogData((prev) => ({ ...prev, data }));
    }, []);

    return {
        show,
        hide,
        isOpen: dialogData.isOpen,
        data: dialogData.data,
        setData,
    };
};

export default useDialog;
