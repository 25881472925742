import { getClientById } from '@api/services/client.service';
import useDataQuery from '@hooks/use-data-query';

const useClientData = (clientId: string) => {
    const clientQuery = useDataQuery(['client', clientId], () => getClientById(clientId), {
        enabled: Boolean(clientId),
    });

    return {
        isLoading: clientQuery.isLoading,
        client: clientQuery.data,
        setData: clientQuery.setData,
    };
};

export default useClientData;
