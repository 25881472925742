import { ICameraCCTV } from '@api/models';
import { getResellerIdFromRedux } from '@utils/common';
import http from 'httpConfig/http';

export const getAllCctvCamerasOfProject = async (clientId: string, projectId: string) => {
    const { data } = await http.get(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/camera-cctvs`
    );
    return data as ICameraCCTV[];
};

export const getCctvCameraOfProjectById = async (clientId: string, projectId: string, cctvCameraId: string) => {
    const { data } = await http.get(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/camera-cctvs/${cctvCameraId}`
    );
    return data as ICameraCCTV;
};

export const createCctvCamera = async (clientId: string, projectId: string, payload: any) => {
    const { data } = await http.post(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/camera-cctvs`,
        payload
    );
    return data as ICameraCCTV;
};

export const updateCctvCamera = async (clientId: string, projectId: string, cameraId: string, payload: any) => {
    const { data } = await http.put(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/camera-cctvs/${cameraId}`,
        payload
    );
    return data as ICameraCCTV;
};

export const moveProjectOfCctvCamera = async (
    clientId: string,
    projectId: string,
    cctvCameraId: string,
    payload: string
) => {
    const { data } = await http.put(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/camera-cctvs/${cctvCameraId}/update-project`,
        {
            newProjectId: payload,
        }
    );
    return data as ICameraCCTV;
};
