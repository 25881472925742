import { FC } from 'react';
import { ICameraCCTV } from '@api/models/camera-cctv.model';
import { Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from 'httpConfig/http';
import { toast } from 'react-toastify';
import { produce } from 'immer';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import { Button, Drawer } from '@hyperflake/react-ui-library';

interface ICctvCameraDeleteConfirmationDrawer {
    open: boolean;
    onClose: () => void;
    ccCamera: ICameraCCTV | null;
}

const CctvCameraDeleteConfirmationDrawer = (props: ICctvCameraDeleteConfirmationDrawer) => {
    const { open, ccCamera, onClose } = props;

    const queryClient = useQueryClient();
    const { clientId, projectId } = useParams();

    const deleteCcCameraMutation = useMutation(async (payload: any) => {
        const { data, status } = await http.delete(
            `${
                import.meta.env.VITE_APP_API_URL
            }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/camera-cctvs/${payload.id}`
        );
        return { data, status };
    });

    const handleDelete = async () => {
        if (ccCamera === null) return onClose();
        try {
            const { data, status } = await deleteCcCameraMutation.mutateAsync({
                id: ccCamera._id,
            });
            console.log({ data, status });
            toast.success('Project deleted Successfully.');
            queryClient.setQueryData<ICameraCCTV[]>(
                ['clients', clientId, 'projects', projectId, 'cctv-cameras'],
                (prev) => {
                    if (!prev) return;

                    const index = prev.findIndex((c) => c._id === ccCamera._id);
                    return produce(prev, (draft) => {
                        draft.splice(index, 1);
                        return draft;
                    });
                }
            );
            onClose();
        } catch (ex: any) {
            onClose();
            console.log(ex.status);
            if (ex.status === 400) {
                toast.error(ex.data.message);
            } else {
                toast.error(ex?.data?.message || 'Some error occured, please try again.');
                console.log(ex);
            }
        }
    };

    return (
        <Drawer open={open} onClose={onClose} title="Confirm Delete">
            <div>
                <div>
                    <Label className="mt-8 inline-block">
                        The following project &ldquo;
                        <span className="font-bold">{ccCamera?.name}</span>&rdquo; will be delete.
                    </Label>

                    <div className="flex gap-2 mt-2">
                        <Button
                            disabled={deleteCcCameraMutation.isLoading}
                            onClick={() => onClose()}
                            variant="default"
                            color={'primary'}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={deleteCcCameraMutation.isLoading}
                            className="w-20"
                            onClick={() => handleDelete()}
                            variant="default"
                            color={'danger'}
                        >
                            {deleteCcCameraMutation.isLoading ? <CircularProgress size={18} /> : 'Delete'}
                        </Button>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default CctvCameraDeleteConfirmationDrawer;
