import { ICamera, IClient, IProject } from '@api/models';
import { EllipsisHorizontalIcon } from '@icon/index';
import { Dropdown, DropdownItem, DropdownList, DropdownTrigger } from '@library/dropdown';
import { GalleryCard, Img } from 'modules/shared/features/content';
import moment from 'moment';
import { ForwardedRef, forwardRef } from 'react';

interface ICameraCard {
    camera: ICamera;

    onClick: () => void;
    isActivator: boolean;
}

const CameraCard = forwardRef((props: ICameraCard, ref: ForwardedRef<HTMLDivElement>) => {
    const { camera, onClick, isActivator } = props;

    const isOlderThan3Days = (date: string) => {
        return moment().diff(moment(date, 'YYYYMMDD'), 'days') <= 3;
    };

    return (
        <div onClick={onClick} ref={isActivator ? ref : null}>
            <GalleryCard>
                <GalleryCard.Media>
                    <Img
                        className="w-full aspect-image rounded-lg object-cover object-center"
                        src={camera.latestImage?.urlThumb}
                    />
                </GalleryCard.Media>
                <GalleryCard.Title>
                    {(camera?.client as IClient)?.name ? (camera?.client as IClient)?.name : '-'}
                </GalleryCard.Title>
                <GalleryCard.Title>{camera.name}</GalleryCard.Title>
                <p>{(camera?.project as IProject)?.name ? (camera?.project as IProject)?.name : '-'}</p>

                <GalleryCard.Footer>
                    <div className="flex flex-wrap gap-4 justify-between">
                        <GalleryCard.FooterItem
                            label="Last Image"
                            content={
                                camera?.latestImage?.datetime ? (
                                    <div
                                        className={
                                            isOlderThan3Days(camera?.latestImage?.datetime)
                                                ? 'text-green-500'
                                                : 'text-red-500'
                                        }
                                    >
                                        {moment(camera?.latestImage?.datetime, 'YYYYMMDDHHmmss').format(
                                            'h:mm A DD MMM, YY'
                                        )}
                                    </div>
                                ) : (
                                    '-'
                                )
                            }
                        ></GalleryCard.FooterItem>

                        <GalleryCard.FooterItem
                            label="Installed On"
                            content={moment(camera?.installationDate, 'YYYYMMDD').format('DD MMM, YYYY')}
                        ></GalleryCard.FooterItem>
                    </div>
                </GalleryCard.Footer>
            </GalleryCard>
        </div>
    );
});

export default CameraCard;
