import { getProjectByIds } from '@api/services/project.service';
import useDataQuery from 'modules/shared/hooks/use-data-query';

const useProjectData = (clientId: string, projectId: string) => {
    const ProjectQuery = useDataQuery(['project'], () => getProjectByIds(clientId, projectId), {
        enabled: Boolean(projectId && clientId),
    });

    return {
        isLoading: ProjectQuery.isLoading,
        project: ProjectQuery.data,
        setData: ProjectQuery.setData,
    };
};
export default useProjectData;
