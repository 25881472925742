import { PublicRequestStatusEnum } from '@api/enum';
import { IPublicRequest } from '@api/models/public-request.model';
import useDialog from '@hooks/use-dialog';
import { Avatar, Chip, Table } from '@hyperflake/react-ui-library';
import { DeleteShapeIcon } from '@icon/index';
import usePublicApiRequestListData from 'modules/public-api-request/hooks/usePublicApiRequestListData';
import EventBlocker from 'modules/shared/components/EventBlocker/EventBlocker';
import { EditIcon } from 'modules/shared/icons';
import DeletePublicApiRequestDrawer from '../DeletePublicApiRequestDrawer/DeletePublicApiRequestDrawer';
import PublicApiRequestDetailDrawer from '../PublicApiRequestDetailDrawer/PublicApiRequestDetailDrawer';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { PermissionEnum } from '@api/enum/permission.enum';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';

const PublicApiRequestList = () => {
    const publicApiDialog = useDialog<IPublicRequest>();

    const publicRequestListData = usePublicApiRequestListData();
    const deleteDialog = useDialog<IPublicRequest>();
    const { hasPermission } = useAuthPermission();

    const handleUpdate = (publicApiRequest: IPublicRequest) => {
        publicRequestListData.updatePublicApiRequest(publicApiRequest._id, publicApiRequest);

        publicApiDialog.setData(publicApiRequest);
    };

    return (
        <PageTemplate>
            <PageHeading>Public Api Request</PageHeading>

            {/* <div className="flex justify-between mt-6">
                <div className="relative flex items-center ">
                    <Input
                        placeholder="Search Client..."
                        className="w-96 bg-white"
                        value={clientListData.searchQuery}
                        onChange={(e) => clientListData.setSearchQuery(e.target.value)}
                    />

                    {clientListData.searchQuery !== '' && (
                        <div
                            className="cursor-pointer bg-white rounded-full  p-2 absolute right-2"
                            onClick={() => clientListData.setSearchQuery('')}
                        >
                            <CrossIcon />
                        </div>
                    )}
                </div>

                <Button onClick={addClientDialog.show}>Add New Client</Button>
            </div> */}

            <Table
                borderStyle="divider"
                className="mt-6"
                // hover
                isLoading={publicRequestListData.isLoading}
                data={publicRequestListData.publicRequest}
                columns={
                    hasPermission(PermissionEnum.PUBLIC_API_REQUEST_DELETE)
                        ? [
                              // {
                              //     header: 'ID',
                              // },
                              {
                                  header: 'Name',
                              },
                              {
                                  header: 'Client',
                              },
                              {
                                  header: 'Status',
                              },
                              {
                                  header: 'Action',
                              },
                              {
                                  header: 'Delete',
                              },
                          ]
                        : [
                              {
                                  header: 'Name',
                              },
                              {
                                  header: 'Client',
                              },
                              {
                                  header: 'Status',
                              },
                              {
                                  header: 'Action',
                              },
                          ]
                }
                renderRow={(row) => (
                    <Table.Row key={row._id} onClick={() => {}}>
                        {/* <Table.Cell>{row._id}</Table.Cell> */}
                        <Table.Cell>
                            {' '}
                            <div className="flex items-center gap-2">
                                <Avatar
                                    src={row.user.dpUrl}
                                    //  alt={row.user.username}
                                    bgColor={row.user.preferences.primaryColor}
                                />
                                {row.user.email}
                            </div>
                        </Table.Cell>
                        <Table.Cell>{row.client.name}</Table.Cell>
                        <Table.Cell>
                            <Chip
                                label={row.status}
                                color={row.status === PublicRequestStatusEnum.COMPELETED ? 'success' : 'danger'}
                                variant="shaded"
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <EventBlocker stopPropagation preventDefault>
                                <button className="p-2" onClick={() => publicApiDialog.show(row)}>
                                    <EditIcon />
                                </button>
                            </EventBlocker>
                        </Table.Cell>
                        {hasPermission(PermissionEnum.PUBLIC_API_REQUEST_DELETE) && (
                            <Table.Cell>
                                <EventBlocker stopPropagation preventDefault>
                                    <button className="p-2 " onClick={() => deleteDialog.show(row)}>
                                        <DeleteShapeIcon />
                                    </button>
                                </EventBlocker>
                            </Table.Cell>
                        )}
                    </Table.Row>
                )}
            />

            <PublicApiRequestDetailDrawer
                open={publicApiDialog.isOpen}
                onClose={publicApiDialog.hide}
                publicApiData={publicApiDialog.data}
                onUpdate={handleUpdate}
            />
            <DeletePublicApiRequestDrawer
                open={deleteDialog.isOpen}
                onClose={deleteDialog.hide}
                publicApiRequest={deleteDialog.data}
                onDelete={publicRequestListData.deletePublicApiRequest}
            />
            {/* 
            <DeleteConfirmationDrawer
                open={deleteDialog.isOpen}
                onClose={deleteDialog.hide}
                message={
                    <>
                        <div>
                            We will remove all of your personal details. Your updates and generated content will remain
                            available anonymously.
                        </div>
                        <div className="mt-4">
                            <span className="text-grayscale-900 font-semibold">Attention: </span> This action is
                            irreversible.
                        </div>
                    </>
                }
                onConfirm={handleDelete}
                isConfirming={deleteMutation.isLoading}
            /> */}
        </PageTemplate>
    );
};

export default PublicApiRequestList;
