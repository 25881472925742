import { FC, ReactNode } from 'react';
import { useField } from 'formik';
import { Label, Switch } from '@library/forms';
import { Popover } from '@library/popover';
import { QuestionCircleIcon } from '@icon/index';

interface ICustomSwitchWithInfo {
    name: string;
    label: string;
    infoMessage: ReactNode;
}

const CustomSwitchWithInfo = (props: ICustomSwitchWithInfo) => {
    const { name, infoMessage: message, label } = props;
    const [field] = useField(name);
    return (
        <div>
            <Label className="flex items-center gap-1">
                {label}
                <Popover content={message}>
                    <QuestionCircleIcon height={10} width={12} />
                </Popover>
            </Label>
            <Switch size={'lg'} checked={field.value} {...field} {...props} />
        </div>
    );
};

export default CustomSwitchWithInfo;
