import { ProjectAssetEnum, ProjectStatusEnum } from '@api/enum';
import { IProject } from '@api/models';
import { getProjectsByClientId, updateProject } from '@api/services/project.service';
import { useMutation } from '@tanstack/react-query';
import useDataQuery from 'modules/shared/hooks/use-data-query';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { IEditProjectForm } from '../helpers/project.types';

interface UpdateProjectFormOptions {
    project: IProject;
    clientId: string;
}
const useProjectUpdateForm = (options: UpdateProjectFormOptions) => {
    const { project, clientId } = options;
    const schema = Yup.object().shape({
        name: Yup.string().required('This field is required'),
        location: Yup.object().shape({
            name: Yup.string().required('This field is required'),
        }),
        assets: Yup.array()
            .typeError('asset names must be array')
            .of(Yup.string().trim().required('This field is required'))
            .min(1, 'Add atleast one asset'),
        hasCameras: Yup.boolean(),
        parentId: Yup.string(),
        statusbar: Yup.string(),
    });

    const initialValues = useMemo(() => {
        if (!project)
            return {
                name: '',
                location: {
                    name: '',
                    latitude: '',
                    longitude: '',
                },
                assets: [] as ProjectAssetEnum[],
                hasCameras: false,
                parentId: '',
                status: ProjectStatusEnum.ARCHIVED,
            };

        return {
            name: project?.name,
            location: {
                name: project?.location?.name,
                latitude: project?.location?.latitude || '',
                longitude: project?.location?.longitude || '',
            },
            assets: project?.assets ? (project.assets?.map((asset) => asset?.name) as ProjectAssetEnum[]) : [],
            hasCameras: project?.hasCameras,
            parentId: project?.parentId || '',
            status: project?.status,
        };
    }, [project]);
    const projectAsParent = useDataQuery(['projects', clientId], () => getProjectsByClientId(clientId), {
        enabled: Boolean(clientId),
    });
    const updateProjectMutation = useMutation((payload: IProject) => updateProject(clientId, project._id, payload));
    const submit = async (values: any) => {
        const { data } = await updateProjectMutation.mutateAsync({
            ...values,
            parentId: values.parentId ? values.parentId : null,
        });
        return data as IProject;
    };

    return {
        schema,
        initialValues,
        projectAsParent: projectAsParent.data,
        projectAsParentIsloading: projectAsParent.isLoading,
        submit,
        isSubmitting: updateProjectMutation.isLoading,
    };
};
export default useProjectUpdateForm;
