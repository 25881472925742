import { ISupportTicket } from '@api/models';
import OverflowTooltip from '@components/OverflowTooltip/OverflowTooltip';
// import EventBlocker from '@components/helpers/EventBlocker';
// import RenderSupportChip from '@components/utils/RenderSupportChip';
import useDialog from '@hooks/use-dialog';
import { Table } from '@hyperflake/react-ui-library';
import { EllipsisVerticalIcon } from '@icon/index';
import { Avatar } from '@library/avatar';
import { Dropdown, DropdownItem, DropdownList, DropdownTrigger } from '@library/dropdown';
import { Input } from '@library/forms';
import debounce from 'lodash.debounce';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import useSupportListData from 'modules/support/hooks/useSupportListData';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteSupportTicketDrawer from '../DeleteSupportTicketDrawer/DeleteSupportTicketDrawer';
import SupportChip from 'modules/shared/components/SupportChip/SupportChip';
import EventBlocker from '@components/EventBlocker/EventBlocker';

const SupportList = () => {
    const [clientName, setClientName] = useState<string>('');

    const { data, isLoading, onDelete } = useSupportListData(clientName);

    const supportTickets = useMemo(() => {
        if (!data) return [];

        return data;
    }, [data]);

    const navigate = useNavigate();
    const deleteSupportTicketDialog = useDialog<ISupportTicket>();

    const handleSearch = debounce((e: any) => {
        const val = e.target.value;

        setClientName(val);
    }, 500);

    return (
        <PageTemplate>
            <PageHeading>Support</PageHeading>

            <div className="mt-6">
                <div className="flex justify-between py-3">
                    <div>
                        <Input
                            placeholder="Search Client"
                            className="w-96 bg-white"
                            onChange={(e) => handleSearch(e)}
                            // value={clientName}
                        />
                    </div>
                </div>

                <Table
                    className="mt-4 h-96!"
                    hover
                    isLoading={isLoading}
                    data={supportTickets}
                    borderStyle="divider"
                    columns={[
                        { header: 'Client Name' },
                        { header: 'User Name' },
                        { header: 'Ticket ID' },
                        { header: 'Date' },
                        { header: 'Help Topic' },
                        { header: 'Subject' },
                        { header: 'Status' },
                        { header: 'Action' },
                    ]}
                    renderRow={(row) => (
                        <Table.Row
                            className=" cursor-pointer"
                            key={row._id}
                            onClick={() => navigate(`/support-tickets/${row._id}`)}
                        >
                            <Table.Cell>
                                <div className="overflow-hidden">
                                    <OverflowTooltip>{row?.client?.name}</OverflowTooltip>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <div className="flex items-center gap-3">
                                    <div>
                                        <Avatar src={row.user.dpUrl} alt={row.user.name} />
                                    </div>

                                    <div className="overflow-hidden">
                                        <OverflowTooltip className="max-w-[120px]">{row.user.name}</OverflowTooltip>
                                    </div>
                                </div>
                            </Table.Cell>

                            <Table.Cell
                                //  onClick={() => navigate(`/support/${row._id}`)}
                                className="text-primary"
                            >
                                #{row._id}
                            </Table.Cell>
                            <Table.Cell>
                                <div className="whitespace-nowrap">
                                    <div className="">
                                        {moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM YYYY')}
                                    </div>
                                    <div className="">{moment(row.createdAt, 'YYYYMMDDHHmmss').format('HH:mm A')}</div>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <OverflowTooltip>{row.topic}</OverflowTooltip>
                            </Table.Cell>
                            <Table.Cell>
                                <OverflowTooltip className="max-w-[140px]">{row.subject}</OverflowTooltip>
                            </Table.Cell>
                            <Table.Cell>
                                <span className="inline-block ">{<SupportChip status={row.status} />}</span>
                            </Table.Cell>
                            <Table.Cell>
                                <Dropdown placement="bottom-start" showArrow>
                                    <EventBlocker stopPropagation preventDefault>
                                        <DropdownTrigger className="w-4 h-4 rounded-full p-6">
                                            <EllipsisVerticalIcon />
                                        </DropdownTrigger>
                                    </EventBlocker>

                                    <DropdownList className="min-w-[100px]">
                                        <EventBlocker stopPropagation preventDefault>
                                            <DropdownItem
                                                className="text-danger"
                                                onClick={() => deleteSupportTicketDialog.show(row)}
                                            >
                                                Delete
                                            </DropdownItem>
                                        </EventBlocker>
                                    </DropdownList>
                                </Dropdown>
                            </Table.Cell>
                        </Table.Row>
                    )}
                />
            </div>

            <DeleteSupportTicketDrawer
                show={deleteSupportTicketDialog.isOpen}
                onClose={deleteSupportTicketDialog.hide}
                onDelete={(ticketId) => onDelete(ticketId)}
                supportTicket={deleteSupportTicketDialog.data}
            />
        </PageTemplate>
    );
};

export default SupportList;
