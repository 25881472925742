import * as Yup from 'yup';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { IDroneFootage } from '@api/models/drone-footage.model';
import { CircularProgress } from '@library/loaders/components';
import { updateDroneFootage } from '@api/services/drone-footage.service';
import { FormHelperText, Label } from '@library/forms';
import { Button } from '@hyperflake/react-ui-library';
import { FormikInput } from 'modules/shared/features/formik';
// import RequiredMark from '@components/FormFields/RequiredMark';

const FormSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    location: Yup.object().shape({
        name: Yup.string().required('This field is required'),
    }),
});

interface IDroneFootageEditDrawerForm {
    show: boolean;
    clientId: string;
    projectId: string;
    onClose: () => void;
    droneFootage: IDroneFootage;
    onUpdate: (droneId: string, droneFootage: Partial<IDroneFootage>) => void;
}

const DroneFootageEditDrawerForm: FC<IDroneFootageEditDrawerForm> = (props) => {
    const { show, clientId, projectId, droneFootage, onUpdate } = props;

    const initialValues = useMemo(() => {
        if (!droneFootage)
            return {
                name: '',
                takenAtDate: moment().format('YYYYMMDD'),
                takenAtTime: '000000',
                location: {
                    name: '',
                },
            };
        return {
            name: droneFootage.name,
            location: {
                name: droneFootage.location.name,
            },
        };
    }, [droneFootage]);

    const updateDroneFootageMutation = useMutation((values: { droneId: string; payload: Partial<IDroneFootage> }) =>
        updateDroneFootage(clientId, projectId, values.droneId, values.payload)
    );

    const handleSubmit = async (values: Partial<IDroneFootage>) => {
        try {
            const updatedDroneFootage = await updateDroneFootageMutation.mutateAsync({
                droneId: droneFootage._id,
                payload: values,
            });

            onUpdate(droneFootage._id, updatedDroneFootage);

            toast.success('Drone Footage updated successfully.');
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured, please try again');
        }
    };

    return (
        <div>
            <div>
                <h4>Edit Drone Footage</h4>
            </div>
            <div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={FormSchema}
                    onSubmit={handleSubmit}
                >
                    {() => (
                        <Form>
                            <div className="mt-4 ">
                                <div className="mt-6">
                                    <Label htmlFor="name">Name</Label>
                                    <FormikInput name="name" placeholder="Enter text here" />
                                    <FormHelperText variant={'error'}>
                                        <ErrorMessage name="name" />
                                    </FormHelperText>
                                </div>

                                <div>
                                    <Label htmlFor="location.name">Location</Label>
                                    <FormikInput name="location.name" placeholder="Enter text here" />
                                    <FormHelperText variant={'error'}>
                                        <ErrorMessage name="location.name" />
                                    </FormHelperText>
                                </div>
                            </div>

                            <Button
                                type="submit"
                                size={'lg'}
                                className="mt-8 w-28"
                                disabled={updateDroneFootageMutation.isLoading}
                            >
                                {updateDroneFootageMutation.isLoading ? (
                                    <CircularProgress size={20} color={'inherit'} />
                                ) : (
                                    'Submit'
                                )}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default DroneFootageEditDrawerForm;
