import { useCallback, useRef, useState } from 'react';

const useComputedStyles = () => {
    const [computedStyle, setComputedStyle] = useState<CSSStyleDeclaration>();

    const ref = useRef<HTMLImageElement>(null);

    const refSetter = useCallback((node: HTMLImageElement) => {
        ref.current = node;

        if (node) {
            setComputedStyle(getComputedStyle(node));
        }
    }, []);

    return {
        refs: {
            element: ref,
            setElement: refSetter,
        },
        computedStyle,
    };
};

export default useComputedStyles;
