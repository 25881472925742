import { CircularProgress } from '@hyperflake/react-ui-library';
import { authActions } from '@store/auth';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
// import http from 'config/http';
// import PageLoader from 'modules/shared/components/PageLoader/PageLoader';
// import ProgressSpinner from 'modules/shared/components/ProgressSpinner/ProgressSpinner';
import { useEffect } from 'react';
// import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { authActions } from 'store/auth';

const AuthorizeToken = () => {
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    // const authorizeTokenMutation = useMutation(async (values) => {

    //     return axios.post(${process.env.REACT_APP_API_URL}/auth/authenticate/token, {
    //         token: values.token,
    //     });
    // });
    const authorizeTokenMutation = useMutation(async (payload: { token: string }) => {
        // const { data } = await axios.post(`${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/auth/login`, payload);

        const { data } = await axios.post(`${import.meta.env.VITE_APP_AUTH_URL}/authenticate/token`, payload);

        return data;
    });

    useEffect(() => {
        const authorizeToken = async (token: string) => {
            try {
                const data = await authorizeTokenMutation.mutateAsync({ token });

                dispatch(authActions.login({ token: data.token, rememberMe: true }));

                navigate('/', { replace: true });
            } catch (err) {
                console.log(err);

                navigate('/login');
            }
        };

        const token = searchParams.get('token');

        if (!token) {
            return navigate('/login');
        }

        authorizeToken(token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, navigate, searchParams]);

    return (
        <div className="fixed inset-0 flex items-center justify-center">
            <CircularProgress />
        </div>
    );
};

export default AuthorizeToken;
