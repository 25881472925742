import { ProjectAssetEnum, ProjectStatusEnum } from '@api/enum';
import { IProject } from '@api/models';

export interface IAddProjectForm {
    name: string;
    location: {
        name: string;
        latitude: string;
        longitude: string;
    };
    assets: string[];
    hasCameras: boolean;
    parentId: string;
    files: File[];
}

export interface IEditProjectForm {
    name: string;
    location: {
        name: string;
        latitude: string;
        longitude: string;
    };
    assets: ProjectAssetEnum[];
    hasCameras: boolean;
    parentId: string;
    status: ProjectStatusEnum;
}

export interface ProjectEditDrawerFormProps {
    open: boolean;
    onClose: () => void;
    projectId: string;
    updateProject: (project: IProject) => void;
}
export enum TabEnum {
    PROJECT_EDIT,
    PROJECT_RENDER,
}

export type IProjectLean = Pick<IProject, '_id' | 'status' | 'name' | 'hasCameras'>;
