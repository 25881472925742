import http from 'httpConfig/http';
import { IConsoleUser } from '@api/models';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';

// const resellerId = getResellerIdFromRedux();

export const getAllConosleUsers = async () => {
    const { data } = await http.get(`${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/console-users`);

    return data as IConsoleUser[];
};

export const getAllConosleUserById = async (id: string) => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/console-users/${id}`
    );

    return data as IConsoleUser;
};

export const getProfile = async () => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/console-users/profile`
    );

    return data as IConsoleUser;
};
