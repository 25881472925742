import { IInternalApplications } from '@api/models';
import { getResellerIdFromRedux } from '@utils/common';
import http from 'httpConfig/http';

export const getAllInternalApplications = async () => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/internal-applications`
    );
    return data as IInternalApplications[];
};

export const getInternalApplicationUrlById = async (internalApplicationId: string) => {
    const { data } = await http.get(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/internal-applications/${internalApplicationId}/?os=mac`
    );

    return data as IInternalApplications;
};
