import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

const StopWatchIllustration = (props: any) => {
    return (
        <svg width={292} height={177} viewBox="0 0 292 177" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_1894_2)">
                <path
                    d="M24.173 38.4c8.31 0 15.048-6.74 15.048-15.054 0-8.315-6.737-15.056-15.048-15.056-8.31 0-15.048 6.74-15.048 15.056 0 8.314 6.737 15.055 15.048 15.055z"
                    fill="#C8DDFA"
                />
                <path
                    d="M36.966 97.278c20.415 0 36.965-16.559 36.965-36.985 0-20.425-16.55-36.983-36.965-36.983C16.55 23.31 0 39.868 0 60.294c0 20.425 16.55 36.984 36.966 36.984z"
                    fill="#8EBBFE"
                />
                <path d="M40.528 171.691h-7.571l3.452-116.967 4.12 116.967z" fill="#000" />
                <path
                    d="M37.076 76.892l8.128-11.251-8.239 14.035-.89-1.559 1.001-1.225zM36.186 88.254L28.06 77.003l8.239 14.036.89-1.56-1.002-1.225zM270.7 171.814h-7.571l3.451-116.967 4.12 116.967z"
                    fill="#000"
                />
                <path
                    d="M267.248 77.015l8.128-11.251-8.239 14.036-.891-1.56 1.002-1.225zM266.358 88.377l-8.128-11.251 8.24 14.036.891-1.56-1.003-1.225z"
                    fill="#000"
                />
                <path
                    d="M150.17 171.794c38.032 0 68.862-30.846 68.862-68.896s-30.83-68.896-68.862-68.896c-38.031 0-68.861 30.846-68.861 68.896s30.83 68.896 68.861 68.896z"
                    fill="#000"
                    stroke="#F7F7F7"
                    strokeWidth={7}
                />
                <path
                    d="M150.169 166.091c34.883 0 63.162-28.293 63.162-63.193s-28.279-63.193-63.162-63.193c-34.883 0-63.161 28.292-63.161 63.193 0 34.9 28.278 63.193 63.161 63.193z"
                    fill="#C8DDFA"
                />
                <path
                    d="M149.941 111.567c4.659 0 8.436-3.779 8.436-8.441 0-4.661-3.777-8.44-8.436-8.44a8.439 8.439 0 00-8.437 8.44 8.439 8.439 0 008.437 8.441zM151.766 42.9h-3.649v6.843h3.649V42.9zM151.766 156.51h-3.649v6.844h3.649v-6.844zM96.586 104.952v-3.65h-6.84v3.65h6.84zM210.141 104.952v-3.65H203.3v3.65h6.841z"
                    fill="#fff"
                />
                <path d="M147.512 106.791l3.118-7.133 39.067 21.648-42.185-14.515z" fill="#fff" />
                <path d="M151 29h-2v9h2v-9z" fill="#000" />
                <path d="M150 32c4.971 0 9-2.462 9-5.5s-4.029-5.5-9-5.5-9 2.462-9 5.5 4.029 5.5 9 5.5z" fill="#000" />
                <path
                    d="M290.53 13.796a14.626 14.626 0 00-4-9.25 14.611 14.611 0 00-24.676 6.167c-.177-.006-.353-.014-.532-.014a14.614 14.614 0 00-14.023 10.503 10.323 10.323 0 00-12.256 3.05h45.589a9.884 9.884 0 009.908-10.24l-.01-.216z"
                    fill="#C8DDFA"
                />
                <path
                    d="M252.418 44.57a3.926 3.926 0 003.925-3.928 3.926 3.926 0 10-7.851 0 3.927 3.927 0 003.926 3.928zM264.269 34.653a3.27 3.27 0 003.268-3.27 3.269 3.269 0 10-6.537 0 3.27 3.27 0 003.269 3.27zM280.222 53.08a3.927 3.927 0 000-7.855 3.926 3.926 0 00-3.925 3.927 3.926 3.926 0 003.925 3.928zM250.457 71.08a3.926 3.926 0 003.925-3.927 3.926 3.926 0 10-7.851 0 3.927 3.927 0 003.926 3.928zM280.222 93.664a3.927 3.927 0 000-7.855 3.926 3.926 0 00-3.925 3.927 3.926 3.926 0 003.925 3.928z"
                    fill="#DFECFD"
                />
                <path
                    d="M274.001 165.468c.001-.043.006-.084.006-.127a3.924 3.924 0 00-1.802-3.306 3.917 3.917 0 00-3.755-.263 3.914 3.914 0 00-6.814 3.245l-.06-.003a3.925 3.925 0 00-2.729 6.756 3.926 3.926 0 005.129.275 3.927 3.927 0 003.259 2.126 3.922 3.922 0 003.486-1.728 3.922 3.922 0 005.856-4.846 3.923 3.923 0 00-2.576-2.129z"
                    fill="#8EBBFE"
                />
                <path
                    d="M283.823 75.663a1.637 1.637 0 10-.001-3.274 1.637 1.637 0 00.001 3.274zM273.354 36.387a1.636 1.636 0 100-3.272 1.636 1.636 0 000 3.272zM267.792 76.972a1.637 1.637 0 10-.001-3.273 1.637 1.637 0 00.001 3.273z"
                    fill="#DFECFD"
                />
                <path d="M269.1 75.335a1.636 1.636 0 100-3.271 1.636 1.636 0 000 3.271z" fill="#DFECFD" />
                <path
                    d="M270.081 73.699a1.637 1.637 0 10-.001-3.274 1.637 1.637 0 00.001 3.274zM266.155 87.445a1.636 1.636 0 100-3.272 1.636 1.636 0 000 3.272zM290.366 105.774a1.637 1.637 0 10-.002-3.274 1.637 1.637 0 00.002 3.274zM255.362 106.755a1.635 1.635 0 10.001-3.27 1.635 1.635 0 00-.001 3.27z"
                    fill="#DFECFD"
                />
                <path d="M257.323 56.352a1.637 1.637 0 10-.001-3.273 1.637 1.637 0 00.001 3.273z" fill="#E6E6E6" />
                <path d="M264.85 85.81a1.636 1.636 0 100-3.273 1.636 1.636 0 000 3.272z" fill="#DFECFD" />
                <path d="M263.214 83.19a1.636 1.636 0 10-.001-3.272 1.636 1.636 0 00.001 3.272z" fill="#DFECFD" />
            </g>
            <path
                d="M145 176c80.081 0 145-1.119 145-2.5s-64.919-2.5-145-2.5-145 1.119-145 2.5 64.919 2.5 145 2.5z"
                fill="#E6E6E6"
            />
            <defs>
                <clipPath id="clip0_1894_2">
                    <path fill="#fff" d="M0 0H292V177H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

interface EmptyDataViewProps extends ComponentPropsWithoutRef<'div'> {
    message?: ReactNode;
    className?: string;
}

const EmptyDataView = (props: EmptyDataViewProps) => {
    const { message = 'No results found', className, ...rest } = props;

    return (
        <div {...rest} className={twMerge('flex flex-col items-center text-center mt-[15vh]', className)}>
            <div>
                <StopWatchIllustration />
            </div>
            <h5 className="mt-10">{message}</h5>
        </div>
    );
};

export default EmptyDataView;
