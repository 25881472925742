import { ICameraCCTV } from '@api/models/camera-cctv.model';
import CustomSelect from '@components/FormFields/CustomSelect';
import { Button, Drawer } from '@hyperflake/react-ui-library';
import { FormHelperText, Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ErrorMessage, Form, Formik } from 'formik';
import http from 'httpConfig/http';
import { produce } from 'immer';
import useCctvCameraAddForm from 'modules/cctv-cameras/hooks/useCctvCameraAddForm';
import FormikDatePicker from 'modules/shared/components/FormFields/FormikDatePicker';
import RequiredMark from 'modules/shared/components/FormFields/RequiredMark';
import { FormikInput } from 'modules/shared/features/formik';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

interface ICctvCameraAddDrawerForm {
    open: boolean;
    onClose: () => void;
    onAddCctvCamera: (cctvCamera: ICameraCCTV) => void;
}

const CctvCameraAddDrawerForm = (props: ICctvCameraAddDrawerForm) => {
    const { open, onClose, onAddCctvCamera } = props;

    // const queryClient = useQueryClient();
    const { clientId, projectId } = useParams();
    const addCctvCameraAddForm = useCctvCameraAddForm(clientId, projectId);

    // const addCctvCameraValidationSchema = Yup.object().shape({
    //     name: Yup.string().trim().required('This field is required'),
    //     installationDate: Yup.string().trim().required('This field is required'),
    //     streamingUrl: Yup.string().trim().url('Enter a valid Url').required('This field is required'),
    //     location: Yup.object().shape({
    //         latitude: Yup.string().trim(),
    //         longitude: Yup.string().trim(),
    //     }),
    //     qLiveUnit: Yup.string().trim().required('This field is required'),
    //     storage: Yup.string().required('This field is required'),
    // });

    // const initialValues = {
    //     name: '',
    //     installationDate: moment(),
    //     streamingUrl: '',
    //     location: {
    //         latitude: '',
    //         longitude: '',
    //     },
    //     qLiveUnit: '',
    //     storage: '',
    // };

    // const addCctvCameraMutation = useMutation(async (payload: any) => {
    //     const { data, status } = await http.post(
    //         `${
    //             import.meta.env.VITE_APP_API_URL
    //         }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/camera-cctvs`,
    //         payload
    //     );
    //     return { data, status };
    // });

    const { data: storages, isLoading: storageIsLoading } = useQuery<string[]>(
        ['clients', clientId, 'projects', projectId, 'cc-cameras', 'storage_names'],
        async () => {
            const { data } = await http.get(
                `${
                    import.meta.env.VITE_APP_API_URL
                }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras/storage-names`
            );
            return data;
        }
    );

    const transformData = (values: any) => {
        return {
            ...values,
            installationDate: moment(values.installationDate).format('YYYYMMDD'),
        };
    };

    const handleSubmit = async (values: any) => {
        try {
            const tranformedData = transformData(values);
            const data = await addCctvCameraAddForm.submit(tranformedData);
            toast.success('CCtv Camera Added Successfully');
            onAddCctvCamera(data);
            // queryClient.setQueryData<ICameraCCTV[]>(
            //     ['clients', clientId, 'projects', projectId, 'cctv-cameras'],
            //     (tempData) => {
            //         if (!tempData) return;
            //         return produce(tempData, (draft) => {
            //             draft.unshift(data);
            //             return draft;
            //         });
            //     }
            // );
            onClose();
        } catch (e: any) {
            console.log(e);
            toast.error('Oops something went wrong.');
        }
    };

    const storageOption = useMemo(() => {
        if (!storages) return [];
        return [{ label: 'Select Storage', value: '' }, ...storages.map((val: string) => ({ label: val, value: val }))];
    }, [storages]);

    // const { hasAdminPermission } = useAuthPermission();

    // if (!hasAdminPermission) return <AdminPermission formTitle="Live Camera" />;

    return (
        <Drawer open={open} onClose={onClose} title="Add Live Camera">
            <div>
                <div>
                    <Formik
                        initialValues={addCctvCameraAddForm.initialValues}
                        enableReinitialize
                        validationSchema={addCctvCameraAddForm.schema}
                        onSubmit={handleSubmit}
                    >
                        {() => (
                            <Form>
                                <div className="mt-4 ">
                                    {/* name */}
                                    <div className="">
                                        <Label>
                                            Name <RequiredMark />
                                        </Label>
                                        <FormikInput type="text" name="name" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="name" />
                                        </FormHelperText>
                                    </div>
                                    {/* installation Date */}
                                    <div className="">
                                        <Label>
                                            Installation Date <RequiredMark />
                                        </Label>
                                        <FormikDatePicker name="installationDate" maxDate={new Date()} />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="installationDate" />
                                        </FormHelperText>
                                    </div>
                                    {/* Streaming Url */}
                                    <div>
                                        <Label>
                                            Streaming Url <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikInput name="streamingUrl" />
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="streamingUrl" />
                                        </FormHelperText>
                                    </div>
                                    {/* qLiveUnit */}
                                    <div>
                                        <Label>
                                            QLive Unit <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikInput name="qLiveUnit" />
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="qLiveUnit" />
                                        </FormHelperText>
                                    </div>
                                    {/* storage */}
                                    <div>
                                        <Label>
                                            Storage <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <CustomSelect name="storage" options={storageOption} />
                                            {storageIsLoading && <CircularProgress size={14} />}
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="storage" />
                                        </FormHelperText>
                                    </div>

                                    {/* latitude */}
                                    <div className="">
                                        <Label> Latitude </Label>
                                        <FormikInput type="text" name="location.latitude" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="location.latitude" />
                                        </FormHelperText>
                                    </div>
                                    {/* longitude */}
                                    <div className="">
                                        <Label>Longitude</Label>
                                        <FormikInput type="text" name="location.longitude" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="location.longitude" />
                                        </FormHelperText>
                                    </div>
                                </div>

                                <Button
                                    type="submit"
                                    size={'lg'}
                                    className="mt-8 w-28"
                                    disabled={addCctvCameraAddForm.isSubmitting}
                                >
                                    {addCctvCameraAddForm.isSubmitting ? (
                                        <CircularProgress size={20} color={'inherit'} />
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Drawer>
    );
};

export default CctvCameraAddDrawerForm;
