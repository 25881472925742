import { ICamera, ICameraImage } from '@api/models';
import http from 'httpConfig/http';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';

export const getAllCamerasOfProject = async (clientId: string, projectId: string) => {
    const { data } = await http.get(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras`
    );
    return data as ICamera[];
};

export const getCameraOfProjectById = async (clientId: string, projectId: string, cameraId: string) => {
    const { data } = await http.get(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras/${cameraId}`
    );
    return data as ICamera;
};

export const getAvailableCameraStorageList = async (clientId: string, projectId: string) => {
    const { data } = await http.get(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras/storage-names`
    );
    return data;
};

export const createCamera = async (clientId: string, projectId: string, payload: any) => {
    const { data } = await http.post(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras`,
        payload
    );
    return data as ICamera;
};
export const updateCamera = async (clientId: string, projectId: string, cameraId: string, payload: any) => {
    const { data } = await http.put(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras/${cameraId}`,
        payload
    );
    return data as ICamera;
};

export const enableCameraAiPlugin = async (clientId: string, projectId: string, cameraId: string) => {
    const { data } = await http.post(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/ai/cameras/${cameraId}/enable`
    );
    return data as ICamera;
};
export const disableCameraAiPlugin = async (clientId: string, projectId: string, cameraId: string) => {
    const { data } = await http.post(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/ai/cameras/${cameraId}/disable`
    );
    return data as ICamera;
};

export const getImagesByCameraId = async (clientId: string, projectId: string, camerasId: string, date: string) => {
    const { data } = await http.get(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras/${camerasId}/images`,
        {
            params: { date: date ? date : null },
        }
    );
    return data as ICameraImage[];
};

export const cameraNameValidation = async (clientId: string, projectId: string, cameraId: string, payload: string) => {
    const { data } = await http.post(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras/${cameraId}/camera-validation`,
        payload
    );
    return data;
};

export const deleteMultipleImages = async (clientId: string, projectId: string, cameraId: string, payload: any) => {
    console.log('payload', payload);
    const { data } = await http.post(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras/${cameraId}/images`,
        payload
    );

    return data;
};
