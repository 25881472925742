import { createContext, useContext } from 'react';
import { IAddProgressTabItem, IProgressTabItem } from '../helpers/progress-tab.types';

interface IProgressTabContextData {
    items: IProgressTabItem[];
    addItem: (values: IAddProgressTabItem) => IProgressTabItem;
    updateItem: (itemId: string, updates: Partial<IProgressTabItem>) => void;
    removeItem: (itemId: string) => void;
    reset: () => void;
}

const ProgressTabContext = createContext<IProgressTabContextData>(null);

export const useProgressTabContext = () => {
    const context = useContext(ProgressTabContext);

    if (context == null) {
        throw new Error('ProgressTabContext must be used within <ProgressTabProvider />');
    }

    return context;
};

export default ProgressTabContext;
