const Icon = (props: any) => {
    return (
        <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M17 8.556a7.45 7.45 0 01-.8 3.377 7.556 7.556 0 01-6.756 4.178 7.448 7.448 0 01-3.377-.8L1 17l1.689-5.067a7.449 7.449 0 01-.8-3.377A7.556 7.556 0 016.067 1.8 7.449 7.449 0 019.444 1h.445A7.538 7.538 0 0117 8.111v.445z"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
