const commonAnimationOptions = {
    transition: { type: 'tween', duration: 0.35, ease: [0.4, 0, 0.2, 1] },
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
};

export const drawerAnimation = {
    right: {
        variants: {
            hidden: {
                x: '100vw',
            },
            visible: {
                x: 0,
            },
        },
        ...commonAnimationOptions,
    },
    left: {
        variants: {
            hidden: {
                x: '-100vw',
            },
            visible: {
                x: 0,
            },
        },
        ...commonAnimationOptions,
    },
    top: {
        variants: {
            hidden: {
                y: '-100vh',
            },
            visible: {
                y: 0,
            },
        },
        ...commonAnimationOptions,
    },
    bottom: {
        variants: {
            hidden: {
                y: '100vh',
            },
            visible: {
                y: 0,
            },
        },
        ...commonAnimationOptions,
    },
};
