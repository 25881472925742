import { VariantProps, cva } from 'class-variance-authority';
import { DetailedHTMLProps, FC, ForwardedRef, SelectHTMLAttributes, forwardRef } from 'react';
import { ISelectOption } from '../helpers/forms.types';
import { twMerge } from 'tailwind-merge';
import styles from './Select.module.scss';

const selectVariants = cva(
    [
        styles['select'],
        'flex items-center',
        'w-full',
        'font-medium text-sm',
        'border border-grayscale-300 ',
        'placeholder:text-grayscale-400',
        'focus:border-primary focus:outline focus:outline-2 focus:outline-primary/10',
    ],
    {
        variants: {
            size: {
                xs: ['text-xs pl-[14px] pr-8 h-8 rounded-md bg-[right_14px_center]'],
                sm: ['text-sm pl-4 pr-9 h-9 rounded-md bg-[right_1rem_center]'],
                normal: ['text-sm pl-4 pr-9 h-10 rounded-md bg-[right_1rem_center]'],
                lg: ['text-base pl-[18px] pr-10 h-11 rounded-lg bg-[right_18px_center]'],
                xl: ['text-base pl-5 pr-11 h-12 rounded-lg bg-[right_1.25rem_center]'],
            },
            invalid: {
                true: ['border-danger outline outline-danger/10 focus:border-danger focus:outline-danger/10'],
                false: [],
            },
            valid: {
                true: ['border-success outline outline-success/10 focus:border-success focus:outline-success/10'],
                false: [],
            },
            disabled: {
                true: ['bg-grayscale-100 opacity-60'],
                false: [],
            },
        },
        defaultVariants: {
            size: 'normal',
            disabled: false,
            invalid: false,
            valid: false,
        },
    }
);

interface SelectProps
    extends Omit<DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, 'children' | 'size'>,
        VariantProps<typeof selectVariants> {
    options: ISelectOption[];
    ref?: ForwardedRef<HTMLSelectElement>;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
    (props: SelectProps, ref: ForwardedRef<HTMLSelectElement>) => {
        const { options, size, invalid, valid, disabled, className, ...rest } = props;

        return (
            <select
                {...rest}
                ref={ref}
                disabled={disabled}
                className={twMerge(selectVariants({ size, invalid, valid, disabled, className }))}
            >
                {options.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        );
    }
);

export default Select;
