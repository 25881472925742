import AuthGuard from 'modules/shared/hocs/AuthGuard/AuthGuard';
import ErrorBoundary from 'modules/shared/hocs/ErrorBoundary/ErrorBoundary';
import MainLayout from 'modules/shared/layouts/MainLayout/MainLayout';
import AppLayout from './AppLayout';

// import Login from '@module/auth/Login';
// import SigninLogs from 'modules/signin-logs/components/SigninLogs/SigninLogs';
import { CameraImagesList, CameraList } from 'modules/cameras';
import { CctvCameraList } from 'modules/cctv-cameras';
import { ClientDetails, ClientList } from 'modules/clients';
import { ConsoleUserList } from 'modules/console-user';
import { DroneDetails, DroneList } from 'modules/drones';
import { ProjectList, SubProjectList } from 'modules/projects';
import { ResellerCameraList, ResellerCameraDetails } from 'modules/reseller-cameras';
import { ListSiteGallery, SiteGalleryDetails } from 'modules/site-gallery';
import { SupportList, SupportDetail } from 'modules/support';
import { AllUsers, UserList } from 'modules/users';
import { PublicApiRequestList } from 'modules/public-api-request';
import { SigninLogs } from 'modules/signin-logs';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import ClientLayout from 'modules/shared/layouts/ClientLayout/ClientLayout';
import Profile from 'modules/profile/components/Profile/Profile';
import ProjectLayout from 'modules/shared/layouts/ProjectLayout/ProjectLayout';
import PermissionGuard from 'modules/shared/hocs/PermissionGuard/PermissionGuard';
import { PermissionEnum } from '@api/enum/permission.enum';
import NotFound from 'modules/shared/hocs/NotFound/NotFound';
import AuthorizeToken from 'modules/auth/AuthorizeToken';
import Login from 'modules/auth/Login';
import { InternalApplicationList } from 'modules/internalApplications';

const router = createBrowserRouter([
    {
        path: '/',
        element: <AppLayout />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/login',
                element: <Login />,
            },
            {
                path: '/authorize',
                element: <AuthorizeToken />,
            },
            {
                path: '/',
                element: <AuthGuard page={<MainLayout />} />,
                children: [
                    {
                        index: true,
                        element: <Navigate to={'/clients'} replace />,
                    },
                    {
                        path: 'clients',
                        index: true,
                        element: <PermissionGuard page={<ClientList />} permissions={[PermissionEnum.CLIENT_READ]} />,
                    },
                    {
                        path: 'clients',
                        element: <ClientLayout />,
                        children: [
                            {
                                path: ':clientId/client-details',
                                element: <ClientDetails />,
                            },
                            {
                                path: ':clientId/users',
                                element: (
                                    <PermissionGuard page={<UserList />} permissions={[PermissionEnum.USER_READ]} />
                                ),
                            },
                            {
                                path: ':clientId/projects',
                                element: (
                                    <PermissionGuard
                                        page={<ProjectList />}
                                        permissions={[PermissionEnum.PROJECT_READ]}
                                    />
                                ),
                            },
                            {
                                path: ':clientId/projects/:projectId',
                                element: (
                                    <PermissionGuard
                                        page={<SubProjectList />}
                                        permissions={[PermissionEnum.PROJECT_READ]}
                                    />
                                ),
                            },

                            // {
                            //     path: '',
                            //     element: <ProjectLayout />,
                            //     children: [
                            //         {
                            //             path: ':clientId/projects/:projectId/cameras',
                            //             element: <CameraList />,
                            //         },
                            //         {
                            //             path: ':clientId/projects/:projectId/cctv-cameras',
                            //             element: <CctvCameraList />,
                            //         },
                            //         {
                            //             path: ':clientId/projects/:projectId/site-galleries',
                            //             element: <ListSiteGallery />,
                            //         },
                            //         {
                            //             path: ':clientId/projects/:projectId/site-gallery/:siteItem',
                            //             element: <SiteGalleryDetails />,
                            //         },
                            //         {
                            //             path: ':clientId/projects/:projectId/drones',
                            //             element: <DroneList />,
                            //         },
                            //         {
                            //             path: ':clientId/projects/:projectId/drones/:droneId',
                            //             element: <DroneDetails />,
                            //         },
                            //     ],
                            // },
                        ],
                    },
                    {
                        path: 'clients/:clientId/projects',
                        element: <ProjectLayout />,
                        children: [
                            {
                                path: ':projectId/cameras',
                                index: true,
                                element: <CameraList />,
                            },

                            {
                                path: ':projectId/cctv-cameras',
                                element: <CctvCameraList />,
                            },
                            {
                                path: ':projectId/site-galleries',
                                element: <ListSiteGallery />,
                            },
                            {
                                path: ':projectId/site-gallery/:siteItem',
                                element: <SiteGalleryDetails />,
                            },
                            {
                                path: ':projectId/drones',
                                element: <DroneList />,
                            },
                            {
                                path: ':projectId/drones/:droneId',
                                element: <DroneDetails />,
                            },
                        ],
                    },
                    {
                        path: 'clients/:clientId/projects/:projectId/cameras/:cameraId',
                        index: true,
                        element: <CameraImagesList />,
                    },
                    {
                        path: 'cameras',
                        element: (
                            <PermissionGuard
                                page={<ResellerCameraList />}
                                permissions={[PermissionEnum.RESELLER_CAMERA_READ]}
                            />
                        ),
                    },
                    {
                        path: 'cameras/:cameraId',
                        element: <ResellerCameraDetails />,
                    },
                    {
                        path: 'all-users',
                        element: <PermissionGuard page={<AllUsers />} permissions={[PermissionEnum.ALL_USERS_READ]} />,
                    },
                    {
                        path: 'sign-in-logs',
                        element: (
                            <PermissionGuard page={<SigninLogs />} permissions={[PermissionEnum.SIGN_IN_LOGS_READ]} />
                        ),
                    },
                    // {
                    //     path: '/console-users',
                    //     element: (
                    //         <PermissionGuard
                    //             page={<ConsoleUserList />}
                    //             permissions={[PermissionEnum.CONSOLE_USER_READ]}
                    //         />
                    //     ),
                    // },
                    {
                        path: '/support-tickets',
                        element: <PermissionGuard page={<SupportList />} permissions={[PermissionEnum.SUPPORT_READ]} />,
                    },
                    { path: 'support-tickets/:ticketId', element: <SupportDetail /> },
                    {
                        path: '/public-requests',
                        element: (
                            <PermissionGuard
                                page={<PublicApiRequestList />}
                                permissions={[PermissionEnum.PUBLIC_API_REQUEST_READ]}
                            />
                        ),
                    },
                    { path: '/internal-applications', element: <InternalApplicationList /> },
                    { path: '/profile', element: <Profile /> },
                ],
            },
            {
                path: '/404',
                element: <NotFound />,
            },
        ],
    },
]);

const AppRouter = () => {
    return <RouterProvider router={router} />;
};

export default AppRouter;
