import { FC, useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

interface ErrorBoundaryProps {}

const ErrorBoundary = () => {
    const error: any = useRouteError();
    const navigate = useNavigate();

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            // Log error to console
            console.log({ message: error.message, stack: error.stack });
        } else {
            // // Log error to server
            // http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/errors`, {
            //     message: error.message,
            //     stack: error.stack,
            //     path: window.location.pathname,
            //     platform: 'WEB',
            // }).catch(() => {});
        }
    }, [error]);

    const handleBackButton = () => {
        navigate(0);
    };
    window.addEventListener('popstate', handleBackButton);

    const goBack = () => {
        navigate('/');
        navigate(0);
    };

    return (
        <div className="flex flex-col items-center justify-center h-[calc(100vh-var(--navbar-height))]">
            <img className="h-10" src="/icons/logo.svg" alt="" />
            <h3 className="mt-8">Oops, something went wrong</h3>
            <p className="mt-6">
                An error occurred while processing your request. Please try again.
                <br />
                If this continues, please contact dev team
                {/* <a className="text-blue" href="mailto:hello@farepod.co">
                    hello@farepod.co
                </a> */}
            </p>
            <button className="text-primary font-medium mt-8" onClick={goBack}>
                Go Back Home
            </button>
        </div>
    );
};

export default ErrorBoundary;
