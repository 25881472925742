import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import store from '@store/index';
import { ToastContainer } from 'react-toastify';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

function App() {
    // Create  a client
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                cacheTime: 0,
            },
        },
    });

    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <AppRouter />

                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
            <ToastContainer autoClose={3000} hideProgressBar={true} position={'top-left'} />
        </Provider>
    );
}

export default App;
