import { getSubProjectsByProjectId } from '@api/services/project.service';
import useDataQuery from '@hooks/use-data-query';

const useSubProjectListData = (clientId: string, projectId: string) => {
    const subProjectListQuery = useDataQuery([projectId, clientId, 'children'], () =>
        getSubProjectsByProjectId(clientId, projectId)
    );

    return {
        data: subProjectListQuery.data,
        isLoading: subProjectListQuery.isLoading,
    };
};

export default useSubProjectListData;
