import { Input } from '@library/forms';
import { useField } from 'formik';
import { FC } from 'react';

interface ICustomInput {
    name: string;
    type?: string;
    disabled?: boolean;
    placeholder?: string;
}

const CustomInput: FC<ICustomInput> = (props) => {
    const { name, placeholder, disabled, type } = props;

    const [field] = useField(name);

    // const [field, meta, helpers] = useField(name);

    return <Input placeholder={placeholder} disabled={disabled} type={type} {...field} {...props} />;
};

CustomInput.defaultProps = {
    disabled: false,
    type: 'text',
    placeholder: '',
};

export default CustomInput;
