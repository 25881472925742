const Icon = (props: any) => {
    return (
        <svg
            height={16}
            viewBox="0 0 32 32"
            width={16}
            xmlns="http://www.w3.org/2000/svg"
            overflow="visible"
            {...props}
        >
            <circle cx={16} cy={16} r={16} fill="currentColor" />
            <path d="M14.5 25h3v-3h-3v3zm0-19v13h3V6h-3z" fill="#e6e6e6" />
        </svg>
    );
};

export default Icon;
