import { IProject, IUser } from '@api/models';
import { getExternalOnSearch, updateExternalProjects } from '@api/services/user.service';
import { Button, CircularProgress, Input, List, ListItem } from '@hyperflake/react-ui-library';
import { CrossIcon } from '@icon/index';
import { useMutation } from '@tanstack/react-query';
import AutoComplete from 'modules/shared/components/AutoComplete/AutoComplete';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
interface ExternalProjectListProps {
    user: IUser;
    onClose: () => void;
    onUserUpdate: (updatedUser: IUser) => void;
}
const ExternalProjectList = (props: ExternalProjectListProps) => {
    // TODO > filter externalProject with selectedProject on Search

    const { user, onClose, onUserUpdate } = props;
    const [fieldValue, setFieldValue] = useState('');
    const { clientId } = useParams();
    const [externalProject, setExternalProject] = useState<IProject[]>([]);
    const [selectedProjects, setSelectedProjects] = useState<IProject[]>(user?.extProjects as IProject[]);
    const externalProjectQuery = useMutation((searchParam: string) =>
        getExternalOnSearch(clientId, user._id, searchParam)
    );
    const updateExternalProjectMutation = useMutation((payload: { externalProjects: string[] }) =>
        updateExternalProjects(clientId, user._id, payload)
    );

    //Debouncing Logic on AutoComplete
    useEffect(() => {
        const timeOutId = setTimeout(() => debouncedGetExternalProject(fieldValue), 500);
        return () => clearTimeout(timeOutId);
    }, [fieldValue]);

    //This will exclude already selected Projects
    const filterProject = (newProjects: IProject[]) => {
        const filteredProjects = newProjects.filter(
            (project) => !selectedProjects.some((selected) => selected._id === project._id)
        );
        setExternalProject(filteredProjects);
    };

    const getExternalProject = async (q: string) => {
        try {
            const data = await externalProjectQuery.mutateAsync(q);

            filterProject(data);
        } catch (err: any) {
            setFieldValue('');
            setExternalProject([]);
            console.error(err);
        }
    };

    const handleDelete = (projectId: string) => {
        setSelectedProjects((prevProjects) => prevProjects.filter((project) => project._id !== projectId));
    };
    const handleUpdate = async () => {
        const selectedExternalProjects = { externalProjects: selectedProjects.map((project: IProject) => project._id) };
        try {
            const { data } = await updateExternalProjectMutation.mutateAsync(selectedExternalProjects);
            toast.success('External Project Updated successfully');
            onUserUpdate(data);
            onClose();
        } catch (err: any) {
            console.error(err.response.data.message);
        }
    };
    const debouncedGetExternalProject = (value: string) => {
        if (value.length > 1) {
            getExternalProject(value);
        }
    };
    // const debouncedGetExternalProject = debounce((value) => {
    //     if (value.length > 1) {
    //         getExternalProject(value);
    //     }
    // }, 2000);

    return (
        <>
            <div>
                <div className="mt-4 text-base font-semibold">Manage {user.name}'s External project.</div>
            </div>
            <div className="relative flex items-center w-full mt-2">
                <AutoComplete
                    renderInput={(inputProps) => <Input {...inputProps} placeholder="Search Projects" />}
                    value={fieldValue}
                    onChange={(value) => {
                        setFieldValue(value);

                        // debouncedGetExternalProject(value);
                    }}
                    onOptionSelect={(option) => {
                        setSelectedProjects((prevProjects: any) => [...prevProjects, option]);
                        setFieldValue('');
                        setExternalProject([]);
                    }}
                    options={externalProject}
                    renderOption={(option: any) => (
                        <div className="relative flex items-center w-full border-black max-h-10  bg-white ">
                            <List divider className="overflow-hidden ">
                                <ListItem className="w-full ">
                                    {option.name}-{option._id}
                                </ListItem>
                            </List>
                        </div>
                    )}
                    isLoading={externalProjectQuery.isLoading}
                    searchableFields={['name']}
                />
            </div>

            <div className="mt-4">
                <h5>External projects</h5>
                <List divider className="mt-4">
                    {selectedProjects?.map((row: any) => {
                        return (
                            <ListItem
                                className="flex items-center justify-between my-1  border border-primary/20 rounded-md"
                                style={{ marginLeft: `${row.hierarchyLevel * 10}px` }}
                                key={row._id}
                            >
                                <div
                                    className=" flex flex-col py-2"
                                    // style={{ marginLeft: `${row.hierarchyLevel * 10}px` }}
                                >
                                    <div className="text-base">{row.name}</div>
                                    <div className="text-xs text-primary">{row._id}</div>
                                </div>
                                <button onClick={() => handleDelete(row._id)}>
                                    <CrossIcon />
                                </button>
                            </ListItem>
                        );
                    })}
                </List>
            </div>
            <Button
                type="submit"
                disabled={updateExternalProjectMutation.isLoading}
                size={'lg'}
                className="mt-8 w-28"
                onClick={handleUpdate}
            >
                {updateExternalProjectMutation.isLoading ? <CircularProgress size={20} color={'inherit'} /> : 'Save'}
            </Button>
        </>
    );
};

export default ExternalProjectList;
