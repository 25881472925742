import { FC } from "react";
import { FormHelperText, Label } from "@library/forms";
import { ErrorMessage, useFormikContext } from "formik";
import FileUploadPreview from "modules/shared/components/FileUploadPreview/FileUploadPreview";
import DragAndDropFileUpload from "modules/shared/components/FormFields/DragAndDropFileUpload";

import { IDroneFootageForm } from "../../helpers/drone-list.types";

interface VideoUploadFormFieldsProps {}

const VideoUploadFormFields: FC<VideoUploadFormFieldsProps> = (props) => {
  const { values, setFieldValue } = useFormikContext<IDroneFootageForm>();

  return (
    <div>
      <Label htmlFor="video">Video</Label>
      <DragAndDropFileUpload
        text="Browse MP4"
        onChange={(files) => setFieldValue("file", files[0])}
        accept={{
          "video/*": [],
        }}
      />
      {values.file && (
        <div className="mt-6">
          <FileUploadPreview
            name={values.file.name}
            size={values.file.size}
            onCancel={() => setFieldValue("file", null)}
          />
        </div>
      )}
      <FormHelperText variant={"error"}>
        <ErrorMessage name="file" />
      </FormHelperText>
    </div>
  );
};

export default VideoUploadFormFields;
