import { FC } from 'react';
import DroneCard from './DroneCard';
import { IDroneFootage } from '@api/models/drone-footage.model';

interface DroneGridProps {
    droneFootages: IDroneFootage[];
    onDroneEdit: (droneFootage: IDroneFootage) => void;
    onDroneDelete: (droneFootage: IDroneFootage) => void;
}

const DroneGrid: FC<DroneGridProps> = (props) => {
    const { droneFootages, onDroneEdit, onDroneDelete } = props;

    return (
        <div className="grid grid-cols-12 gap-7">
            {droneFootages.map((r) => (
                <div key={r._id} className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-4 2xl:col-span-4">
                    <DroneCard droneFootage={r} onEdit={() => onDroneEdit(r)} onDelete={() => onDroneDelete(r)} />
                    {/* <DroneCard2 droneFootage={r} onEdit={() => onDroneEdit(r)} onDelete={() => onDroneDelete(r)} /> */}
                </div>
            ))}
        </div>
    );
};

export default DroneGrid;
