import http from "httpConfig/http";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { getResellerIdFromRedux } from "modules/shared/helpers/shared.utils";

const useNavName = (
  clientId: string | undefined,
  projectId: string | undefined = "undefined"
) => {
  const [navLabel, setNavLabel] = useState({
    clientName: clientId,
    projectName: projectId,
  });

  const navLabelMutation = useMutation(async (payload: any) => {
    const { data } = await http.post(
      `${
        import.meta.env.VITE_APP_API_URL
      }/${getResellerIdFromRedux()}/clients/nav-details`,
      payload
    );
    return data;
  });

  useEffect(() => {
    navLabelMutation.mutate(
      {
        clientId,
        projectId,
      },
      {
        onSuccess(data, variables, context) {
          setNavLabel(() => {
            return {
              clientName: data?.client,
              projectName: data?.project,
            };
          });
        },
      }
    );
  }, [clientId, projectId]);

  return navLabel;
};

export default useNavName;
