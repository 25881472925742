import { Card, Input, useControlledState } from '@hyperflake/react-ui-library';
import { CrossIcon } from '@icon/index';

interface SearchBarProps {
    value?: string;
    onChange: (value: string) => void;
}

const SearchBar = (props: SearchBarProps) => {
    const { value: controlledValue, onChange } = props;

    const [value, setValue] = useControlledState({
        initialState: '',
        value: controlledValue,
        setValue: (value) => onChange(value as string),
    });

    return (
        <Card className="max-w-[420px]">
            <div className="relative flex items-center">
                <Input placeholder="Search..." value={value} onChange={(e) => setValue(e.target.value)} />

                {value !== '' && (
                    <button
                        type="button"
                        className="cursor-pointer text-grayscale-500 rounded-full p-2 absolute right-1"
                        onClick={() => setValue('')}
                    >
                        <CrossIcon />
                    </button>
                )}
            </div>
        </Card>
    );
};

export default SearchBar;
