import { Breadcrumb, ContentLayout, NavPills, NavTab } from '@hyperflake/react-ui-library';
import useClientData from 'modules/clients/hooks/useClientData';
import useProjectData from 'modules/projects/hooks/useProjectData';
import ProjectHierarchyBreadcrumb from 'modules/shared/components/ProjectHierarchyBreadcrumb/ProjectHierarchyBreadcrumb';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

const ProjectLayout = () => {
    const navigate = useNavigate();

    const { pathname } = useLocation();

    const { clientId, projectId } = useParams();

    const { project } = useProjectData(clientId, projectId);
    const { client } = useClientData(clientId);
    const navItems = [
        {
            content: 'TimeLapse Camera ',
            isActive: pathname.startsWith(`/clients/${clientId}/projects/${projectId}/cameras`),
            onClick: () => navigate(`/clients/${clientId}/projects/${projectId}/cameras`),
        },
        {
            content: 'Live Camera',
            isActive: pathname.startsWith(`/clients/${clientId}/projects/${projectId}/cctv-cameras`),
            onClick: () => navigate(`/clients/${clientId}/projects/${projectId}/cctv-cameras`),
        },
        {
            content: 'Site Gallery',
            isActive: pathname.startsWith(`/clients/${clientId}/projects/${projectId}/site-galleries`),
            onClick: () => navigate(`/clients/${clientId}/projects/${projectId}/site-galleries`),
        },
        {
            content: 'Drone',
            isActive: pathname.startsWith(`/clients/${clientId}/projects/${projectId}/drones`),
            onClick: () => navigate(`/clients/${clientId}/projects/${projectId}/drones`),
        },
    ];

    return (
        <div className="min-h-screen">
            <div className=" p-3">
                {/* <Breadcrumb
                    items={[
                        {
                            label: 'Client',
                            onClick: () => {
                                navigate('/');
                            },
                        },
                        {
                            label: client?.name,
                            onClick: () => {
                                navigate(`/clients/${client?._id}/client-details`);
                            },
                        },
                        {
                            label: project?.name,
                            onClick: () => {},
                        },
                    ]}
                /> */}
                <ProjectHierarchyBreadcrumb
                    appendItems={[
                        {
                            label: 'Client',
                            to: '/',
                        },
                        {
                            label: client?.name,
                            to: `/clients/${client?._id}/client-details`,
                        },
                    ]}
                />

                {/* <div className="flex items-center gap-8 px-5 bg-white border-b border-separator-color-light sticky top-0">
                // TODO - Implement back click here
                <div className="flex items-center gap-6">
                    <button className="flex items-center gap-[6px] text-grayscale-700 text-sm font-[Inter]">
                        <svg width={8} height={11} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4 1L1 4l3 3"
                                stroke="currentColor"
                                strokeWidth={1.5}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <h6>{project?.name}</h6>
                    </button>
                </div>

                <NavTab
                    className="h-[calc(var(--navbar-height)-1px)]"
                    items={[
                        {
                            content: 'TimeLapse Camera ',
                            isActive: pathname.startsWith(`/clients/${clientId}/projects/${projectId}/cameras`),
                            onClick: () => navigate(`/clients/${clientId}/projects/${projectId}/cameras`),
                        },
                        {
                            content: 'Live Camera',
                            isActive: pathname.startsWith(`/clients/${clientId}/projects/${projectId}/cctv-cameras`),
                            onClick: () => navigate(`/clients/${clientId}/projects/${projectId}/cctv-cameras`),
                        },
                        {
                            content: 'Site Gallery',
                            isActive: pathname.startsWith(`/clients/${clientId}/projects/${projectId}/site-galleries`),
                            onClick: () => navigate(`/clients/${clientId}/projects/${projectId}/site-galleries`),
                        },
                        {
                            content: 'Drone',
                            isActive: pathname.startsWith(`/clients/${clientId}/projects/${projectId}/drones`),
                            onClick: () => navigate(`/clients/${clientId}/projects/${projectId}/drones`),
                        },
                    ]}
                />
            </div> */}
                <NavPills
                    className="mt-4"
                    items={navItems.map((item) => ({
                        content: item.content,
                        isActive: item.isActive,
                        onClick: () => item.onClick(),
                    }))}
                />
            </div>

            <Outlet />
        </div>
    );
};
export default ProjectLayout;
