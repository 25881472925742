import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const useLoading = (dependency: any): [boolean, Dispatch<SetStateAction<boolean>>] => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setIsLoading(true);
    }, [dependency]);

    return [isLoading, setIsLoading];
};

export default useLoading;
