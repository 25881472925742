type ButtonColor = 'primary' | 'secondary' | 'success' | 'danger' | 'warning';

type ButtonVariant = 'default' | 'shaded' | 'outlined' | 'text';

export const ButtonDefaultVariants: {
    color: ButtonColor;
    variant: 'default';
    className: string[] | string;
}[] = [
    {
        color: 'primary',
        variant: 'default',
        className: ['bg-primary', 'text-primary-contrast'],
    },
    {
        color: 'secondary',
        variant: 'default',
        className: ['bg-secondary', 'text-secondary-contrast'],
    },
    {
        color: 'success',
        variant: 'default',
        className: ['bg-success', 'text-white'],
    },
    {
        color: 'danger',
        variant: 'default',
        className: ['bg-danger', 'text-white'],
    },
    {
        color: 'warning',
        variant: 'default',
        className: ['bg-warning', 'text-white'],
    },
];

export const ButtonShadedVariants: {
    color: ButtonColor;
    variant: 'shaded';
    className: string[] | string;
}[] = [
    {
        color: 'primary',
        variant: 'shaded',
        className: ['bg-primary/10', 'text-primary'],
    },
    {
        color: 'secondary',
        variant: 'shaded',
        className: ['bg-secondary/10', 'text-secondary'],
    },
    {
        color: 'success',
        variant: 'shaded',
        className: ['bg-success/10', 'text-success'],
    },
    {
        color: 'danger',
        variant: 'shaded',
        className: ['bg-danger/10', 'text-danger'],
    },
    {
        color: 'warning',
        variant: 'shaded',
        className: ['bg-warning/10', 'text-warning'],
    },
];

export const ButtonOutlinedVariants: {
    color: ButtonColor;
    variant: 'outlined';
    className: string[] | string;
}[] = [
    {
        color: 'primary',
        variant: 'outlined',
        className: ['border border-primary', 'text-primary'],
    },
    {
        color: 'secondary',
        variant: 'outlined',
        className: ['border border-secondary', 'text-secondary'],
    },
    {
        color: 'success',
        variant: 'outlined',
        className: ['border border-success', 'text-success'],
    },
    {
        color: 'danger',
        variant: 'outlined',
        className: ['border border-danger', 'text-danger'],
    },
    {
        color: 'warning',
        variant: 'outlined',
        className: ['border border-warning', 'text-warning'],
    },
];

export const ButtonTextVariants: {
    color: ButtonColor;
    variant: 'text';
    className: string[] | string;
}[] = [
    {
        color: 'primary',
        variant: 'text',
        className: ['text-primary', 'font-semibold', 'hover:bg-primary/5'],
    },
    {
        color: 'secondary',
        variant: 'text',
        className: ['text-secondary', 'font-semibold', 'hover:bg-secondary/5'],
    },
    {
        color: 'success',
        variant: 'text',
        className: ['text-success', 'font-semibold', 'hover:bg-success/5'],
    },
    {
        color: 'danger',
        variant: 'text',
        className: ['text-danger', 'font-semibold', 'hover:bg-danger/5'],
    },
    {
        color: 'warning',
        variant: 'text',
        className: ['text-warning', 'font-semibold', 'hover:bg-warning/5'],
    },
];

export const ButtonCompoundVariants: {
    color: ButtonColor;
    variant: ButtonVariant;
    className: string[] | string;
}[] = [...ButtonDefaultVariants, ...ButtonShadedVariants, ...ButtonOutlinedVariants, ...ButtonTextVariants];
