import { FloatingElement, useFloatingContext } from '@library/floating';
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface DropdownListProps extends DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement> {
    children: ReactNode;
}

const DropdownList: FC<DropdownListProps> = (props) => {
    const { children, className, ...rest } = props;

    const { context, refs, getFloatingProps } = useFloatingContext();

    return (
        <FloatingElement context={context} refs={refs} {...getFloatingProps()}>
            <ul {...rest} className={twMerge('py-2 max-h-[inherit] max-w-[inherit] overflow-y-auto', className)}>
                {children}
            </ul>
        </FloatingElement>
    );
};

export default DropdownList;
