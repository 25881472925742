import { getUserByClientIdUserId } from '@api/services/user.service';
import useDataQuery from 'modules/shared/hooks/use-data-query';

const useUserData = (clientId: string, userId: string) => {
    const userQuery = useDataQuery(['project', clientId, userId], () => getUserByClientIdUserId(clientId, userId), {
        enabled: Boolean(userId),
    });

    return {
        data: userQuery.data,
        isLoading: userQuery.isLoading,
        setData: userQuery.setData,
    };
};
export default useUserData;
