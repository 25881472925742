import { ContainerLayout, ContentLayout } from '@hyperflake/react-ui-library';
import { ProgressTabModal, ProgressTabProvider } from '@library/progress-tab';
import { AppNavbar, AppSidebar } from 'modules/shared/features/navigation';
import { Outlet } from 'react-router-dom';
import useCurrentUser from '../hooks/useCurrentUser';

const MainLayout = () => {
    // useCurrentUser();
    return (
        <ProgressTabProvider>
            <ContainerLayout>
                <AppNavbar />

                <AppSidebar />

                <ContentLayout className="p-0">
                    <Outlet />
                </ContentLayout>
            </ContainerLayout>

            <ProgressTabModal />
        </ProgressTabProvider>
    );
};

export default MainLayout;
