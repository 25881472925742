import { useMemo } from 'react';
import useBoxDimensions from 'modules/shared/hooks/use-box-dimensions';
// import useCSSVariables from 'modules/shared/hooks/use-css-variables';
import { getViewportHeight, getViewportWidth } from 'modules/shared/helpers/shared.utils';

interface CameraViewDimensionOptions {
    aspectRatio: number;
    offsetTop: number;
    isFullScreen: boolean;
}
const useCameraViewDimensions = (options: CameraViewDimensionOptions) => {
    const { aspectRatio, offsetTop, isFullScreen } = options;

    // const { sidebarWidth: offsetLeft } = useCSSVariables();

    const offsetLeft = 220;

    const containerHeight = useMemo(() => {
        return isFullScreen ? window.innerHeight : window.innerHeight - offsetTop;
    }, [isFullScreen, offsetTop]);

    const containerWidth = useMemo(() => {
        return isFullScreen ? window.innerWidth : window.innerWidth - offsetLeft;
    }, [isFullScreen, offsetLeft]);

    const { refs, dimensions } = useBoxDimensions({
        aspectRatio: aspectRatio,
        containerHeight: isFullScreen ? getViewportHeight() : getViewportHeight() - offsetTop,
        containerWidth: isFullScreen ? getViewportWidth() : getViewportWidth() - offsetLeft,
    });

    return {
        refs,
        dimensions,
        containerDimensions: {
            width: containerWidth,
            height: containerHeight,
        },
    };
};

export default useCameraViewDimensions;
