import OverflowTooltip from "modules/shared/components/OverflowTooltip/OverflowTooltip";
import {
  ComponentPropsWithRef,
  DetailedHTMLProps,
  FC,
  ForwardedRef,
  HTMLAttributes,
  forwardRef,
} from "react";

interface CardTitleProps extends ComponentPropsWithRef<"h6"> {}

const CardTitle = forwardRef<HTMLHeadingElement, CardTitleProps>(
  (props: CardTitleProps, ref: ForwardedRef<HTMLHeadingElement>) => {
    const { children, className, ...rest } = props;

    return (
      <h6 ref={ref} {...rest} className={className}>
        <OverflowTooltip>{children}</OverflowTooltip>
      </h6>
    );
  }
);
export default CardTitle;
