import { ProjectStatusEnum } from '@api/enum/project-status.enum';
import { FormHelperText, Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { ErrorMessage, Form, Formik } from 'formik';
import upperCase from 'lodash.uppercase';
import CustomMultiSelect from 'modules/shared/components/FormFields/CustomMultiSelect';
import CustomSwitch from 'modules/shared/components/FormFields/CustomSwitch';
import RequiredMark from 'modules/shared/components/FormFields/RequiredMark';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PermissionEnum } from '@api/enum/permission.enum';
import { Button, Drawer, LinearProgress, NavTab } from '@hyperflake/react-ui-library';
import { useQueryClient } from '@tanstack/react-query';
import { ProjectEditDrawerFormProps, TabEnum } from 'modules/projects/helpers/project.types';
import useProjectData from 'modules/projects/hooks/useProjectData';
import useProjectUpdateForm from 'modules/projects/hooks/useProjectUpdateForm';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import { FORMATTED_ASSET_LIST } from '../../helpers/projects.constants';
import ProjectRendersDrawer from '../ProjectRendersDrawer/ProjectRendersDrawer';
import { FormikInput, FormikSelect } from 'modules/shared/features/formik';

// type IProjectLean = Pick<IProject, '_id' | 'status' | 'name' | 'hasCameras'>;

const ProjectEditDrawerForm = (props: ProjectEditDrawerFormProps) => {
    const { open, onClose, projectId, updateProject } = props;
    const [activeTab, setActiveTab] = useState(TabEnum.PROJECT_EDIT);

    const { clientId } = useParams();
    const queryClient = useQueryClient();
    const { project, isLoading } = useProjectData(clientId, projectId);
    const updateProjectFrom = useProjectUpdateForm({ project, clientId });

    const handleSubmit = async (values: any) => {
        try {
            const data = await updateProjectFrom.submit(values);
            toast.success('Project Updated Successfully.');
            updateProject(data);

            onClose();

            // add to client
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    const ProjectStatusOptions = useMemo(() => {
        return Object.values(ProjectStatusEnum).map((projectStatus) => ({
            value: projectStatus as ProjectStatusEnum,
            label: upperCase(projectStatus),
        }));
    }, []);

    const parentProjectOptions = useMemo(() => {
        if (!updateProjectFrom.projectAsParent) return [];
        const temp = updateProjectFrom.projectAsParent
            .filter((proj) => proj.hasCameras === false && proj._id !== projectId)
            .map((project) => ({
                label: project.name,
                value: project._id,
            }));

        return [
            {
                label: 'Select an option',
                value: '',
            },
            ...temp,
        ];
    }, [updateProjectFrom.projectAsParent]);

    const { hasPermission } = useAuthPermission();

    // if (isLoading || projectListLoading) return <DrawerFormLoading formTitle="Edit Project" />;

    return (
        <Drawer open={open} onClose={onClose} title="Edit Project">
            <NavTab
                items={[
                    {
                        content: 'Project Edit',
                        isActive: TabEnum.PROJECT_EDIT === activeTab,
                        onClick: () => setActiveTab(TabEnum.PROJECT_EDIT),
                    },
                    {
                        content: 'Project Render',
                        isActive: TabEnum.PROJECT_RENDER === activeTab,
                        onClick: () => setActiveTab(TabEnum.PROJECT_RENDER),
                    },
                ]}
            />
            {activeTab === TabEnum.PROJECT_EDIT && (
                <div>
                    {isLoading || updateProjectFrom.projectAsParentIsloading ? (
                        <LinearProgress color="primary" />
                    ) : (
                        <div>
                            <Formik
                                initialValues={updateProjectFrom.initialValues}
                                enableReinitialize
                                validationSchema={updateProjectFrom.schema}
                                onSubmit={handleSubmit}
                            >
                                {({}) => (
                                    <Form>
                                        <fieldset disabled={!hasPermission(PermissionEnum.PROJECT_UPDATE)}>
                                            <div className="mt-4 ">
                                                {/* name */}
                                                <div className="">
                                                    <Label>
                                                        Name <RequiredMark />
                                                    </Label>
                                                    <FormikInput type="text" name="name" />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="name" />
                                                    </FormHelperText>
                                                </div>

                                                {/* location.name */}
                                                <div>
                                                    <Label>
                                                        Location <RequiredMark />
                                                    </Label>
                                                    <FormikInput type="text" name="location.name" />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="location.name" />
                                                    </FormHelperText>
                                                </div>

                                                {/* location.latitude */}
                                                <div className="">
                                                    <Label>Latitude</Label>
                                                    <FormikInput type="text" name="location.latitude" />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="location.latitude" />
                                                    </FormHelperText>
                                                </div>

                                                {/* location.longitude */}
                                                <div className="">
                                                    <Label>Longitude</Label>
                                                    <FormikInput type="text" name="location.longitude" />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="location.longitude" />
                                                    </FormHelperText>
                                                </div>

                                                {/* parentId */}
                                                <div>
                                                    <Label>Parent</Label>
                                                    <div className="flex gap-2">
                                                        <FormikSelect name="parentId" options={parentProjectOptions} />
                                                        {updateProjectFrom.projectAsParentIsloading && (
                                                            <CircularProgress size={14} />
                                                        )}
                                                    </div>
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="parentId" />
                                                    </FormHelperText>
                                                </div>

                                                {/* assets */}
                                                <div>
                                                    <Label>Assets</Label>
                                                    <CustomMultiSelect
                                                        name="assets"
                                                        options={FORMATTED_ASSET_LIST}
                                                        className=""
                                                    />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="assets" />
                                                    </FormHelperText>
                                                </div>

                                                <div>
                                                    <Label>Status</Label>
                                                    <FormikSelect name="status" options={ProjectStatusOptions} />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="status" />
                                                    </FormHelperText>
                                                </div>

                                                {/* Has Assets */}
                                                <div className="">
                                                    <CustomSwitch name="hasCameras" label="Has Assets" />
                                                    <FormHelperText variant={'error'} />
                                                </div>

                                                {/* <div>
                                    <Label>Images</Label>
                                    <FieldArray
                                        name="files"
                                        render={(arrayHelpers) => {
                                            return (
                                                <div className="">
                                                    {values.files.map((_, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex gap-4 items-center mt-2 overflow-hidden"
                                                        >
                                                            <div className="w-full">
                                                                <div className="relative h-40">
                                                                    <img
                                                                        className="w-full h-ful bg-cover"
                                                                        src={URL.createObjectURL(values.files[index])}
                                                                        alt=""
                                                                    />
                                                                    <div
                                                                        className="absolute top-0 left-0 bottom-0 w-full h-full  flex items-center justify-center text-xs font-semibold text-white bg-black/40 cursor-pointer select-none transition-opacity duration-300 opacity-0 hover:opacity-100"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                    >
                                                                        Remove
                                                                    </div>
                                                                </div>
                                                                <div className="form-error">
                                                                    <ErrorMessage name={`assets.${index}`} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <div className="relative mt-2 w-full flex items-center justify-center h-20 ">
                                                        <Button variant={'shaded'} size={'xs'} className="">
                                                            <UploadIcon className="cursor-pointer" /> Upload Image
                                                        </Button>
                                                        <input
                                                            type="file"
                                                            className="absolute top-0 left-0 bottom-0 right-0 w-full h-full opacity-0"
                                                            onChange={(event) => {
                                                                const files = event.target.files;
                                                                if (files && files.length > 0) {
                                                                    setFieldValue(
                                                                        `files[${values.files.length}]`,
                                                                        files[0]
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    />
                                </div> */}
                                            </div>

                                            <Button
                                                type="submit"
                                                size={'lg'}
                                                className="mt-8 w-28"
                                                disabled={updateProjectFrom.isSubmitting}
                                            >
                                                {updateProjectFrom.isSubmitting ? (
                                                    <CircularProgress size={20} color={'inherit'} />
                                                ) : (
                                                    'Submit'
                                                )}
                                            </Button>
                                        </fieldset>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    )}
                </div>
            )}
            {activeTab === TabEnum.PROJECT_RENDER && <ProjectRendersDrawer projectId={projectId} />}
        </Drawer>
    );
};

export default ProjectEditDrawerForm;
