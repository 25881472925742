import { VariantProps, cva } from 'class-variance-authority';
import { DetailedHTMLProps, FC, ForwardedRef, HTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const dividerVariants = cva([], {
    variants: {
        orientation: {
            horizontal: ['border-b'],
            vertical: ['border-l '],
        },
        variant: {
            default: ['border-separator-color'],
            light: ['border-separator-color-light'],
            dark: ['border-separator-color-dark'],
        },
    },
    defaultVariants: {
        orientation: 'horizontal',
        variant: 'default',
    },
});

export interface DividerProps
    extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'children'>,
        VariantProps<typeof dividerVariants> {
    ref?: ForwardedRef<HTMLDivElement>;
}

const Divider = forwardRef((props: DividerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { className, orientation, variant, ...rest } = props;

    return <div ref={ref} {...rest} className={twMerge(dividerVariants({ orientation, variant, className }))} />;
});

export default Divider;
