import { CircularProgress } from '@library/loaders/components';
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface OverlayLoaderProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    show: boolean;
}

const OverlayLoader: FC<OverlayLoaderProps> = (props) => {
    const { show, className, children, ...rest } = props;

    if (!show) return null;

    return (
        <div
            {...rest}
            className={twMerge(
                'absolute top-0 left-0 w-full h-full bg-background-color text-primary flex flex-col items-center justify-center gap-6 z-[2]',
                className
            )}
        >
            <CircularProgress />

            {children}
        </div>
    );
};

export default OverlayLoader;
