import moment from 'moment';
import groupBy from 'lodash.groupby';
import { Drawer } from '@library/drawer';
import { useMemo, useState } from 'react';
import useNavName from 'modules/shared/hooks/useNavName';
import { IconButton } from '@library/buttons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Breadcrumb } from '@library/breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import IllustrationView from 'modules/shared/components/IllustrationView/IllustrationView';
import SiteGalleryGrid from '../ui/SiteGalleryGrid';
import { ISiteGalleryItem } from '@api/models/site-gallery-item.model';
import { getAllSiteGallery } from '@api/services/site-gallery.service';
import NoImagesIllustration from 'modules/shared/components/Illustrations/NoImagesIllustration';
import { PlusIcon } from '@icon/index';
import SiteGalleryAddDrawerForm from '../SiteGalleryAddDrawerForm/SiteGalleryAddDrawerForm';
import SiteGalleryDeleteDrawerForm from '../SiteGalleryDeleteDrawerForm/SiteGalleryDeleteDrawerForm';

import { produce } from 'immer';
import { CircularProgress } from '@library/loaders/components';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { PermissionEnum } from '@api/enum/permission.enum';

const ListSiteGallery = () => {
    const navigate = useNavigate();
    const { clientId, projectId } = useParams();
    const { hasPermission } = useAuthPermission();
    const queryClient = useQueryClient();

    const [addDrawer, setAddDrawer] = useState({ open: false, anchor: 'right' });
    const [confirmationDrawer, setConfirmationDrawer] = useState<{
        open: boolean;
        anchor: string;
        galleryItem: null | ISiteGalleryItem;
    }>({ open: false, anchor: 'right', galleryItem: null });

    const { clientName, projectName } = useNavName(clientId, projectId);

    // const navArray = [
    //     { label: 'Clients', onClick: () => navigate('/client') },
    //     { label: clientName, onClick: () => navigate(`/client?query=${clientId}`) },
    //     {
    //         label: 'Projects',
    //         onClick: () => navigate(`/client/${clientId}/project`),
    //     },
    //     {
    //         label: projectName,
    //         onClick: () => navigate(`/client/${clientId}/project?query=${projectId}`),
    //     },
    //     { label: 'Site Gallery', onClick: () => {} },
    // ];

    const { data, isLoading } = useQuery<ISiteGalleryItem[]>(
        ['clients', clientId, 'projects', projectId, 'site-gallery'],
        async () => getAllSiteGallery(clientId, projectId)
    );

    const siteGallery = useMemo(() => {
        if (isLoading) return [];

        const siteGallery = data;

        siteGallery.sort((r1, r2) => {
            return new Date(r2.createdAt).getTime() - new Date(r1.createdAt).getTime();
        });

        const siteGalleryMap = groupBy(siteGallery, (item) => {
            return moment(item.createdAt).format('YYYYMMDD');
        });

        return Object.keys(siteGalleryMap)
            .map((key) => ({ key, list: siteGalleryMap[key] }))
            .sort((r1, r2) => +r2.key - +r1.key);
    }, [isLoading, data]);

    const handleOnUpload = (data: ISiteGalleryItem[]) => {
        queryClient.setQueryData<ISiteGalleryItem[]>(
            ['clients', clientId, 'projects', projectId, 'site-gallery'],
            (prev) => {
                if (!prev) return;

                return produce(prev, (draft) => {
                    // draft.splice(index, 1);
                    return [...data, ...prev];
                });
            }
        );
    };

    if (isLoading)
        return (
            <PageTemplate>
                <PageHeading>Site Gallery</PageHeading>
                {/* <Navbar /> */}
                {/* <div className="pt-[var(--navbar-height)]"> */}
                <div>
                    {/* <Breadcrumb separator="/" items={navArray} /> */}

                    <div className="mt-6 flex justify-center items-center min-h-[600px]">
                        <CircularProgress />
                    </div>
                </div>
                {/* </div> */}
            </PageTemplate>
        );
    return (
        <PageTemplate>
            {/* <Navbar /> */}
            {/* <div className="pt-[var(--navbar-height)]"> */}
            <PageHeading>Site Gallery</PageHeading>

            {hasPermission(PermissionEnum.SITE_GALLERY_CREATE) && (
                <IconButton
                    className="fixed right-8 bottom-10 z-30"
                    onClick={() => setAddDrawer({ open: true, anchor: 'right' })}
                >
                    <PlusIcon />
                </IconButton>
            )}

            <IconButton
                className="fixed right-8 bottom-10 z-30"
                onClick={() => setAddDrawer({ open: true, anchor: 'right' })}
            >
                <PlusIcon />
            </IconButton>

            <div>
                {/* <Breadcrumb separator="/" items={navArray} /> */}

                <div>
                    <div>
                        {/* <div>
                            
                                <Input
                                    placeholder="Search Client"
                                    className="w-96 bg-white"
                                    onChange={(e) => handleSearch(e.target.value)}
                                    value={query}
                                />
                            
                        </div> */}
                    </div>
                    <div className="p-4">
                        {siteGallery.length > 0 ? (
                            <section className="space-y-6">
                                {siteGallery.map((row) => (
                                    <div key={row.key}>
                                        <div className="flex items-center gap-[10px]">
                                            <div className="text-lg font-medium pl-2">
                                                {moment(row.key, 'YYYYMMDD').format('DD MMM, YYYY')}
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            <SiteGalleryGrid
                                                siteGallery={row.list}
                                                onSiteGalleryItemDelete={(item: ISiteGalleryItem) => {
                                                    setConfirmationDrawer({
                                                        open: true,
                                                        anchor: 'right',
                                                        galleryItem: item,
                                                    });
                                                }}
                                                hasDeletePermission={hasPermission(PermissionEnum.SITE_GALLERY_DELETE)}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </section>
                        ) : (
                            <IllustrationView
                                className="mt-[10vh]"
                                illustration={<NoImagesIllustration />}
                                title="No Site Gallery yet"
                                message="No results found"
                            />
                        )}
                    </div>
                </div>
            </div>
            {/* </div> */}

            {/* Add Gallery Item Drawer */}
            <Drawer
                open={addDrawer.open}
                onClose={() => setAddDrawer({ open: false, anchor: 'right' })}
                anchor={addDrawer.anchor as any}
            >
                <SiteGalleryAddDrawerForm
                    onClose={() => setAddDrawer({ open: false, anchor: 'right' })}
                    clientId={clientId}
                    projectId={projectId}
                    // onUpload={() => console.log('on upload started')}
                    onUpload={handleOnUpload}
                />
            </Drawer>

            {/* Delete Gallery Item Drawer */}
            <Drawer
                open={confirmationDrawer.open}
                onClose={() =>
                    setConfirmationDrawer({
                        open: false,
                        anchor: 'right',
                        galleryItem: null,
                    })
                }
                anchor={confirmationDrawer.anchor as any}
            >
                <SiteGalleryDeleteDrawerForm
                    onClose={() =>
                        setConfirmationDrawer({
                            open: false,
                            anchor: 'right',
                            galleryItem: null,
                        })
                    }
                    galleryItem={confirmationDrawer.galleryItem}
                    projectId={projectId}
                    clientId={clientId}
                />
            </Drawer>
        </PageTemplate>
    );
};

export default ListSiteGallery;
