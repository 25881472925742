import { IClient } from '@api/models';
import useDialog from '@hooks/use-dialog';
import { Avatar, Button, Card, CircularProgress } from '@hyperflake/react-ui-library';
import CardBody from '@hyperflake/react-ui-library/cards/components/CardBody.js';
import CardHeader from '@hyperflake/react-ui-library/cards/components/CardHeader.js';
import useClientData from 'modules/clients/hooks/useClientData';
import { PageTemplate } from 'modules/shared/features/content';
import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import ClientDeleteConfirmationDrawer from '../ClientDeleteConfirmationDrawer/ClientDeleteConfirmationDrawer';

const ClientDetails = () => {
    // const { unit } = props;
    const { clientId } = useParams();
    const { client, isLoading } = useClientData(clientId);
    const confirmationsDrawerDialog = useDialog<IClient>();

    const data: { label: string; value: ReactNode }[] = [
        {
            label: 'Client Id',
            value: client?._id,
        },
        {
            label: 'Name',
            value: (
                <div className="flex items-center gap-2">
                    <Avatar src={client?.logoUrl} alt={client?.name} bgColor={client?.preferences?.primaryColor} />
                    {client?.name}
                </div>
            ),
        },

        {
            label: 'Storage',
            value: client?.storageName,
        },
        {
            label: 'Status',
            value: client?.status,
        },
    ];

    return (
        <PageTemplate>
            {isLoading ? (
                <div className="flex flex-col justify-center items-center gap-2  h-36">
                    <CircularProgress />
                    <div className="text-secondary font-medium text-base">Fetching Data... </div>
                </div>
            ) : (
                <>
                    <Card className="">
                        <CardHeader>
                            <h6>Client Details</h6>
                        </CardHeader>
                        <CardBody>
                            <div className="grid grid-cols-12 gap-6">
                                {data.map((row, index) => (
                                    <div key={index} className="col-span-6 items-start gap-x-8">
                                        <div className="text-sm text-grayscale-500">{row.label} </div>
                                        <h6 className="mt-[6px]">{row.value}</h6>
                                    </div>
                                ))}
                            </div>
                        </CardBody>
                    </Card>
                    <Card className="mt-4">
                        <CardHeader>
                            <h6>Client Delete</h6>
                        </CardHeader>
                        <CardBody>
                            <Button onClick={() => confirmationsDrawerDialog.show(client)}>Delete</Button>
                        </CardBody>
                    </Card>
                </>
            )}

            <ClientDeleteConfirmationDrawer
                open={confirmationsDrawerDialog.isOpen}
                onClose={confirmationsDrawerDialog.hide}
                client={confirmationsDrawerDialog.data}
            />
        </PageTemplate>
    );
};

export default ClientDetails;
