import { SupportTicketStatusEnum } from '@api/enum/support-ticket-status.enum';
import { ISupportTicket } from '@api/models';
import { updateSupporticketStatus } from '@api/services/support.service';
import OverflowTooltip from 'modules/shared/components/OverflowTooltip/OverflowTooltip';
import { Avatar } from '@library/avatar';
import { Label, Select } from '@library/forms';
import { SupportStatus } from 'modules/shared/helpers/shared.utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { FC } from 'react';
import { toast } from 'react-toastify';
import produce from 'immer';

interface TicketDetailProps {
    ticket: ISupportTicket;
    onUpdate: (updatedTicket: ISupportTicket) => void;
}

const TicketDetail: FC<TicketDetailProps> = (props) => {
    const { ticket, onUpdate } = props;
    const queryClient = useQueryClient();

    const updateTicketStatusMutation = useMutation((status: string) => updateSupporticketStatus(ticket._id, status));

    const handleSupportStatusChange = async (status: SupportTicketStatusEnum) => {
        const prevStatus = ticket.status;

        try {
            // Optimistic update
            onUpdate(
                produce(ticket, (draftTicket) => {
                    draftTicket.status = status;
                })
            );

            // API call to update status
            await updateTicketStatusMutation.mutateAsync(status);
            queryClient.prefetchQuery(['support-counter']);

            toast.success('Status updated successfully');
        } catch (err: any) {
            // Optimistic update
            onUpdate(
                produce(ticket, (draftTicket) => {
                    draftTicket.status = prevStatus;
                })
            );

            toast.error(err.data.message || 'Some error occured while updating the role, please try again.');
        }
    };

    return (
        <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <div className="text-grayscale-500 text-sm mb-[6px]">Created by</div>
                <div className="flex gap-2">
                    <div>
                        <Avatar src={ticket.user.dpUrl} alt={ticket.user.name} size={24} />
                    </div>

                    <h6 className="overflow-hidden">
                        <OverflowTooltip>{ticket.user.name}</OverflowTooltip>
                    </h6>
                </div>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <div className="text-grayscale-500 text-sm mb-[6px]">Time & Date</div>
                <h6>
                    <OverflowTooltip>
                        <div className="whitespace-nowrap">
                            {moment(ticket.createdAt, 'YYYYMMDDHHmmss').format('DD MMM YYYY')}
                        </div>
                    </OverflowTooltip>
                </h6>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <div className="text-grayscale-500 text-sm mb-[6px]">Help Topic</div>
                <h6>
                    <OverflowTooltip>{ticket.topic}</OverflowTooltip>
                </h6>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <div>
                    <Label htmlFor="role">Status</Label>

                    <div className="relative flex items-center">
                        <Select
                            className="pr-16"
                            value={ticket.status}
                            onChange={(e) => handleSupportStatusChange(e.target.value as SupportTicketStatusEnum)}
                            options={SupportStatus}
                        />
                    </div>
                </div>
            </div>
            <div className="col-span-12">
                <div className="text-grayscale-500 text-sm mb-[6px]">Subject</div>
                <h6>{ticket.subject}</h6>
            </div>
            <div className="col-span-12">
                <div className="text-grayscale-500 text-sm mb-[6px]">Details</div>
                <div className="text-sm font-medium">{ticket.detail}</div>
            </div>
        </div>
    );
};

export default TicketDetail;
