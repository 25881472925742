import useBoxDimensions from '@hooks/use-box-dimensions';
import { Backdrop, Button, Label } from '@hyperflake/react-ui-library';
import { CrossIcon } from '@icon/index';
import { Input } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo, useState } from 'react';
import ScrollLock from 'react-scrolllock';

interface ConfimationDialogProps {
    show: boolean;
    onClose: () => void;
    handleDelete: (val: string) => void;
    isLoading: boolean;
    name: string;
}
const modalAnimation = {
    variants: {
        hidden: {
            scale: 0.15,
            opacity: 0,
        },
        visible: {
            scale: 1,
            opacity: 1,
        },
    },
    transition: { duration: 0.35 },
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
};

const ConfirmationDialog = (props: ConfimationDialogProps) => {
    const { show, onClose, handleDelete, isLoading, name } = props;
    const [cameraName, setCameraName] = useState('');

    const { aspectRatio, headerHeight, containerWidth, containerHeight } = useMemo(() => {
        const headerHeight = 60;

        return {
            aspectRatio: 1.49,
            headerHeight: 60,
            containerWidth: window.innerWidth - 96,
            containerHeight: window.innerHeight - 96 - headerHeight,
        };
    }, []);

    const { refs, dimensions } = useBoxDimensions({ aspectRatio, containerWidth, containerHeight });
    return (
        <AnimatePresence initial={false} mode="wait">
            {show && (
                <>
                    <Backdrop ref={refs.setReference} show={show} onClick={onClose} className="z-[30]" />
                    <ScrollLock>
                        <div
                            className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-[100] mt-5"
                            style={{ height: dimensions.height + headerHeight, width: dimensions.width }}
                        >
                            <motion.div {...modalAnimation} onClick={(e) => e.stopPropagation()}>
                                <div
                                    className={`flex items-center bg-card-color px-6 rounded-t-2xl`}
                                    style={{ height: headerHeight }}
                                >
                                    <div className="flex items-center w-full gap-6">
                                        <h5 className="font-bold text-grayscale-900">Confirm Delete</h5>

                                        <button
                                            className="ml-auto grid place-items-center w-7 h-7 rounded-full bg-background-color hover-transition hover:bg-primary/10"
                                            onClick={onClose}
                                        >
                                            <CrossIcon className="text-grayscale-500" width="10" height="10" />
                                        </button>
                                    </div>
                                </div>
                                <div className="relative bg-white p-10">
                                    <div>
                                        <Label className="mt-2 inline-block">
                                            To Confirm Delete Write Camera Name. Example:{name}
                                        </Label>
                                        <Input
                                            placeholder="Enter Camera Name"
                                            // className="w-96 bg-white"
                                            onChange={(e) => setCameraName(e.target.value)}
                                            value={cameraName}
                                        />

                                        <div className="flex gap-2 mt-2">
                                            <Button
                                                disabled={isLoading}
                                                onClick={() => onClose()}
                                                variant="default"
                                                color={'primary'}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                disabled={cameraName !== name}
                                                className="w-20"
                                                onClick={() => handleDelete(cameraName)}
                                                variant="default"
                                                color={'primary'}
                                            >
                                                {isLoading ? (
                                                    <CircularProgress size={18} color={'inherit'} />
                                                ) : (
                                                    'Delete'
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </ScrollLock>
                </>
            )}
        </AnimatePresence>
    );
};

export default ConfirmationDialog;
