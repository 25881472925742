import { ProjectAssetEnum } from "@api/enum";
import capitalize from "lodash.capitalize";
import lowerCase from "lodash.lowercase";

export const FORMATTED_ASSET_LIST = Object.values(ProjectAssetEnum).map(
  (val) => ({
    label:
      val === ProjectAssetEnum.CAMERA_CCTV
        ? capitalize(lowerCase("live camera"))
        : capitalize(lowerCase(val)),
    value: val,
  })
);
