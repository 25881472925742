import ImageSlider from "modules/shared/components/ImageSlider/ImageSlider";
import { ICameraImage } from "@api/models";
import { FC, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
// import { DesktopMediaQuery, MobileOrTabletMediaQuery } from 'library/media-query';
// import ImageSlider from 'modules/dashboard/components/image-slider/ImageSlider';

const sliderAnimation = {
  variants: {
    hidden: {
      y: "200px",
    },
    visible: {
      y: 0,
    },
  },
  transition: { duration: 0.35 },
  initial: "hidden",
  animate: "visible",
  exit: "hidden",
};

interface OverlayImageSliderProps {
  show: boolean;
  onClose: () => void;
  images: ICameraImage[];
  selectedImage: ICameraImage;
  onSelectedImageChange: (image: ICameraImage) => void;
  selectedDate: string;
  onSelectedDateChange: (date: string) => void;
  startDate: string;
  endDate: string;
  isLoading: boolean;
}

const OverlayImageSlider: FC<OverlayImageSliderProps> = (props) => {
  const {
    show,
    onClose,
    images,
    selectedImage,
    onSelectedImageChange,
    selectedDate,
    onSelectedDateChange,
    startDate,
    endDate,
    isLoading,
  } = props;

  const sliderRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (!sliderRef.current) return;

      if (
        sliderRef.current === e.target ||
        sliderRef.current.contains(e.target)
      )
        return;

      onClose();
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <DesktopMediaQuery> */}
      <div>
        <AnimatePresence initial={false} mode="wait">
          {show && (
            <motion.div
              {...sliderAnimation}
              className="relative md:absolute z-10 bottom-0 left-0 right-0"
              onClick={(e) => e.stopPropagation()}
            >
              <ImageSlider
                ref={sliderRef}
                className="bg-background-color/60"
                images={images}
                selectedImage={selectedImage}
                onSelectedImageChange={onSelectedImageChange}
                selectedDate={selectedDate}
                onSelectedDateChange={onSelectedDateChange}
                startDate={startDate}
                endDate={endDate}
                isLoading={isLoading}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {/* </DesktopMediaQuery> */}

      {/* <MobileOrTabletMediaQuery>
                <ImageSlider
                    ref={sliderRef}
                    className='bg-background-color'
                    images={images}
                    selectedImage={selectedImage}
                    onSelectedImageChange={onSelectedImageChange}
                    selectedDate={selectedDate}
                    onSelectedDateChange={onSelectedDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    isLoading={isLoading}
                />
            </MobileOrTabletMediaQuery> */}
    </>
  );
};

export default OverlayImageSlider;
