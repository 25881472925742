import { ComponentPropsWithRef, ForwardedRef, forwardRef } from 'react';

interface PageHeadingProps extends ComponentPropsWithRef<'h3'> {}

const PageHeading = forwardRef((props: PageHeadingProps, ref: ForwardedRef<HTMLHeadingElement>) => {
    const { children, ...rest } = props;

    return (
        <h4 {...rest} ref={ref}>
            {children}
        </h4>
    );
});

export default PageHeading;
