import { AnimatePresence, motion } from 'framer-motion';
// import { FloatingPortal } from 'library/floating';
// import { ChevronDownIcon, CrossIcon, ExclamationCircleFilledIcon } from 'library/icons';
import { FC, useCallback, useState } from 'react';
import { useProgressTabContext } from '../context/progress-tab-context';
import { collapseAnimation, modalAnimation } from '../helpers/progress-tab.animations';
import { IProgressTabItem } from '../helpers/progress-tab.types';
import ProgressFileIcon from './ProgressFileIcon';
import { ChevronDownIcon, CrossIcon, ExclamationCircleFilledIcon } from '@icon/index';
import { FloatingPortal } from '@floating-ui/react';

interface ProgressTabModalProps {}

const ProgressTabModal: FC<ProgressTabModalProps> = () => {
    const { items, removeItem, reset } = useProgressTabContext();

    const [isExpanded, setIsExpanded] = useState<boolean>(true);

    const handleCancel = useCallback(
        (item: IProgressTabItem) => {
            if (!item.isCompleted) {
                item.onCancel();
            }

            removeItem(item._id);
        },
        [removeItem]
    );

    const progressClass = useCallback((item: IProgressTabItem) => {
        if (item.isCompleted) return 'bg-green-500';
        else if (item.isErrored) return 'bg-danger-500';
        else return 'bg-primary';
    }, []);

    const handleCloseClick = useCallback(() => {
        reset();

        setIsExpanded(true);
    }, [reset]);

    return (
        <FloatingPortal root={document.getElementById('modal-root')}>
            <AnimatePresence initial={false} mode='wait'>
                {items.length > 0 && (
                    <motion.div
                        {...modalAnimation}
                        className='fixed right-10 bottom-10 w-[346px] overflow-y-auto z-[70] rounded-[10px] bg-floating-color border border-[#F4F5F7] dark:border-grayscale-50 shadow-[0px_2px_32px_-4px_rgba(3,17,22,0.1)] dark:shadow-[0px_2px_32px_-4px_rgba(3,17,22,0.8)] outline-none'
                    >
                        <div className='flex items-center p-4'>
                            <div className='text-sm font-medium'>Progress Tab</div>

                            <div className='ml-auto flex items-center gap-2'>
                                <button
                                    className='grid place-items-center w-4 h-4 text-grayscale-500 rotate-180'
                                    aria-expanded={isExpanded}
                                    onClick={() => setIsExpanded((prev) => !prev)}
                                >
                                    <ChevronDownIcon className='collapse-arrow' />
                                </button>
                                <button className='text-xs text-grayscale-500 font-medium' onClick={handleCloseClick}>
                                    Close
                                </button>
                            </div>
                        </div>

                        <AnimatePresence initial={false} mode='wait'>
                            {isExpanded && (
                                <motion.div
                                    {...collapseAnimation}
                                    className='divide-y divide-separator-color-light border-t border-separator-color-light overflow-hidden'
                                >
                                    {items.map((item) => (
                                        <div key={item._id} className='p-4'>
                                            <div className='flex items-center gap-3'>
                                                <div>
                                                    <ProgressFileIcon className='w-10' extension={item.extension} />
                                                </div>

                                                <div className='w-full overflow-hidden'>
                                                    <div className='text-sm font-semibold overflow-hidden whitespace-nowrap text-ellipsis'>
                                                        {item.title}
                                                    </div>
                                                    <div className='text-xs text-grayscale-500 mt-1'>{item.text}</div>
                                                </div>
                                                {item.isCancellable && (
                                                    <button className='ml-auto' onClick={() => handleCancel(item)}>
                                                        <CrossIcon
                                                            className='text-grayscale-500'
                                                            width='10'
                                                            height='10'
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                            <div className='flex items-center gap-3 mt-2'>
                                                {/* Empty div  */}
                                                <div>
                                                    <div className='w-10' />
                                                </div>

                                                <div className='relative h-1 w-full overflow-hidden bg-[#EDEFF0] rounded-2xl'>
                                                    <div
                                                        className={[
                                                            'absolute top-0 left-0 h-full rounded-2xl',
                                                            progressClass(item),
                                                        ].join(' ')}
                                                        style={{ width: `${item.progress}%` }}
                                                    ></div>
                                                </div>

                                                {item.isErrored ? (
                                                    <ExclamationCircleFilledIcon className='text-danger-500' />
                                                ) : (
                                                    <div className='text-xs text-grayscale-500'>{item.progress}%</div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </motion.div>
                )}
            </AnimatePresence>
        </FloatingPortal>
    );
};

export default ProgressTabModal;
