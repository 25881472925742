import { FC } from 'react';
import { Input } from '@library/forms';
import { useFormikContext } from 'formik';
import { ChevronDownIcon } from '@icon/index';
import { List, ListItem } from '@library/list';
import { FloatingContextProvider } from '@library/floating';
import { CountryDialCodeList } from 'modules/shared/helpers/shared.constants';
import { FormikInput } from 'modules/shared/features/formik';

interface IPhoneDetails {}

const PhoneDetails: FC<IPhoneDetails> = () => {
    const { setFieldValue, values } = useFormikContext<any>();

    const handleSelect = (val: any) => {
        setFieldValue('phone', {
            ...values.phone.countryCode,
            dialCode: val.dialCode,
            countryCode: val.countryCode,
        });
    };

    return (
        <div className="grid grid-cols-12 gap-2 relative">
            <FloatingContextProvider>
                {({ context, refs, getReferenceProps, getFloatingProps }) => (
                    <>
                        <div
                            {...getReferenceProps()}
                            ref={refs.setReference}
                            className=" pl-6 absolute inset-0 flex items-center justify-center w-20"
                        >
                            <div className="flex items-center gap-2 p-2 cursor-pointer">
                                <img
                                    className="w-5 h-5 rounded-full object-cover object-center border border-grayscale-100"
                                    src={`/flags/${values.phone.countryCode}.svg`}
                                    alt=""
                                />
                                <div className="text-sm font-medium">{values.phone.dialCode}</div>
                                <ChevronDownIcon className="text-grayscale-400 h-8 w-8" />
                            </div>
                        </div>

                        {context.open && (
                            <div
                                {...getFloatingProps()}
                                ref={refs.setFloating}
                                style={context.floatingStyles}
                                className="shadow-lg z-[1]"
                            >
                                <List className="h-96 overflow-auto">
                                    {CountryDialCodeList.map((row, i) => (
                                        <ListItem
                                            key={row.countryCode}
                                            // ref={(el) => (itemsRef.current[i] = el)}
                                            className={`cursor-pointer ${
                                                row.countryCode === values.phone.countryCode
                                                    ? 'floating-list-item-active'
                                                    : 'text-grayscale-900'
                                            }`}
                                            onClick={() => {
                                                handleSelect(row);
                                                context.onOpenChange(false);
                                            }}
                                        >
                                            <div>
                                                <img
                                                    className="bg-grayscale-100 min-w-[1.25rem] w-5 h-5 rounded-full object-cover object-center border border-grayscale-100"
                                                    src={row.flag}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="text-sm">
                                                {row.countryName}{' '}
                                                <span className="text-xs text-grayscale-500"> {row.dialCode}</span>
                                            </div>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        )}
                    </>
                )}
            </FloatingContextProvider>
            <div className="col-span-4">
                <Input type="text" className="cursor-default" readOnly />
            </div>
            <div className="col-span-8">
                <FormikInput name="phone.number" type="number" />
            </div>
        </div>
    );
};

export default PhoneDetails;
