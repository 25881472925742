// import { Themes } from 'modules/shared/constants/themes';
// import useAuthSelector from 'modules/shared/hooks/useAuthSelector';
// import { getCurrentAccount } from 'api/services';
// import { setPrimaryColor, setTheme } from 'modules/shared/utils/theme-utils';
// import { authActions, authListenerMiddleware } from 'store/auth';
import { getCurrentAccount } from '@api/services/user.service';
import useAuthSelector from '@hooks/use-auth-selector';
import { authActions, authListenerMiddleware } from '@store/auth';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

const useCurrentUser = () => {
    const queryClient = useQueryClient();

    const dispatch = useDispatch();

    const user = useAuthSelector();

    const currentAccountQuery = useQuery(['profileUser'], () => getCurrentAccount());

    /******************** Logout Events ********************/

    useEffect(() => {
        authListenerMiddleware.startListening({
            actionCreator: authActions.logout,
            effect: () => {
                // Reset everything on logout
                // setTheme('LIGHT');
                // setPrimaryColor('#0052CC');
                // Reset all queries
                queryClient.removeQueries();
            },
        });
    }, [queryClient]);

    /******************** Set User ********************/
    useEffect(() => {
        if (currentAccountQuery.isLoading) return;

        // const { user, client } = currentAccountQuery.data;

        // dispatch(authActions.setUserAndClient({ user: user.toObject(), client: client.toObject() }));
        dispatch(authActions.setToken(currentAccountQuery.data.groupId));
    }, [currentAccountQuery.isLoading, currentAccountQuery.data, dispatch]);

    /******************** Set Theme & Primary Color ********************/

    return {
        user,
        isLoading: currentAccountQuery.isLoading,
    };
};

export default useCurrentUser;
