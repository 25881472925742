import {
    ReferenceType,
    autoUpdate,
    arrow as floatingUiArrow,
    flip as floatingUiFlip,
    hide as floatingUiHide,
    offset as floatingUiOffset,
    size as floatingUiSize,
    safePolygon,
    useClick,
    useDismiss,
    useFloating,
    useHover,
    useInteractions,
} from '@floating-ui/react';
import useControlledState from '@library/common/hooks/use-controlled-state';
import { useEffect, useMemo, useRef } from 'react';
import { FloatingElementOptions, FloatingElementReturn } from '../helpers/floating.types';

const useFloatingElement = <RT extends ReferenceType = ReferenceType>(
    options: FloatingElementOptions = {}
): FloatingElementReturn<RT> => {
    const {
        open: controlledOpen,
        setOpen: controlledSetOpen,
        placement = 'bottom-end',
        offset = 8,
        triggers = ['click'],
        interactive = true,
        showArrow = false,
        closeOnScroll = false,
    } = options;

    const [open, setOpen] = useControlledState({
        initialState: false,
        value: controlledOpen,
        setValue: controlledSetOpen,
    });

    const enabled = useMemo(() => {
        return {
            click: triggers.includes('click'),
            hover: triggers.includes('hover'),
        };
    }, [triggers]);

    const arrowRef = useRef<SVGSVGElement>(null);

    const arrowPadding = ['top', 'right', 'bottom', 'left'].includes(placement) ? 0 : 16;

    const { refs, context, middlewareData } = useFloating<RT>({
        whileElementsMounted: autoUpdate,
        placement: placement,
        middleware: [
            floatingUiOffset(offset),
            floatingUiFlip({ padding: 20 }),
            floatingUiSize({
                apply: ({ availableWidth, availableHeight, elements }) => {
                    Object.assign(elements.floating.style, {
                        maxWidth: `${availableWidth}px`,
                        maxHeight: `${availableHeight}px`,
                    });
                },
                padding: 20,
            }),
            showArrow && floatingUiArrow({ element: arrowRef, padding: arrowPadding }),
            floatingUiHide({
                padding: 20,
            }),
        ],
        open: open,
        onOpenChange: setOpen,
    });

    useEffect(() => {
        const hidden = middlewareData.hide?.referenceHidden;

        if (hidden) {
            setOpen(false);
        }
    }, [middlewareData.hide?.referenceHidden, setOpen]);

    const click = useClick(context, { enabled: enabled.click });
    const hover = useHover(context, { enabled: enabled.hover, handleClose: interactive ? safePolygon() : null });

    const dismiss = useDismiss(context, { ancestorScroll: closeOnScroll });

    const { getReferenceProps, getFloatingProps } = useInteractions([click, hover, dismiss]);

    return {
        context,
        refs: { ...refs, arrow: arrowRef },
        getReferenceProps,
        getFloatingProps,
    };
};

export default useFloatingElement;
