const Icon = (props: any) => {
    return (
        <svg width={16} height={16} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.157 2.687H2.59A1.59 1.59 0 001 4.277V15.41A1.59 1.59 0 002.59 17h11.133a1.59 1.59 0 001.59-1.59V9.842"
                stroke="#465065"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.12 1.494a1.687 1.687 0 012.386 2.386l-7.554 7.554-3.181.795.795-3.18 7.554-7.555z"
                stroke="#465065"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
