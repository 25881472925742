const Icon = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 32 32" fill="none" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.668 32H31.8928V25.7461H25.668V32Z" fill="#DF542C" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.9729 4.5779C8.7808 4.5779 4.55679 8.82171 4.55679 14.0379C4.55679 19.2541 8.7808 23.4976 13.9729 23.4976C19.1651 23.4976 23.3896 19.2541 23.3896 14.0379C23.3896 8.82171 19.1651 4.5779 13.9729 4.5779ZM13.9729 28.0755C6.268 28.0755 0 21.7785 0 14.0379C0 6.29727 6.268 0 13.9729 0C21.6779 0 27.9461 6.29727 27.9461 14.0379C27.9461 21.7785 21.6779 28.0755 13.9729 28.0755Z"
                fill="#DF542C"
            />
        </svg>
    );
};
export default Icon;
