// import { ConsoleUserRoleEnum } from "@api/enum";
// import { TRootState } from "@store/index";
// import { useSelector } from "react-redux";

// const useAuthPermission = () => {
//   const loggedUser = useSelector((state: TRootState) => state.profile);

//   // const isAdmin = user.role === UserRoleEnum.ADMIN;

//   const isAdmin = loggedUser.info.role === ConsoleUserRoleEnum.ADMIN;
//   const isViewer = loggedUser.info.role === ConsoleUserRoleEnum.VIEWER;

//   return {
//     // hasEditPermission: isEditor || isAdmin,

//     hasAdminPermission: isAdmin,
//     hasViewerPermission: isViewer,
//   };
// };

// export default useAuthPermission;

import { useSelector } from 'react-redux';
import { TRootState } from '@store/index';
// import { PermissionEnum, UserRoleEnum } from 'api/enums';
import { useCallback, useMemo } from 'react';
import { PermissionMap } from '@api/helpers/constants';
import { PermissionEnum } from '@api/enum/permission.enum';
import { ConsoleUserRoleEnum } from '@api/enum';
// import { PermissionMap } from 'api/helpers';

const useAuthPermission = () => {
    const { info: user } = useSelector((state: TRootState) => state.profile);

    const userPermissions = useMemo(() => {
        return PermissionMap[user.role];
    }, [user.role]);

    const hasPermission = useCallback(
        (permission: PermissionEnum): boolean => {
            return userPermissions.includes(permission);
        },
        [userPermissions]
    );

    const hasPermissions = useCallback(
        (permissions: PermissionEnum[]): boolean => {
            return permissions.every((p) => userPermissions.includes(p));
        },
        [userPermissions]
    );

    //TODO need to remove this
    // const isEditor = user.role === UserRoleEnum.EDITOR;
    // const isAdmin = user.role === UserRoleEnum.ADMIN;
    const isAdmin = user.role === ConsoleUserRoleEnum.ADMIN;
    const isViewer = user.role === ConsoleUserRoleEnum.VIEWER;

    return {
        // hasEditPermission: isEditor || isAdmin,
        // hasAdminPermission: isAdmin,
        // hasAdminPermission: isAdmin,
        // hasViewerPermission: isViewer,
        hasPermission,
        hasPermissions,
    };
};

export default useAuthPermission;
