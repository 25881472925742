import * as Yup from 'yup';
import useUniqueMutation from './useUniqueMutation';
import moment from 'moment';
import { useMemo } from 'react';
import useUserData from './useUserData';
import { useMutation } from '@tanstack/react-query';
import { IUser } from '@api/models';
import { updateUser } from '@api/services/user.service';

const useUserEditForm = (clientId: string, userId: string) => {
    const { uniqueEmailMutation, uniqueUsernameMutation } = useUniqueMutation();
    const hasPhoneNumber = (data: any) =>
        data.phone && data.phone.number && data.phone.dialCode && data.phone.countryCode;

    const { data, isLoading } = useUserData(clientId, userId);

    const updateUsertMutation = useMutation((payload: IUser) => updateUser(clientId, userId, payload));

    const schema = Yup.object().shape({
        name: Yup.string().trim().required('This field is required'),
        username: Yup.string()
            .trim()
            .required('This field is required')
            .test(
                'Unique Username',
                'Username already in use', // <- key, message
                function (value) {
                    return new Promise(async (resolve) => {
                        try {
                            if (data?.username === value) return resolve(true);
                            let res = await uniqueUsernameMutation.mutateAsync({
                                username: value,
                            });
                            if (res.status === 200) {
                                resolve(true);
                            }
                        } catch (e) {
                            resolve(false);
                        }
                    });
                }
            ),
        phone: Yup.object().shape({
            dialCode: Yup.string().trim(),
            countryCode: Yup.string().trim(),
            number: Yup.string().trim(),
            fullNumber: Yup.string(),
        }),
        email: Yup.string()
            .email('Please enter a valid email address.')
            .required('This field is required')
            .trim()
            .test(
                'Unique Email',
                'Email already in use', // <- key, message
                function (value) {
                    return new Promise(async (resolve) => {
                        try {
                            if (data?.email === value) return resolve(true);
                            let res = await uniqueEmailMutation.mutateAsync({ email: value });
                            if (res.status === 200) {
                                resolve(true);
                            }
                        } catch (e) {
                            resolve(false);
                        }
                    });
                }
            ),
        role: Yup.string(),
        dob: Yup.date().nullable(),
        designation: Yup.string().trim(),
        tags: Yup.array().typeError('tags must be an array').of(Yup.string()),
        preferences: Yup.object().shape({
            timezone: Yup.string().trim().required('This field is required'),
            primaryColor: Yup.string().trim(),
            theme: Yup.string().trim(),
            isEmailNotificationEnabled: Yup.boolean(),
            show4k: Yup.boolean(),
            showZoomed: Yup.boolean(),
            showTimestamp: Yup.boolean(),
        }),
        projects: Yup.array().typeError('tags must be an array').of(Yup.string()),
    });

    const initialValues = useMemo(() => {
        if (!data)
            return {
                name: '',
                username: '',
                password: '',
                phone: {
                    dialCode: '+966',
                    countryCode: 'sa',
                    number: '',
                },
                email: '',
                role: 'VIEWER',
                dob: moment().toDate(),
                designation: '',
                tags: [],
                preferences: {
                    timezone: '',
                    primaryColor: '#0052CC',
                    theme: 'LIGHT',
                    isEmailNotificationEnabled: false,
                    show4k: false,
                    showZoomed: false,
                    showTimestamp: false,
                },
                projects: [],
                status: '',
            };

        return {
            name: data.name,
            username: data.username,
            password: data.password,
            phone:
                data && hasPhoneNumber(data)
                    ? {
                          dialCode: data.phone?.dialCode,
                          countryCode: data.phone?.countryCode,
                          number: data.phone?.number,
                          fullNumber: data.phone?.dialCode.trim() || '' + data.phone?.number.trim() || '',
                      }
                    : {
                          number: '',
                          fullNumber: '',
                          dialCode: '+966',
                          countryCode: 'sa',
                      },
            email: data.email,
            role: data.role,
            dob: data.dob ? moment(data.dob, 'YYYYMMDD').toDate() : null,
            designation: data.designation || '',
            tags: data.tags || [],
            preferences: {
                timezone: data.preferences?.timezone,
                primaryColor: data.preferences?.primaryColor,
                theme: data.preferences?.theme,
                isEmailNotificationEnabled: data.preferences?.isEmailNotificationEnabled,
                show4k: data.preferences?.show4k,
                showZoomed: data.preferences?.showZoomed,
                showTimestamp: data.preferences?.showTimestamp,
            },
            projects: data.projects?.map((project) => (typeof project === 'string' ? project : project?._id)),
            status: data.status,
        };
    }, [data]);

    const serializeData = (values: any) => {
        const temp = { ...values };

        // convert dob
        temp.dob = moment(temp.dob).format('YYYYMMDD');

        // handle phone number
        const hasPhoneNumber = values.phone && values.phone.number && values.phone.dialCode && values.phone.countryCode;
        if (hasPhoneNumber) {
            temp.phone.fullNumber = temp.phone.dialCode + values.phone.number;
        } else {
            temp.phone = null;
        }
        return temp;
    };

    const submit = async (payload: any) => {
        const serializedData = serializeData(payload);
        const data = await updateUsertMutation.mutateAsync(serializedData);

        return data;
    };

    return {
        schema,
        initialValues,
        UserData: data,
        userLoading: isLoading,
        submit,
        isSubmitting: updateUsertMutation.isLoading,
    };
};

export default useUserEditForm;
