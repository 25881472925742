const Icon = (props: any) => {
    return (
        <svg
            className="svg-icon"
            style={{
                width: '1em',
                height: '1em',
                verticalAlign: 'middle',
            }}
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            overflow="hidden"
            {...props}
        >
            <path
                d="M861.867 264.533c48.469 69.803 76.8 154.283 76.8 247.467 0 234.53-191.028 426.325-426.667 426.667V1024c282.77 0 512-230.153 512-512 0-103.996-29.542-198.997-76.8-281.6v8.533c25.165 1.929 48.538-11.64 51.2-34.133 9.523-23.16-3.977-46.703-25.6-51.2l-119.467-34.133c-8.414-1.588-19.95.273-25.6 8.533-13.303 2.697-20.667 11.802-25.6 25.6l-25.6 110.933c-9.54 25.43 3.968 48.896 25.6 51.2 23.851 9.933 47.292-3.626 51.2-25.6l8.534-25.6zM162.133 759.467c-48.469-69.803-76.8-154.283-76.8-247.467C85.333 277.47 276.361 85.675 512 85.333V0C229.23 0 0 230.153 0 512c0 103.996 29.542 198.997 76.8 281.6v-8.533c-25.165-1.929-48.538 11.64-51.2 34.133-9.523 23.16 3.977 46.703 25.6 51.2l119.467 34.133c8.414 1.588 19.95-.273 25.6-8.533 13.303-2.697 20.667-11.802 25.6-25.6l25.6-110.933c9.54-25.43-3.968-48.896-25.6-51.2-23.851-9.933-47.292 3.626-51.2 25.6l-8.534 25.6z"
                fill="#4990EE"
            />
        </svg>
    );
};

export default Icon;
