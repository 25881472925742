import { getAllSupportTicket } from '@api/services/support.service';
import useDataQuery from '@hooks/use-data-query';
import { produce } from 'immer';

const useSupportListData = (clientName: string) => {
    const supportListQuery = useDataQuery(['All Support Tickets', clientName], () => getAllSupportTicket(clientName));
    const onDelete = (ticketId: string) => {
        supportListQuery.setData((prevList) => {
            return produce(prevList, (draftList) => {
                const index = draftList?.findIndex((row) => row._id === ticketId);

                draftList.splice(index, 1);

                return draftList;
            });
        });
    };

    return {
        data: supportListQuery.data,
        isLoading: supportListQuery.isLoading,
        onDelete,
    };
};
export default useSupportListData;
