import { FC, useMemo } from 'react';
import PickerHeader from './PickerHeader';
import { twMerge } from 'tailwind-merge';
import { getYearsOnCalendar } from '../helpers/datepicker.utils';

const COUNT = 15;

interface YearPickerProps {
    selectedYear: number;
    viewYear: number;
    onViewYearChange: (year: number) => void;
    onMonthPickerSelect: () => void;
    minYear?: number;
    maxYear?: number;
}

const YearPicker: FC<YearPickerProps> = (props) => {
    const { selectedYear, viewYear, onViewYearChange, onMonthPickerSelect, minYear, maxYear } = props;

    const yearsOnCalendar = useMemo(() => {
        return getYearsOnCalendar(viewYear, COUNT);
    }, [viewYear]);

    const prevDisabled = useMemo(() => {
        if (!minYear) return false;

        return yearsOnCalendar[0] <= minYear;
    }, [minYear, yearsOnCalendar]);

    const nextDisabled = useMemo(() => {
        if (!maxYear) return false;

        return yearsOnCalendar[yearsOnCalendar.length - 1] >= maxYear;
    }, [maxYear, yearsOnCalendar]);

    const isSameYearAsSelected = (year: number) => {
        return selectedYear === year;
    };

    const isLessThanMinYear = (year: number) => {
        if (!minYear) return false;

        return year < minYear;
    };

    const isGreaterThanMaxYear = (year: number) => {
        if (!maxYear) return false;

        return year > maxYear;
    };

    const handlePrevClick = () => {
        if (prevDisabled) {
            return;
        }

        onViewYearChange(viewYear - COUNT);
    };

    const handleNextClick = () => {
        if (nextDisabled) {
            return;
        }

        onViewYearChange(viewYear + COUNT);
    };

    const handleSelect = (year: number) => {
        if (isGreaterThanMaxYear(year) || isLessThanMinYear(year)) {
            return;
        }

        onViewYearChange(year);
        onMonthPickerSelect();
    };

    const dynamicClass = (year: number) => {
        let className = 'bg-floating-color text-grayscale-900 cursor-pointer hover:bg-primary/5';

        if (isSameYearAsSelected(year)) {
            className = 'bg-primary text-primary-contrast cursor-pointer hover:bg-primary';
        }

        if (isGreaterThanMaxYear(year) || isLessThanMinYear(year)) {
            className = 'bg-floating-color text-grayscale-400 cursor-not-allowed ';
        }

        return className;
    };

    return (
        <>
            <PickerHeader
                content={
                    <>
                        {yearsOnCalendar[0]} - {yearsOnCalendar[yearsOnCalendar.length - 1]}
                    </>
                }
                isPrevDisabled={prevDisabled}
                onPrevClick={handlePrevClick}
                isNextDisabled={nextDisabled}
                onNextClick={handleNextClick}
            />

            <div className="grid grid-cols-3 gap-2 mt-3">
                {yearsOnCalendar.map((year, i) => (
                    <div key={i} className="col-span-1">
                        <div
                            className={twMerge(
                                'flex items-center justify-center h-9 text-[13px] font-medium rounded-lg',
                                dynamicClass(year)
                            )}
                            onClick={() => handleSelect(year)}
                        >
                            {year}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default YearPicker;
