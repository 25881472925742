import { VariantProps, cva } from 'class-variance-authority';
import { DetailedHTMLProps, FC, ForwardedRef, HTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import styles from './Card.module.scss';

const cardVariants = cva(['bg-card-color rounded-lg'], {
    variants: {
        elevation: {
            '0': [styles['elevation-0']],
            '1': ['shadow-sm', styles['elevation-1']],
            '2': ['shadow', styles['elevation-2']],
            '3': ['shadow-md', styles['elevation-3']],
            '4': ['shadow-lg', styles['elevation-4']],
            '6': ['shadow-xl', styles['elevation-6']],
            '8': ['shadow-2xl', styles['elevation-8']],
        },
    },
    defaultVariants: {
        elevation: '0',
    },
});

interface CardProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
        VariantProps<typeof cardVariants> {
    ref?: ForwardedRef<HTMLDivElement>;
}
const Card = forwardRef<HTMLDivElement, CardProps>((props: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, elevation, className, ...rest } = props;

    return (
        <div {...rest} ref={ref} className={twMerge(cardVariants({ elevation, className }))}>
            {children}
        </div>
    );
});

export default Card;
