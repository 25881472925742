import { publicApiRequestPendingCount } from '@api/services/public-request.service';
import { supportOpenedCounter } from '@api/services/support.service';
import useDataQuery from '@hooks/use-data-query';

export const useSupportCounterData = () => {
    const supportCounterQuery = useDataQuery(['support-counter'], () => supportOpenedCounter());
    const publicApiCounterQuery = useDataQuery(['requestApi-counter'], () => publicApiRequestPendingCount());

    return {
        supportCounter: supportCounterQuery.data,
        publicApiCounter: publicApiCounterQuery.data,
        // refetch: supportCounterQuery.refetch(),
    };
};
