import { FC } from "react";
import { ErrorMessage } from "formik";
import { FormHelperText, Label } from "@library/forms";
import CustomInput from "modules/shared/components/FormFields/CustomInput";

interface VimeoFormFieldsProps {}

const VimeoFormFields: FC<VimeoFormFieldsProps> = (props) => {
  return (
    <div>
      <Label htmlFor="url">Vimeo URL</Label>
      <CustomInput name="url" placeholder="Enter link here" />
      <FormHelperText variant={"error"}>
        <ErrorMessage
          component="div"
          className="form-error-message"
          name="url"
        />
      </FormHelperText>
    </div>
  );
};

export default VimeoFormFields;
