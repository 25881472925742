import { VariantProps, cva } from 'class-variance-authority';
import { DetailedHTMLProps, FC, ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const inputVariants = cva(
    [
        'flex items-center',
        'w-full',
        'font-medium text-sm',
        'bg-transparent border border-grayscale-300 ',
        'placeholder:text-grayscale-400',
        'focus:border-primary focus:outline focus:outline-2 focus:outline-primary/10',
    ],
    {
        variants: {
            size: {
                xs: ['text-xs px-[14px] h-8 rounded-md'],
                sm: ['text-sm px-4 h-9 rounded-md'],
                normal: ['text-sm px-4 h-10 rounded-md'],
                lg: ['text-base px-[18px] h-11 rounded-lg'],
                xl: ['text-base px-5 h-12 rounded-lg'],
            },
            invalid: {
                true: ['border-danger outline outline-danger/10 focus:border-danger focus:outline-danger/10'],
                false: [],
            },
            valid: {
                true: ['border-success outline outline-success/10 focus:border-success focus:outline-success/10'],
                false: [],
            },
            disabled: {
                true: ['bg-grayscale-100 opacity-60'],
                false: [],
            },
        },
        defaultVariants: {
            size: 'normal',
            disabled: false,
            invalid: false,
            valid: false,
        },
    }
);

interface InputProps
    extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size'>,
        VariantProps<typeof inputVariants> {
    ref?: ForwardedRef<HTMLInputElement>;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { size, invalid, valid, disabled, className, ...rest } = props;

    return (
        <input
            {...rest}
            ref={ref}
            disabled={disabled}
            className={twMerge(inputVariants({ size, invalid, valid, disabled, className }))}
        />
    );
});

export default Input;
