import { getResellerIdFromRedux } from "modules/shared/helpers/shared.utils";
/* -------------------------------------------------------------------------------------------------
 * Get all
 * -----------------------------------------------------------------------------------------------*/

import { ISiteGalleryItem } from "@api/models/site-gallery-item.model";
import http from "httpConfig/http";

export const getAllSiteGallery = async (
  clientId: string,
  projectId: string
) => {
  const { data } = await http.get(
    `${
      import.meta.env.VITE_APP_API_URL
    }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/site-gallery`
  );

  return data as ISiteGalleryItem[];
};

/* -------------------------------------------------------------------------------------------------
 * Get by id
 * -----------------------------------------------------------------------------------------------*/

export const getSiteGalleryItemById = async (
  clientId: string,
  projectId: string,
  itemId: string
) => {
  const { data } = await http.get(
    `${
      import.meta.env.VITE_APP_API_URL
    }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/site-gallery/${itemId}`
  );

  return data as ISiteGalleryItem;
};

/* -------------------------------------------------------------------------------------------------
 * Create
 * -----------------------------------------------------------------------------------------------*/

export const uploadSiteGallery = async (
  clientId: string,
  projectId: string,
  payload: FormData,
  config: {
    signal?: AbortSignal;
    onUploadProgress?: (progress: number) => void;
  }
) => {
  const { data } = await http.post(
    `${
      import.meta.env.VITE_APP_API_URL
    }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/site-gallery`,
    payload,
    {
      signal: config.signal,
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;

        config?.onUploadProgress?.(Math.trunc(progress));
      },
    }
  );

  return data as ISiteGalleryItem[];
};
