import { IPublicRequest } from '@api/models/public-request.model';
import { getAllPublicApiRequest } from '@api/services/public-request.service';
import useDataQuery from 'modules/shared/hooks/use-data-query';
import { produce } from 'immer';

const usePublicApiRequestListData = () => {
    const publicRequestListQuery = useDataQuery(['public-request'], () => getAllPublicApiRequest());

    const updatePublicApiRequest = async (publicApiRequestId: string, updates: IPublicRequest) => {
        publicRequestListQuery.setData((prevList: IPublicRequest[]) => {
            return produce(prevList, (draftList) => {
                const index = draftList.findIndex((row) => row._id === publicApiRequestId);

                draftList[index] = updates;

                return draftList;
            });
        });
    };

    const deletePublicApiRequest = async (publicApiRequestId: string) => {
        publicRequestListQuery.setData((prevList: IPublicRequest[]) => {
            return produce(prevList, (draftList) => {
                const index = draftList.findIndex((row) => row._id === publicApiRequestId);

                draftList.splice(index, 1);

                return draftList;
            });
        });
    };

    return {
        isLoading: publicRequestListQuery.isLoading,
        publicRequest: publicRequestListQuery.data,
        updatePublicApiRequest,
        deletePublicApiRequest,
    };
};

export default usePublicApiRequestListData;
