import useResizeObserver from "@react-hook/resize-observer";
import { MutableRefObject, useCallback, useRef, useState } from "react";

const useBoundingClientRect = (): [
  (node: any) => void,
  DOMRect,
  MutableRefObject<any>
] => {
  const ref = useRef(null);
  const [boundingClientRect, setBoundingClientRect] = useState<DOMRect>(
    new DOMRect()
  );

  const refSetter = useCallback((node: any) => {
    if (node !== null) {
      ref.current = node;
      setBoundingClientRect(node.getBoundingClientRect());
    }
  }, []);

  useResizeObserver(ref.current, (entry) => {
    setBoundingClientRect(entry.target.getBoundingClientRect());
  });

  return [refSetter, boundingClientRect, ref];
};

export default useBoundingClientRect;
