import { ICamera } from '@api/models/camera.model';
import { Button } from '@hyperflake/react-ui-library';
import { Drawer } from '@library/drawer';
import { Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from 'httpConfig/http';
import { produce } from 'immer';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface ICameraDeleteConfirmationDrawer {
    open: boolean;
    onClose: () => void;
    camera: ICamera | null;
}

const CameraDeleteConfirmationDrawer: FC<ICameraDeleteConfirmationDrawer> = (props) => {
    const { onClose, camera, open } = props;

    const { clientId, projectId } = useParams();
    const queryClient = useQueryClient();

    const deleteCameraMutation = useMutation(async (payload: any) => {
        const { data, status } = await http.delete(
            `${
                import.meta.env.VITE_APP_API_URL
            }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras/${payload.id}`
        );
        return { data, status };
    });

    const handleDelete = async () => {
        if (camera === null) return onClose();
        try {
            const { data, status } = await deleteCameraMutation.mutateAsync({
                id: camera._id,
            });
            console.log({ data, status });
            toast.success(' TimeLapse Camera deleted Successfully.');
            queryClient.setQueryData<ICamera[]>(['clients', clientId, 'projects', projectId, 'cameras'], (prev) => {
                if (!prev) return;

                const index = prev.findIndex((c) => c._id === camera._id);
                return produce(prev, (draft) => {
                    draft.splice(index, 1);
                    return draft;
                });
            });
            onClose();
        } catch (ex: any) {
            onClose();
            console.log(ex.status);
            if (ex.status === 400) {
                toast.error(ex.data.message);
            } else {
                toast.error(ex?.data?.message || 'Some error occured, please try again.');
                console.log(ex);
            }
        }
    };

    return (
        <Drawer open={open} onClose={onClose} title="Confirm Delete">
            <div>
                <div>
                    <Label className="mt-8 inline-block">
                        The following timeLapse camera &ldquo;
                        <span className="font-bold">{camera?.name}</span>&rdquo; will be delete.
                    </Label>

                    <div className="flex gap-2 mt-2">
                        <Button
                            disabled={deleteCameraMutation.isLoading}
                            onClick={() => onClose()}
                            variant="default"
                            color={'primary'}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={deleteCameraMutation.isLoading}
                            className="w-20"
                            onClick={() => handleDelete()}
                            variant="default"
                            color={'danger'}
                        >
                            {deleteCameraMutation.isLoading ? <CircularProgress size={18} /> : 'Delete'}
                        </Button>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default CameraDeleteConfirmationDrawer;
