import http from "httpConfig/http";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { getResellerIdFromRedux } from "modules/shared/helpers/shared.utils";

const useUniqueMutation = () => {
  const { clientId } = useParams();

  const uniqueEmailMutation = useMutation(async (payload: any) => {
    const data = await http.post(
      `${
        import.meta.env.VITE_APP_API_URL
      }/${getResellerIdFromRedux()}/clients/${clientId}/users/email/unique`,
      payload
    );
    return data;
  });

  const uniqueUsernameMutation = useMutation(async (payload: any) => {
    const data = await http.post(
      `${
        import.meta.env.VITE_APP_API_URL
      }/${getResellerIdFromRedux()}/clients/${clientId}/users/username/unique`,
      payload
    );
    return data;
  });

  return {
    uniqueEmailMutation,
    uniqueUsernameMutation,
  };
};

export default useUniqueMutation;
