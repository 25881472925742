import { ISupportTicketReply } from '@api/models';
import { createSupportTicketReply } from '@api/services/support.service';
import { SendIcon } from '@icon/index';
import { CircularProgress } from '@library/loaders/components';
import { useMutation } from '@tanstack/react-query';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { FC } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const TicketReplySchema = Yup.object().shape({
    message: Yup.string().trim().required('Please enter a message'),
});

interface AddReplyFormProps {
    ticketId: string;
    onAdd: (reply: ISupportTicketReply) => void;
}

const AddReplyForm: FC<AddReplyFormProps> = (props) => {
    const { ticketId, onAdd } = props;

    const addReplyMutation = useMutation((message: string) => createSupportTicketReply(ticketId, message));

    const handleAddReply = async (values: { message: string }, formikHelpers: FormikHelpers<{ message: string }>) => {
        try {
            const reply = await addReplyMutation.mutateAsync(values.message);

            formikHelpers.resetForm();

            onAdd(reply);
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured, please try again.');
        }
    };

    return (
        <Formik
            initialValues={{ message: '' }}
            enableReinitialize
            validationSchema={TicketReplySchema}
            onSubmit={handleAddReply}
            validateOnMount
        >
            {({ values }) => (
                <Form>
                    <div className="px-6 py-4 border-t border-separator-color">
                        <div className="flex items-center justify-between gap-4">
                            <Field
                                type="text"
                                name="message"
                                className="font-medium w-full bg-transparent placeholder:text-grayscale-500 outline-none"
                                placeholder="Type message here..."
                            />

                            <button
                                type="submit"
                                disabled={!values.message}
                                // disabled={!values.message}
                                className="bg-primary grid place-items-center w-[52px] h-12 rounded-xl text-white shadow-[0px_1px_3px_rgba(20,86,242,0.04)] disabled:opacity-50"
                            >
                                {addReplyMutation.isLoading ? (
                                    <CircularProgress size={20} className="text-white" />
                                ) : (
                                    <SendIcon />
                                )}
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AddReplyForm;
