import { FloatingContextProvider, FloatingElementOptions } from '@library/floating';
import { FC, ReactNode } from 'react';

interface DropdownProps
    extends Pick<FloatingElementOptions, 'open' | 'setOpen' | 'placement' | 'offset' | 'showArrow'> {
    children: ReactNode;
}
const Dropdown: FC<DropdownProps> = (props) => {
    const { children, ...rest } = props;

    return <FloatingContextProvider {...rest}>{children}</FloatingContextProvider>;
};

Dropdown.defaultProps = {
    placement: 'bottom-end',
    offset: 8,
};

export default Dropdown;
