import { Input } from '@hyperflake/react-ui-library';
import { Label } from '@library/forms';
import { Popover } from '@library/popover';
import React, { ReactNode } from 'react';
import { ChromePicker, ColorResult } from 'react-color';

interface colorPickerProps {
    value: string;
    setColor: (e: ColorResult) => void;
}

const ColorPicker = (props: colorPickerProps) => {
    const { value, setColor } = props;
    return (
        <>
            <Popover
                placement="bottom-start"
                contentClassName="z-[82] p-0"
                content={<ChromePicker color={value} onChange={(e) => setColor(e)} />}
            >
                <div className="relative flex items-center">
                    <Input name="colorSpec" readOnly value={value} className="pl-20" />
                    <div
                        style={{
                            backgroundColor: value,
                        }}
                        className="left-0 p-4 rounded-l-md h-full w-16 absolute "
                    />
                </div>
            </Popover>
        </>
    );
};
export default ColorPicker;
