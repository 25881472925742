import { VariantProps, cva } from 'class-variance-authority';
import { DetailedHTMLProps, FC, HTMLAttributes, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import styles from './CircularProgress.module.scss';

const progressVariants = cva(['relative inline-flex justify-center items-center rounded-full align-top'], {
    variants: {
        variant: {
            indeterminate: [styles['progress-indeterminate']],
            determinate: [styles['progress-determinate']],
        },
        color: {
            inherit: ['text-inherit'],
            primary: ['text-primary'],
            secondary: ['text-secondary'],
            success: ['text-success'],
            danger: ['text-danger'],
            warning: ['text-warning'],
        },
    },
    defaultVariants: {
        variant: 'indeterminate',
        color: 'primary',
    },
});

const CIRCUMFERENCE = 2 * Math.PI * 20.2;

interface CircularProgressProps
    extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>, 'color'>,
        VariantProps<typeof progressVariants> {
    size?: number;
    value?: number;
    showLabel?: boolean;
}

const CircularProgress: FC<CircularProgressProps> = (props) => {
    const { size, value, showLabel, variant, color, className, ...rest } = props;

    const offset = useMemo(() => {
        let _value = Math.max(0, value);
        _value = Math.min(_value, 100);

        return CIRCUMFERENCE - (CIRCUMFERENCE * _value) / 100;
    }, [value]);

    const fontSize = useMemo(() => Math.min(size / 3.5), [size]);

    return (
        <span role="progressbar" {...rest} className={twMerge(progressVariants({ variant, color, className }))}>
            <svg viewBox="22 22 44 44" width={size} height={size}>
                <circle
                    className={styles['circle']}
                    cx={44}
                    cy={44}
                    r={20}
                    fill="none"
                    strokeWidth={4}
                    stroke="currentColor"
                    strokeDasharray={CIRCUMFERENCE}
                    strokeDashoffset={offset}
                />
            </svg>

            {showLabel && variant === 'determinate' && (
                <span className="absolute rotate-90 font-medium" style={{ fontSize }}>
                    {value}%
                </span>
            )}
        </span>
    );
};

CircularProgress.defaultProps = {
    size: 40,
    value: 0,
    showLabel: false,
    variant: 'indeterminate',
};

export default CircularProgress;
