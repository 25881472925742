import { produce } from 'immer';
import React, { FC } from 'react';
import http from 'httpConfig/http';
import { Label } from '@library/forms';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISiteGalleryItem } from '@api/models/site-gallery-item.model';
import { CircularProgress } from '@library/loaders/components';
import { IDroneFootage } from '@api/models/drone-footage.model';
import { deleteDroneFootage } from '@api/services/drone-footage.service';
import { Button } from '@hyperflake/react-ui-library';

interface IDroneFootageDeleteDrawerForm {
    onClose: () => void;
    droneFootage: IDroneFootage | null;
    projectId: string;
    clientId: string;
}

const DroneFootageDeleteDrawerForm: FC<IDroneFootageDeleteDrawerForm> = (props) => {
    const { onClose, droneFootage, projectId } = props;
    const { clientId } = useParams();
    const queryClient = useQueryClient();

    const deleteDroneFootageMutation = useMutation(async (payload: any) => {
        deleteDroneFootage(clientId, projectId, payload.id);
    });

    const handleDelete = async () => {
        if (droneFootage === null) return onClose();
        try {
            await deleteDroneFootageMutation.mutateAsync({ id: droneFootage._id });
            toast.success('Drone Footage deleted Successfully.');
            queryClient.setQueryData<ISiteGalleryItem[]>(
                ['clients', clientId, 'projects', projectId, 'drone'],
                (prev) => {
                    if (!prev) return;

                    const index = prev.findIndex((c) => c._id === droneFootage._id);
                    return produce(prev, (draft) => {
                        draft.splice(index, 1);
                        return draft;
                    });
                }
            );
            onClose();
        } catch (ex: any) {
            onClose();
            console.log(ex.status);
            if (ex.status === 400) {
                toast.error(ex.data.message);
            } else {
                toast.error(ex?.data?.message || 'Some error occured, please try again.');
                console.log(ex);
            }
        }
    };

    return (
        <div>
            <div>
                <h5>Confirm Delete</h5>
            </div>
            <div>
                <Label className="mt-8 inline-block">
                    The following item &ldquo;
                    <span className="font-bold">{droneFootage?.name}</span>&rdquo; will be removed.
                </Label>

                <div className="flex gap-2 mt-2">
                    <Button
                        disabled={deleteDroneFootageMutation.isLoading}
                        onClick={() => onClose()}
                        variant="default"
                        color={'primary'}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={deleteDroneFootageMutation.isLoading}
                        className="w-20"
                        onClick={() => handleDelete()}
                        variant="default"
                        color={'danger'}
                    >
                        {deleteDroneFootageMutation.isLoading ? <CircularProgress size={18} /> : 'Delete'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DroneFootageDeleteDrawerForm;
