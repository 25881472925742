import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice, { authListenerMiddleware } from './auth';
import { profile } from 'console';
import profileSlice from './profile';

const rootReducer = combineReducers({
    auth: authSlice.reducer,
    profile: profileSlice.reducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat(authListenerMiddleware.middleware),
});

export type TRootState = ReturnType<typeof rootReducer>;

export default store;
