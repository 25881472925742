const Icon = (props: any) => {
    return (
        <svg width={15} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1 7.117c0-1.414 0-2.121.234-2.678a3 3 0 011.605-1.606C3.396 2.6 4.103 2.6 5.518 2.6H10.2c1.68 0 2.52 0 3.162.327a3 3 0 011.311 1.31C15 4.88 15 5.72 15 7.4v4.8c0 1.68 0 2.52-.327 3.161a3 3 0 01-1.311 1.311C12.72 17 11.88 17 10.2 17H5.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.31C1 14.718 1 13.878 1 12.198V7.117v0zM4.889 1v2.4M11.111 1v2.4M11.89 6.6H4.11M11.889 13.8h-1.556"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
