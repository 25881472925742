import { DetailedHTMLProps, FC, ForwardedRef, HTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface HeaderProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
    ref?: ForwardedRef<HTMLElement>;
}

const Header = forwardRef<HTMLElement, HeaderProps>((props: HeaderProps, ref: ForwardedRef<HTMLElement>) => {
    const { children, className, ...rest } = props;

    return (
        <header ref={ref} {...rest} className={twMerge('bg-card-color theme-custom:bg-white/30', className)}>
            {children}
        </header>
    );
});

export default Header;
