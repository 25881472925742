import { ICamera } from '@api/models';
import { getAllCamerasOfProject } from '@api/services/camera.service';
import { useQuery } from '@tanstack/react-query';
import useDataQuery from 'modules/shared/hooks/use-data-query';
import { produce } from 'immer';

export const useCameraListData = (clientId: string, projectId: string) => {
    const cameraListQuery = useDataQuery(['clients', clientId, 'projects', projectId, 'cameras'], () =>
        getAllCamerasOfProject(clientId as string, projectId as string)
    );

    const addCamera = (camera: ICamera) => {
        cameraListQuery.setData((prevList: ICamera[]) => {
            return produce(prevList, (draftList) => {
                draftList.unshift(camera);

                return draftList;
            });
        });
    };
    const updateCamera = async (cameraId: string, updates: ICamera) => {
        cameraListQuery.setData((prevList: ICamera[]) => {
            return produce(prevList, (draftList) => {
                const index = draftList?.findIndex((row) => row._id === cameraId);

                draftList[index] = updates;

                return draftList;
            });
        });
    };
    return {
        cameraList: cameraListQuery.data,
        isLoading: cameraListQuery.isLoading,
        addCamera,
        updateCamera,
    };
};
