import { IClient } from '@api/models/client.model';
import { Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import useAuthPermission from 'modules/shared/hooks/use-auth-permission';

import useClientUpdatePluginForm from 'modules/clients/hooks/useClientUpdatePluginForm';
import { FormikSwitch } from 'modules/shared/features/formik';
import { Button } from '@hyperflake/react-ui-library';
import { PermissionEnum } from '@api/enum/permission.enum';
import { PluginIndentifierEnum } from '@api/enum';

interface IClientEditDrawerForm {
    onClose: () => void;
    client: IClient;
    onClientUpdate: (updatedClient: IClient) => void;
}
const ClientEditFormPlugin = (props: IClientEditDrawerForm) => {
    const { onClose, onClientUpdate, client } = props;

    const updateClientForm = useClientUpdatePluginForm({ client });

    const handleSubmit = async (values: any) => {
        try {
            const updatedClient = await updateClientForm.submit(values);

            onClientUpdate(updatedClient);

            toast.success('Client updated successfully.');

            onClose();
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    const { hasPermission } = useAuthPermission();

    return (
        <>
            <div>
                <Formik
                    initialValues={updateClientForm.initialValues}
                    enableReinitialize
                    // validationSchema={updateClientForm.schema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <fieldset disabled={!hasPermission(PermissionEnum.CLIENT_UPDATE)}>
                                <div className="space-y-4">
                                    {values.plugins.map((plugin, index) => (
                                        <div className="flex items-center gap-2" key={index}>
                                            <Label
                                                htmlFor="preferences.hasCdnEnabled"
                                                className="font-medium text-grayscale-900 mb-0"
                                            >
                                                {plugin.name}
                                            </Label>

                                            <FormikSwitch
                                                color="success"
                                                name={`plugins[${index}].isEnabled`}
                                                disabled={plugin.name === PluginIndentifierEnum.WHATSAPP_BOT}
                                            />
                                        </div>
                                    ))}
                                </div>

                                <Button
                                    type="submit"
                                    size={'lg'}
                                    className="mt-10"
                                    disabled={updateClientForm.isSubmitting}
                                >
                                    {updateClientForm.isSubmitting ? (
                                        <CircularProgress color={'inherit'} size={18} />
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </fieldset>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default ClientEditFormPlugin;
