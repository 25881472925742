import { DetailedHTMLProps, FC, ForwardedRef, HTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface CardHeaderProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    ref?: ForwardedRef<HTMLDivElement>;
}
const CardHeader = forwardRef<HTMLDivElement, CardHeaderProps>((props, ref) => {
    const { children, className, ...rest } = props;

    return (
        <div {...rest} ref={ref} className={twMerge('px-5 py-3 border-b border-b-separator-color-light', className)}>
            {children}
        </div>
    );
});

export default CardHeader;
