import { IUser } from '@api/models';
import moment from 'moment';
import React, { FC } from 'react';

interface UserReplyProps {
    user: IUser;
    message: string;
    createdAt: Date;
}
const UserReply: FC<UserReplyProps> = (props) => {
    const { user, message, createdAt } = props;

    return (
        <div className="flex justify-end gap-4">
            <div className="max-w-[90%] sm:max-w-[80%] lg:max-w-[50%] px-4 py-3 bg-[#F0F3F9] dark:bg-[#20262d] shadow-[0px_1px_2px_rgba(40,52,82,0.02)] rounded-xl rounded-br-none">
                <div className="text-xs font-semibold">{user.name}</div>
                <div className="mt-1">{message}</div>
                <div className="text-right text-[10px] text-grayscale-400 mt-[2px]">
                    {moment(createdAt, 'YYYYMMDDHHmmss').format('HH:mm A')}
                </div>
            </div>
        </div>
    );
};

export default UserReply;
