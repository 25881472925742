import { ISupportTicketReply } from '@api/models';
import { getSupportTicketById } from '@api/services/support.service';
import OverflowTooltip from 'modules/shared/components/OverflowTooltip/OverflowTooltip';
import SupportChip from 'modules/shared/components/SupportChip/SupportChip';
import useDataQuery from '@hooks/use-data-query';
import { BackButton } from '@library/buttons';
import { Card, CardBody, CardHeader } from '@library/cards';
import { AppHeader } from '@library/header';
import { CircularProgress } from '@library/loaders/components';
import produce from 'immer';
import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import AddReplyForm from '../AddReplyForm/AddReplyForm';
import TicketDetail from '../TicketDetail/TicketDetail';
import TicketRepliesCard from '../TicketReplies/TicketReplies';

interface SupportDetailPageProps {}

const SupportDetail: FC<SupportDetailPageProps> = () => {
    const { ticketId } = useParams();

    const { data, isLoading, setData } = useDataQuery(['Support Tickets', ticketId], () =>
        getSupportTicketById(ticketId)
    );

    const handleAddReply = useCallback((reply: ISupportTicketReply) => {
        setData((prevData) => {
            return produce(prevData, (draftData) => {
                draftData.replies.push(reply);
            });
        });
    }, []);

    if (isLoading)
        <div className="flex justify-center items-center">
            <CircularProgress />
        </div>;

    if (!data) return null;

    return (
        <div className="h-screen">
            <AppHeader
                headerComponent={<BackButton />}
                title={
                    <div className="flex items-center gap-[10px]">
                        <OverflowTooltip>{`Ticket #${ticketId}`}</OverflowTooltip>
                        <span className="inline-block">
                            <SupportChip status={data.status} />
                        </span>
                    </div>
                }
            />
            <main className="p-6">
                <Card>
                    <CardHeader>
                        <span className=" font-medium">Ticket Information</span>
                    </CardHeader>

                    <CardBody>
                        <TicketDetail ticket={data} onUpdate={setData} />
                    </CardBody>
                </Card>

                <div className="mt-6">
                    <Card>
                        <CardHeader>
                            <span className="font-medium">Resolution Board</span>
                        </CardHeader>

                        <TicketRepliesCard replies={data.replies} />

                        <AddReplyForm ticketId={ticketId} onAdd={handleAddReply} />
                    </Card>
                </div>
            </main>
        </div>
    );
};

export default SupportDetail;
