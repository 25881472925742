const Icon = (props: any) => {
    return (
        <svg width={11} height={12} viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.198 4.428L3.159.401A1.889 1.889 0 00.222 1.976v8.053A1.889 1.889 0 003.159 11.6l6.039-4.027a1.889 1.889 0 000-3.143v-.002z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Icon;
