import { IUser } from '@api/models';
import http from 'httpConfig/http';
import { Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { produce } from 'immer';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import { Button, Drawer } from '@hyperflake/react-ui-library';

interface IUserDeleteConfirmationDrawerProps {
    open: boolean;
    onClose: () => void;
    user: IUser | null;
}

const UserDeleteConfirmationDrawer = (props: IUserDeleteConfirmationDrawerProps) => {
    const { open, onClose, user } = props;
    const { clientId } = useParams();
    const queryClient = useQueryClient();

    const deleteUserMutation = useMutation(async (payload: any) => {
        const { data, status } = await http.delete(
            `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/users/${payload.id}`
        );
        return { data, status };
    });

    const handleDelete = async () => {
        if (user === null) return onClose();
        try {
            const { data, status } = await deleteUserMutation.mutateAsync({
                id: user._id,
            });
            console.log({ data, status });
            toast.success('User deleted Successfully.');
            queryClient.setQueryData<IUser[]>(['clients', clientId, 'users'], (prev) => {
                if (!prev) return;

                const index = prev.findIndex((c) => c._id === user._id);
                return produce(prev, (draft) => {
                    draft.splice(index, 1);
                    return draft;
                });
            });
            onClose();
        } catch (ex: any) {
            onClose();
            console.log(ex.status);
            if (ex.status === 400) {
                toast.error(ex.data.message);
            } else {
                toast.error(ex?.data?.message || 'Some error occured, please try again.');
                console.log(ex);
            }
        }
    };

    return (
        <Drawer open={open} onClose={onClose} title="Confirm Delete">
            <div>
                <div>
                    <Label className="mt-8 inline-block">
                        The following user &ldquo;
                        <span className="font-bold">{user?.name}</span>&rdquo; will be deleted.
                    </Label>

                    <div className="flex gap-2 mt-2">
                        <Button
                            disabled={deleteUserMutation.isLoading}
                            onClick={() => onClose()}
                            variant="default"
                            color={'primary'}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={deleteUserMutation.isLoading}
                            className="w-20"
                            onClick={() => handleDelete()}
                            variant="default"
                            color={'danger'}
                        >
                            {deleteUserMutation.isLoading ? <CircularProgress size={18} /> : 'Delete'}
                        </Button>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default UserDeleteConfirmationDrawer;
