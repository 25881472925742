import { FC } from 'react';
import { useField } from 'formik';
import { Label, Switch } from '@library/forms';

interface ICustomSwitch {
    name: string;
    label: string;
    disabled?: boolean;
}

const CustomSwitch: FC<ICustomSwitch> = (props) => {
    const { name, label, disabled = false } = props;
    const [field] = useField(name);
    return (
        <div>
            <Label className="mr-4">{label}</Label>
            <Switch size={'lg'} checked={field.value} {...field} {...props} disabled={disabled} />
        </div>
    );
};

export default CustomSwitch;
