import { SupportTicketStatusEnum } from '@api/enum/support-ticket-status.enum';
import { Chip } from '@hyperflake/react-ui-library';
import { useMemo } from 'react';

interface SupportChipProps {
    status: SupportTicketStatusEnum;
}

const SupportChip = (props: SupportChipProps) => {
    const { status } = props;

    const color = useMemo(() => {
        let statusColor: 'primary' | 'warning' | 'success' | 'danger' | 'secondary';

        switch (status) {
            case SupportTicketStatusEnum.OPEN:
                statusColor = 'danger';
                break;
            case SupportTicketStatusEnum.CLOSED:
                statusColor = 'success';
                break;
            default:
                statusColor = 'primary';
                break;
        }
        return statusColor;
    }, []);

    return <Chip label={status} variant={'outlined'} color={color} />;
};

export default SupportChip;
