import useCameraViewImages from '../../hooks/use-camera-view';
import { useParams } from 'react-router-dom';
import CameraDetailViewer from '../CameraDetailViewer/CameraDetailViewer';
import { useQuery } from '@tanstack/react-query';
import http from 'httpConfig/http';
import { ICamera, ICameraImage, IProject } from '@api/models';
import useBoundingClientRect from 'modules/shared/hooks/use-bounding-client-rect';
import IllustrationView from 'modules/shared/components/IllustrationView/IllustrationView';
import NoImagesIllustration from 'modules/shared/components/Illustrations/NoImagesIllustration';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import { PageTemplate } from 'modules/shared/features/content';

const ResellerCameraDetails = () => {
    const { cameraId } = useParams();

    const {
        isInitialLoading,
        isImagesQueryLoading,
        images,
        selectedImage,
        changeSelectedImage,
        selectedDate,
        changeSelectedDate,
        startDate,
        endDate,
    } = useCameraViewImages('projectId', cameraId as string);

    // fetch camera details from sever and  add loading

    const [headerRefSetter, headerBoundingClientRect] = useBoundingClientRect();

    const camera = useQuery(['cameras', cameraId], async () => {
        const { data } = await http.get(
            `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/cameras/${cameraId}`
        );
        return data as ICamera;
    });

    if (isImagesQueryLoading || camera.isLoading) return <h3> Loading...</h3>;

    return (
        <>
            {/* <ScrollIntoView> */}
            {/* <AppHeader
                ref={headerRefSetter}
                headerComponent={
                    isDesktop ? (
                        <ProjectHierarchyBreadcrumb
                            to='cameras'
                            appendItems={[
                                {
                                    label: camera.name,
                                },
                            ]}
                        />
                    ) : (
                        <BackButton />
                    )
                }
                title={`${camera.name} - ${camera.project.name}`}
                headerActions={
                    <>
                        <Button color='secondary' onClick={changeCameraDialog.show}>
                            <CameraIcon width='20' height='16' className='text-grayscale-600' />
                            <DesktopMediaQuery>Change Camera</DesktopMediaQuery>
                        </Button>

                        {camera.hasImages && (
                            <div className='relative'>
                                <Dropdown>
                                    <DropdownTrigger>
                                        <Button color='secondary' disabled={singleImageDownloadMutation.isLoading}>
                                            {singleImageDownloadMutation.isLoading ? (
                                                <span className='flex text-grayscale-600'>
                                                    <CircularProgress size={16} />
                                                </span>
                                            ) : (
                                                <DownloadIcon width='16' height='16' className='text-grayscale-600' />
                                            )}
                                            <DesktopMediaQuery>
                                                Download image <ChevronDownIcon className='text-grayscale-600' />
                                            </DesktopMediaQuery>
                                        </Button>
                                    </DropdownTrigger>

                                    <DropdownList className='min-w-full'>
                                        <DropdownItem
                                            className='flex items-center gap-2'
                                            onClick={handleSingleImageDownload}
                                        >
                                            <ImageIcon />
                                            Single
                                        </DropdownItem>

                                        <DropdownItem
                                            className='flex items-center gap-2'
                                            onClick={multiImageDownloadDialog.show}
                                        >
                                            <PlusWindowIcon />
                                            Multiple
                                        </DropdownItem>
                                    </DropdownList>
                                </Dropdown>
                            </div>
                        )}
                    </>
                }
            /> */}
            <PageTemplate>
                {camera.data && camera?.data.hasImages ? (
                    <CameraDetailViewer
                        camera={camera.data as ICamera}
                        project={camera.data.project as IProject}
                        images={images}
                        selectedImage={selectedImage as ICameraImage}
                        onSelectedImageChange={changeSelectedImage}
                        selectedDate={selectedDate as string}
                        onSelectedDateChange={changeSelectedDate}
                        startDate={startDate as string}
                        endDate={endDate as string}
                        isLoading={isImagesQueryLoading}
                        offsetTop={headerBoundingClientRect.height}
                    />
                ) : (
                    // <h4> no images yet</h4>
                    <IllustrationView
                        className="mt-[15vh]"
                        illustration={<NoImagesIllustration />}
                        title="No Images yet"
                        message="No results found"
                    />
                )}
            </PageTemplate>
            {/* <ChangeCameraDrawer
                show={changeCameraDialog.isOpen}
                onClose={changeCameraDialog.hide}
                projectId={projectId}
                selectedCameraId={cameraId}
            /> */}
            {/* <MultiImageDownloadDrawer
                show={multiImageDownloadDialog.isOpen}
                onClose={multiImageDownloadDialog.hide}
                projectId={projectId}
                camera={camera}
                startDate={startDate}
                endDate={endDate}
            /> */}

            {/* </ScrollIntoView> */}
        </>
    );
};

export default ResellerCameraDetails;
