import { FC } from 'react';
import { Skelton } from '@library/skeltons';
import { Card, CardBody } from '@library/cards';

interface ICameraCardSkelton {
    unique: string;
}
const CameraCardSkelton: FC<ICameraCardSkelton> = ({ unique }) => {
    return (
        <Card key={unique}>
            <div className='relative h-40 bg-gray-200'></div>

            <CardBody className='flex flex-col '>
                <Skelton width={'90%'} height={40} />

                <div className=''>
                    <Skelton width={'70%'} height={30} />
                </div>

                <div className=''>
                    <Skelton width={'50%'} />
                </div>

                <div className='mt-auto'>
                    <div className='flex  justify-between'>
                        <div>
                            <Skelton width={65} className='py-0 my-0' />
                            <Skelton width={65} />
                        </div>
                        <div>
                            <Skelton width={65} className='py-0 my-0' />
                            <Skelton width={65} />
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default CameraCardSkelton;
