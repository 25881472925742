import { FC, useState } from 'react';
import http from 'httpConfig/http';
import { IClient } from '@api/models';
import { Label } from '@library/forms';
import { toast } from 'react-toastify';
import { Avatar } from '@library/avatar';
import { useMutation } from '@tanstack/react-query';
import DisplayImageView from '../ui/DisplayImageView';
import UploadImageButton from '../ui/UploadImageButton';
import useUploadProgress from 'modules/shared/hooks/use-upload-progress';

import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';

interface ILogoForm {
    // onLogoUrlUpdate: <K extends keyof IClient>(field: K, value: IClient[K]) => void;
    client: IClient;
    // clientId: string;
    // logoUrl: string;
    onLogoUrlUpdate: (logoUrl: string) => void;
}

const LogoForm: FC<ILogoForm> = (props) => {
    const { client, onLogoUrlUpdate } = props;
    // const [urlLogo, setUrlLogo] = useState(client.logoUrl);

    const clientLogoUpdateMutation = async (
        payload: FormData,
        config: { onUploadProgress?: (progress: number) => void }
    ) => {
        const { data } = await http.put(
            `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${client._id}/logo`,
            payload,
            {
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;

                    config?.onUploadProgress?.(Math.trunc(progress));
                },
            }
        );

        return data as Pick<IClient, 'logoUrl'>;
    };
    const updateLogoMutation = useMutation((payload: FormData) =>
        clientLogoUpdateMutation(payload, {
            onUploadProgress: (progress) => {
                setUploadProgress(progress);
            },
        })
    );
    const removeLogoMutation = useMutation(async () => {
        await http.delete(`${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${client._id}/logo`);
    });

    const { uploadProgress, setUploadProgress } = useUploadProgress(client.logoUrl);

    const handleClientLogoUpdate = async (file: File) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const { logoUrl } = await updateLogoMutation.mutateAsync(formData);
            onLogoUrlUpdate(logoUrl);
            toast.success('Logo updated successfully');
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured');
            console.log(err);
        }
    };

    const handleLogoRemove = async () => {
        try {
            await removeLogoMutation.mutateAsync();
            toast.success('Logo removed successfully');
            onLogoUrlUpdate(null);
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured');

            console.log(err);
        }
    };

    return (
        <div>
            <Label>Client Logo</Label>

            <div className="flex   items-center gap-2">
                {/* TODO Show confirmation on remove */}
                <DisplayImageView
                    showRemove={Boolean(client.logoUrl)}
                    onRemove={handleLogoRemove}
                    isUploading={updateLogoMutation.isLoading || removeLogoMutation.isLoading}
                    uploadProgress={uploadProgress}
                >
                    <Avatar
                        src={client.logoUrl}
                        alt={client.name}
                        bgColor={client.preferences.primaryColor}
                        size={88}
                    />
                </DisplayImageView>

                <div className="flex flex-col items-center">
                    <UploadImageButton onFileChange={handleClientLogoUpdate} />
                    <div className="text-xs font-medium text-grayscale-400 mt-2">Max size up to 10MB</div>
                </div>
            </div>
        </div>
    );
};

export default LogoForm;
