import { ICameraCCTV } from '@api/models';
import { Drawer, NavTab } from '@hyperflake/react-ui-library';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import CctvCameraEditForm from '../CctvCameraEditDrawerForm/CctvCameraEditDrawerForm';
import ChangeProjectForm from '../ChangeProjectForm/ChangeProjectForm';

enum TabEnum {
    BASIC_DETAILS,
    CHANGE_PROJECT,
}
interface CctvCameraDrawerLayoutProps {
    open: boolean;
    onClose: () => void;
    cctvCamera: ICameraCCTV;
    onUpdateCctvCamera: (cctvCamera: ICameraCCTV) => void;
}
const CctvCameraDrawerLayout = (props: CctvCameraDrawerLayoutProps) => {
    const { open, onClose, cctvCamera, onUpdateCctvCamera } = props;
    const { clientId, projectId } = useParams();
    // const cameraData = useCameraData(clientId, projectId, cameraId);
    // const { camera } = cameraData;
    // const CctvCameraUpdateForm = useCctvCameraUpdateForm({ clientId, projectId, cameraId, camera });
    const [activeTab, setActiveTab] = useState(TabEnum.BASIC_DETAILS);
    return (
        <Drawer open={open} onClose={onClose} title="Edit Live Camera">
            <NavTab
                items={[
                    {
                        content: 'Basic',
                        isActive: TabEnum.BASIC_DETAILS === activeTab,
                        onClick: () => setActiveTab(TabEnum.BASIC_DETAILS),
                    },

                    {
                        content: 'Change Project',
                        isActive: TabEnum.CHANGE_PROJECT === activeTab,
                        onClick: () => setActiveTab(TabEnum.CHANGE_PROJECT),
                    },
                ]}
            />
            <div className="mt-6">
                {activeTab === TabEnum.BASIC_DETAILS && (
                    <CctvCameraEditForm
                        onClose={onClose}
                        cctvCamera={cctvCamera}
                        onUpdateCctvCamera={onUpdateCctvCamera}
                    />
                )}

                {activeTab === TabEnum.CHANGE_PROJECT && <ChangeProjectForm cctvCamera={cctvCamera} />}
            </div>
        </Drawer>
    );
};
export default CctvCameraDrawerLayout;
