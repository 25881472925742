import { ICameraImage } from '@api/models';
import { useCallback, useState } from 'react';

const useImageSliderState = () => {
    const [images, setImages] = useState<ICameraImage[]>([]);
    // const [selectedImage, setSelectedImage] = useState<ICameraImage>(null);
    // const [selectedDate, setSelectedDate] = useState<string>(null);
    // const [startDate, setStartDate] = useState<string>(null);
    // const [endDate, setEndDate] = useState<string>(null);

    const [selectedImage, setSelectedImage] = useState<ICameraImage | null>(null);
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);

    const setState = useCallback(
        (data: { images: ICameraImage[]; startDate: string; endDate: string; index: number }) => {
            setImages(data.images);

            setStartDate(data.startDate);

            setEndDate(data.endDate);

            if (data.images.length > 0) {
                setSelectedImage(data.images[data.index]);

                setSelectedDate(data.images[data.index].date);
            }
        },
        []
    );

    const resetState = useCallback(() => {
        setImages([]);

        setSelectedImage(null);

        setSelectedDate(null);

        setStartDate(null);

        setEndDate(null);
    }, []);

    return {
        images,
        setImages,
        selectedImage,
        setSelectedImage,
        selectedDate,
        setSelectedDate,
        endDate,
        setEndDate,
        startDate,
        setStartDate,
        setState,
        resetState,
    };
};

export default useImageSliderState;
