import { ICamera } from '@api/models';
import { EllipsisHorizontalIcon } from '@icon/index';
import { Dropdown, DropdownItem, DropdownList, DropdownTrigger } from '@library/dropdown';
import { GalleryCard, Img } from 'modules/shared/features/content';
import moment from 'moment';

interface ICameraCard {
    camera: ICamera;
    onDeleteClick: (camera: ICamera) => void;
    onEditClick: () => void;
    hasPermission: boolean;
}

const CameraCard = (props: ICameraCard) => {
    const { camera, onEditClick, hasPermission, onDeleteClick } = props;

    const isOlderThan3Days = (date: string) => {
        return moment().diff(moment(date, 'YYYYMMDD'), 'days') <= 3;
    };

    return (
        <GalleryCard>
            <GalleryCard.ActionBar>
                <Dropdown placement="bottom-end">
                    <DropdownTrigger className="w-6 h-6 grid place-items-center rounded-[4px] bg-floating-color image-overlay-button-shadow hover-transition hover:brightness-90">
                        <EllipsisHorizontalIcon className="text-grayscale-600" />
                    </DropdownTrigger>

                    <DropdownList className="min-w-[100px]">
                        <DropdownItem onClick={onEditClick}>Edit</DropdownItem>
                        {hasPermission && (
                            <DropdownItem onClick={() => onDeleteClick(camera)} className="text-danger">
                                Delete
                            </DropdownItem>
                        )}
                    </DropdownList>
                </Dropdown>
            </GalleryCard.ActionBar>

            <GalleryCard.Media>
                <Img
                    className="w-full aspect-image rounded-lg object-cover object-center"
                    src={camera.latestImage?.urlThumb}
                />
            </GalleryCard.Media>

            <GalleryCard.Title>{camera.name}</GalleryCard.Title>

            <GalleryCard.Footer>
                <div className="flex flex-wrap gap-4 justify-between">
                    <GalleryCard.FooterItem
                        label="Last Image"
                        content={
                            camera?.latestImage?.datetime ? (
                                <div
                                    className={
                                        isOlderThan3Days(camera?.latestImage?.datetime)
                                            ? 'text-green-500'
                                            : 'text-red-500'
                                    }
                                >
                                    {moment(camera?.latestImage?.datetime, 'YYYYMMDDHHmmss').format(
                                        'h:mm A DD MMM, YY'
                                    )}
                                </div>
                            ) : (
                                '-'
                            )
                        }
                    ></GalleryCard.FooterItem>

                    <GalleryCard.FooterItem
                        label="Installed On"
                        content={moment(camera?.installationDate, 'YYYYMMDD').format('DD MMM, YYYY')}
                    ></GalleryCard.FooterItem>
                </div>
            </GalleryCard.Footer>
        </GalleryCard>
    );
};

export default CameraCard;
