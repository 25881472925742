import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useCameraImages from './use-camera-images';
import { ICameraImage } from '@api/models';

const useCameraViewImages = (projectId: string, cameraId: string) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const date = useMemo(() => searchParams.get('date'), [searchParams]);
    const time = useMemo(() => searchParams.get('time'), [searchParams]);

    const {
        isInitialLoading,
        isLoading,
        images,
        selectedImage,
        setSelectedImage,
        selectedDate,
        setSelectedDate,
        startDate,
        endDate,
    } = useCameraImages(projectId, cameraId, date as string, time as string);

    useEffect(() => {
        if (!date) return;

        setSelectedDate(date);
    }, [date, setSelectedDate]);

    useEffect(() => {
        const removeDateParam = () => {
            searchParams.delete('date');
            searchParams.delete('time');

            setSearchParams(searchParams);
        };

        window.addEventListener('load', removeDateParam);

        return () => {
            window.removeEventListener('load', removeDateParam);
        };
    }, [searchParams, setSearchParams]);

    const changeSelectedDate = useCallback(
        (date: string) => {
            searchParams.set('date', date);
            searchParams.delete('time');

            setSearchParams(searchParams, {
                replace: true,
            });
        },
        [searchParams, setSearchParams]
    );

    const changeSelectedImage = useCallback(
        (image: ICameraImage) => {
            setSelectedImage(image);

            searchParams.set('time', image.time);

            setSearchParams(searchParams, {
                replace: true,
            });
        },
        [searchParams, setSearchParams, setSelectedImage]
    );

    return {
        isInitialLoading,
        isImagesQueryLoading: isLoading,
        images,
        selectedImage,
        changeSelectedImage,
        selectedDate,
        changeSelectedDate,
        startDate,
        endDate,
    };
};

export default useCameraViewImages;
