import { Select } from '@library/forms';
import { useField } from 'formik';
import { FC } from 'react';

interface IOption {
    label: string;
    value: string;
}

interface ICustomMultiSelect {
    name: string;
    options: IOption[];
    className: string;
}

const CustomMultiSelect: FC<ICustomMultiSelect> = (props) => {
    const { name, options } = props;
    const [field, , helpers] = useField(name);

    return (
        <Select
            className="h-40 py-3 text-lg"
            options={options}
            multiple
            size={'xl'}
            name={name}
            value={field.value}
            onChange={(e) => {
                const options = e.target.options;
                const selectedValues = [];
                for (let i = 0; i < options.length; i++) {
                    if (options[i].selected) {
                        selectedValues.push(options[i].value);
                    }
                }
                helpers.setValue(selectedValues);
            }}
        />
    );
};

export default CustomMultiSelect;
