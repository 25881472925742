import { UserRoleEnum } from '@api/enum';
import { PermissionEnum } from '@api/enum/permission.enum';

export const ADMIN_PERMISSIONS = Object.values(PermissionEnum);

export const EDITOR_PERMISSIONS = [
    PermissionEnum.PROJECT_READ,
    PermissionEnum.PROJECT_CREATE,
    PermissionEnum.PROJECT_UPDATE,
    PermissionEnum.PROJECT_DELETE,
];

export const VIEWER_PERMISSIONS = [PermissionEnum.PROJECT_READ];

export const PermissionMap = {
    [UserRoleEnum.ADMIN]: ADMIN_PERMISSIONS,
    [UserRoleEnum.EDITOR]: EDITOR_PERMISSIONS,
    [UserRoleEnum.VIEWER]: VIEWER_PERMISSIONS,
};
