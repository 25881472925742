import { IPublicRequest } from '@api/models/public-request.model';
import http from 'httpConfig/http';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';

export const getAllPublicApiRequest = async () => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/public-api/developer-access-requests`
    );
    return data as IPublicRequest[];
};
export const getPublicApiRequestById = async (publicApiId: string) => {
    const { data } = await http.get(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/public-api/developer-access-requests/${publicApiId}` //change this end point
    );

    return data as IPublicRequest;
};
export const givePublicApiAccess = async (publicApiId: string) => {
    const { data } = await http.post(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/public-api/developer-access-requests/${publicApiId}`
    );

    return data as IPublicRequest;
};
export const deletePublicApiRequest = async (publicApiId: string) => {
    const { data } = await http.delete(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/public-api/developer-access-requests/${publicApiId}`
    );

    return data as IPublicRequest;
};

export const publicApiRequestPendingCount = async () => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/public-api/developer-access-requests/count`
    );
    return data;
};
// GET /api/v4/reseller/61caf1dccb3a8396b119a117/public-api/developer-access-requests
