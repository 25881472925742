const Icon = (props: any) => {
    return (
        <svg width={14} viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx={2} cy={2} r={2} fill="currentColor" />
            <circle cx={8} cy={2} r={2} fill="currentColor" />
            <circle cx={14} cy={2} r={2} fill="currentColor" />
        </svg>
    );
};

export default Icon;
