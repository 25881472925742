import { useSelector } from 'react-redux';
import { profileAction } from '@store/profile';
import { FC, ReactNode, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useLocation } from 'react-router-dom';
import { getProfile } from '@api/services/console-user.service';
import { CircularProgress } from '@library/loaders/components';
import store, { TRootState } from '@store/index';
import useAuthSelector from 'modules/shared/hooks/use-auth-selector';
import useCurrentUser from 'modules/shared/layouts/hooks/useCurrentUser';

interface AuthGuardProps {
    page: ReactNode;
}

const AuthGuard = (props: AuthGuardProps) => {
    const location = useLocation();
    const { token } = useAuthSelector();
    const { info } = useSelector((state: TRootState) => state.profile);

    const { user, isLoading } = useCurrentUser();

    const { data } = useQuery(['profile'], () => getProfile(), {
        enabled: Boolean(user.resellerId),
    });
    useEffect(() => {
        if (data) {
            store.dispatch(profileAction.setProfile(data));
        }
    }, [data]);

    if (!token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (isLoading || !info)
        return (
            <div className="h-screen w-screen flex justify-center items-center">
                <CircularProgress />
            </div>
        );

    // const payload = jwtDecode(token);
    // const isExpired = moment().isAfter(moment.unix(payload?.exp));
    // if (isExpired) {
    //     dispatch(authActions.logout());
    //     return <Navigate to="/login" state={{ from: location }} replace />;
    // }

    return props.page;
};

export default AuthGuard;
