import capitalize from 'lodash.capitalize';
import { UserRoleEnum } from '@api/enum/user-role.enum';
import { UserThemeEnum } from '@api/enum/user-theme.enum';
import { UserStatusEnum } from '@api/enum/user-status.enum';
// import { timezoneList } from "modules/shared/helpers/shared.constants";

export const CountryDialCodeList = [
    {
        countryName: 'Afghanistan',
        countryCode: 'af',
        dialCode: '+93',
        areaCodes: [],
        flag: '/flags/af.svg',
    },
    {
        countryName: 'Albania',
        countryCode: 'al',
        dialCode: '+355',
        areaCodes: [],
        flag: '/flags/al.svg',
    },
    {
        countryName: 'Algeria',
        countryCode: 'dz',
        dialCode: '+213',
        areaCodes: [],
        flag: '/flags/dz.svg',
    },
    {
        countryName: 'American Samoa',
        countryCode: 'as',
        dialCode: '+1',
        areaCodes: ['684'],
        flag: '/flags/as.svg',
    },
    {
        countryName: 'Andorra',
        countryCode: 'ad',
        dialCode: '+376',
        areaCodes: [],
        flag: '/flags/ad.svg',
    },
    {
        countryName: 'Angola',
        countryCode: 'ao',
        dialCode: '+244',
        areaCodes: [],
        flag: '/flags/ao.svg',
    },
    {
        countryName: 'Anguilla',
        countryCode: 'ai',
        dialCode: '+1',
        areaCodes: ['264'],
        flag: '/flags/ai.svg',
    },
    {
        countryName: 'Antigua and Barbuda',
        countryCode: 'ag',
        dialCode: '+1',
        areaCodes: ['268'],
        flag: '/flags/ag.svg',
    },
    {
        countryName: 'Argentina',
        countryCode: 'ar',
        dialCode: '+54',
        areaCodes: [],
        flag: '/flags/ar.svg',
    },
    {
        countryName: 'Armenia',
        countryCode: 'am',
        dialCode: '+374',
        areaCodes: [],
        flag: '/flags/am.svg',
    },
    {
        countryName: 'Aruba',
        countryCode: 'aw',
        dialCode: '+297',
        areaCodes: [],
        flag: '/flags/aw.svg',
    },
    {
        countryName: 'Ascension Island',
        countryCode: 'ac',
        dialCode: '+247',
        areaCodes: [],
        flag: '/flags/ac.svg',
    },
    {
        countryName: 'Australia',
        countryCode: 'au',
        dialCode: '+61',
        areaCodes: [],
        flag: '/flags/au.svg',
    },
    {
        countryName: 'Austria',
        countryCode: 'at',
        dialCode: '+43',
        areaCodes: [],
        flag: '/flags/at.svg',
    },
    {
        countryName: 'Azerbaijan',
        countryCode: 'az',
        dialCode: '+994',
        areaCodes: [],
        flag: '/flags/az.svg',
    },
    {
        countryName: 'Bahamas',
        countryCode: 'bs',
        dialCode: '+1',
        areaCodes: ['242'],
        flag: '/flags/bs.svg',
    },
    {
        countryName: 'Bahrain',
        countryCode: 'bh',
        dialCode: '+973',
        areaCodes: [],
        flag: '/flags/bh.svg',
    },
    {
        countryName: 'Bangladesh',
        countryCode: 'bd',
        dialCode: '+880',
        areaCodes: [],
        flag: '/flags/bd.svg',
    },
    {
        countryName: 'Barbados',
        countryCode: 'bb',
        dialCode: '+1',
        areaCodes: ['246'],
        flag: '/flags/bb.svg',
    },
    {
        countryName: 'Belarus',
        countryCode: 'by',
        dialCode: '+375',
        areaCodes: [],
        flag: '/flags/by.svg',
    },
    {
        countryName: 'Belgium',
        countryCode: 'be',
        dialCode: '+32',
        areaCodes: [],
        flag: '/flags/be.svg',
    },
    {
        countryName: 'Belize',
        countryCode: 'bz',
        dialCode: '+501',
        areaCodes: [],
        flag: '/flags/bz.svg',
    },
    {
        countryName: 'Benin',
        countryCode: 'bj',
        dialCode: '+229',
        areaCodes: [],
        flag: '/flags/bj.svg',
    },
    {
        countryName: 'Bermuda',
        countryCode: 'bm',
        dialCode: '+1',
        areaCodes: ['441'],
        flag: '/flags/bm.svg',
    },
    {
        countryName: 'Bhutan',
        countryCode: 'bt',
        dialCode: '+975',
        areaCodes: [],
        flag: '/flags/bt.svg',
    },
    {
        countryName: 'Bolivia',
        countryCode: 'bo',
        dialCode: '+591',
        areaCodes: [],
        flag: '/flags/bo.svg',
    },
    {
        countryName: 'Bosnia and Herzegovina ',
        countryCode: 'ba',
        dialCode: '+387',
        areaCodes: [],
        flag: '/flags/ba.svg',
    },
    {
        countryName: 'Botswana',
        countryCode: 'bw',
        dialCode: '+267',
        areaCodes: [],
        flag: '/flags/bw.svg',
    },
    {
        countryName: 'Brazil ',
        countryCode: 'br',
        dialCode: '+55',
        areaCodes: [],
        flag: '/flags/br.svg',
    },
    {
        countryName: 'British Indian Ocean Territory',
        countryCode: 'io',
        dialCode: '+246',
        areaCodes: [],
        flag: '/flags/io.svg',
    },
    {
        countryName: 'British Virgin Islands',
        countryCode: 'vg',
        dialCode: '+1',
        areaCodes: ['284'],
        flag: '/flags/vg.svg',
    },
    {
        countryName: 'Brunei',
        countryCode: 'bn',
        dialCode: '+673',
        areaCodes: [],
        flag: '/flags/bn.svg',
    },
    {
        countryName: 'Bulgaria ',
        countryCode: 'bg',
        dialCode: '+359',
        areaCodes: [],
        flag: '/flags/bg.svg',
    },
    {
        countryName: 'Burkina Faso',
        countryCode: 'bf',
        dialCode: '+226',
        areaCodes: [],
        flag: '/flags/bf.svg',
    },
    {
        countryName: 'Burundi ',
        countryCode: 'bi',
        dialCode: '+257',
        areaCodes: [],
        flag: '/flags/bi.svg',
    },
    {
        countryName: 'Cambodia ',
        countryCode: 'kh',
        dialCode: '+855',
        areaCodes: [],
        flag: '/flags/kh.svg',
    },
    {
        countryName: 'Cameroon ',
        countryCode: 'cm',
        dialCode: '+237',
        areaCodes: [],
        flag: '/flags/cm.svg',
    },
    {
        countryName: 'Canada',
        countryCode: 'ca',
        dialCode: '+1',
        areaCodes: [
            '204',
            '226',
            '236',
            '249',
            '250',
            '263',
            '289',
            '306',
            '343',
            '354',
            '365',
            '367',
            '368',
            '382',
            '387',
            '403',
            '416',
            '418',
            '428',
            '431',
            '437',
            '438',
            '450',
            '584',
            '468',
            '474',
            '506',
            '514',
            '519',
            '548',
            '579',
            '581',
            '584',
            '587',
            '604',
            '613',
            '639',
            '647',
            '672',
            '683',
            '705',
            '709',
            '742',
            '753',
            '778',
            '780',
            '782',
            '807',
            '819',
            '825',
            '867',
            '873',
            '902',
            '905',
        ],
        flag: '/flags/ca.svg',
    },
    {
        countryName: 'Cape Verde ',
        countryCode: 'cv',
        dialCode: '+238',
        areaCodes: [],
        flag: '/flags/cv.svg',
    },
    {
        countryName: 'Caribbean Netherlands',
        countryCode: 'bq',
        dialCode: '+599',
        areaCodes: ['3', '4', '7'],
        flag: '/flags/bq.svg',
    },
    {
        countryName: 'Cayman Islands',
        countryCode: 'ky',
        dialCode: '+1',
        areaCodes: ['345'],
        flag: '/flags/ky.svg',
    },
    {
        countryName: 'Central African Republic ',
        countryCode: 'cf',
        dialCode: '+236',
        areaCodes: [],
        flag: '/flags/cf.svg',
    },
    {
        countryName: 'Chad ',
        countryCode: 'td',
        dialCode: '+235',
        areaCodes: [],
        flag: '/flags/td.svg',
    },
    {
        countryName: 'Chile',
        countryCode: 'cl',
        dialCode: '+56',
        areaCodes: [],
        flag: '/flags/cl.svg',
    },
    {
        countryName: 'China ',
        countryCode: 'cn',
        dialCode: '+86',
        areaCodes: [],
        flag: '/flags/cn.svg',
    },
    {
        countryName: 'Christmas Island',
        countryCode: 'cx',
        dialCode: '+61',
        areaCodes: ['89164'],
        flag: '/flags/cx.svg',
    },
    {
        countryName: 'Cocos  Islands',
        countryCode: 'cc',
        dialCode: '+61',
        areaCodes: ['89162'],
        flag: '/flags/cc.svg',
    },
    {
        countryName: 'Colombia',
        countryCode: 'co',
        dialCode: '+57',
        areaCodes: [],
        flag: '/flags/co.svg',
    },
    {
        countryName: 'Comoros ',
        countryCode: 'km',
        dialCode: '+269',
        areaCodes: [],
        flag: '/flags/km.svg',
    },
    {
        countryName: 'Congo ',
        countryCode: 'cd',
        dialCode: '+243',
        areaCodes: [],
        flag: '/flags/cd.svg',
    },
    {
        countryName: 'Congo ',
        countryCode: 'cg',
        dialCode: '+242',
        areaCodes: [],
        flag: '/flags/cg.svg',
    },
    {
        countryName: 'Cook Islands',
        countryCode: 'ck',
        dialCode: '+682',
        areaCodes: [],
        flag: '/flags/ck.svg',
    },
    {
        countryName: 'Costa Rica',
        countryCode: 'cr',
        dialCode: '+506',
        areaCodes: [],
        flag: '/flags/cr.svg',
    },
    {
        countryName: 'Côte d’Ivoire',
        countryCode: 'ci',
        dialCode: '+225',
        areaCodes: [],
        flag: '/flags/ci.svg',
    },
    {
        countryName: 'Croatia ',
        countryCode: 'hr',
        dialCode: '+385',
        areaCodes: [],
        flag: '/flags/hr.svg',
    },
    {
        countryName: 'Cuba',
        countryCode: 'cu',
        dialCode: '+53',
        areaCodes: [],
        flag: '/flags/cu.svg',
    },
    {
        countryName: 'Curaçao',
        countryCode: 'cw',
        dialCode: '+599',
        areaCodes: [],
        flag: '/flags/cw.svg',
    },
    {
        countryName: 'Cyprus ',
        countryCode: 'cy',
        dialCode: '+357',
        areaCodes: [],
        flag: '/flags/cy.svg',
    },
    {
        countryName: 'Czech Republic ',
        countryCode: 'cz',
        dialCode: '+420',
        areaCodes: [],
        flag: '/flags/cz.svg',
    },
    {
        countryName: 'Denmark ',
        countryCode: 'dk',
        dialCode: '+45',
        areaCodes: [],
        flag: '/flags/dk.svg',
    },
    {
        countryName: 'Djibouti',
        countryCode: 'dj',
        dialCode: '+253',
        areaCodes: [],
        flag: '/flags/dj.svg',
    },
    {
        countryName: 'Dominica',
        countryCode: 'dm',
        dialCode: '+1',
        areaCodes: ['767'],
        flag: '/flags/dm.svg',
    },
    {
        countryName: 'Dominican Republic ',
        countryCode: 'do',
        dialCode: '+1',
        areaCodes: ['809', '829', '849'],
        flag: '/flags/do.svg',
    },
    {
        countryName: 'Ecuador',
        countryCode: 'ec',
        dialCode: '+593',
        areaCodes: [],
        flag: '/flags/ec.svg',
    },
    {
        countryName: 'Egypt ',
        countryCode: 'eg',
        dialCode: '+20',
        areaCodes: [],
        flag: '/flags/eg.svg',
    },
    {
        countryName: 'El Salvador',
        countryCode: 'sv',
        dialCode: '+503',
        areaCodes: [],
        flag: '/flags/sv.svg',
    },
    {
        countryName: 'Equatorial Guinea ',
        countryCode: 'gq',
        dialCode: '+240',
        areaCodes: [],
        flag: '/flags/gq.svg',
    },
    {
        countryName: 'Eritrea',
        countryCode: 'er',
        dialCode: '+291',
        areaCodes: [],
        flag: '/flags/er.svg',
    },
    {
        countryName: 'Estonia ',
        countryCode: 'ee',
        dialCode: '+372',
        areaCodes: [],
        flag: '/flags/ee.svg',
    },
    {
        countryName: 'Eswatini',
        countryCode: 'sz',
        dialCode: '+268',
        areaCodes: [],
        flag: '/flags/sz.svg',
    },
    {
        countryName: 'Ethiopia',
        countryCode: 'et',
        dialCode: '+251',
        areaCodes: [],
        flag: '/flags/et.svg',
    },
    {
        countryName: 'Falkland Islands ',
        countryCode: 'fk',
        dialCode: '+500',
        areaCodes: [],
        flag: '/flags/fk.svg',
    },
    {
        countryName: 'Faroe Islands ',
        countryCode: 'fo',
        dialCode: '+298',
        areaCodes: [],
        flag: '/flags/fo.svg',
    },
    {
        countryName: 'Fiji',
        countryCode: 'fj',
        dialCode: '+679',
        areaCodes: [],
        flag: '/flags/fj.svg',
    },
    {
        countryName: 'Finland ',
        countryCode: 'fi',
        dialCode: '+358',
        areaCodes: [],
        flag: '/flags/fi.svg',
    },
    {
        countryName: 'France',
        countryCode: 'fr',
        dialCode: '+33',
        areaCodes: [],
        flag: '/flags/fr.svg',
    },
    {
        countryName: 'French Guiana ',
        countryCode: 'gf',
        dialCode: '+594',
        areaCodes: [],
        flag: '/flags/gf.svg',
    },
    {
        countryName: 'French Polynesia ',
        countryCode: 'pf',
        dialCode: '+689',
        areaCodes: [],
        flag: '/flags/pf.svg',
    },
    {
        countryName: 'Gabon',
        countryCode: 'ga',
        dialCode: '+241',
        areaCodes: [],
        flag: '/flags/ga.svg',
    },
    {
        countryName: 'Gambia',
        countryCode: 'gm',
        dialCode: '+220',
        areaCodes: [],
        flag: '/flags/gm.svg',
    },
    {
        countryName: 'Georgia ',
        countryCode: 'ge',
        dialCode: '+995',
        areaCodes: [],
        flag: '/flags/ge.svg',
    },
    {
        countryName: 'Germany ',
        countryCode: 'de',
        dialCode: '+49',
        areaCodes: [],
        flag: '/flags/de.svg',
    },
    {
        countryName: 'Ghana ',
        countryCode: 'gh',
        dialCode: '+233',
        areaCodes: [],
        flag: '/flags/gh.svg',
    },
    {
        countryName: 'Gibraltar',
        countryCode: 'gi',
        dialCode: '+350',
        areaCodes: [],
        flag: '/flags/gi.svg',
    },
    {
        countryName: 'Greece ',
        countryCode: 'gr',
        dialCode: '+30',
        areaCodes: [],
        flag: '/flags/gr.svg',
    },
    {
        countryName: 'Greenland ',
        countryCode: 'gl',
        dialCode: '+299',
        areaCodes: [],
        flag: '/flags/gl.svg',
    },
    {
        countryName: 'Grenada',
        countryCode: 'gd',
        dialCode: '+1',
        areaCodes: ['473'],
        flag: '/flags/gd.svg',
    },
    {
        countryName: 'Guadeloupe',
        countryCode: 'gp',
        dialCode: '+590',
        areaCodes: [],
        flag: '/flags/gp.svg',
    },
    {
        countryName: 'Guam',
        countryCode: 'gu',
        dialCode: '+1',
        areaCodes: ['671'],
        flag: '/flags/gu.svg',
    },
    {
        countryName: 'Guatemala',
        countryCode: 'gt',
        dialCode: '+502',
        areaCodes: [],
        flag: '/flags/gt.svg',
    },
    {
        countryName: 'Guernsey',
        countryCode: 'gg',
        dialCode: '+44',
        areaCodes: ['1481', '7781', '7839', '7911'],
        flag: '/flags/gg.svg',
    },
    {
        countryName: 'Guinea ',
        countryCode: 'gn',
        dialCode: '+224',
        areaCodes: [],
        flag: '/flags/gn.svg',
    },
    {
        countryName: 'Guinea-Bissau ',
        countryCode: 'gw',
        dialCode: '+245',
        areaCodes: [],
        flag: '/flags/gw.svg',
    },
    {
        countryName: 'Guyana',
        countryCode: 'gy',
        dialCode: '+592',
        areaCodes: [],
        flag: '/flags/gy.svg',
    },
    {
        countryName: 'Haiti',
        countryCode: 'ht',
        dialCode: '+509',
        areaCodes: [],
        flag: '/flags/ht.svg',
    },
    {
        countryName: 'Honduras',
        countryCode: 'hn',
        dialCode: '+504',
        areaCodes: [],
        flag: '/flags/hn.svg',
    },
    {
        countryName: 'Hong Kong ',
        countryCode: 'hk',
        dialCode: '+852',
        areaCodes: [],
        flag: '/flags/hk.svg',
    },
    {
        countryName: 'Hungary ',
        countryCode: 'hu',
        dialCode: '+36',
        areaCodes: [],
        flag: '/flags/hu.svg',
    },
    {
        countryName: 'Iceland ',
        countryCode: 'is',
        dialCode: '+354',
        areaCodes: [],
        flag: '/flags/is.svg',
    },
    {
        countryName: 'India ',
        countryCode: 'in',
        dialCode: '+91',
        areaCodes: [],
        flag: '/flags/in.svg',
    },
    {
        countryName: 'Indonesia',
        countryCode: 'id',
        dialCode: '+62',
        areaCodes: [],
        flag: '/flags/id.svg',
    },
    {
        countryName: 'Iran ',
        countryCode: 'ir',
        dialCode: '+98',
        areaCodes: [],
        flag: '/flags/ir.svg',
    },
    {
        countryName: 'Iraq ',
        countryCode: 'iq',
        dialCode: '+964',
        areaCodes: [],
        flag: '/flags/iq.svg',
    },
    {
        countryName: 'Ireland',
        countryCode: 'ie',
        dialCode: '+353',
        areaCodes: [],
        flag: '/flags/ie.svg',
    },
    {
        countryName: 'Isle of Man',
        countryCode: 'im',
        dialCode: '+44',
        areaCodes: ['1624', '74576', '7524', '7924', '7624'],
        flag: '/flags/im.svg',
    },
    {
        countryName: 'Israel ',
        countryCode: 'il',
        dialCode: '+972',
        areaCodes: [],
        flag: '/flags/il.svg',
    },
    {
        countryName: 'Italy ',
        countryCode: 'it',
        dialCode: '+39',
        areaCodes: [],
        flag: '/flags/it.svg',
    },
    {
        countryName: 'Jamaica',
        countryCode: 'jm',
        dialCode: '+1',
        areaCodes: ['876', '658'],
        flag: '/flags/jm.svg',
    },
    {
        countryName: 'Japan ',
        countryCode: 'jp',
        dialCode: '+81',
        areaCodes: [],
        flag: '/flags/jp.svg',
    },
    {
        countryName: 'Jersey',
        countryCode: 'je',
        dialCode: '+44',
        areaCodes: ['1534', '7509', '7700', '7797', '7829', '7937'],
        flag: '/flags/je.svg',
    },
    {
        countryName: 'Jordan ',
        countryCode: 'jo',
        dialCode: '+962',
        areaCodes: [],
        flag: '/flags/jo.svg',
    },
    {
        countryName: 'Kazakhstan ',
        countryCode: 'kz',
        dialCode: '+7',
        areaCodes: ['33', '7'],
        flag: '/flags/kz.svg',
    },
    {
        countryName: 'Kenya',
        countryCode: 'ke',
        dialCode: '+254',
        areaCodes: [],
        flag: '/flags/ke.svg',
    },
    {
        countryName: 'Kiribati',
        countryCode: 'ki',
        dialCode: '+686',
        areaCodes: [],
        flag: '/flags/ki.svg',
    },
    {
        countryName: 'Kosovo',
        countryCode: 'xk',
        dialCode: '+383',
        areaCodes: [],
        flag: '/flags/xk.svg',
    },
    {
        countryName: 'Kuwait ',
        countryCode: 'kw',
        dialCode: '+965',
        areaCodes: [],
        flag: '/flags/kw.svg',
    },
    {
        countryName: 'Kyrgyzstan ',
        countryCode: 'kg',
        dialCode: '+996',
        areaCodes: [],
        flag: '/flags/kg.svg',
    },
    {
        countryName: 'Laos ',
        countryCode: 'la',
        dialCode: '+856',
        areaCodes: [],
        flag: '/flags/la.svg',
    },
    {
        countryName: 'Latvia ',
        countryCode: 'lv',
        dialCode: '+371',
        areaCodes: [],
        flag: '/flags/lv.svg',
    },
    {
        countryName: 'Lebanon ',
        countryCode: 'lb',
        dialCode: '+961',
        areaCodes: [],
        flag: '/flags/lb.svg',
    },
    {
        countryName: 'Lesotho',
        countryCode: 'ls',
        dialCode: '+266',
        areaCodes: [],
        flag: '/flags/ls.svg',
    },
    {
        countryName: 'Liberia',
        countryCode: 'lr',
        dialCode: '+231',
        areaCodes: [],
        flag: '/flags/lr.svg',
    },
    {
        countryName: 'Libya ',
        countryCode: 'ly',
        dialCode: '+218',
        areaCodes: [],
        flag: '/flags/ly.svg',
    },
    {
        countryName: 'Liechtenstein',
        countryCode: 'li',
        dialCode: '+423',
        areaCodes: [],
        flag: '/flags/li.svg',
    },
    {
        countryName: 'Lithuania ',
        countryCode: 'lt',
        dialCode: '+370',
        areaCodes: [],
        flag: '/flags/lt.svg',
    },
    {
        countryName: 'Luxembourg',
        countryCode: 'lu',
        dialCode: '+352',
        areaCodes: [],
        flag: '/flags/lu.svg',
    },
    {
        countryName: 'Macau ',
        countryCode: 'mo',
        dialCode: '+853',
        areaCodes: [],
        flag: '/flags/mo.svg',
    },
    {
        countryName: 'Madagascar ',
        countryCode: 'mg',
        dialCode: '+261',
        areaCodes: [],
        flag: '/flags/mg.svg',
    },
    {
        countryName: 'Malawi',
        countryCode: 'mw',
        dialCode: '+265',
        areaCodes: [],
        flag: '/flags/mw.svg',
    },
    {
        countryName: 'Malaysia',
        countryCode: 'my',
        dialCode: '+60',
        areaCodes: [],
        flag: '/flags/my.svg',
    },
    {
        countryName: 'Maldives',
        countryCode: 'mv',
        dialCode: '+960',
        areaCodes: [],
        flag: '/flags/mv.svg',
    },
    {
        countryName: 'Mali',
        countryCode: 'ml',
        dialCode: '+223',
        areaCodes: [],
        flag: '/flags/ml.svg',
    },
    {
        countryName: 'Malta',
        countryCode: 'mt',
        dialCode: '+356',
        areaCodes: [],
        flag: '/flags/mt.svg',
    },
    {
        countryName: 'Marshall Islands',
        countryCode: 'mh',
        dialCode: '+692',
        areaCodes: [],
        flag: '/flags/mh.svg',
    },
    {
        countryName: 'Martinique',
        countryCode: 'mq',
        dialCode: '+596',
        areaCodes: [],
        flag: '/flags/mq.svg',
    },
    {
        countryName: 'Mauritania ',
        countryCode: 'mr',
        dialCode: '+222',
        areaCodes: [],
        flag: '/flags/mr.svg',
    },
    {
        countryName: 'Mauritius ',
        countryCode: 'mu',
        dialCode: '+230',
        areaCodes: [],
        flag: '/flags/mu.svg',
    },
    {
        countryName: 'Mayotte',
        countryCode: 'yt',
        dialCode: '+262',
        areaCodes: ['269', '639'],
        flag: '/flags/yt.svg',
    },
    {
        countryName: 'Mexico ',
        countryCode: 'mx',
        dialCode: '+52',
        areaCodes: [],
        flag: '/flags/mx.svg',
    },
    {
        countryName: 'Micronesia',
        countryCode: 'fm',
        dialCode: '+691',
        areaCodes: [],
        flag: '/flags/fm.svg',
    },
    {
        countryName: 'Moldova ',
        countryCode: 'md',
        dialCode: '+373',
        areaCodes: [],
        flag: '/flags/md.svg',
    },
    {
        countryName: 'Monaco',
        countryCode: 'mc',
        dialCode: '+377',
        areaCodes: [],
        flag: '/flags/mc.svg',
    },
    {
        countryName: 'Mongolia ',
        countryCode: 'mn',
        dialCode: '+976',
        areaCodes: [],
        flag: '/flags/mn.svg',
    },
    {
        countryName: 'Montenegro ',
        countryCode: 'me',
        dialCode: '+382',
        areaCodes: [],
        flag: '/flags/me.svg',
    },
    {
        countryName: 'Montserrat',
        countryCode: 'ms',
        dialCode: '+1',
        areaCodes: ['664'],
        flag: '/flags/ms.svg',
    },
    {
        countryName: 'Morocco ',
        countryCode: 'ma',
        dialCode: '+212',
        areaCodes: [],
        flag: '/flags/ma.svg',
    },
    {
        countryName: 'Mozambique ',
        countryCode: 'mz',
        dialCode: '+258',
        areaCodes: [],
        flag: '/flags/mz.svg',
    },
    {
        countryName: 'Myanmar ',
        countryCode: 'mm',
        dialCode: '+95',
        areaCodes: [],
        flag: '/flags/mm.svg',
    },
    {
        countryName: 'Namibia ',
        countryCode: 'na',
        dialCode: '+264',
        areaCodes: [],
        flag: '/flags/na.svg',
    },
    {
        countryName: 'Nauru',
        countryCode: 'nr',
        dialCode: '+674',
        areaCodes: [],
        flag: '/flags/nr.svg',
    },
    {
        countryName: 'Nepal ',
        countryCode: 'np',
        dialCode: '+977',
        areaCodes: [],
        flag: '/flags/np.svg',
    },
    {
        countryName: 'Netherlands ',
        countryCode: 'nl',
        dialCode: '+31',
        areaCodes: [],
        flag: '/flags/nl.svg',
    },
    {
        countryName: 'New Caledonia ',
        countryCode: 'nc',
        dialCode: '+687',
        areaCodes: [],
        flag: '/flags/nc.svg',
    },
    {
        countryName: 'New Zealand',
        countryCode: 'nz',
        dialCode: '+64',
        areaCodes: [],
        flag: '/flags/nz.svg',
    },
    {
        countryName: 'Nicaragua',
        countryCode: 'ni',
        dialCode: '+505',
        areaCodes: [],
        flag: '/flags/ni.svg',
    },
    {
        countryName: 'Niger ',
        countryCode: 'ne',
        dialCode: '+227',
        areaCodes: [],
        flag: '/flags/ne.svg',
    },
    {
        countryName: 'Nigeria',
        countryCode: 'ng',
        dialCode: '+234',
        areaCodes: [],
        flag: '/flags/ng.svg',
    },
    {
        countryName: 'Niue',
        countryCode: 'nu',
        dialCode: '+683',
        areaCodes: [],
        flag: '/flags/nu.svg',
    },
    {
        countryName: 'Norfolk Island',
        countryCode: 'nf',
        dialCode: '+672',
        areaCodes: [],
        flag: '/flags/nf.svg',
    },
    {
        countryName: 'North Korea ',
        countryCode: 'kp',
        dialCode: '+850',
        areaCodes: [],
        flag: '/flags/kp.svg',
    },
    {
        countryName: 'North Macedonia ',
        countryCode: 'mk',
        dialCode: '+389',
        areaCodes: [],
        flag: '/flags/mk.svg',
    },
    {
        countryName: 'Northern Mariana Islands',
        countryCode: 'mp',
        dialCode: '+1',
        areaCodes: ['670'],
        flag: '/flags/mp.svg',
    },
    {
        countryName: 'Norway ',
        countryCode: 'no',
        dialCode: '+47',
        areaCodes: [],
        flag: '/flags/no.svg',
    },
    {
        countryName: 'Oman ',
        countryCode: 'om',
        dialCode: '+968',
        areaCodes: [],
        flag: '/flags/om.svg',
    },
    {
        countryName: 'Pakistan ',
        countryCode: 'pk',
        dialCode: '+92',
        areaCodes: [],
        flag: '/flags/pk.svg',
    },
    {
        countryName: 'Palau',
        countryCode: 'pw',
        dialCode: '+680',
        areaCodes: [],
        flag: '/flags/pw.svg',
    },
    {
        countryName: 'Palestine ',
        countryCode: 'ps',
        dialCode: '+970',
        areaCodes: [],
        flag: '/flags/ps.svg',
    },
    {
        countryName: 'Panama ',
        countryCode: 'pa',
        dialCode: '+507',
        areaCodes: [],
        flag: '/flags/pa.svg',
    },
    {
        countryName: 'Papua New Guinea',
        countryCode: 'pg',
        dialCode: '+675',
        areaCodes: [],
        flag: '/flags/pg.svg',
    },
    {
        countryName: 'Paraguay',
        countryCode: 'py',
        dialCode: '+595',
        areaCodes: [],
        flag: '/flags/py.svg',
    },
    {
        countryName: 'Peru ',
        countryCode: 'pe',
        dialCode: '+51',
        areaCodes: [],
        flag: '/flags/pe.svg',
    },
    {
        countryName: 'Philippines',
        countryCode: 'ph',
        dialCode: '+63',
        areaCodes: [],
        flag: '/flags/ph.svg',
    },
    {
        countryName: 'Poland ',
        countryCode: 'pl',
        dialCode: '+48',
        areaCodes: [],
        flag: '/flags/pl.svg',
    },
    {
        countryName: 'Portugal',
        countryCode: 'pt',
        dialCode: '+351',
        areaCodes: [],
        flag: '/flags/pt.svg',
    },
    {
        countryName: 'Puerto Rico',
        countryCode: 'pr',
        dialCode: '+1',
        areaCodes: ['787', '939'],
        flag: '/flags/pr.svg',
    },
    {
        countryName: 'Qatar ',
        countryCode: 'qa',
        dialCode: '+974',
        areaCodes: [],
        flag: '/flags/qa.svg',
    },
    {
        countryName: 'Réunion ',
        countryCode: 're',
        dialCode: '+262',
        areaCodes: [],
        flag: '/flags/re.svg',
    },
    {
        countryName: 'Romania ',
        countryCode: 'ro',
        dialCode: '+40',
        areaCodes: [],
        flag: '/flags/ro.svg',
    },
    {
        countryName: 'Russia ',
        countryCode: 'ru',
        dialCode: '+7',
        areaCodes: [],
        flag: '/flags/ru.svg',
    },
    {
        countryName: 'Rwanda',
        countryCode: 'rw',
        dialCode: '+250',
        areaCodes: [],
        flag: '/flags/rw.svg',
    },
    {
        countryName: 'Saint Barthélemy',
        countryCode: 'bl',
        dialCode: '+590',
        areaCodes: [],
        flag: '/flags/bl.svg',
    },
    {
        countryName: 'Saint Helena',
        countryCode: 'sh',
        dialCode: '+290',
        areaCodes: [],
        flag: '/flags/sh.svg',
    },
    {
        countryName: 'Saint Kitts and Nevis',
        countryCode: 'kn',
        dialCode: '+1',
        areaCodes: ['869'],
        flag: '/flags/kn.svg',
    },
    {
        countryName: 'Saint Lucia',
        countryCode: 'lc',
        dialCode: '+1',
        areaCodes: ['758'],
        flag: '/flags/lc.svg',
    },
    {
        countryName: 'Saint Martin ',
        countryCode: 'mf',
        dialCode: '+590',
        areaCodes: [],
        flag: '/flags/mf.svg',
    },
    {
        countryName: 'Saint Pierre and Miquelon ',
        countryCode: 'pm',
        dialCode: '+508',
        areaCodes: [],
        flag: '/flags/pm.svg',
    },
    {
        countryName: 'Saint Vincent and the Grenadines',
        countryCode: 'vc',
        dialCode: '+1',
        areaCodes: ['784'],
        flag: '/flags/vc.svg',
    },
    {
        countryName: 'Samoa',
        countryCode: 'ws',
        dialCode: '+685',
        areaCodes: [],
        flag: '/flags/ws.svg',
    },
    {
        countryName: 'San Marino',
        countryCode: 'sm',
        dialCode: '+378',
        areaCodes: [],
        flag: '/flags/sm.svg',
    },
    {
        countryName: 'São Tomé and Príncipe ',
        countryCode: 'st',
        dialCode: '+239',
        areaCodes: [],
        flag: '/flags/st.svg',
    },
    {
        countryName: 'Saudi Arabia ',
        countryCode: 'sa',
        dialCode: '+966',
        areaCodes: [],
        flag: '/flags/sa.svg',
    },
    {
        countryName: 'Senegal ',
        countryCode: 'sn',
        dialCode: '+221',
        areaCodes: [],
        flag: '/flags/sn.svg',
    },
    {
        countryName: 'Serbia ',
        countryCode: 'rs',
        dialCode: '+381',
        areaCodes: [],
        flag: '/flags/rs.svg',
    },
    {
        countryName: 'Seychelles',
        countryCode: 'sc',
        dialCode: '+248',
        areaCodes: [],
        flag: '/flags/sc.svg',
    },
    {
        countryName: 'Sierra Leone',
        countryCode: 'sl',
        dialCode: '+232',
        areaCodes: [],
        flag: '/flags/sl.svg',
    },
    {
        countryName: 'Singapore',
        countryCode: 'sg',
        dialCode: '+65',
        areaCodes: [],
        flag: '/flags/sg.svg',
    },
    {
        countryName: 'Sint Maarten',
        countryCode: 'sx',
        dialCode: '+1',
        areaCodes: ['721'],
        flag: '/flags/sx.svg',
    },
    {
        countryName: 'Slovakia ',
        countryCode: 'sk',
        dialCode: '+421',
        areaCodes: [],
        flag: '/flags/sk.svg',
    },
    {
        countryName: 'Slovenia ',
        countryCode: 'si',
        dialCode: '+386',
        areaCodes: [],
        flag: '/flags/si.svg',
    },
    {
        countryName: 'Solomon Islands',
        countryCode: 'sb',
        dialCode: '+677',
        areaCodes: [],
        flag: '/flags/sb.svg',
    },
    {
        countryName: 'Somalia ',
        countryCode: 'so',
        dialCode: '+252',
        areaCodes: [],
        flag: '/flags/so.svg',
    },
    {
        countryName: 'South Africa',
        countryCode: 'za',
        dialCode: '+27',
        areaCodes: [],
        flag: '/flags/za.svg',
    },
    {
        countryName: 'South Korea ',
        countryCode: 'kr',
        dialCode: '+82',
        areaCodes: [],
        flag: '/flags/kr.svg',
    },
    {
        countryName: 'South Sudan ',
        countryCode: 'ss',
        dialCode: '+211',
        areaCodes: [],
        flag: '/flags/ss.svg',
    },
    {
        countryName: 'Spain ',
        countryCode: 'es',
        dialCode: '+34',
        areaCodes: [],
        flag: '/flags/es.svg',
    },
    {
        countryName: 'Sri Lanka ',
        countryCode: 'lk',
        dialCode: '+94',
        areaCodes: [],
        flag: '/flags/lk.svg',
    },
    {
        countryName: 'Sudan ',
        countryCode: 'sd',
        dialCode: '+249',
        areaCodes: [],
        flag: '/flags/sd.svg',
    },
    {
        countryName: 'Suriname',
        countryCode: 'sr',
        dialCode: '+597',
        areaCodes: [],
        flag: '/flags/sr.svg',
    },
    {
        countryName: 'Svalbard and Jan Mayen',
        countryCode: 'sj',
        dialCode: '+47',
        areaCodes: ['79'],
        flag: '/flags/sj.svg',
    },
    {
        countryName: 'Sweden ',
        countryCode: 'se',
        dialCode: '+46',
        areaCodes: [],
        flag: '/flags/se.svg',
    },
    {
        countryName: 'Switzerland ',
        countryCode: 'ch',
        dialCode: '+41',
        areaCodes: [],
        flag: '/flags/ch.svg',
    },
    {
        countryName: 'Syria ',
        countryCode: 'sy',
        dialCode: '+963',
        areaCodes: [],
        flag: '/flags/sy.svg',
    },
    {
        countryName: 'Taiwan ',
        countryCode: 'tw',
        dialCode: '+886',
        areaCodes: [],
        flag: '/flags/tw.svg',
    },
    {
        countryName: 'Tajikistan',
        countryCode: 'tj',
        dialCode: '+992',
        areaCodes: [],
        flag: '/flags/tj.svg',
    },
    {
        countryName: 'Tanzania',
        countryCode: 'tz',
        dialCode: '+255',
        areaCodes: [],
        flag: '/flags/tz.svg',
    },
    {
        countryName: 'Thailand ',
        countryCode: 'th',
        dialCode: '+66',
        areaCodes: [],
        flag: '/flags/th.svg',
    },
    {
        countryName: 'Timor-Leste',
        countryCode: 'tl',
        dialCode: '+670',
        areaCodes: [],
        flag: '/flags/tl.svg',
    },
    {
        countryName: 'Togo',
        countryCode: 'tg',
        dialCode: '+228',
        areaCodes: [],
        flag: '/flags/tg.svg',
    },
    {
        countryName: 'Tokelau',
        countryCode: 'tk',
        dialCode: '+690',
        areaCodes: [],
        flag: '/flags/tk.svg',
    },
    {
        countryName: 'Tonga',
        countryCode: 'to',
        dialCode: '+676',
        areaCodes: [],
        flag: '/flags/to.svg',
    },
    {
        countryName: 'Trinidad and Tobago',
        countryCode: 'tt',
        dialCode: '+1',
        areaCodes: ['868'],
        flag: '/flags/tt.svg',
    },
    {
        countryName: 'Tunisia ',
        countryCode: 'tn',
        dialCode: '+216',
        areaCodes: [],
        flag: '/flags/tn.svg',
    },
    {
        countryName: 'Turkey ',
        countryCode: 'tr',
        dialCode: '+90',
        areaCodes: [],
        flag: '/flags/tr.svg',
    },
    {
        countryName: 'Turkmenistan',
        countryCode: 'tm',
        dialCode: '+993',
        areaCodes: [],
        flag: '/flags/tm.svg',
    },
    {
        countryName: 'Turks and Caicos Islands',
        countryCode: 'tc',
        dialCode: '+1',
        areaCodes: ['649'],
        flag: '/flags/tc.svg',
    },
    {
        countryName: 'Tuvalu',
        countryCode: 'tv',
        dialCode: '+688',
        areaCodes: [],
        flag: '/flags/tv.svg',
    },
    {
        countryName: 'U.S. Virgin Islands',
        countryCode: 'vi',
        dialCode: '+1',
        areaCodes: ['340'],
        flag: '/flags/vi.svg',
    },
    {
        countryName: 'Uganda',
        countryCode: 'ug',
        dialCode: '+256',
        areaCodes: [],
        flag: '/flags/ug.svg',
    },
    {
        countryName: 'Ukraine ',
        countryCode: 'ua',
        dialCode: '+380',
        areaCodes: [],
        flag: '/flags/ua.svg',
    },
    {
        countryName: 'United Arab Emirates ',
        countryCode: 'ae',
        dialCode: '+971',
        areaCodes: [],
        flag: '/flags/ae.svg',
    },
    {
        countryName: 'United Kingdom',
        countryCode: 'gb',
        dialCode: '+44',
        areaCodes: [],
        flag: '/flags/gb.svg',
    },
    {
        countryName: 'United States',
        countryCode: 'us',
        dialCode: '+1',
        areaCodes: [],
        flag: '/flags/us.svg',
    },
    {
        countryName: 'Uruguay',
        countryCode: 'uy',
        dialCode: '+598',
        areaCodes: [],
        flag: '/flags/uy.svg',
    },
    {
        countryName: 'Uzbekistan ',
        countryCode: 'uz',
        dialCode: '+998',
        areaCodes: [],
        flag: '/flags/uz.svg',
    },
    {
        countryName: 'Vanuatu',
        countryCode: 'vu',
        dialCode: '+678',
        areaCodes: [],
        flag: '/flags/vu.svg',
    },
    {
        countryName: 'Vatican City ',
        countryCode: 'va',
        dialCode: '+39',
        areaCodes: ['06698'],
        flag: '/flags/va.svg',
    },
    {
        countryName: 'Venezuela',
        countryCode: 've',
        dialCode: '+58',
        areaCodes: [],
        flag: '/flags/ve.svg',
    },
    {
        countryName: 'Vietnam ',
        countryCode: 'vn',
        dialCode: '+84',
        areaCodes: [],
        flag: '/flags/vn.svg',
    },
    {
        countryName: 'Wallis and Futuna ',
        countryCode: 'wf',
        dialCode: '+681',
        areaCodes: [],
        flag: '/flags/wf.svg',
    },
    {
        countryName: 'Western Sahara ',
        countryCode: 'eh',
        dialCode: '+212',
        areaCodes: ['5288', '5289'],
        flag: '/flags/eh.svg',
    },
    {
        countryName: 'Yemen ',
        countryCode: 'ye',
        dialCode: '+967',
        areaCodes: [],
        flag: '/flags/ye.svg',
    },
    {
        countryName: 'Zambia',
        countryCode: 'zm',
        dialCode: '+260',
        areaCodes: [],
        flag: '/flags/zm.svg',
    },
    {
        countryName: 'Zimbabwe',
        countryCode: 'zw',
        dialCode: '+263',
        areaCodes: [],
        flag: '/flags/zw.svg',
    },
    {
        countryName: 'Åland Islands',
        countryCode: 'ax',
        dialCode: '+358',
        areaCodes: ['18'],
        flag: '/flags/ax.svg',
    },
];

export const timezoneList = [
    {
        label: '(UTC-11:00) Midway Island',
        value: 'Pacific/Midway',
    },
    {
        label: '(UTC-11:00) Samoa',
        value: 'Pacific/Samoa',
    },
    {
        label: '(UTC-10:00) Hawaii',
        value: 'Pacific/Honolulu',
    },
    {
        label: '(UTC-09:00) Alaska',
        value: 'US/Alaska',
    },
    {
        label: '(UTC-08:00) Pacific Time (US & Canada)',
        value: 'America/Los_Angeles',
    },
    {
        label: '(UTC-08:00) Tijuana',
        value: 'America/Tijuana',
    },
    {
        label: '(UTC-07:00) Arizona',
        value: 'US/Arizona',
    },
    {
        label: '(UTC-07:00) Chihuahua',
        value: 'America/Chihuahua',
    },
    {
        label: '(UTC-07:00) La Paz',
        value: 'America/Chihuahua',
    },
    {
        label: '(UTC-07:00) Mazatlan',
        value: 'America/Mazatlan',
    },
    {
        label: '(UTC-07:00) Mountain Time (US & Canada)',
        value: 'US/Mountain',
    },
    {
        label: '(UTC-06:00) Central America',
        value: 'America/Managua',
    },
    {
        label: '(UTC-06:00) Central Time (US & Canada)',
        value: 'US/Central',
    },
    {
        label: '(UTC-06:00) Guadalajara',
        value: 'America/Mexico_City',
    },
    {
        label: '(UTC-06:00) Mexico City',
        value: 'America/Mexico_City',
    },
    {
        label: '(UTC-06:00) Monterrey',
        value: 'America/Monterrey',
    },
    {
        label: '(UTC-06:00) Saskatchewan',
        value: 'Canada/Saskatchewan',
    },
    {
        label: '(UTC-05:00) Bogota',
        value: 'America/Bogota',
    },
    {
        label: '(UTC-05:00) Eastern Time (US & Canada)',
        value: 'US/Eastern',
    },
    {
        label: '(UTC-05:00) Indiana (East)',
        value: 'US/East-Indiana',
    },
    {
        label: '(UTC-05:00) Lima',
        value: 'America/Lima',
    },
    {
        label: '(UTC-05:00) Quito',
        value: 'America/Bogota',
    },
    {
        label: '(UTC-04:00) Atlantic Time (Canada)',
        value: 'Canada/Atlantic',
    },
    {
        label: '(UTC-04:30) Caracas',
        value: 'America/Caracas',
    },
    {
        label: '(UTC-04:00) La Paz',
        value: 'America/La_Paz',
    },
    {
        label: '(UTC-04:00) Santiago',
        value: 'America/Santiago',
    },
    {
        label: '(UTC-03:30) Newfoundland',
        value: 'Canada/Newfoundland',
    },
    {
        label: '(UTC-03:00) Brasilia',
        value: 'America/Sao_Paulo',
    },
    {
        label: '(UTC-03:00) Buenos Aires',
        value: 'America/Argentina/Buenos_Aires',
    },
    {
        label: '(UTC-03:00) Georgetown',
        value: 'America/Argentina/Buenos_Aires',
    },
    {
        label: '(UTC-03:00) Greenland',
        value: 'America/Godthab',
    },
    {
        label: '(UTC-02:00) Mid-Atlantic',
        value: 'America/Noronha',
    },
    {
        label: '(UTC-01:00) Azores',
        value: 'Atlantic/Azores',
    },
    {
        label: '(UTC-01:00) Cape Verde Is.',
        value: 'Atlantic/Cape_Verde',
    },
    {
        label: '(UTC+00:00) Casablanca',
        value: 'Africa/Casablanca',
    },
    {
        label: '(UTC+00:00) Edinburgh',
        value: 'Europe/London',
    },
    {
        label: '(UTC+00:00) Greenwich Mean Time : Dublin',
        value: 'Etc/Greenwich',
    },
    {
        label: '(UTC+00:00) Lisbon',
        value: 'Europe/Lisbon',
    },
    {
        label: '(UTC+00:00) London',
        value: 'Europe/London',
    },
    {
        label: '(UTC+00:00) Monrovia',
        value: 'Africa/Monrovia',
    },
    {
        label: '(UTC+00:00) UTC',
        value: 'UTC',
    },
    {
        label: '(UTC+01:00) Amsterdam',
        value: 'Europe/Amsterdam',
    },
    {
        label: '(UTC+01:00) Belgrade',
        value: 'Europe/Belgrade',
    },
    {
        label: '(UTC+01:00) Berlin',
        value: 'Europe/Berlin',
    },
    {
        label: '(UTC+01:00) Bern',
        value: 'Europe/Berlin',
    },
    {
        label: '(UTC+01:00) Bratislava',
        value: 'Europe/Bratislava',
    },
    {
        label: '(UTC+01:00) Brussels',
        value: 'Europe/Brussels',
    },
    {
        label: '(UTC+01:00) Budapest',
        value: 'Europe/Budapest',
    },
    {
        label: '(UTC+01:00) Copenhagen',
        value: 'Europe/Copenhagen',
    },
    {
        label: '(UTC+01:00) Ljubljana',
        value: 'Europe/Ljubljana',
    },
    {
        label: '(UTC+01:00) Madrid',
        value: 'Europe/Madrid',
    },
    {
        label: '(UTC+01:00) Paris',
        value: 'Europe/Paris',
    },
    {
        label: '(UTC+01:00) Prague',
        value: 'Europe/Prague',
    },
    {
        label: '(UTC+01:00) Rome',
        value: 'Europe/Rome',
    },
    {
        label: '(UTC+01:00) Sarajevo',
        value: 'Europe/Sarajevo',
    },
    {
        label: '(UTC+01:00) Skopje',
        value: 'Europe/Skopje',
    },
    {
        label: '(UTC+01:00) Stockholm',
        value: 'Europe/Stockholm',
    },
    {
        label: '(UTC+01:00) Vienna',
        value: 'Europe/Vienna',
    },
    {
        label: '(UTC+01:00) Warsaw',
        value: 'Europe/Warsaw',
    },
    {
        label: '(UTC+01:00) West Central Africa',
        value: 'Africa/Lagos',
    },
    {
        label: '(UTC+01:00) Zagreb',
        value: 'Europe/Zagreb',
    },
    {
        label: '(UTC+02:00) Athens',
        value: 'Europe/Athens',
    },
    {
        label: '(UTC+02:00) Bucharest',
        value: 'Europe/Bucharest',
    },
    {
        label: '(UTC+02:00) Cairo',
        value: 'Africa/Cairo',
    },
    {
        label: '(UTC+02:00) Harare',
        value: 'Africa/Harare',
    },
    {
        label: '(UTC+02:00) Helsinki',
        value: 'Europe/Helsinki',
    },
    {
        label: '(UTC+02:00) Istanbul',
        value: 'Europe/Istanbul',
    },
    {
        label: '(UTC+02:00) Jerusalem',
        value: 'Asia/Jerusalem',
    },
    {
        label: '(UTC+02:00) Kyiv',
        value: 'Europe/Helsinki',
    },
    {
        label: '(UTC+02:00) Pretoria',
        value: 'Africa/Johannesburg',
    },
    {
        label: '(UTC+02:00) Riga',
        value: 'Europe/Riga',
    },
    {
        label: '(UTC+02:00) Sofia',
        value: 'Europe/Sofia',
    },
    {
        label: '(UTC+02:00) Tallinn',
        value: 'Europe/Tallinn',
    },
    {
        label: '(UTC+02:00) Vilnius',
        value: 'Europe/Vilnius',
    },
    {
        label: '(UTC+03:00) Baghdad',
        value: 'Asia/Baghdad',
    },
    {
        label: '(UTC+03:00) Kuwait',
        value: 'Asia/Kuwait',
    },
    {
        label: '(UTC+03:00) Minsk',
        value: 'Europe/Minsk',
    },
    {
        label: '(UTC+03:00) Nairobi',
        value: 'Africa/Nairobi',
    },
    {
        label: '(UTC+03:00) Riyadh',
        value: 'Asia/Riyadh',
    },
    {
        label: '(UTC+03:00) Volgograd',
        value: 'Europe/Volgograd',
    },
    {
        label: '(UTC+03:30) Tehran',
        value: 'Asia/Tehran',
    },
    {
        label: '(UTC+04:00) Abu Dhabi',
        value: 'Asia/Muscat',
    },
    {
        label: '(UTC+04:00) Baku',
        value: 'Asia/Baku',
    },
    {
        label: '(UTC+04:00) Moscow',
        value: 'Europe/Moscow',
    },
    {
        label: '(UTC+04:00) Muscat',
        value: 'Asia/Muscat',
    },
    {
        label: '(UTC+04:00) St. Petersburg',
        value: 'Europe/Moscow',
    },
    {
        label: '(UTC+04:00) Tbilisi',
        value: 'Asia/Tbilisi',
    },
    {
        label: '(UTC+04:00) Dubai',
        value: 'Asia/Dubai',
    },
    {
        label: '(UTC+04:00) Yerevan',
        value: 'Asia/Yerevan',
    },
    {
        label: '(UTC+04:30) Kabul',
        value: 'Asia/Kabul',
    },
    {
        label: '(UTC+05:00) Islamabad',
        value: 'Asia/Karachi',
    },
    {
        label: '(UTC+05:00) Karachi',
        value: 'Asia/Karachi',
    },
    {
        label: '(UTC+05:00) Tashkent',
        value: 'Asia/Tashkent',
    },
    {
        label: '(UTC+05:30) Kolkata',
        value: 'Asia/Kolkata',
    },
    {
        label: '(UTC+05:45) Kathmandu',
        value: 'Asia/Katmandu',
    },
    {
        label: '(UTC+06:00) Almaty',
        value: 'Asia/Almaty',
    },
    {
        label: '(UTC+06:00) Astana',
        value: 'Asia/Dhaka',
    },
    {
        label: '(UTC+06:00) Dhaka',
        value: 'Asia/Dhaka',
    },
    {
        label: '(UTC+06:00) Ekaterinburg',
        value: 'Asia/Yekaterinburg',
    },
    {
        label: '(UTC+06:30) Rangoon',
        value: 'Asia/Rangoon',
    },
    {
        label: '(UTC+07:00) Bangkok',
        value: 'Asia/Bangkok',
    },
    {
        label: '(UTC+07:00) Jakarta',
        value: 'Asia/Jakarta',
    },
    {
        label: '(UTC+07:00) Novosibirsk',
        value: 'Asia/Novosibirsk',
    },
    {
        label: '(UTC+08:00) Beijing',
        value: 'Asia/Hong_Kong',
    },
    {
        label: '(UTC+08:00) Chongqing',
        value: 'Asia/Chongqing',
    },
    {
        label: '(UTC+08:00) Hong Kong',
        value: 'Asia/Hong_Kong',
    },
    {
        label: '(UTC+08:00) Krasnoyarsk',
        value: 'Asia/Krasnoyarsk',
    },
    {
        label: '(UTC+08:00) Kuala Lumpur',
        value: 'Asia/Kuala_Lumpur',
    },
    {
        label: '(UTC+08:00) Perth',
        value: 'Australia/Perth',
    },
    {
        label: '(UTC+08:00) Singapore',
        value: 'Asia/Singapore',
    },
    {
        label: '(UTC+08:00) Taipei',
        value: 'Asia/Taipei',
    },
    {
        label: '(UTC+08:00) Ulaan Bataar',
        value: 'Asia/Ulan_Bator',
    },
    {
        label: '(UTC+08:00) Urumqi',
        value: 'Asia/Urumqi',
    },
    {
        label: '(UTC+09:00) Irkutsk',
        value: 'Asia/Irkutsk',
    },
    {
        label: '(UTC+09:00) Osaka',
        value: 'Asia/Tokyo',
    },
    {
        label: '(UTC+09:00) Sapporo',
        value: 'Asia/Tokyo',
    },
    {
        label: '(UTC+09:00) Seoul',
        value: 'Asia/Seoul',
    },
    {
        label: '(UTC+09:00) Tokyo',
        value: 'Asia/Tokyo',
    },
    {
        label: '(UTC+09:30) Adelaide',
        value: 'Australia/Adelaide',
    },
    {
        label: '(UTC+09:30) Darwin',
        value: 'Australia/Darwin',
    },
    {
        label: '(UTC+10:00) Brisbane',
        value: 'Australia/Brisbane',
    },
    {
        label: '(UTC+10:00) Canberra',
        value: 'Australia/Canberra',
    },
    {
        label: '(UTC+10:00) Guam',
        value: 'Pacific/Guam',
    },
    {
        label: '(UTC+10:00) Hobart',
        value: 'Australia/Hobart',
    },
    {
        label: '(UTC+10:00) Melbourne',
        value: 'Australia/Melbourne',
    },
    {
        label: '(UTC+10:00) Port Moresby',
        value: 'Pacific/Port_Moresby',
    },
    {
        label: '(UTC+10:00) Sydney',
        value: 'Australia/Sydney',
    },
    {
        label: '(UTC+10:00) Yakutsk',
        value: 'Asia/Yakutsk',
    },
    {
        label: '(UTC+11:00) Vladivostok',
        value: 'Asia/Vladivostok',
    },
    {
        label: '(UTC+12:00) Auckland',
        value: 'Pacific/Auckland',
    },
    {
        label: '(UTC+12:00) Fiji',
        value: 'Pacific/Fiji',
    },
    {
        label: '(UTC+12:00) International Date Line West',
        value: 'Pacific/Kwajalein',
    },
    {
        label: '(UTC+12:00) Kamchatka',
        value: 'Asia/Kamchatka',
    },
    {
        label: '(UTC+12:00) Magadan',
        value: 'Asia/Magadan',
    },
    {
        label: '(UTC+12:00) Marshall Is.',
        value: 'Pacific/Fiji',
    },
    {
        label: '(UTC+12:00) New Caledonia',
        value: 'Asia/Magadan',
    },
    {
        label: '(UTC+12:00) Solomon Is.',
        value: 'Asia/Magadan',
    },
    {
        label: '(UTC+12:00) Wellington',
        value: 'Pacific/Auckland',
    },
    {
        label: "(UTC+13:00) Nuku'alofa",
        value: 'Pacific/Tongatapu',
    },
];

export const timezoneOption = [
    {
        label: 'Select Timezone',
        value: '',
    },
    ...timezoneList.map((tz) => ({
        label: tz.label,
        value: tz.value,
    })),
];

export const themeOption = Object.values(UserThemeEnum).map((theme) => ({
    label: theme,
    value: theme,
}));

export const roleOption = Object.values(UserRoleEnum).map((role) => ({
    label: capitalize(role),
    value: role,
}));

export const statusOption = Object.values(UserStatusEnum).map((status) => ({
    label: capitalize(status),
    value: status,
}));

export const resolutionOption = [
    { width: 2992, height: 2000 },
    { width: 3456, height: 2304 },
    { width: 3984, height: 2656 },
    { width: 4496, height: 3000 },
    { width: 4512, height: 3000 },
    { width: 5184, height: 3456 },
    { width: 6000, height: 4000 },
];
