import { CircularProgress, List, ListItem } from '@hyperflake/react-ui-library';
import { FloatingElementOptions, useFloatingElement } from '@library/floating';
import {
    ChangeEvent,
    DetailedHTMLProps,
    FocusEvent,
    ForwardedRef,
    InputHTMLAttributes,
    ReactNode,
    useCallback,
    useMemo,
} from 'react';

interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    ref?: ForwardedRef<HTMLInputElement>;
}

interface AutoCompleteProps<T>
    extends Omit<InputProps, 'ref' | 'value' | 'onChange' | 'size'>,
        Pick<FloatingElementOptions, 'closeOnScroll'> {
    value: string;
    onChange: (value: string) => void;
    options: T[];
    onOptionSelect: (option: T) => void;
    renderOption?: (option: T) => ReactNode;
    renderInput?: (inputProps: Omit<InputProps, 'ref' | 'value' | 'onChange' | 'size'>) => ReactNode;
    searchableFields?: string[];
    isLoading?: boolean;
}

const AutoComplete = <T extends { value: string }>(props: AutoCompleteProps<T>) => {
    const {
        value,
        onChange,
        options,
        renderInput,
        onOptionSelect,
        renderOption,
        searchableFields,
        onFocus,
        isLoading = false,
        ...rest
    } = props;

    const _searchableFields = useMemo(() => ['value', ...searchableFields], [searchableFields]);

    const { context, refs, getReferenceProps, getFloatingProps } = useFloatingElement<HTMLInputElement>({
        placement: 'bottom-start',
        offset: 8,
        triggers: [],
    });

    const handleFocus = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            context.onOpenChange(true);

            onFocus?.(e);
        },
        [context, onFocus]
    );

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            context.onOpenChange(true);

            onChange?.(e.target.value);
        },
        [context, onChange]
    );

    const handleSelect = useCallback(
        (option: T) => {
            refs.domReference.current?.focus();

            onOptionSelect(option);

            context.onOpenChange(false);
        },
        [context, onOptionSelect, refs.domReference]
    );

    const inputProps = useMemo(() => {
        return {
            ...rest,
            ...getReferenceProps(),
            ref: refs.setReference,
            value: value,
            onChange: handleChange,
            onFocus: handleFocus,
            type: 'text',
            autoComplete: 'off',
        };
    }, [getReferenceProps, handleChange, handleFocus, refs.setReference, rest, value]);

    const searchOptions = useCallback(() => {
        return options.filter((option: any) => {
            return _searchableFields.some((field) => {
                return option[field]?.toLowerCase().startsWith(value.toString().toLowerCase());
            });
        });
    }, [_searchableFields, options, value]);

    const filteredOptions = searchOptions();

    return (
        <>
            <div className="relative w-full">
                {renderInput ? renderInput(inputProps) : <input {...inputProps} />}
                {isLoading && (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <CircularProgress size={20} />
                    </div>
                )}
            </div>

            {context.open && filteredOptions.length > 0 && (
                <List
                    {...getFloatingProps()}
                    style={{ ...context.floatingStyles }}
                    ref={refs.setFloating}
                    className="w-full z-10 "
                >
                    {filteredOptions.map((option, i) => {
                        return (
                            <div className="border-b" key={i} onClick={() => handleSelect(option)}>
                                {renderOption?.(option) || option.value}
                            </div>
                        );
                    })}
                </List>
            )}
        </>
    );
};

AutoComplete.defaultProps = {
    searchableFields: [],
};

export default AutoComplete;
