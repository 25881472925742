import { Tooltip } from "@library/tooltip";
import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
// import Tooltip from './Tooltip';
import { twMerge } from "tailwind-merge";

interface OverflowTooltipProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
}

const OverflowTooltip: FC<OverflowTooltipProps> = (props) => {
  const { children, className, ...rest } = props;

  const containerRef = useRef(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    setIsOverflowed(container.scrollWidth > container.clientWidth);
  }, [children]);

  return (
    <Tooltip
      content={isOverflowed ? children : null}
      contentClassName="max-w-lg z-[100]"
      placement="bottom-start"
      className="overflow-hidden"
      interactive
    >
      <div
        {...rest}
        ref={containerRef}
        className={twMerge("truncate", className)}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTooltip;
