import { FC, ReactNode } from 'react';

interface BlurBackgroundImageProps {
    children: ReactNode;
    src: string;
}

const BlurBackgroundImage: FC<BlurBackgroundImageProps> = (props) => {
    const { children, src } = props;

    return (
        <div
            className="relative bg-no-repeat bg-cover bg-center h-full w-full"
            style={{ backgroundImage: `url(${src})` }}
        >
            <div className="bg-black/15 backdrop-blur-[100px]">
                <div className="relative">{children}</div>
            </div>
        </div>
    );
};

export default BlurBackgroundImage;
