import { getCameraOfProjectById } from '@api/services/camera.service';
import useDataQuery from '@hooks/use-data-query';

const useCameraData = (clientId: string, projectId: string, cameraId: string) => {
    const cameraQuery = useDataQuery(
        ['camera-query', clientId, projectId, cameraId],
        () => getCameraOfProjectById(clientId, projectId, cameraId),
        {
            enabled: Boolean(cameraId),
        }
    );

    return {
        isLoading: cameraQuery.isLoading,
        camera: cameraQuery.data,
        setData: cameraQuery.setData,
    };
};

export default useCameraData;
