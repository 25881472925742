const Icon = (props: any) => {
    return (
        <svg width={16} height={16} viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.1462 22.409L13.8689 18.7077L8.99408 13.5704C6.9365 11.4023 3.60076 11.4023 1.54318 13.5704C-0.514395 15.739 -0.514395 19.2544 1.54318 21.4227C1.72532 21.6147 1.90745 21.8066 2.08958 21.9985C2.55414 22.4881 3.01869 22.9779 3.483 23.4672L5.3567 25.4418C6.01886 26.1394 6.68102 26.8375 7.34318 27.535L9.07517 29.3602C9.43918 29.7438 9.77724 30.1556 10.1667 30.5116C10.2802 30.6152 10.4017 30.7091 10.5247 30.8016C10.3196 30.632 10.1136 30.4637 9.92919 30.2579C7.93248 28.0275 8.02954 24.5132 10.1462 22.409Z"
                fill="#FFCC48"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M38.5638 1.74219C36.5671 -0.48822 33.2323 -0.590762 31.1156 1.51371L14.3149 18.2172L10.1396 22.4098C8.02293 24.5141 7.92587 28.0283 9.92258 30.2588C10.107 30.4646 10.313 30.6329 10.5179 30.8025C10.6401 30.8942 10.7606 30.9878 10.8789 31.0843C10.957 31.1382 11.0403 31.179 11.1206 31.2282C11.8886 31.698 12.7369 31.972 13.6016 31.9988H13.6019C13.8559 32.0064 14.1093 31.9762 14.3623 31.9454C14.4978 31.9262 14.6333 31.9144 14.7678 31.8842C14.8803 31.8608 14.9901 31.8226 15.1011 31.7911C15.0824 31.7969 15.0647 31.8074 15.0459 31.8129C15.8633 31.5831 16.6447 31.1677 17.3034 30.5127L38.347 9.59109C40.4634 7.48689 40.5605 3.9726 38.5638 1.74219Z"
                fill="#FE5C55"
            />
        </svg>
    );
};
export default Icon;
