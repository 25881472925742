import { ProjectStatusEnum } from '@api/enum';
import { PermissionEnum } from '@api/enum/permission.enum';
import { IProject } from '@api/models';
import useNavName from '@hooks/useNavName';
import { Chip, Table } from '@hyperflake/react-ui-library';
import { DeleteShapeIcon, EditIcon } from '@icon/index';
import { Tooltip } from '@library/tooltip';
import upperCase from 'lodash.uppercase';
import { acitivityColor } from 'modules/projects/helpers/project.utils';
import useProjectListData from 'modules/projects/hooks/useProjectListData';
import useSubProjectListData from 'modules/projects/hooks/useSubProjectListData';
import EventBlocker from 'modules/shared/components/EventBlocker/EventBlocker';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const SubProjectList = () => {
    const navigate = useNavigate();
    const { clientId, projectId } = useParams();
    // const { clientName } = useNavName(clientId);
    const projectListData = useSubProjectListData(clientId, projectId);
    const { hasPermission } = useAuthPermission();
    return (
        <PageTemplate>
            <PageHeading>Projects</PageHeading>

            <Table
                borderStyle="divider"
                className="mt-6"
                hover
                isLoading={projectListData.isLoading}
                // data={sortProjectHierarchical() as IProject[]}
                data={projectListData.data}
                columns={[
                    {
                        header: 'ID',
                    },
                    {
                        header: 'Name',
                    },
                    {
                        header: 'Location',
                    },
                    {
                        header: 'Parent',
                    },
                    {
                        header: 'Activity',
                    },
                    {
                        header: 'Status',
                    },
                    {
                        header: 'Action',
                    },
                ]}
                renderRow={(row) => (
                    <Table.Row
                        key={row._id}
                        onClick={() => {
                            row.hasCameras
                                ? navigate(`/clients/${clientId}/projects/${row._id}/cameras`)
                                : navigate(`/clients/${clientId}/projects/${row._id}`);
                        }}
                    >
                        <Table.Cell>{row._id}</Table.Cell>
                        <Table.Cell>
                            <div>{row.name}</div>
                        </Table.Cell>
                        <Table.Cell>
                            <div>{row.location.name}</div>
                        </Table.Cell>
                        <Table.Cell>{row.parentId ? row.parentId : '-'}</Table.Cell>
                        <Table.Cell>
                            {row?.activity ? (
                                <Chip
                                    variant="shaded"
                                    color={acitivityColor(row?.activity)}
                                    label={upperCase(row?.activity)}
                                    size={'sm'}
                                />
                            ) : (
                                <div className="">-</div>
                            )}
                        </Table.Cell>
                        <Table.Cell>
                            {
                                <Chip
                                    variant="shaded"
                                    color={row.status === ProjectStatusEnum.ACTIVE ? 'success' : 'warning'}
                                    label={row.status}
                                    size={'sm'}
                                />
                            }
                        </Table.Cell>
                        <Table.Cell>
                            <EventBlocker stopPropagation preventDefault>
                                <>
                                    {/* <Dropdown placement="bottom-start" showArrow>
                                <DropdownTrigger className="w-4 h-4 rounded-full p-4">
                                    <EllipsisVerticalIcon />
                                </DropdownTrigger>

                                <DropdownList className="min-w-[100px]">
                                    <DropdownItem onClick={() => editDrawerDialog.show(row._id)}>
                                        Edit
                                    </DropdownItem>

                                    {hasAdminPermission ? (
                                        <DropdownItem
                                            onClick={() => confirmationsDrawerDialog.show(row)}
                                            className="text-danger"
                                        >
                                            Delete
                                        </DropdownItem>
                                    ) : (
                                        <Tooltip
                                            placement="left"
                                            content={'Only Admins are authorized to delete project'}
                                        >
                                            <DropdownItem
                                                className="text-gray-300 cursor-not-allowed"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                Delete
                                            </DropdownItem>
                                        </Tooltip>
                                    )}
                                </DropdownList>
                            </Dropdown> */}
                                    <EventBlocker stopPropagation preventDefault>
                                        <div className="grid gap-2 grid-cols-2">
                                            <button
                                                onClick={
                                                    () => {}
                                                    // editDrawerDialog.show(row._id)
                                                }
                                            >
                                                <EditIcon />
                                            </button>
                                            {hasPermission(PermissionEnum.PROJECT_DELETE) ? (
                                                <button
                                                    onClick={
                                                        () => {}
                                                        // confirmationsDrawerDialog.show(row)
                                                    }
                                                >
                                                    <DeleteShapeIcon />
                                                </button>
                                            ) : (
                                                <Tooltip
                                                    placement="left"
                                                    content={'Only Admins are authorized to delete project'}
                                                >
                                                    <button className="text-gray-300 cursor-not-allowed">
                                                        <DeleteShapeIcon />
                                                    </button>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </EventBlocker>
                                </>
                            </EventBlocker>
                        </Table.Cell>
                    </Table.Row>
                )}
            />
        </PageTemplate>
    );
};

export default SubProjectList;
