import { Card, CardProps } from '@hyperflake/react-ui-library';
import EventBlocker from 'modules/shared/components/EventBlocker/EventBlocker';
import { ReactNode } from 'react';

interface GalleryCardMediaProps {
    children: ReactNode;
}

const GalleryCardMedia = (props: GalleryCardMediaProps) => {
    const { children } = props;

    return (
        <div className="mb-5">
            <div className="w-full aspect-image rounded-lg object-cover object-center">{children}</div>
        </div>
    );
};

interface GalleryCardActionBarProps {
    children: ReactNode;
}

const GalleryCardActionBar = (props: GalleryCardActionBarProps) => {
    const { children } = props;

    return (
        <EventBlocker className="absolute top-6 right-6 z-[1] flex items-center gap-2" stopPropagation preventDefault>
            {children}
        </EventBlocker>
    );
};

interface GalleryCardTitleProps {
    children: ReactNode;
}

const GalleryCardTitle = (props: GalleryCardTitleProps) => {
    const { children } = props;

    return <div className="font-semibold">{children}</div>;
};

interface GalleryCardFooterProps {
    children: ReactNode;
}

const GalleryCardFooter = (props: GalleryCardFooterProps) => {
    const { children } = props;

    return (
        <div className="mt-auto">
            <div className="mt-4">{children}</div>
        </div>
    );
};

interface GalleryCardFooterItemProps {
    label: ReactNode;
    content: ReactNode;
}

const GalleryCardFooterItem = (props: GalleryCardFooterItemProps) => {
    const { label, content } = props;

    return (
        <div>
            <div className="text-[11px] text-grayscale-500">{label}</div>
            <div className="text-xs text-grayscale-900 font-medium mt-2">{content}</div>
        </div>
    );
};

interface GalleryCardProps extends CardProps<'div'> {
    children: ReactNode;
}

const GalleryCard = (props: GalleryCardProps) => {
    const { children, ...rest } = props;

    return (
        <Card
            {...rest}
            className="relative rounded-xl cursor-pointer transition-shadow duration-300 hover:shadow-[0px_4px_16px_rgba(4,34,99,0.08)] h-full"
        >
            <Card.Body className="relative p-4">{children}</Card.Body>
        </Card>
    );
};

GalleryCard.Media = GalleryCardMedia;
GalleryCard.ActionBar = GalleryCardActionBar;
GalleryCard.Title = GalleryCardTitle;
GalleryCard.Footer = GalleryCardFooter;
GalleryCard.FooterItem = GalleryCardFooterItem;

export default GalleryCard;
