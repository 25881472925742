import { ConsoleUserRoleEnum, UserRoleEnum } from '@api/enum';
import { Chip } from '@hyperflake/react-ui-library';
import { useMemo } from 'react';

interface RoleChipProps {
    status: UserRoleEnum | ConsoleUserRoleEnum;
}

const RoleChip = (props: RoleChipProps) => {
    const { status } = props;

    const color = useMemo(() => {
        let statusColor: 'primary' | 'warning' | 'success' | 'danger' | 'secondary';

        switch (status) {
            case UserRoleEnum.ADMIN:
                statusColor = 'success';
                break;
            case UserRoleEnum.EDITOR:
                statusColor = 'primary';
                break;
            case UserRoleEnum.VIEWER:
                statusColor = 'warning';
                break;
            default:
                statusColor = 'primary';
                break;
        }
        return statusColor;
    }, []);

    return <Chip label={status} variant={'outlined'} color={color} />;
};

export default RoleChip;
