import useResizeObserver from "@react-hook/resize-observer";
import { useCallback, useRef, useState } from "react";

interface IDimension {
  width: number;
  height: number;
}

interface BoxDimensionOptions {
  aspectRatio: number;
  containerWidth: number;
  containerHeight: number;
}

const useBoxDimensions = (options: BoxDimensionOptions) => {
  const { aspectRatio, containerWidth, containerHeight } = options;

  const [dimensions, setDimensions] = useState<IDimension>({
    width: 0,
    height: 0,
  });

  const resizeContainerRef = useRef(null);

  const updateDimension = useCallback(() => {
    const boxAspectRatio = containerWidth / containerHeight;

    let height: number, width: number;

    if (aspectRatio < boxAspectRatio) {
      height = containerHeight;
      width = height * aspectRatio;
    } else {
      width = containerWidth;
      height = width / aspectRatio;
    }

    setDimensions({ width, height });
  }, [aspectRatio, containerHeight, containerWidth]);

  useResizeObserver(resizeContainerRef.current, () => {
    updateDimension();
  });

  const resizeContainerRefSetter = useCallback(
    (node: any) => {
      if (node !== null) {
        resizeContainerRef.current = node;

        updateDimension();
      }
    },
    [updateDimension]
  );

  return {
    refs: {
      reference: resizeContainerRef,
      setReference: resizeContainerRefSetter,
    },
    dimensions,
  };
};

export default useBoxDimensions;
