import { getProjectHierarchy } from '@api/services/project.service';
import useDataQuery from '@hooks/use-data-query';
import { Breadcrumb, IBreadcrumbItem } from '@hyperflake/react-ui-library';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface AppendItemsProps {
    label: string;
    to: string;
}
interface ProjectHierarchyBreadcrumbProps {
    to?: string;
    appendItems?: AppendItemsProps[];
}

const ProjectHierarchyBreadcrumb = (props: ProjectHierarchyBreadcrumbProps) => {
    const { to, appendItems } = props;

    const { clientId, projectId } = useParams();
    const navigate = useNavigate();

    const projectHierarchyQuery = useDataQuery(
        [clientId, 'projectHierarchy', projectId],
        () => getProjectHierarchy(clientId, projectId),
        {
            enabled: Boolean(projectId),
        }
    );

    const items = useMemo(() => {
        if (projectHierarchyQuery.isLoading || projectHierarchyQuery.isError) return [...appendItems];

        const projectHeirarchyList = projectHierarchyQuery.data.map((p, i) => {
            const href = `/clients/${clientId}/projects/${p._id}`;
            // const href =
            //     i < projectHierarchyQuery.data.length - 1
            //         ? `/clients/${clientId}/projects/${p._id}`
            //         : `/clients/${clientId}/projects/${p._id}`;

            return { label: p.name, to: href };
        });

        return [...appendItems, ...projectHeirarchyList];
    }, [projectHierarchyQuery.isLoading, projectHierarchyQuery.data, appendItems, to]);

    const transformedItems: IBreadcrumbItem[] = items.map((item) => ({
        label: item.label,
        onClick: () => navigate(item.to),
    }));

    return <Breadcrumb items={transformedItems} />;
};

ProjectHierarchyBreadcrumb.defaultProps = {
    appendItems: [],
    to: '',
};

export default ProjectHierarchyBreadcrumb;
