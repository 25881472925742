import { IProject } from '@api/models';
import { getProjectsByClientId } from '@api/services/project.service';
import useDataQuery from 'modules/shared/hooks/use-data-query';
import { produce } from 'immer';
import { useSearchParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import useProjectHierarchySelection from 'modules/shared/hooks/use-project-hierarchy-selection';

const useProjectListData = (clientId: string) => {
    const [searchParams, setSearchParams] = useSearchParams();

    let searchQueryString = searchParams.get('search') || '';

    const [searchValue, setSearchValue] = useState(searchQueryString);

    const setSearchQuery = (value: string) => {
        setSearchValue(value);

        if (value) {
            searchParams.set('search', value);
        } else {
            searchParams.delete('search');
        }

        setSearchParams(searchParams);
    };
    const projectListQuery = useDataQuery(['clients', clientId, 'projects'], () => getProjectsByClientId(clientId));

    const projectHierarchy = useProjectHierarchySelection({
        projects: projectListQuery.data || [],
    });

    const filteredProjects = useMemo(() => {
        if (projectListQuery.isLoading) return [];
        let arrangedArray: any = [];
        if (projectListQuery.data) {
            const handleChildren = (id: string) => {
                if (!projectHierarchy.hasChildren(id)) return;
                projectHierarchy.getChildren(id).forEach((proj) => {
                    arrangedArray.push(proj);
                    handleChildren(proj._id);
                });
            };
            const grandParentList = projectHierarchy.getChildren('root');
            if (!grandParentList) return arrangedArray;
            grandParentList.forEach((grandProject) => {
                arrangedArray.push(grandProject);
                handleChildren(grandProject._id);
            });
        }
        let _Projects = arrangedArray;
        const val = searchValue.toLowerCase();
        if (val) {
            _Projects = _Projects.filter((row: any) => {
                let flag = row.name.toLowerCase().includes(val);

                flag = flag || row._id.toLowerCase().includes(val);

                return flag;
            });
        }

        return _Projects;

        // return arrangedArray;
    }, [projectListQuery.isLoading, projectListQuery.data, searchValue]);

    const onAddProject = async (project: IProject) => {
        projectListQuery.setData((prevList: IProject[]) => {
            return produce(prevList, (draftList) => {
                draftList.unshift(project);

                return draftList;
            });
        });
    };

    const onUpdateProject = async (projectId: string, updates: IProject) => {
        projectListQuery.setData((prevList: IProject[]) => {
            return produce(prevList, (draftList) => {
                const index = draftList?.findIndex((row) => row._id === projectId);

                draftList[index] = updates;

                return draftList;
            });
        });
    };

    return {
        data: projectListQuery.data,
        isLoading: projectListQuery.isLoading,
        onAddProject,
        onUpdateProject,
        setSearchQuery,
        searchValue,
        filteredProjects,
    };
};
export default useProjectListData;
