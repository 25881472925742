import { FC } from 'react';
import useLoading from '@hooks/use-loading';
import BlurBackgroundImage from './BlurBackgroundImage';
import ZoomViewer from './ZoomViewer';
import OverlayLoader from './OverlayLoader';

interface ImageViewerProps {
    src: string;
    orientation: string;
    width: number;
    height: number;
    containerHeight: number;
}

const ImageViewer: FC<ImageViewerProps> = (props) => {
    const { src, width, height, containerHeight } = props;
    const [imgLoading, setImgLoading] = useLoading(src);

    return (
        <div className="relative">
            <BlurBackgroundImage src={src}>
                <ZoomViewer
                    contentWidth={width}
                    contentHeight={height}
                    containerHeight={containerHeight}
                    showControls={true}
                    // zoomedView={user.preferences.showZoomed }
                    zoomedView={true}
                >
                    <img
                        className="w-full h-full"
                        src={src}
                        alt=""
                        // style={{ scale: `${height / width}`, rotate: `${orientation}deg` }}
                        onLoad={() => setImgLoading(false)}
                    />
                </ZoomViewer>
            </BlurBackgroundImage>

            <OverlayLoader show={imgLoading} />

            {!src && (
                <div className="absolute inset-0 flex items-center justify-center bg-background-color text-grayscale-500 font-medium z-[2]">
                    No image on this date
                </div>
            )}
        </div>
    );
};

export default ImageViewer;
