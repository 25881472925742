import { FloatingElement, FloatingElementOptions, useFloatingElement } from '@library/floating';
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface PopoverProps
    extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'content'>,
        Pick<FloatingElementOptions, 'open' | 'setOpen' | 'placement' | 'triggers'> {
    content: ReactNode;
    contentClassName?: string;
}

const Popover: FC<PopoverProps> = (props) => {
    const { children, content, contentClassName, open, setOpen, placement, triggers, className, ...rest } = props;

    const { context, refs, getReferenceProps, getFloatingProps } = useFloatingElement({
        open,
        setOpen,
        placement,
        triggers,
        offset: 12,
        showArrow: true,
    });

    return (
        <>
            <div
                {...rest}
                {...getReferenceProps()}
                ref={refs.setReference}
                className={twMerge(content ? 'cursor-pointer' : '', className)}
            >
                {children}
            </div>

            <FloatingElement
                context={context}
                refs={refs}
                {...getFloatingProps()}
                className={twMerge('px-5 py-4', contentClassName)}
            >
                {content}
            </FloatingElement>
        </>
    );
};

export default Popover;
