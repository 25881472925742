import { useFloatingContext } from '@library/floating';
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface DropdownTriggerProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {}

const DropdownTrigger: FC<DropdownTriggerProps> = (props) => {
    const { children, className, ...rest } = props;

    const { refs, getReferenceProps } = useFloatingContext();

    return (
        <div
            {...rest}
            {...getReferenceProps()}
            ref={refs.setReference}
            className={twMerge('cursor-pointer w-fit', className)}
        >
            {children}
        </div>
    );
};

export default DropdownTrigger;
