import { Field } from 'formik';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { Switch, SwitchProps } from '@hyperflake/react-ui-library/forms';

interface FormikSwitchProps extends SwitchProps {}

const FormikSwitch = forwardRef((props: FormikSwitchProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { name, id, ...rest } = props;

    const idOrName = useMemo(() => {
        return id || name;
    }, [id, name]);

    return <Field type="checkbox" {...rest} ref={ref} id={idOrName} name={name} as={Switch} />;
});

export default FormikSwitch;
