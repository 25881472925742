import { SupportTicketStatusEnum } from '@api/enum/support-ticket-status.enum';
import store from '@store/index';
import moment from 'moment';
import { filesize } from 'filesize';
// import { getSupportTicketStatusLabel } from "modules/shared/helpers/shared.utils";

export const getResellerIdFromRedux = () => {
    const {
        auth: { resellerId },
    } = store.getState();
    return resellerId;
    // return resellerId ? resellerId : "61caf1dccb3a8396b119a117";
};

export const getSupportTicketStatusLabel = (role: SupportTicketStatusEnum) => {
    const map = {
        OPEN: 'Open',
        CLOSED: 'Closed',
    };

    return map[role];
};

export const SupportStatus = [
    {
        label: getSupportTicketStatusLabel(SupportTicketStatusEnum.OPEN),
        value: SupportTicketStatusEnum.OPEN,
    },
    {
        label: getSupportTicketStatusLabel(SupportTicketStatusEnum.CLOSED),
        value: SupportTicketStatusEnum.CLOSED,
    },
];

export const formatDate = (inputDate: string, format: string) => {
    return (
        moment(inputDate, 'YYYYMMDD') &&
        moment(inputDate, 'YYYYMMDD').isValid() &&
        moment(inputDate, 'YYYYMMDD').format(format)
    );
};

export const formatTime = (inputTime: string, format: string) => {
    return (
        moment(inputTime, 'HHmmss') &&
        moment(inputTime, 'HHmmss').isValid() &&
        moment(inputTime, 'HHmmss').format(format)
    );
};

export const formatDatetime = (inputDate: string, format: string) => {
    return (
        moment(inputDate, 'YYYYMMDDHHmmss') &&
        moment(inputDate, 'YYYYMMDDHHmmss').isValid() &&
        moment(inputDate, 'YYYYMMDDHHmmss').format(format)
    );
};

export const convertFileSize = (size: number) => {
    return filesize(size, { round: 1 }).toString();
};

export const getFileExtension = (filename: string) => {
    return filename.split('.').pop();
};

export const getViewportWidth = () => {
    return document.body.clientWidth;
};

export const getViewportHeight = () => {
    return window.innerHeight;
};
