import dayjs, { Dayjs } from 'dayjs';
import { FC, useMemo } from 'react';
import PickerHeader from './PickerHeader';
import { twMerge } from 'tailwind-merge';
import { getDaysOnCalendar } from '../helpers/datepicker.utils';
import { WeekDays } from '../helpers/datepicker.constants';

interface DayPickerProps {
    selectedDate: Dayjs;
    onSelectedDateChange: (date: Dayjs) => void;
    viewDate: Dayjs;
    onViewDateChange: (date: Dayjs) => void;
    onYearPickerSelect: () => void;
    onMonthPickerSelect: () => void;
    minDate?: Dayjs;
    maxDate?: Dayjs;
}

const DayPicker: FC<DayPickerProps> = (props) => {
    const {
        selectedDate,
        onSelectedDateChange,
        viewDate,
        onViewDateChange,
        onYearPickerSelect,
        onMonthPickerSelect,
        minDate,
        maxDate,
    } = props;

    const daysOnCalendar = useMemo(() => {
        return getDaysOnCalendar(viewDate.year(), viewDate.month());
    }, [viewDate]);

    const prevDisabled = useMemo(() => {
        if (!minDate) return false;

        return +viewDate.format('YYYYMM') <= +minDate.format('YYYYMM');
    }, [minDate, viewDate]);

    const nextDisabled = useMemo(() => {
        if (!maxDate) return false;

        return +viewDate.format('YYYYMM') >= +maxDate.format('YYYYMM');
    }, [maxDate, viewDate]);

    const isSameDateAsSelected = (date: Dayjs) => {
        return +selectedDate.format('YYYYMMDD') === +date.format('YYYYMMDD');
    };

    const isSameMonthAsView = (date: Dayjs) => {
        return viewDate.month() === date.month();
    };

    const isLessThanMinDate = (date: Dayjs) => {
        if (!minDate) return false;

        return +date.format('YYYYMMDD') < +minDate.format('YYYYMMDD');
    };

    const isGreaterThanMaxDate = (date: Dayjs) => {
        if (!maxDate) return false;

        return +date.format('YYYYMMDD') > +maxDate.format('YYYYMMDD');
    };

    const handlePrevClick = () => {
        if (prevDisabled) {
            return;
        }

        onViewDateChange(viewDate.clone().subtract(1, 'months'));
    };

    const handleNextClick = () => {
        if (nextDisabled) {
            return;
        }

        onViewDateChange(viewDate.clone().add(1, 'months'));
    };

    const handleSelect = (date: Dayjs) => {
        if (isGreaterThanMaxDate(date) || isLessThanMinDate(date)) {
            return;
        }

        if (!isSameMonthAsView(date)) {
            onViewDateChange(dayjs().year(date.year()).month(date.month()).date(1));
        }

        onSelectedDateChange(date);
    };

    const dynamicClass = (date: Dayjs) => {
        let className = 'bg-floating-color text-grayscale-400 cursor-pointer';

        if (isSameMonthAsView(date)) {
            className = 'bg-floating-color text-grayscale-900 cursor-pointer hover:bg-primary/5';
        }

        if (isGreaterThanMaxDate(date) || isLessThanMinDate(date)) {
            className = 'bg-floating-color text-grayscale-400 cursor-not-allowed';
        }

        if (isSameDateAsSelected(date)) {
            className = 'bg-primary text-primary-contrast cursor-pointer hover:bg-primary';
        }

        return className;
    };

    return (
        <>
            <PickerHeader
                content={
                    <>
                        <div
                            role="button"
                            className="grid place-items-center h-6 px-3 rounded-md hover:bg-primary/5"
                            onClick={onMonthPickerSelect}
                        >
                            {viewDate.format('MMMM')}
                        </div>
                        <div
                            role="button"
                            className="grid place-items-center h-6 px-3 rounded-md hover:bg-primary/5"
                            onClick={onYearPickerSelect}
                        >
                            {viewDate.year()}
                        </div>
                    </>
                }
                isPrevDisabled={prevDisabled}
                onPrevClick={handlePrevClick}
                isNextDisabled={nextDisabled}
                onNextClick={handleNextClick}
            />

            <div className="grid grid-cols-7 mt-2">
                {WeekDays.map((r) => (
                    <div key={r} className="col-span-1">
                        <div className="flex items-center justify-center text-xs font-medium text-grayscale-400 py-[10px] border-b border-separator-color-light">
                            {r}
                        </div>
                    </div>
                ))}
            </div>

            <div className="grid grid-cols-7 mt-2">
                {daysOnCalendar.map((date, i) => (
                    <div key={i} className="col-span-1">
                        <div
                            className={twMerge(
                                'flex items-center justify-center h-8 w-10 text-[13px] font-medium rounded-lg',
                                dynamicClass(date)
                            )}
                            onClick={() => handleSelect(date)}
                        >
                            {date.format('D')}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default DayPicker;
