export enum PermissionEnum {
    // project
    PROJECT_READ = 'project:read',
    PROJECT_CREATE = 'project:create',
    PROJECT_UPDATE = 'project:update',
    PROJECT_DELETE = 'project:delete',

    // client
    CLIENT_READ = 'client:read',
    CLIENT_CREATE = 'client:create',
    CLIENT_UPDATE = 'client:update',
    CLIENT_DELETE = 'client:delete',

    // user
    USER_READ = 'user:read',
    USER_CREATE = 'user:create',
    USER_UPDATE = 'user:update',
    USER_DELETE = 'user:delete',

    // camera
    RESELLER_CAMERA_READ = 'resellerCamera:read',
    RESELLER_CAMERA_CREATE = 'resellerCamera:create',
    RESELLER_CAMERA_UPDATE = 'resellerCamera:update',
    RESELLER_CAMERA_DELETE = 'resellerCamera:delete',

    // All Users
    ALL_USERS_READ = 'allUsers:read',
    ALL_USERS_CREATE = 'allUsers:create',
    ALL_USERS_UPDATE = 'allUsers:update',
    ALL_USERS_DELETE = 'allUsers:delete',

    // Sign In logs
    SIGN_IN_LOGS_READ = 'signInLogs:read',
    SIGN_IN_LOGS_CREATE = 'signInLogs:create',
    SIGN_IN_LOGS_UPDATE = 'signInLogs:update',
    SIGN_IN_LOGS_DELETE = 'signInLogs:delete',

    // console User
    CONSOLE_USER_READ = 'consoleUser:read',
    CONSOLE_USER_CREATE = 'consoleUser:create',
    CONSOLE_USER_UPDATE = 'consoleUser:update',
    CONSOLE_USER_DELETE = 'consoleUser:delete',

    // Support
    SUPPORT_READ = 'support:read',
    SUPPORT_CREATE = 'support:create',
    SUPPORT_UPDATE = 'support:update',
    SUPPORT_DELETE = 'support:delete',

    // Public Api Request
    PUBLIC_API_REQUEST_READ = 'publicApiRequest:read',
    PUBLIC_API_REQUEST_CREATE = 'publicApiRequest:create',
    PUBLIC_API_REQUEST_UPDATE = 'publicApiRequest:update',
    PUBLIC_API_REQUEST_DELETE = 'publicApiRequest:delete',

    // TimeLapse Camera
    TIMELAPSE_CAMERA_READ = 'timelapseCamera:read',
    TIMELAPSE_CAMERA_CREATE = 'timelapseCamera:create',
    TIMELAPSE_CAMERA_UPDATE = 'timelapseCamera:update',
    TIMELAPSE_CAMERA_DELETE = 'timelapseCamera:delete',

    // TimeLapse Camera
    LIVE_CAMERA_READ = 'liveCamera:read',
    LIVE_CAMERA_CREATE = 'liveCamera:create',
    LIVE_CAMERA_UPDATE = 'liveCamera:update',
    LIVE_CAMERA_DELETE = 'liveCamera:delete',
    // TimeLapse Camera
    SITE_GALLERY_READ = 'siteGallery:read',
    SITE_GALLERY_CREATE = 'siteGallery:create',
    SITE_GALLERY_UPDATE = 'siteGallery:update',
    SITE_GALLERY_DELETE = 'siteGallery:delete',
    // TimeLapse Camera
    DRONE_READ = 'drone:read',
    DRONE_CREATE = 'drone:create',
    DRONE_UPDATE = 'drone:update',
    DRONE_DELETE = 'drone:delete',
}
