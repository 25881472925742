import React, { FC } from 'react';
import { CrossIcon } from '@icon/index';
import DocFileIcon from 'modules/shared/components/DocFileIcon/DocFileIcon';
import OverflowTooltip from '@components/OverflowTooltip/OverflowTooltip';
import { convertFileSize, getFileExtension } from 'modules/shared/helpers/shared.utils';
// import { convertFileSize, getFileExtension } from 'modules/shared/utils/file-utils';

interface FileUploadPreviewProps {
    name: string;
    size: number;
    onCancel: () => void;
}

const FileUploadPreview: FC<FileUploadPreviewProps> = (props) => {
    const { name, size, onCancel } = props;

    return (
        <div className="border border-grayscale-100 rounded-[10px] p-4">
            <div className="flex items-center gap-3">
                <div>
                    <DocFileIcon className="w-8" extension={getFileExtension(name)} />
                </div>

                <div className="flex-1 overflow-hidden mr-3">
                    <OverflowTooltip className="text-sm font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                        {name}
                    </OverflowTooltip>
                    <OverflowTooltip className="text-xs text-grayscale-500 mt-1">
                        {convertFileSize(size)}
                    </OverflowTooltip>
                </div>

                <button type="button" className="ml-auto" onClick={onCancel}>
                    <CrossIcon className="text-danger-500" width="10" height="10" />
                </button>
            </div>
        </div>
    );
};

export default FileUploadPreview;
