import { FC } from "react";
import { ErrorMessage } from "formik";
import { FormHelperText, Label } from "@library/forms";
import CustomInput from "modules/shared/components/FormFields/CustomInput";

interface YouTubeFormFieldsProps {}

const YouTubeFormFields: FC<YouTubeFormFieldsProps> = (props) => {
  return (
    <div>
      <Label htmlFor="url">YouTube URL</Label>
      <CustomInput name="url" placeholder="Enter link here" />
      <FormHelperText variant={"error"}>
        <ErrorMessage
          component="div"
          className="form-error-message"
          name="url"
        />
      </FormHelperText>
    </div>
  );
};

export default YouTubeFormFields;
