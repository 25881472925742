import moment from 'moment';

export const formatDate = (inputDate: string, format: string) => {
    return (
        moment(inputDate, 'YYYYMMDD') &&
        moment(inputDate, 'YYYYMMDD').isValid() &&
        moment(inputDate, 'YYYYMMDD').format(format)
    );
};

export const formatTime = (inputTime: string, format: string) => {
    return (
        moment(inputTime, 'HHmmss') &&
        moment(inputTime, 'HHmmss').isValid() &&
        moment(inputTime, 'HHmmss').format(format)
    );
};

export const formatDatetime = (inputDate: string, format: string) => {
    return (
        moment(inputDate, 'YYYYMMDDHHmmss') &&
        moment(inputDate, 'YYYYMMDDHHmmss').isValid() &&
        moment(inputDate, 'YYYYMMDDHHmmss').format(format)
    );
};

// export const dateFromNow = (date: Date, options: { timezone?: string; showSuffix?: boolean } = {}) => {
//     const { timezone, showSuffix = true } = options;

//     let fromNow = timezone ? moment.tz(date, timezone).fromNow(!showSuffix) : moment(date).fromNow(!showSuffix);

//     fromNow = fromNow
//         .replace(/second/g, 'sec')
//         .replace(/minute/g, 'min')
//         .replace(/hour/g, 'hr')
//         .replace(/a few/g, 'few');

//     return fromNow;
// };
