import { ICamera } from '@api/models';
import { Drawer, NavTab } from '@hyperflake/react-ui-library';
import useCameraData from 'modules/cameras/hooks/useCameraData';
import useCameraUpdateForm from 'modules/cameras/hooks/useCameraUpdateForm';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import CameraEditForm from '../CameraEditForm/CameraEditForm';
import CameraPluginForm from '../CameraPluginForm/CameraPluginForm';
import ChangeProjectForm from '../ChangeProjectForm/ChangeProjectForm';

enum TabEnum {
    BASIC_DETAILS,
    PULGINS,
    CHANGE_PROJECT,
}
interface CameraDrawerLayoutProps {
    open: boolean;
    onClose: () => void;
    cameraId: string;
    onCameraUpdate: (camera: ICamera) => void;
}
const CameraDrawerLayout = (props: CameraDrawerLayoutProps) => {
    const { open, onClose, cameraId, onCameraUpdate } = props;
    const { clientId, projectId } = useParams();
    const cameraData = useCameraData(clientId, projectId, cameraId);
    const { camera } = cameraData;
    const cameraUpdateForm = useCameraUpdateForm({ clientId, projectId, cameraId, camera });
    const [activeTab, setActiveTab] = useState(TabEnum.BASIC_DETAILS);
    return (
        <Drawer open={open} onClose={onClose} title="Edit Camera">
            <NavTab
                items={[
                    {
                        content: 'Basic',
                        isActive: TabEnum.BASIC_DETAILS === activeTab,
                        onClick: () => setActiveTab(TabEnum.BASIC_DETAILS),
                    },
                    {
                        content: 'Plugins',
                        isActive: TabEnum.PULGINS === activeTab,
                        onClick: () => setActiveTab(TabEnum.PULGINS),
                    },
                    {
                        content: 'Change Project',
                        isActive: TabEnum.CHANGE_PROJECT === activeTab,
                        onClick: () => setActiveTab(TabEnum.CHANGE_PROJECT),
                    },
                ]}
            />
            <div className="mt-6">
                {activeTab === TabEnum.BASIC_DETAILS && (
                    <CameraEditForm
                        onClose={onClose}
                        cameraId={cameraId}
                        onCameraUpdate={onCameraUpdate}
                        camera={cameraData.camera}
                        isLoading={cameraData.isLoading}
                    />
                )}
                {activeTab === TabEnum.PULGINS && (
                    <CameraPluginForm
                        camera={cameraData.camera}
                        clientId={clientId}
                        projectId={projectId}
                        onCameraUpdate={onCameraUpdate}
                        isLoading={cameraData.isLoading}
                    />
                )}
                {activeTab === TabEnum.CHANGE_PROJECT && <ChangeProjectForm camera={cameraData.camera} />}
            </div>
        </Drawer>
    );
};
export default CameraDrawerLayout;
