import { useFloatingContext } from '@library/floating';
import { DetailedHTMLProps, FC, ForwardedRef, LiHTMLAttributes, MouseEvent, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface DropdownItemProps extends DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
    ref?: ForwardedRef<HTMLLIElement>;
}

const DropdownItem = forwardRef<HTMLLIElement, DropdownItemProps>(
    (props: DropdownItemProps, ref: ForwardedRef<HTMLLIElement>) => {
        const { children, className, onClick, ...rest } = props;

        const { context } = useFloatingContext();

        const handleClick = (e: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => {
            if (onClick) {
                onClick?.(e);
            }

            context.onOpenChange(false);
        };

        return (
            <li
                ref={ref}
                {...rest}
                className={twMerge(
                    'flex items-center gap-2 text-sm font-medium px-4 py-2 outline-none cursor-pointer transition-colors duration-200 hover:bg-primary/5',
                    className
                )}
                onClick={handleClick}
            >
                {children}
            </li>
        );
    }
);

export default DropdownItem;
