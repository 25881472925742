import { IUser } from '@api/models/user.model';
import { CommentIcon } from '@icon/index';
import EmptyTableTemplate from 'modules/shared/components/EmptyTableTemplate/EmptyTableTemplate';
import useNavName from 'modules/shared/hooks/useNavName';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Tooltip } from '@library/tooltip';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import ProjectAccessDrawerForm from '../ProjectAccessDrawerForm/ProjectAccessDrawerForm';
import UserAddDrawerForm from '../UserAddDrawerForm/UserAddDrawerForm';
import UserDeleteConfirmationDrawer from '../UserDeleteConfirmationDrawer/UserDeleteConfirmationDrawer';
import UserEditDrawerForm from '../UserEditDrawerForm/UserEditDrawerForm';

import { PermissionEnum } from '@api/enum/permission.enum';
import useDialog from '@hooks/use-dialog';
import { Avatar, Button, Table } from '@hyperflake/react-ui-library';
import RoleChip from 'modules/shared/components/RoleChip/RoleChip';
import StatusChip from 'modules/shared/components/StatusChip/StatusChip';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { DeleteShapeIcon, EditIcon } from 'modules/shared/icons';
import useUserListData from 'modules/users/hooks/useUserListData';

const UserList = () => {
    const navigate = useNavigate();
    const { clientId } = useParams();
    const { clientName } = useNavName(clientId);
    const [searchParams, setSearchParams] = useSearchParams();
    const addUserDailog = useDialog();
    const projectAccessDailog = useDialog<string>();
    const editUserDailog = useDialog<IUser>();
    const deleteUserDailog = useDialog<IUser>();
    const { data, isLoading, addUser, updateUser } = useUserListData(clientId);

    const users = useMemo(() => {
        if (!data) return [];
        return data;
    }, [data]);

    const { pathname } = useLocation();

    useEffect(() => {
        if (Boolean(searchParams.get('showDialog')) === true) {
            if (searchParams.get('userid')?.trim() !== '') {
                // editUserDailog.show(data);
            }
            searchParams.delete('showDialog');
            searchParams.delete('userid');
            setSearchParams(searchParams, {
                replace: true,
            });
        }
    }, [searchParams]);

    const { hasPermission } = useAuthPermission();
    const onUserAdd = (id: string) => {
        projectAccessDailog.show(id);
        addUserDailog.hide();
    };

    return (
        <PageTemplate>
            <div className="flex justify-between">
                <PageHeading>Users</PageHeading>
                <Button onClick={() => addUserDailog.show()}>Add New User</Button>
            </div>
            <div>
                <Table
                    className="mt-6"
                    borderStyle="divider"
                    // hover
                    isLoading={isLoading}
                    data={users}
                    columns={[
                        {
                            header: 'ID',
                        },
                        {
                            header: 'Name',
                        },
                        {
                            header: 'Email',
                        },
                        {
                            header: 'Role',
                        },
                        {
                            header: 'Status',
                        },
                        {
                            header: 'Action',
                        },
                    ]}
                    renderRow={(row) => (
                        <Table.Row key={row._id} onClick={() => {}}>
                            <Table.Cell>
                                {
                                    <div
                                        className="text-primary cursor-pointer"
                                        onClick={() => editUserDailog.show(row)}
                                    >
                                        {row._id}
                                    </div>
                                }
                            </Table.Cell>
                            <Table.Cell>
                                <div className="flex items-center gap-2">
                                    <Avatar src={row?.dpUrl} alt={row?.name} />
                                    {row?.name}
                                </div>
                            </Table.Cell>
                            <Table.Cell>{row.email}</Table.Cell>
                            <Table.Cell>{<RoleChip status={row.role} />}</Table.Cell>
                            <Table.Cell>{<StatusChip status={row.status} />}</Table.Cell>
                            <Table.Cell>
                                {
                                    <>
                                        {/* <Dropdown placement="bottom-start" showArrow>
                                            <DropdownTrigger className="w-4 h-4 rounded-full">
                                                <EllipsisVerticalIcon />
                                            </DropdownTrigger>

                                            <DropdownList className="min-w-[100px]">
                                                <DropdownItem onClick={() => editUserDailog.show(row._id)}>
                                                    Edit
                                                </DropdownItem>

                                                <DropdownItem onClick={() => projectAccessDailog.show(row._id)}>
                                                    Project Access
                                                </DropdownItem>
                                                {hasAdminPermission ? (
                                                    <DropdownItem
                                                        onClick={() => deleteUserDailog.show(row)}
                                                        className="text-danger"
                                                    >
                                                        Delete
                                                    </DropdownItem>
                                                ) : (
                                                    <Tooltip
                                                        placement="left"
                                                        content={'Only Admins are authorized to delete user'}
                                                    >
                                                        <DropdownItem
                                                            className="text-gray-300 cursor-not-allowed"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                        >
                                                            Delete
                                                        </DropdownItem>
                                                    </Tooltip>
                                                )}
                                            </DropdownList>
                                        </Dropdown> */}

                                        <div className="grid gap-2 grid-cols-3">
                                            <Tooltip placement="top" content={'Edit'}>
                                                <button onClick={() => editUserDailog.show(row)}>
                                                    <EditIcon />
                                                </button>
                                            </Tooltip>
                                            <Tooltip placement="top" content={'Project Access'}>
                                                <button onClick={() => projectAccessDailog.show(row._id)}>
                                                    <CommentIcon />
                                                </button>
                                            </Tooltip>
                                            {hasPermission(PermissionEnum.USER_DELETE) ? (
                                                <Tooltip placement="top" content={'Delete'}>
                                                    <button onClick={() => deleteUserDailog.show(row)}>
                                                        <DeleteShapeIcon />
                                                    </button>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip
                                                    placement="left"
                                                    content={'Only Admins are authorized to delete User'}
                                                >
                                                    <button className="text-gray-300 cursor-not-allowed">
                                                        <DeleteShapeIcon />
                                                    </button>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </>
                                }
                            </Table.Cell>
                        </Table.Row>
                    )}
                />
                {!isLoading && users.length === 0 && <EmptyTableTemplate />}
            </div>

            {/* Add drawer */}
            <UserAddDrawerForm
                open={addUserDailog.isOpen}
                onClose={addUserDailog.hide}
                onSubmit={(id) => onUserAdd(id)}
                onAddUser={addUser}
            />
            {/* edit drawer */}

            <UserEditDrawerForm
                open={editUserDailog.isOpen}
                userId={editUserDailog.data?._id}
                // user={editUserDailog.data}
                onClose={editUserDailog.hide}
                onUserUpdate={(user) => updateUser(user._id, user)}
            />
            {/* Project Access Drawer */}

            <ProjectAccessDrawerForm
                open={projectAccessDailog.isOpen}
                onClose={projectAccessDailog.hide}
                userId={projectAccessDailog.data}
            />

            {/* delete confirmation Dialog */}

            <UserDeleteConfirmationDrawer
                open={deleteUserDailog.isOpen}
                onClose={deleteUserDailog.hide}
                user={deleteUserDailog.data}
            />
        </PageTemplate>
    );
};

export default UserList;
