import { IProject } from '@api/models';
import { UploadIcon } from '@icon/index';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import http from 'httpConfig/http';
import { produce } from 'immer';
import ImageLoader from 'modules/shared/components/ImageLoader/ImageLoader';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, CircularProgress, LinearProgress } from '@hyperflake/react-ui-library';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import { PermissionEnum } from '@api/enum/permission.enum';
import Img from 'modules/shared/components/Img/Img';
import { DeleteIcon } from 'modules/shared/icons';

interface ProjectRendersDrawerProps {
    // open: boolean;
    // onClose: () => void;
    projectId: string;
}

const ProjectRendersDrawer = (props: ProjectRendersDrawerProps) => {
    const {
        // open,
        // onClose,
        projectId,
    } = props;
    const { clientId } = useParams();
    const queryClient = useQueryClient();
    const imageScroll = useRef<HTMLDivElement>(null);

    const getTimeStamp = () => {
        const d = new Date();
        return d.valueOf();
    };

    const { data: project, isLoading } = useQuery<IProject>(
        ['clients', clientId, 'projects', projectId],
        async () => {
            const { data } = await http.get(
                `${
                    import.meta.env.VITE_APP_API_URL
                }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}`
            );
            return data;
        },
        {
            enabled: Boolean(projectId),
        }
    );

    const removeProjectRenderMutation = useMutation(async (imageId: string) => {
        const { data } = await http.delete(
            `${
                import.meta.env.VITE_APP_API_URL
            }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/images/${imageId}`
        );
        return data;
    });

    const handleProjectRenderRemove = async (imageId: string) => {
        try {
            queryClient.setQueryData<IProject>(['clients', clientId, 'projects', projectId], (prev) => {
                if (!prev?.images || prev.images.length === 0) return prev;
                const index = prev?.images.findIndex((val) => val._id === imageId);
                if (index < 0) return prev;
                const updatedData = produce(prev, (draft) => {
                    draft.images.splice(index, 1);
                    return draft;
                });

                return updatedData;
            });
            await removeProjectRenderMutation.mutateAsync(imageId);
            toast.success('Project render removed successfully.');
        } catch (ex: any) {
            queryClient.fetchQuery(['clients', clientId, 'projects', projectId]);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    const uploadProjectRenderMutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${
                import.meta.env.VITE_APP_API_URL
            }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/images`,
            payload
        );
        return data;
    });

    const handleImageUpload = async (file: any) => {
        queryClient.setQueryData<IProject>(['clients', clientId, 'projects', projectId], (prev) => {
            return produce(prev, (draft) => {
                draft?.images.push({
                    filepath: 'objectUpload',
                    url: URL.createObjectURL(file),
                    _id: getTimeStamp().toString(),
                    name: '',
                });
                return draft;
            });
        });
        const formData = new FormData();
        formData.append('file', file);
        try {
            let imgObj = await uploadProjectRenderMutation.mutateAsync(formData);
            toast.success('Image Uploaded');
            queryClient.setQueryData<IProject>(['clients', clientId, 'projects', projectId], (prev) => {
                return produce(prev, (draft) => {
                    if (draft?.images) {
                        draft.images[draft?.images.length - 1] = imgObj;
                    }
                    return draft;
                });
            });
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occurred, please try again.');
            console.log(ex);
        }
    };

    const { hasPermission } = useAuthPermission();

    useEffect(() => {
        if (imageScroll.current) {
            console.log(imageScroll.current.scrollHeight);
            imageScroll.current.scrollTop = imageScroll.current?.scrollHeight;
        }
    }, [project]);

    // if (isLoading || isLoading) return <DrawerFormLoading formTitle="Project Renders" />;

    return (
        // <Drawer open={open} onClose={onClose} title="Project Renders">
        <div>
            {isLoading ? (
                <LinearProgress color="primary" />
            ) : (
                <div className=" h-[calc(100%_-_24px)]">
                    <div className="flex flex-col h-full ">
                        <div
                            ref={imageScroll}
                            className="flex-grow h-full overflow-auto rounded-lg py-0 mt-2  gap-2 scroll-smooth mb-2"
                        >
                            <div className="grid grid-cols-3 gap-4 mb-4 empty:hidden">
                                {project.images.map((loginImage, index) => (
                                    <div key={index} className="col-span-1 relative rounded-md overflow-hidden">
                                        <Img
                                            src={loginImage.url}
                                            className="w-full h-full aspect-square object-cover object-center"
                                        />
                                        <button
                                            className="absolute right-2 top-2 w-6 h-6 grid place-items-center bg-card-color rounded-md text-grayscale-500"
                                            onClick={() => handleProjectRenderRemove(loginImage._id)}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <div className=" ">
                                <div className="relative flex justify-center cursor-pointer bg-primary/10 py-6 rounded-lg">
                                    {removeProjectRenderMutation.isLoading || uploadProjectRenderMutation.isLoading ? (
                                        <CircularProgress size={20} color={'inherit'} />
                                    ) : (
                                        <>
                                            <Button
                                                variant={'shaded'}
                                                size={'xs'}
                                                className="cursor-pointer "
                                                disabled={!hasPermission(PermissionEnum.PROJECT_UPDATE)}
                                            >
                                                <UploadIcon className="cursor-pointer" /> Upload Image
                                            </Button>
                                            <input
                                                type="file"
                                                disabled={!hasPermission(PermissionEnum.PROJECT_UPDATE)}
                                                className="absolute z-20 top-0 left-0 bottom-0 right-0 w-full h-full opacity-0 cursor-pointer"
                                                onChange={(event) => {
                                                    const files = event.target.files;
                                                    // console.log({ files });
                                                    if (files && files.length > 0) {
                                                        handleImageUpload(files[0]);
                                                    }
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* {project.images.map((render) => {
                                return (
                                    <ImageLoader
                                        key={render._id}
                                        handleImageDelete={
                                            hasPermission(PermissionEnum.PROJECT_UPDATE)
                                                ? handleProjectRenderRemove
                                                : () => {
                                                      toast.error('only Admins are authorized to remove image');
                                                  }
                                        }
                                        image={render}
                                    />
                                );
                            })} */}
                        </div>
                        {/* <div className=" ">
                            <div className="relative flex justify-center cursor-pointer bg-primary/10 py-6 rounded-lg">
                                <Button
                                    variant={'shaded'}
                                    size={'xs'}
                                    className="cursor-pointer "
                                    disabled={!hasPermission(PermissionEnum.PROJECT_UPDATE)}
                                >
                                    <UploadIcon className="cursor-pointer" /> Upload Image
                                </Button>
                                <input
                                    type="file"
                                    disabled={!hasPermission(PermissionEnum.PROJECT_UPDATE)}
                                    className="absolute z-20 top-0 left-0 bottom-0 right-0 w-full h-full opacity-0 cursor-pointer"
                                    onChange={(event) => {
                                        const files = event.target.files;
                                        // console.log({ files });
                                        if (files && files.length > 0) {
                                            handleImageUpload(files[0]);
                                        }
                                    }}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
            )}
        </div>
        // </Drawer>
    );
};

export default ProjectRendersDrawer;
