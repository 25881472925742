import { ButtonCompoundVariants } from '@library/common/utils/class-variants';
import { cva, VariantProps } from 'class-variance-authority';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ForwardedRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import styles from './Button.module.scss';

const buttonVariants = cva(
    [
        'inline-flex items-center justify-center gap-2',
        'whitespace-nowrap',
        'font-semibold',
        'cursor-pointer',
        'transition-all duration-200 hover:brightness-110',
    ],
    {
        variants: {
            color: {
                primary: [],
                secondary: [],
                success: [],
                danger: [],
                warning: [],
            },
            variant: {
                default: [],
                shaded: [styles['shaded-elevation']],
                outlined: [],
                text: [],
            },
            size: {
                xs: ['text-xs px-[14px] h-8 rounded-lg'],
                sm: ['text-sm px-4 h-9 rounded-[10px]'],
                normal: ['text-sm px-5 h-10 rounded-[10px]'],
                lg: ['text-base px-6 h-11 rounded-xl'],
                xl: ['text-base px-[26px] h-12 rounded-xl'],
            },
        },
        compoundVariants: ButtonCompoundVariants,
        defaultVariants: {
            color: 'primary',
            variant: 'default',
            size: 'normal',
        },
    }
);

export interface ButtonProps
    extends Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'color'>,
        VariantProps<typeof buttonVariants> {
    ref?: ForwardedRef<HTMLButtonElement>;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
        const { children, variant, color, size, className, ...rest } = props;

        return (
            <button ref={ref} {...rest} className={twMerge(buttonVariants({ color, variant, size, className }))}>
                {children}
            </button>
        );
    }
);

export default Button;
