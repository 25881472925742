import '@hyperflake/react-ui-library/style.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import 'plyr/dist/plyr.css';
import './index.scss';

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
