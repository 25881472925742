const Icon = (props: any) => {
    return (
        <svg height={14} viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx={2} cy={2} r={2} fill="currentColor" />
            <circle cx={2} cy={8} r={2} fill="currentColor" />
            <circle cx={2} cy={14} r={2} fill="currentColor" />
        </svg>
    );
};

export default Icon;
