const Icon = (props: any) => {
    return (
        <svg width={5} height={8} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4 1L1 4l3 3"
                stroke="currentColor"
                strokeWidth={props.strokeWidth || 1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
