import { IConsoleUser } from '@api/models';
import { Avatar } from '@library/avatar';
import moment from 'moment';
import { FC } from 'react';

interface ConsoleUserReplyProps {
    user: IConsoleUser;
    message: string;
    createdAt: Date;
}
const ConsoleUserReply: FC<ConsoleUserReplyProps> = (props) => {
    const { user, message, createdAt } = props;

    return (
        <div className="flex gap-4">
            <div className="relative flex flex-col items-center mt-1">
                <Avatar src={user.dpUrl} alt={user.name} size={40} />
            </div>
            <div className="max-w-[90%] sm:max-w-[80%] lg:max-w-[50%] px-4 py-3 border border-grayscale-100 shadow-[0px_1px_2px_rgba(40,52,82,0.02)] rounded-xl rounded-tl-none">
                <div className="text-xs text-grayscale-500 font-semibold">{user.name}</div>
                <div className="mt-1">{message}</div>
                <div className="text-right text-[10px] text-grayscale-400 mt-[2px]">
                    {moment(createdAt, 'YYYYMMDDHHmmss').format('HH:mm A')}
                </div>
            </div>
        </div>
    );
};

export default ConsoleUserReply;
