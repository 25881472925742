import { produce } from 'immer';
import React, { FC } from 'react';
import http from 'httpConfig/http';
import { Label } from '@library/forms';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISiteGalleryItem } from '@api/models/site-gallery-item.model';
import { CircularProgress } from '@library/loaders/components';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import { Button } from '@hyperflake/react-ui-library';

interface ISiteGalleryDeleteDrawerForm {
    onClose: () => void;
    galleryItem: ISiteGalleryItem | null;
    projectId: string;
    clientId: string;
}

const SiteGalleryDeleteDrawerForm: FC<ISiteGalleryDeleteDrawerForm> = (props) => {
    const { onClose, galleryItem, projectId } = props;
    const { clientId } = useParams();
    const queryClient = useQueryClient();

    const deleteGalleryItemMutation = useMutation(async (payload: any) => {
        const { data, status } = await http.delete(
            `${
                import.meta.env.VITE_APP_API_URL
            }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/site-gallery/${payload.id}`
        );
        return { data, status };
    });

    const handleDelete = async () => {
        if (galleryItem === null) return onClose();
        try {
            const { data, status } = await deleteGalleryItemMutation.mutateAsync({
                id: galleryItem._id,
            });
            console.log({ data, status });
            toast.success('Galery Item deleted Successfully.');
            queryClient.setQueryData<ISiteGalleryItem[]>(
                ['clients', clientId, 'projects', projectId, 'site-gallery'],
                (prev) => {
                    if (!prev) return;

                    const index = prev.findIndex((c) => c._id === galleryItem._id);
                    return produce(prev, (draft) => {
                        draft.splice(index, 1);
                        return draft;
                    });
                }
            );
            onClose();
        } catch (ex: any) {
            onClose();
            console.log(ex.status);
            if (ex.status === 400) {
                toast.error(ex.data.message);
            } else {
                toast.error(ex?.data?.message || 'Some error occured, please try again.');
                console.log(ex);
            }
        }
    };

    return (
        <div>
            <div>
                <h5>Confirm Delete</h5>
            </div>
            <div>
                <Label className="mt-8 inline-block">
                    The following item &ldquo;
                    <span className="font-bold">{galleryItem?.name}</span>&rdquo; will be removed.
                </Label>

                <div className="flex gap-2 mt-2">
                    <Button
                        disabled={deleteGalleryItemMutation.isLoading}
                        onClick={() => onClose()}
                        variant="default"
                        color={'primary'}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={deleteGalleryItemMutation.isLoading}
                        className="w-20"
                        onClick={() => handleDelete()}
                        variant="default"
                        color={'danger'}
                    >
                        {deleteGalleryItemMutation.isLoading ? <CircularProgress size={18} /> : 'Delete'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SiteGalleryDeleteDrawerForm;
