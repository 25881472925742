import { ICameraImage } from '@api/models';
import { cameraNameValidation, deleteMultipleImages } from '@api/services/camera.service';
import useDialog from '@hooks/use-dialog';
import { Avatar, Breadcrumb, Button, Checkbox, Label, Table } from '@hyperflake/react-ui-library';
import { DatePicker } from '@library/datepicker';
import useCameraData from 'modules/cameras/hooks/useCameraData';
// import { DatePicker } from '@library/datepicker';
import useCameraImagesListData from 'modules/cameras/hooks/useCameraImagesListData';
import useClientData from 'modules/clients/hooks/useClientData';
import useProjectData from 'modules/projects/hooks/useProjectData';
import CameraSneakPeekModal from 'modules/shared/components/CameraSneakPeekModal/CameraSneakPeekModal';
import ConfirmationDialog from 'modules/shared/components/ConfirmationDialog/ConfirmationDialog';
import EmptyTableTemplate from 'modules/shared/components/EmptyTableTemplate/EmptyTableTemplate';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { formatDatetime } from 'modules/shared/helpers/shared.utils';
import { DeleteIcon } from 'modules/shared/icons';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const CameraImagesList = () => {
    const [date, setDate] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const { clientId, projectId, cameraId } = useParams();
    const navigate = useNavigate();
    const { project } = useProjectData(clientId, projectId);
    const { client } = useClientData(clientId);
    const sneakPeakDialog = useDialog<ICameraImage>();
    const confirmationDialog = useDialog<string>();

    const { camera } = useCameraData(clientId, projectId, cameraId);

    const cameraImageListData = useCameraImagesListData(clientId, projectId, cameraId, date);

    const handleCheckboxChange = (e: any, rowId: any) => {
        if (e.target.checked) {
            // Add the ID if it's not already in the list
            setSelectedIds((prevSelectedIds) =>
                prevSelectedIds.includes(rowId) ? prevSelectedIds : [...prevSelectedIds, rowId]
            );
        } else {
            // Remove the ID if it's currently in the list
            setSelectedIds((prevSelectedIds) => prevSelectedIds.filter((id) => id !== rowId));
        }
    };
    const handleDelete = async (name: string) => {
        try {
            await cameraImageListData.handleDeleteImage(selectedIds);
            toast.success('Image Deleted successfully.');
            confirmationDialog.hide();
        } catch (err: any) {
            console.log(err);
            toast.error(err?.message || 'Some error occurred, please try again.');
        }
    };

    return (
        <>
            <div className=" p-3">
                <Breadcrumb
                    items={[
                        {
                            label: 'Clients',
                            onClick: () => {
                                navigate('/');
                            },
                        },
                        {
                            label: client?.name,
                            onClick: () => {
                                navigate(`/clients/${client?._id}/client-details`);
                            },
                        },
                        {
                            label: project?.name,
                            onClick: () => {
                                navigate(`/clients/${client?._id}/projects/${projectId}/cameras`);
                            },
                        },
                        {
                            label: camera?.name,
                            onClick: () => {},
                        },
                    ]}
                />
            </div>
            <PageTemplate>
                <div className="flex justify-between items-center">
                    <PageHeading>Images</PageHeading>
                    <div className="flex items-end  gap-3">
                        <div>
                            <Label>Select Date</Label>
                            <DatePicker
                                className="flex items-center w-full font-medium bg-transparent border border-grayscale-300 placeholder:text-grayscale-400 focus:border-primary focus:outline focus:outline-2 focus:outline-primary/10 text-sm px-4 h-10 rounded-md"
                                onChange={(val) => setDate(moment(val).format('YYYYMMDD'))}
                            />
                        </div>
                        {selectedIds.length > 0 && (
                            <div>
                                <Button
                                    onClick={() => {
                                        confirmationDialog.show(camera?.name);
                                    }}
                                >
                                    <DeleteIcon />
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <Table
                        className="mt-6"
                        borderStyle="divider"
                        // hover
                        isLoading={cameraImageListData.isLoading}
                        data={cameraImageListData?.images}
                        columns={[
                            {
                                header: '',
                            },
                            {
                                header: 'ID',
                            },
                            {
                                header: 'Date Time',
                            },
                            {
                                header: 'Name',
                            },

                            // {
                            //     header: 'Action',
                            // },
                        ]}
                        renderRow={(row) => (
                            <Table.Row key={row._id}>
                                <Table.Cell>
                                    <div>
                                        <Checkbox color="primary" onChange={(e) => handleCheckboxChange(e, row._id)} />
                                    </div>
                                </Table.Cell>
                                <Table.Cell>{row._id}</Table.Cell>
                                <Table.Cell>
                                    {row?.datetime ? formatDatetime(row?.datetime, 'h:mm a [·] DD MMM, YYYY') : '-'}
                                </Table.Cell>
                                <Table.Cell>
                                    <div
                                        className="flex items-center gap-2"
                                        onClick={() => {
                                            sneakPeakDialog.show(row);
                                        }}
                                    >
                                        <img src={row.urlThumb} alt="" width="60" height="60" />
                                        {/* <Avatar src={row.urlThumb} alt={row?.name} /> */}
                                        {row?.name}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    />
                </div>

                <CameraSneakPeekModal
                    show={sneakPeakDialog.isOpen}
                    onClose={sneakPeakDialog.hide}
                    cameraImage={sneakPeakDialog.data}
                />
                <ConfirmationDialog
                    show={confirmationDialog.isOpen}
                    onClose={confirmationDialog.hide}
                    handleDelete={handleDelete}
                    isLoading={cameraImageListData.isDeleting}
                    name={confirmationDialog.data}
                />
            </PageTemplate>
        </>
    );
};

export default CameraImagesList;
