import http from 'httpConfig/http';
import { Switch } from '@library/forms';
import { useParams } from 'react-router-dom';
import { IProject, IUser } from '@api/models';
import { List, ListItem } from '@library/list';
import { useQuery } from '@tanstack/react-query';
import { FC, useEffect, useMemo, useState } from 'react';

import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import { Button } from '@hyperflake/react-ui-library';

interface IUserAccessDrawerFrom {
    onClose: () => void;
    project: IProject | null;
}

const UserAccessDrawerFrom: FC<IUserAccessDrawerFrom> = (props) => {
    const { project } = props;
    const { clientId } = useParams();

    const [selectedUser, setSelectedUser] = useState(new Set());

    const { data, isLoading } = useQuery<IUser[]>(['clients', clientId, 'users'], async () => {
        const { data } = await http.get(
            `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/users`
        );
        return data;
    });

    const { data: authorizedUsers, isLoading: authorizedUsersLoading } = useQuery<string[]>(
        ['clients', clientId, 'projects', project._id, 'users'],
        async () => {
            const { data } = await http.get(
                `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/projects/${
                    project._id
                }/users`
            );
            return data;
        }
    );

    const users = useMemo(() => {
        if (!data) return [];
        return data;
    }, [isLoading, data]);

    useEffect(() => {
        if (authorizedUsers) {
            // setSelectedUser(() => {
            //     return produce(authorizedUsers, (draftState) => {
            //         return draftState;
            //     });
            // });
            // console.log({ authorizedUsers });
            setSelectedUser(() => {
                let x = new Set();
                authorizedUsers.forEach((userId) => {
                    x.add(userId);
                });
                return x;
            });
        }
    }, [authorizedUsers, authorizedUsersLoading]);

    console.log({ selectedUser });

    const isUserSelected = (id: string): boolean => {
        // return selectedUser.find((val) => val === id);
        return selectedUser.has(id);
    };

    const handleSwitchToggle = (id: string, toggleState: boolean) => {
        console.log({ id, toggleState });

        setSelectedUser((prevSelected) => {
            let tempSet = new Set(prevSelected);
            if (toggleState) {
                tempSet.add(id);
            } else {
                tempSet.delete(id);
            }
            return tempSet;
        });

        // setSelectedUser((prevSelected) => {
        //     return [];
        // });
    };

    if (isLoading || authorizedUsersLoading) return <h1>Loading...</h1>;

    return (
        <div>
            <div>
                <h4>Project Access</h4>
                <div className="mt-4 text-base font-semibold">Manage access of {project.name}.</div>
            </div>
            <div>
                <List divider>
                    {users.map((row, index) => {
                        return (
                            <ListItem
                                className="grid grid-cols-12 gap-2 my-1  border border-primary/20 rounded-md"
                                key={row._id}
                            >
                                <div className="col-span-10 flex flex-col py-2">
                                    <div className="text-xs">{row._id}</div>
                                    <div className="text-base">{row.name}</div>
                                </div>
                                <div className="col-span-1">
                                    <Switch
                                        checked={isUserSelected(row._id)}
                                        onChange={(e) => {
                                            handleSwitchToggle(row._id, e.target.checked);
                                        }}
                                    />
                                </div>
                            </ListItem>
                        );
                    })}
                </List>
            </div>
            <Button onClick={() => console.log(selectedUser)}>check </Button>
        </div>
    );
};

export default UserAccessDrawerFrom;
