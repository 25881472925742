import { ICameraCCTV } from '@api/models';
import { getAllCctvCamerasOfProject } from '@api/services/cctv-camera.service';
import { produce } from 'immer';
import useDataQuery from 'modules/shared/hooks/use-data-query';

export const useCctvCameraListData = (clientId: string, projectId: string) => {
    const CctvCameraListQuery = useDataQuery(['clients', clientId, 'projects', projectId, 'cctv-cameras'], () =>
        getAllCctvCamerasOfProject(clientId as string, projectId as string)
    );

    const addCctvCamera = (cctvCamera: ICameraCCTV) => {
        CctvCameraListQuery.setData((prevList: ICameraCCTV[]) => {
            return produce(prevList, (draftList) => {
                draftList.unshift(cctvCamera);

                return draftList;
            });
        });
    };
    const updateCctvCamera = async (cctvCameraId: string, updates: ICameraCCTV) => {
        CctvCameraListQuery.setData((prevList: ICameraCCTV[]) => {
            return produce(prevList, (draftList) => {
                const index = draftList?.findIndex((row) => row._id === cctvCameraId);

                draftList[index] = updates;

                return draftList;
            });
        });
    };
    return {
        cctvCameras: CctvCameraListQuery.data,
        isLoading: CctvCameraListQuery.isLoading,
        addCctvCamera,
        updateCctvCamera,
    };
};
