import http from 'httpConfig/http';
import { IClient } from '@api/models';
import { ClientStatusEnum } from '@api/enum';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';

export const getAllClients = async () => {
    const { data } = await http.get(`${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients`);
    return data as IClient[];
};

export const getClientById = async (clientId: string) => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}`
    );

    return data as IClient;
};

export const clientStatusUpdate = async (clientId: string, status: ClientStatusEnum) => {
    const { data } = await http.put(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/status`,
        {
            status,
        }
    );
    return data as IClient;
};

export const addClient = async (payload: any) => {
    const { data, status } = await http.post(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients`,
        payload
    );
    return { data, status };
};

export const updateClient = async (clientId: string, payload: IClient) => {
    const { data } = await http.put(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}`,
        payload
    );
    return data as IClient;
};

export const getClientStorages = async () => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/storage-names`
    );
    return data as string[];
};

export const uploadLoginImage = async (payload: any, clientId: string) => {
    const { data } = await http.post(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/preferences/login-images`,
        payload
    );
    return data;
};

export const removeLoginImage = async (imageId: string, clientId: string) => {
    const { data } = await http.delete(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/preferences/login-images/${imageId}`
    );

    return data;
};
