import { FC, ForwardedRef, ReactNode, forwardRef } from 'react';
import { DrawerProps } from './Drawer';
import ConfirmationDrawer from './ConfirmationDrawer';

interface DeleteConfirmationDrawerProps extends Omit<DrawerProps, 'title' | 'children'> {
    title?: ReactNode;
    message: ReactNode;
    onConfirm?: () => void;
    isConfirming?: boolean;
    confirmText?: string;
    confirmLoadingText?: string;
    cancelText?: string;
}

const DeleteConfirmationDrawer = forwardRef(
    (props: DeleteConfirmationDrawerProps, ref: ForwardedRef<HTMLDivElement>) => {
        const { title, ...rest } = props;

        return <ConfirmationDrawer ref={ref} title={title} {...rest} />;
    }
);

DeleteConfirmationDrawer.defaultProps = {
    title: 'Confirm delete',
    isConfirming: false,
    confirmText: 'Delete',
    confirmLoadingText: 'Deleting',
    cancelText: 'Cancel',
};

export default DeleteConfirmationDrawer;
