const Icon = (props: any) => {
    return (
        <svg width={14} height={16} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1 3.8h12M11.665 3.8v9.8c0 .371-.14.727-.39.99-.25.262-.59.41-.943.41H3.665c-.353 0-.692-.148-.942-.41a1.436 1.436 0 01-.391-.99V3.8m2 0V2.4c0-.371.14-.727.39-.99.25-.262.59-.41.943-.41h2.667c.354 0 .693.147.943.41s.39.619.39.99v1.4"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
