import { FloatingContextProvider, FloatingElement } from '@library/floating';
import dayjs, { Dayjs } from 'dayjs';
import { DetailedHTMLProps, FC, InputHTMLAttributes, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Picker from './Picker';

// value
// onChange
// format
// excludeDates
// includeDates
// minDate
// maxDate
// highlightDates

interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

interface DatePickerProps extends Omit<InputProps, 'value' | 'onChange'> {
    value?: Date;
    onChange?: (date: Date) => void;
    format?: string;
    minDate?: Date;
    maxDate?: Date;
}
const DatePicker: FC<DatePickerProps> = (props) => {
    const { value: controlledValue, onChange, format, minDate, maxDate, className, ...rest } = props;

    const [unControlledValue, setUnControlledValue] = useState(dayjs());

    const value = controlledValue !== undefined ? dayjs(controlledValue) : unControlledValue;
    const setValue = controlledValue !== undefined ? undefined : setUnControlledValue;

    const [open, setOpen] = useState(false);

    const handleChange = (date: Dayjs) => {
        setValue?.(date);

        onChange?.(date.toDate());

        setOpen(false);
    };

    return (
        <FloatingContextProvider open={open} setOpen={setOpen} placement="bottom-start">
            {({ context, refs, getReferenceProps, getFloatingProps }) => (
                <>
                    <input
                        {...rest}
                        ref={refs.setReference}
                        {...getReferenceProps()}
                        type="text"
                        className={twMerge('caret-transparent cursor-pointer', className)}
                        value={value && value.isValid() ? value.format(format) : ''}
                        onChange={() => {}}
                        autoComplete="off"
                    />

                    <FloatingElement context={context} refs={refs} {...getFloatingProps()} className="rounded-xl">
                        <Picker
                            value={value}
                            onChange={handleChange}
                            minDate={minDate ? dayjs(minDate) : null}
                            maxDate={maxDate ? dayjs(maxDate) : null}
                        />
                    </FloatingElement>
                </>
            )}
        </FloatingContextProvider>
    );
};

DatePicker.defaultProps = {
    format: 'DD MMM, YYYY',
    placeholder: 'Select from calendar',
};

export default DatePicker;
