import dayjs, { Dayjs } from 'dayjs';
import { FC, useState } from 'react';
import DayPicker from './DayPicker';
import MonthPicker from './MonthPicker';
import YearPicker from './YearPicker';
import { getInitialViewDate } from '../helpers/datepicker.utils';
import { PickerType } from '../helpers/datepicker.enums';

interface PickerProps {
    value: Dayjs;
    onChange: (date: Dayjs) => void;
    minDate?: Dayjs;
    maxDate?: Dayjs;
}

const Picker: FC<PickerProps> = (props) => {
    const { value, onChange, minDate, maxDate } = props;

    const _initialViewDate = getInitialViewDate(value, maxDate);

    const [viewDate, setViewDate] = useState(_initialViewDate);

    const [picker, setPicker] = useState(PickerType.DAY);

    const handleViewDateChange = (date: Dayjs) => {
        setViewDate(date);
    };

    const handleViewMonthChange = (month: number) => {
        setViewDate((prev) => dayjs().year(prev.year()).month(month).date(prev.date()));
    };

    const handleViewYearChange = (year: number) => {
        setViewDate((prev) => dayjs().year(year).month(prev.month()).date(prev.date()));
    };

    return (
        <div className="p-4 w-[312px] max-w-full min-h-[240px] select-none">
            {picker === PickerType.DAY && (
                <DayPicker
                    selectedDate={value}
                    onSelectedDateChange={onChange}
                    viewDate={viewDate}
                    onViewDateChange={handleViewDateChange}
                    onYearPickerSelect={() => setPicker(PickerType.YEAR)}
                    onMonthPickerSelect={() => setPicker(PickerType.MONTH)}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            )}

            {picker === PickerType.MONTH && (
                <MonthPicker
                    selectedYear={value.year()}
                    selectedMonth={value.month()}
                    viewYear={viewDate.year()}
                    onViewYearChange={handleViewYearChange}
                    onViewMonthChange={handleViewMonthChange}
                    onYearPickerSelect={() => setPicker(PickerType.YEAR)}
                    onDayPickerSelect={() => setPicker(PickerType.DAY)}
                    minYear={minDate?.year()}
                    maxYear={maxDate?.year()}
                    minMonth={minDate?.month()}
                    maxMonth={maxDate?.month()}
                />
            )}

            {picker === PickerType.YEAR && (
                <YearPicker
                    selectedYear={value.month()}
                    viewYear={viewDate.year()}
                    onViewYearChange={handleViewYearChange}
                    onMonthPickerSelect={() => setPicker(PickerType.MONTH)}
                    minYear={minDate?.year()}
                    maxYear={maxDate?.year()}
                />
            )}
        </div>
    );
};

export default Picker;
