import { InfoCircleIcon } from '@icon/index';
import React from 'react';
interface ErrorMessageProps {
    message: string;
}

const ErrorMessageInfo = (props: ErrorMessageProps) => {
    const { message } = props;
    return (
        <div className="flex items-center bg-[#f5cfcf] gap-3 p-2 mt-2">
            <InfoCircleIcon className="text-danger " />
            <p className="text-xs font-medium text-danger">{message}</p>
        </div>
    );
};

export default ErrorMessageInfo;
