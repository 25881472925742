import { ClientStatusEnum } from '@api/enum';
import { PermissionEnum } from '@api/enum/permission.enum';
import useDialog from '@hooks/use-dialog';
import { Avatar, Button, Chip, Table } from '@hyperflake/react-ui-library';
import useClientListData from 'modules/clients/hooks/useClientListData';
import EventBlocker from 'modules/shared/components/EventBlocker/EventBlocker';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { SearchBar } from 'modules/shared/features/search';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import { EditIcon } from 'modules/shared/icons';
import { useNavigate } from 'react-router-dom';
import AddClientDrawer from '../AddClientDrawer/AddClientDrawer';
import EditClientDrawer from '../EditClientDrawer/EditClientDrawer';

const ClientList = () => {
    const navigate = useNavigate();

    const addClientDialog = useDialog();
    const editClientDialog = useDialog<string>();

    const clientListData = useClientListData();

    const { hasPermission } = useAuthPermission();

    return (
        <PageTemplate>
            <div className="flex justify-between">
                <PageHeading>Clients</PageHeading>

                {hasPermission(PermissionEnum.CLIENT_CREATE) && (
                    <Button onClick={addClientDialog.show}>Add Client</Button>
                )}
            </div>

            <div className="mt-6">
                <SearchBar value={clientListData.searchQuery} onChange={clientListData.setSearchQuery} />
            </div>

            <Table
                className="mt-6"
                borderStyle="divider"
                hover
                isLoading={clientListData.isLoading}
                data={clientListData.filteredClients}
                columns={[
                    {
                        header: 'ID',
                    },
                    {
                        header: 'Name',
                    },
                    {
                        header: 'Primary Color',
                    },
                    {
                        header: 'Domain',
                    },
                    {
                        header: 'Status',
                    },
                    {
                        header: 'Edit',
                    },
                ]}
                renderRow={(row) => (
                    <Table.Row
                        key={row._id}
                        onClick={() => {
                            hasPermission(PermissionEnum.CLIENT_READ)
                                ? navigate(`/clients/${row._id}/client-details`)
                                : hasPermission(PermissionEnum.PROJECT_READ)
                                ? navigate(`/clients/${row._id}/projects`)
                                : hasPermission(PermissionEnum.USER_READ)
                                ? navigate(`/clients/${row._id}/users`)
                                : null;
                        }}
                    >
                        <Table.Cell>{row._id}</Table.Cell>
                        <Table.Cell>
                            <div className="flex items-center gap-2">
                                <Avatar src={row?.logoUrl} alt={row?.name} bgColor={row?.preferences?.primaryColor} />
                                {row?.name}
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            <div className="flex items-center gap-3 ">
                                <div
                                    className="h-5 w-5 rounded-full"
                                    style={{ backgroundColor: row.preferences.primaryColor }}
                                />

                                {row.preferences.primaryColor.toUpperCase()}
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            {<span className="w-10 truncate">{row.domain ? row.domain : '-'}</span>}
                        </Table.Cell>
                        <Table.Cell>
                            <Chip
                                label={row.status}
                                color={row.status === ClientStatusEnum.ENABLED ? 'success' : 'danger'}
                                variant="shaded"
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <EventBlocker stopPropagation preventDefault>
                                <button
                                    className="p-1 text-grayscale-500"
                                    onClick={() => editClientDialog.show(row._id)}
                                >
                                    <EditIcon />
                                </button>
                            </EventBlocker>
                        </Table.Cell>
                    </Table.Row>
                )}
            />

            <AddClientDrawer
                open={addClientDialog.isOpen}
                onClose={addClientDialog.hide}
                onClientAdd={clientListData.addClient}
            />

            <EditClientDrawer
                open={editClientDialog.isOpen}
                clientId={editClientDialog.data}
                onClientUpdate={(client) => clientListData.updateClient(client._id, client)}
                onClose={editClientDialog.hide}
            />
        </PageTemplate>
    );
};

export default ClientList;
