import { ICamera } from '@api/models/camera.model';
import { getAvailableCameraStorageList } from '@api/services/camera.service';
import { Button, Drawer } from '@hyperflake/react-ui-library';
import { DatePicker } from '@library/datepicker';
import { FormHelperText, Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { useQuery } from '@tanstack/react-query';
import { ErrorMessage, Form, Formik } from 'formik';
import useCameraAddForm from 'modules/cameras/hooks/useCameraAddForm';
import RequiredMark from 'modules/shared/components/FormFields/RequiredMark';
import { FormikInput, FormikSelect, FormikSwitch } from 'modules/shared/features/formik';
import { resolutionOption } from 'modules/shared/helpers/shared.constants';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface CameraAddDrawerFormProps {
    open: boolean;
    onClose: () => void;
    onCameraAdd: (camera: ICamera) => void;
}

const AddCameraDrawer = (props: CameraAddDrawerFormProps) => {
    const { open, onClose, onCameraAdd } = props;
    const { clientId, projectId } = useParams();
    // const queryClient = useQueryClient();
    const addCameraForm = useCameraAddForm(clientId, projectId);

    // const addCameraMutation = useMutation(async (payload: ICamera) => {
    //     const { data, status } = await createCamera(clientId, projectId, payload);
    //     return { data, status };
    // });

    const { data: storages, isLoading: storageIsLoading } = useQuery<string[]>(
        ['clients', clientId, 'projects', projectId, 'cameras', 'storage_names'],
        async () => {
            const data = getAvailableCameraStorageList(clientId, projectId);
            return data;
        }
    );

    // option for drop downs
    const resOption = useMemo(() => {
        return resolutionOption.map((val) => ({
            label: JSON.stringify(val),
            value: JSON.stringify(val),
        }));
    }, []);

    // const timezoneOption = useMemo(() => {
    //     return [
    //         {
    //             label: 'Select Timezone',
    //             value: '',
    //         },
    //         ...timezoneList.map((tz) => ({
    //             label: tz.label,
    //             value: tz.value,
    //         })),
    //     ];
    // }, []);

    const storageOption = useMemo(() => {
        if (!storages) return [];
        return [{ label: 'Select Storage', value: '' }, ...storages.map((val: string) => ({ label: val, value: val }))];
    }, [storages]);

    const handleCameraAdd = async (payload: any) => {
        try {
            const camera = await addCameraForm.submit(payload);
            toast.success('Camera Add Successfully.');

            onCameraAdd(camera);
            onClose();
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    const handleSubmit = (values: any) => {
        const formattedData = addCameraForm.handleDataTransformation(values);
        handleCameraAdd(formattedData);
    };

    // const { hasAdminPermission } = useAuthPermission();

    // if (!hasAdminPermission) return <AdminPermission formTitle="TimeLapse Camera" />;
    return (
        <Drawer open={open} onClose={onClose} title="Add TimeLapse Camera">
            <div>
                <div>
                    <Formik
                        initialValues={addCameraForm.initialValues}
                        // enableReinitialize
                        validationSchema={addCameraForm.schema}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <Form>
                                <div className="">
                                    <div className="flex items-center gap-2 mt-4">
                                        <Label htmlFor="isPointedTo" className="font-medium text-grayscale-900 mb-0">
                                            Is Pointed To
                                        </Label>

                                        <FormikSwitch color="success" name="isPointedTo" />
                                    </div>
                                    {formik.values.isPointedTo && (
                                        <div className="mt-4">
                                            <Label>Folder</Label> <RequiredMark />
                                            <div className="flex gap-2">
                                                <FormikInput type="text" name="folder" />
                                            </div>
                                            <FormHelperText variant={'error'}>
                                                <ErrorMessage name="folder" />
                                            </FormHelperText>
                                        </div>
                                    )}
                                    {/* Id */}
                                    {/* <div className="">
                                        <Label>ID</Label>
                                        <FormikInput type='text' name='_id' />
                                        <Input
                                            type="text"
                                            name="_id"
                                            onChange={(event: any) => {
                                                formik.setFieldValue('_id', event.target.value.trim());
                                                formik.setFieldValue('folder', event.target.value.trim());
                                            }}
                                        />

                                        <button onClick={() => console.log({ errors: formik.errors })}>eros</button>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="_id" />
                                        </FormHelperText>
                                    </div> */}
                                    {/* name */}
                                    <div className="mt-4">
                                        <Label>
                                            Name <RequiredMark />
                                        </Label>
                                        <FormikInput type="text" name="name" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="name" />
                                        </FormHelperText>
                                    </div>
                                    {/* installation Date */}
                                    <div className="">
                                        <Label>
                                            Installation Date <RequiredMark />
                                        </Label>
                                        <DatePicker
                                            className="flex items-center w-full font-medium bg-transparent border border-grayscale-300 placeholder:text-grayscale-400 focus:border-primary focus:outline focus:outline-2 focus:outline-primary/10 text-sm px-4 h-10 rounded-md"
                                            // disabled={formik.values.pointedTo !== ''}
                                            onChange={(val) => formik.setFieldValue('installationDate', val)}
                                            value={formik.values.installationDate}
                                            maxDate={new Date()}
                                        />

                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="installationDate" />
                                        </FormHelperText>
                                    </div>

                                    {/* folder */}
                                    {/* <div>
                                        <Label>Folder</Label>
                                        <div className="flex gap-2">
                                            <FormikInput type="text" name="folder" />
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="folder" />
                                        </FormHelperText>
                                    </div> */}

                                    {/* camera/unit type */}
                                    <div>
                                        <Label>
                                            Type <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikSelect
                                                name="details.type"
                                                options={addCameraForm.unitTypeOption}
                                                // disabled={formik.values.pointedTo !== ''}
                                            />
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="details.type" />
                                        </FormHelperText>
                                    </div>
                                    {/* camera/unit version */}
                                    <div className="">
                                        <Label>Version</Label>
                                        <FormikInput
                                            type="text"
                                            name="details.version"
                                            // disabled={formik.values.pointedTo !== ''}
                                        />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="details.version" />
                                        </FormHelperText>
                                    </div>
                                    {/* timezone */}
                                    <div>
                                        <Label>
                                            Timezone <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikSelect name="timezone" options={addCameraForm.timezoneOption} />
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="timezone" />
                                        </FormHelperText>
                                    </div>
                                    {/* orientation */}
                                    <div className="">
                                        <Label>Orientation</Label>
                                        <FormikInput type="text" name="orientation" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="orientation" />
                                        </FormHelperText>
                                    </div>
                                    {/* storage */}
                                    <div>
                                        <Label>
                                            Storage <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikSelect name="storage" options={storageOption} />
                                            {storageIsLoading && <CircularProgress size={14} />}
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="storage" />
                                        </FormHelperText>
                                    </div>
                                    {/* Latest Image  */}
                                    <div>
                                        <Label>Image Name</Label>
                                        <FormikInput type="text" name="latestImage.name" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="latestImage.name" />
                                        </FormHelperText>
                                    </div>

                                    <div>
                                        <Label>Resolution</Label>
                                        <div className="flex gap-2">
                                            <FormikSelect name="latestImage.resolution" options={resOption} />
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="latestImage.resolution" />
                                        </FormHelperText>
                                    </div>
                                    {/* Location */}
                                    <div className="mt-4">
                                        <h5>Location</h5>
                                        <div className="mt-4">
                                            <Label>Latitude</Label>
                                            <FormikInput type="text" name="location.latitude" />
                                            <FormHelperText variant={'error'}>
                                                <ErrorMessage name="location.latitude" />
                                            </FormHelperText>
                                        </div>
                                        <div className="">
                                            <Label>Longitude</Label>
                                            <FormikInput type="text" name="location.longitude" />
                                            <FormHelperText variant={'error'}>
                                                <ErrorMessage name="location.longitude" />
                                            </FormHelperText>
                                        </div>
                                    </div>
                                </div>

                                <Button
                                    type="submit"
                                    size={'lg'}
                                    className="mt-8 w-28"
                                    disabled={addCameraForm.isSubmitting}
                                >
                                    {addCameraForm.isSubmitting ? (
                                        <CircularProgress size={20} color={'inherit'} />
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Drawer>
    );
};

export default AddCameraDrawer;
