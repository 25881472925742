import { FloatingArrow as FloatingUiArrow } from '@floating-ui/react';
import { DetailedHTMLProps, FC, ForwardedRef, HTMLAttributes, forwardRef } from 'react';
import { FloatingElementReturn } from '../helpers/floating.types';

interface FloatingArrowProps
    extends DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>,
        Pick<FloatingElementReturn, 'context'> {
    ref?: ForwardedRef<SVGSVGElement>;
}
const FloatingArrow = forwardRef<SVGSVGElement, FloatingArrowProps>(
    (props: FloatingArrowProps, ref: ForwardedRef<SVGSVGElement>) => {
        const { context, ...rest } = props;

        return <FloatingUiArrow context={context} ref={ref} {...rest} fill="currentColor" />;
    }
);

export default FloatingArrow;
