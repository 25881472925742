import { createContext, useContext } from 'react';
import { FloatingElementReturn } from '../helpers/floating.types';

/************************************* Context **********************************************/

export const FloatingContext = createContext<FloatingElementReturn>(null);

/************************************* Hooks **********************************************/

export const useFloatingContext = () => {
    const context = useContext(FloatingContext);

    if (context == null) {
        throw new Error('Floating components must be wrapped in <FloatingContextProvider />');
    }

    return context;
};
