import { DetailedHTMLProps, FC, ForwardedRef, LabelHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface LabelProps extends DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
    ref?: ForwardedRef<HTMLLabelElement>;
}

const Label = forwardRef<HTMLLabelElement, LabelProps>((props: LabelProps, ref: ForwardedRef<HTMLLabelElement>) => {
    const { children, className, ...rest } = props;

    return (
        <label
            {...rest}
            ref={ref}
            className={twMerge('inline-flex text-grayscale-500 text-sm font-medium mb-[6px]', className)}
        >
            {children}
        </label>
    );
});

export default Label;
