import { FC } from 'react';
import moment from 'moment';
import { Card } from '@library/cards';
import { useNavigate } from 'react-router-dom';
import VideoPlayer from 'modules/shared/components/VideoPlayer/VideoPlayer';
import EventBlocker from 'modules/shared/components/EventBlocker/EventBlocker';
import { EllipsisHorizontalIcon, PlayIcon } from '@icon/index';
import { IDroneFootage } from '@api/models/drone-footage.model';
import { Dropdown, DropdownItem, DropdownList, DropdownTrigger } from '@library/dropdown';
import CardTitle from 'modules/shared/components/CardTitle/CardTitle';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import { PermissionEnum } from '@api/enum/permission.enum';

interface DroneCardProps {
    droneFootage: IDroneFootage;
    onEdit: () => void;
    onDelete: () => void;
}

const DroneCard: FC<DroneCardProps> = (props) => {
    const { droneFootage, onEdit, onDelete } = props;
    const { hasPermission } = useAuthPermission();

    const navigate = useNavigate();

    return (
        <Card className="px-4 py-3 cursor-pointer w-80" elevation="3" onClick={() => navigate(droneFootage._id)}>
            <div className="relative flex justify-center items-center">
                <div className="absolute z-[1]">
                    <button className="flex items-center w-9 h-9 rounded-full glass-effect pl-[13px]">
                        <PlayIcon className="text-white" />
                    </button>
                </div>

                {(hasPermission(PermissionEnum.DRONE_UPDATE) || hasPermission(PermissionEnum.DRONE_DELETE)) && (
                    <EventBlocker className="absolute top-2 right-2 ml-auto z-[4]" stopPropagation preventDefault>
                        <Dropdown>
                            <DropdownTrigger className="w-6 h-6 grid place-items-center rounded-[4px] bg-floating-color hover-transition hover:brightness-90">
                                <EllipsisHorizontalIcon className="text-grayscale-600" />
                            </DropdownTrigger>
                            <DropdownList>
                                {hasPermission(PermissionEnum.DRONE_UPDATE) && (
                                    <DropdownItem onClick={onEdit}>Edit</DropdownItem>
                                )}
                                {hasPermission(PermissionEnum.DRONE_DELETE) && (
                                    <DropdownItem className="text-red-500" onClick={onDelete}>
                                        Delete
                                    </DropdownItem>
                                )}
                            </DropdownList>
                        </Dropdown>
                    </EventBlocker>
                )}

                <VideoPlayer
                    src={droneFootage.url}
                    poster={droneFootage.coverImageUrl}
                    provider={droneFootage.details.provider}
                    className="w-full h-full rounded-lg aspect-video"
                    controls={false}
                    muted={true}
                />
            </div>

            <div className="flex items-center justify-between gap-4 mt-5">
                <CardTitle className="flex-1 overflow-hidden">{droneFootage.name}</CardTitle>

                <div className="inline-flex items-center rounded-full px-2 font-medium text-[11px] h-6 bg-red-100 text-red-500">
                    Video
                </div>
            </div>

            <div className="flex justify-between gap-4 mt-4">
                <div>
                    <div className="text-[11px] text-grayscale-500">Uploaded on</div>
                    <div className="text-xs text-grayscale-900 font-medium mt-2">
                        {/* <TzDateFormatter date={droneFootage.createdAt} format={'h:mm a [·] DD MMM, YYYY'} /> */}
                        {moment(droneFootage.createdAt).format('h:mm a [·] DD MMM, YYYY')}
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default DroneCard;
