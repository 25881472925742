export const backdropAnimation = {
    variants: {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
        },
    },
    transition: { type: 'tween', duration: 0.35, ease: 'linear' },
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
};
