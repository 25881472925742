import { getAllInternalApplications } from '@api/services/internal-applications.service';
import useDataQuery from '@hooks/use-data-query';

const useInternalApplicationListData = () => {
    const internalApplicationsListQuery = useDataQuery(['internal_applications'], () => getAllInternalApplications());
    return {
        data: internalApplicationsListQuery.data,
        loading: internalApplicationsListQuery.isLoading,
    };
};

export default useInternalApplicationListData;
