import { ComponentPropsWithRef, ForwardedRef, forwardRef } from 'react';

interface EventBlockerProps extends ComponentPropsWithRef<'div'> {
    stopPropagation?: boolean;
    preventDefault?: boolean;
}

const EventBlocker = forwardRef((props: EventBlockerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, stopPropagation = false, preventDefault = false, onClick, ...rest } = props;

    const handleClick = (e: any) => {
        if (preventDefault) {
            e.preventDefault();
        }

        if (stopPropagation) {
            e.stopPropagation();
        }

        onClick?.(e);
    };

    return (
        <div {...rest} ref={ref} onClick={handleClick}>
            {children}
        </div>
    );
});

export default EventBlocker;
