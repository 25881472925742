import { FileTypeEnum } from '@api/enum/file-type.enum';
import { ISiteGalleryItem } from '@api/models/site-gallery-item.model';
import { Dropdown, DropdownItem, DropdownList, DropdownTrigger } from '@library/dropdown';
import { GalleryCard, Img } from 'modules/shared/features/content';
import { EllipsisHorizontalIcon, PlayIcon } from 'modules/shared/icons';
import moment from 'moment';

interface SiteGalleryCardProps {
    siteGallery: ISiteGalleryItem;
    onSiteGalleryItemDelete: (item: ISiteGalleryItem) => void;
    hasDeletePermission: boolean;
}
const SiteGalleryCard = (props: SiteGalleryCardProps) => {
    const { siteGallery, onSiteGalleryItemDelete, hasDeletePermission } = props;
    const fileTypeMap = {
        IMAGE: {
            label: 'Image',
            className: 'bg-blue-100 text-blue-500',
        },
        VIDEO: {
            label: 'Video',
            className: 'bg-red-100 text-red-500',
        },
    };
    const isOlderThan3Days = (date: string) => {
        return moment().diff(moment(date, 'YYYYMMDD'), 'days') <= 3;
    };
    return (
        <div>
            <GalleryCard>
                <GalleryCard.ActionBar>
                    <Dropdown placement="bottom-end">
                        <DropdownTrigger className="w-6 h-6 grid place-items-center rounded-[4px] bg-floating-color image-overlay-button-shadow hover-transition hover:brightness-90">
                            <EllipsisHorizontalIcon className="text-grayscale-600" />
                        </DropdownTrigger>

                        <DropdownList className="min-w-[100px]">
                            {/* <DropdownItem onClick={null}>Edit</DropdownItem> */}
                            {hasDeletePermission && (
                                <DropdownItem
                                    onClick={() => onSiteGalleryItemDelete(siteGallery)}
                                    className="text-danger"
                                >
                                    Delete
                                </DropdownItem>
                            )}
                        </DropdownList>
                    </Dropdown>
                </GalleryCard.ActionBar>
                <GalleryCard.Media>
                    {siteGallery.type === FileTypeEnum.IMAGE ? (
                        <Img
                            parentClassName="w-full h-full"
                            className="w-full aspect-image rounded-lg object-cover object-center"
                            src={siteGallery.url}
                            alt=""
                        />
                    ) : (
                        <div className="relative flex justify-center items-center">
                            <div className="absolute z-[1]">
                                <button className="flex items-center w-9 h-9 rounded-full glass-effect pl-[13px]">
                                    <PlayIcon className="text-white" />
                                </button>
                            </div>

                            <video
                                src={siteGallery.url}
                                className="w-full aspect-image rounded-lg object-cover object-center"
                            />
                        </div>
                    )}
                    {/* <Img className="w-full aspect-image rounded-lg object-cover object-center" src={siteGallery.url} /> */}
                </GalleryCard.Media>
                <GalleryCard.Title>
                    <div className="flex items-center justify-between gap-4 mt-5">
                        <div className="flex-1 truncate ">{siteGallery.name}</div>
                        <div
                            className={[
                                'inline-flex items-center rounded-full px-2 font-medium text-[11px] h-6',
                                fileTypeMap[siteGallery.type].className,
                            ].join(' ')}
                        >
                            {fileTypeMap[siteGallery.type].label}
                        </div>
                    </div>
                </GalleryCard.Title>
                <GalleryCard.Footer>
                    <div className="flex flex-wrap gap-4 justify-between">
                        <GalleryCard.FooterItem
                            label="Updated On"
                            content={
                                siteGallery?.updatedAt ? (
                                    <div
                                        className={
                                            isOlderThan3Days(siteGallery?.updatedAt) ? 'text-green-500' : 'text-red-500'
                                        }
                                    >
                                        {moment(siteGallery?.updatedAt, 'YYYYMMDDHHmmss').format('h:mm A DD MMM, YY')}
                                    </div>
                                ) : (
                                    '-'
                                )
                            }
                        ></GalleryCard.FooterItem>

                        <GalleryCard.FooterItem
                            label="Created On"
                            content={moment(siteGallery?.createdAt, 'YYYYMMDD').format('DD MMM, YYYY')}
                        ></GalleryCard.FooterItem>
                    </div>
                </GalleryCard.Footer>
            </GalleryCard>
        </div>
    );
};
export default SiteGalleryCard;
