import { Select } from '@library/forms';
import { useField } from 'formik';
import { FC } from 'react';

interface IOption {
    label: string;
    value: string;
}

interface ICustomSelect {
    name: string;
    options: IOption[];
    disabled?: boolean;
}

const CustomSelect: FC<ICustomSelect> = (props) => {
    const { name, options, disabled } = props;
    const [field, , helpers] = useField(name);

    return (
        <Select
            options={options}
            value={field.value}
            name="parentId"
            onChange={async (event) => {
                await helpers.setValue(event.target.value);
            }}
            disabled={disabled}
        />
    );
};

CustomSelect.defaultProps = {
    disabled: false,
};

export default CustomSelect;
