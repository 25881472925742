import { IClient } from '@api/models';
import { removeLoginImage, uploadLoginImage } from '@api/services/client.service';
import { useMutation } from '@tanstack/react-query';

const useClientLoginImageForm = (client: IClient) => {
    const uploadLoginImageMutation = useMutation((payload: any) => uploadLoginImage(payload, client._id));
    const removeLoginImageMutation = useMutation((imageId: string) => removeLoginImage(imageId, client._id));

    const uploadImage = async (payload: any) => {
        const data = await uploadLoginImageMutation.mutateAsync(payload);
        return data;
    };

    const removeImage = async (imageId: string) => {
        const data = await removeLoginImageMutation.mutateAsync(imageId);
        return data;
    };

    return {
        removeImage,
        removeImageLoading: removeLoginImageMutation.isLoading,
        uploadImage,
        uploadImageLoading: uploadLoginImageMutation.isLoading,
    };
};
export default useClientLoginImageForm;
