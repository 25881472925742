// import { Img } from 'library/media';
import Img from '@components/Img/Img';
import { FC } from 'react';

interface ImageSliderCardProps {
    url: string;
    time: string;
    isActive: boolean;
    onClick: () => void;
}
const ImageSliderCard: FC<ImageSliderCardProps> = (props) => {
    const { url, time, isActive, onClick } = props;

    return (
        <div
            className={[
                'w-20 mb-4 flex flex-col gap-2 p-[6px] pb-1 bg-card-color rounded-xl border-2 select-none cursor-pointer',
                isActive ? 'border-primary shadow-[0px_4px_16px_rgba(4,34,99,0.08)]' : 'border-transparent',
            ].join(' ')}
            onClick={onClick}
        >
            <Img className="w-20 h-16 rounded-md object-cover object-center" src={url} />

            <div className="text-grayscale-700 text-sm font-semibold">{time}</div>
        </div>
    );
};

export default ImageSliderCard;
