import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { FloatingElementReturn } from '../helpers/floating.types';
import FloatingArrow from './FloatingArrow';

interface FloatingElementProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
        Pick<FloatingElementReturn, 'context' | 'refs'> {}

const FloatingElement: FC<FloatingElementProps> = (props) => {
    const { children, context, refs, className, style, ...rest } = props;

    return (
        <>
            {context.open && (
                <div
                    {...rest}
                    ref={refs.setFloating}
                    className={twMerge(
                        'bg-floating-color fill-floating-color shadow-[0px_1px_16px_2px_rgba(13,16,33,0.08),1px_6px_32px_rgba(5,22,35,0.06)] outline-none rounded-lg z-[60]',
                        className
                    )}
                    style={{ ...style, ...context.floatingStyles }}
                >
                    {children}

                    {context.middlewareData.arrow && (
                        <FloatingArrow context={context} ref={refs.arrow} className="fill-[inherit]" />
                    )}
                </div>
            )}
        </>
    );
};

export default FloatingElement;
