import { FC, ReactNode } from 'react';
import { FloatingContext } from '../context/floating-context';
import useFloatingElement from '../hooks/use-floating-element';
import { FloatingElementOptions, FloatingElementReturn } from '../helpers/floating.types';

interface FloatingContextProviderProps extends FloatingElementOptions {
    children: ReactNode | ((params: FloatingElementReturn) => ReactNode);
}
const FloatingContextProvider: FC<FloatingContextProviderProps> = (props) => {
    const { children, ...rest } = props;

    const data = useFloatingElement(rest);

    return (
        <FloatingContext.Provider value={data}>
            {typeof children === 'function' ? children(data) : children}
        </FloatingContext.Provider>
    );
};

export default FloatingContextProvider;
