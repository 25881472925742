import Plyr, { Provider } from "plyr";
import {
  CSSProperties,
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";

interface VideoPlayerProps {
  src: string;
  provider?: "PROGRESSCENTER" | "VIMEO" | "YOUTUBE";
  className?: string;
  style?: CSSProperties;
  controls?: boolean;
  muted?: boolean;
  keyboard?: boolean;
  poster?: string;
}

const VideoPlayer: FC<VideoPlayerProps> = forwardRef((props, ref) => {
  const { src, provider, className, style, controls, muted, keyboard, poster } =
    props;

  const videoRef = useRef(null);

  const videoProvider: Provider = useMemo(() => {
    const externalVideoProviderMap: { [x: string]: Provider } = {
      VIMEO: "vimeo",
      YOUTUBE: "youtube",
      PROGRESSCENTER: "html5",
    };

    return externalVideoProviderMap[provider] || "html5";
  }, [provider]);

  useImperativeHandle(ref, () => videoRef.current, [videoRef]);

  useEffect(() => {
    const controlsList = controls
      ? [
          "play-large",
          "play",
          "progress",
          "current-time",
          "duration",
          "mute",
          "volume",
          "settings",
          "fullscreen",
        ]
      : [];

    const player = new Plyr(videoRef.current, {
      controls: controlsList,
      keyboard: { global: keyboard },
    });

    player.source = {
      type: "video",
      sources: [
        {
          src: src,
          provider: videoProvider,
        },
      ],
    };

    player.muted = muted;
    player.poster = poster;

    // return () => {
    //     player.destroy();
    // };
  }, [controls, keyboard, muted, poster, provider, src, videoProvider]);

  return (
    <div className={className} style={style}>
      <video ref={videoRef} />
    </div>
  );
});

VideoPlayer.defaultProps = {
  controls: true,
  muted: false,
  keyboard: false,
};

export default VideoPlayer;
