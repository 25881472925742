import { FC } from 'react';
// import SiteGalleryCard from './SiteGalleryCard';
import { ISiteGalleryItem } from '@api/models/site-gallery-item.model';
import SiteGalleryCard from '../SiteGalleryCard/SiteGalleryCard';

interface SiteGalleryGridProps {
    siteGallery: ISiteGalleryItem[];
    onSiteGalleryItemDelete: (item: ISiteGalleryItem) => void;
    hasDeletePermission: boolean;
}

const SiteGalleryGrid = (props: SiteGalleryGridProps) => {
    const { siteGallery, onSiteGalleryItemDelete, hasDeletePermission } = props;

    return (
        <div>
            {/* {.map((r, i) => (
                <div key={r._id} className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-2">
                    <SiteGalleryCard
                        siteGalleryItem={r}
                        onDelete={() => onSiteGalleryItemDelete(r)}
                        hasDeletePermission={hasDeletePermission}
                    />
                </div>
            ))} */}
            <div className="grid grid-cols-4 gap-6 mt-6 empty:hidden">
                {siteGallery.map((row) => (
                    <div className="col-span-1" key={row._id}>
                        <SiteGalleryCard
                            siteGallery={row}
                            onSiteGalleryItemDelete={(item) => onSiteGalleryItemDelete(item)}
                            hasDeletePermission={hasDeletePermission}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SiteGalleryGrid;
