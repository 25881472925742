import { VariantProps, cva } from 'class-variance-authority';
import { DetailedHTMLProps, FC, ForwardedRef, LiHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const listItemVariants = cva(['flex items-center gap-2 text-sm font-medium px-4 py-2 outline-none'], {
    variants: {
        hover: {
            true: 'cursor-pointer transition-colors duration-200 hover:bg-primary/5',
            false: '',
        },
    },
    defaultVariants: {
        hover: false,
    },
});

interface ListItemProps
    extends DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>,
        VariantProps<typeof listItemVariants> {
    ref?: ForwardedRef<any>;
}

const ListItem = forwardRef<HTMLLIElement, ListItemProps>((props: ListItemProps, ref: ForwardedRef<any>) => {
    const { children, hover, className, ...rest } = props;

    return (
        <li {...rest} ref={ref} className={twMerge(listItemVariants({ hover, className }), className)}>
            {children}
        </li>
    );
});

export default ListItem;
