import { VariantProps, cva } from 'class-variance-authority';
import { DetailedHTMLProps, FC, ForwardedRef, HTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const formHelperTextVariants = cva(['inline-flex text-xs font-medium', 'mt-2'], {
    variants: {
        variant: {
            default: ['text-grayscale-500'],
            error: ['text-danger'],
            success: ['text-success'],
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

interface FormHelperTextProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
        VariantProps<typeof formHelperTextVariants> {
    ref?: ForwardedRef<HTMLDivElement>;
}

const FormHelperText = forwardRef<HTMLDivElement, FormHelperTextProps>(
    (props: FormHelperTextProps, ref: ForwardedRef<HTMLDivElement>) => {
        const { children, variant, className, ...rest } = props;

        return (
            <div {...rest} ref={ref} className={twMerge(formHelperTextVariants({ variant, className }))}>
                {children}
            </div>
        );
    }
);

export default FormHelperText;
