import { ISiteGalleryItem } from '@api/models/site-gallery-item.model';
import { uploadSiteGallery } from '@api/services/site-gallery.service';
import { Button } from '@hyperflake/react-ui-library';
import { useProgressTabContext } from '@library/progress-tab';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage, Form, Formik } from 'formik';
import produce from 'immer';
import AdminPermission from 'modules/shared/components/AdminPermission/AdminPermission';
import FileUploadPreview from 'modules/shared/components/FileUploadPreview/FileUploadPreview';
import DragAndDropFileUpload from 'modules/shared/components/FormFields/DragAndDropFileUpload';
import { convertFileSize, getFileExtension } from 'modules/shared/helpers/shared.utils';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import { FC } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

const FormSchema = Yup.object().shape({
    files: Yup.array().test('validate-files', function (files: File[]) {
        if (files.length === 0) {
            return this.createError({ message: 'Please upload at least one file' });
        }

        if (files.length > 25) {
            return this.createError({
                message: 'A bulk upload of 25 files is possible at a time',
            });
        }

        return true;
    }),
});

interface ISiteGalleryAddDrawerForm {
    onClose: () => void;
    clientId: string;
    projectId: string;
    onUpload: (siteGallery: ISiteGalleryItem[]) => void;
}

const SiteGalleryAddDrawerForm: FC<ISiteGalleryAddDrawerForm> = (props) => {
    const { onClose, clientId, projectId, onUpload } = props;
    // const { hasAdminPermission } = useAuthPermission();

    const progressTabCtx = useProgressTabContext();

    const addSiteGalleryListMutation = useMutation(
        (values: { progressTabItemId: string; abortController: AbortController; payload: FormData }) => {
            const { progressTabItemId, abortController, payload } = values;

            return uploadSiteGallery(clientId, projectId, payload, {
                signal: abortController.signal,
                onUploadProgress: (progress) => {
                    progressTabCtx.updateItem(progressTabItemId, { progress });
                },
            });
        }
    );

    const handleSubmit = async (values: { files: File[] }) => {
        console.log({ values });

        const formData = new FormData();
        for (const file of values.files) {
            formData.append('files', file);
        }

        const totalSize = values.files.reduce((acc, cur) => {
            acc += cur.size;

            return acc;
        }, 0);

        const abortController = new AbortController();

        const itemData = progressTabCtx.addItem({
            _id: uuidv4(),
            title: 'Uploading Files',
            text: convertFileSize(totalSize),
            extension: getFileExtension(values.files[0].name),
            onCancel: () => {
                abortController.abort();
            },
        });

        onClose();

        try {
            const siteGalleryList = await addSiteGalleryListMutation.mutateAsync({
                progressTabItemId: itemData._id,
                abortController: abortController,
                payload: formData,
            });

            progressTabCtx.updateItem(itemData._id, {
                progress: 100,
                isCompleted: true,
            });

            onUpload(siteGalleryList);
        } catch (err: any) {
            if (err?.message !== 'canceled') {
                toast.error(err.data?.message || 'Some error occured, please try again.');

                progressTabCtx.updateItem(itemData._id, {
                    progress: 100,
                    isErrored: true,
                });
            }
        }
    };

    // if (!hasAdminPermission) return <AdminPermission formTitle="Site Gallery" />;

    return (
        <div>
            <div>
                <h4>Add Gallery Items</h4>
            </div>
            <div className="h-full">
                <Formik
                    initialValues={{ files: [] }}
                    validationSchema={FormSchema}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form className="flex flex-col flex-1">
                            <div className="mt-6">
                                <DragAndDropFileUpload
                                    text="Browse JPG/PNG/MP4"
                                    onChange={(files) => {
                                        if (values.files.length + files.length > 25) {
                                            toast.error('You can only add upto 25 files (photos or videos) at a time');
                                            return;
                                        }

                                        setFieldValue('files', [...values.files, ...files]);
                                    }}
                                    accept={{
                                        'image/*': ['.jpeg', '.jpg', '.png'],
                                        'video/*': [],
                                    }}
                                    multiple
                                />

                                <ErrorMessage component="div" className="form-error-message" name="files" />
                            </div>

                            <div className="space-y-4 mt-6">
                                {values.files.map((file, index) => (
                                    <FileUploadPreview
                                        key={index}
                                        name={file.name}
                                        size={file.size}
                                        onCancel={() => {
                                            const files = produce(values.files, (draftFiles) => {
                                                draftFiles.splice(index, 1);
                                            });

                                            setFieldValue('files', files);
                                        }}
                                    />
                                ))}
                            </div>

                            <div className="mt-auto">
                                <div className="flex gap-4 mt-8">
                                    <Button size="sm" className="font-medium" onClick={onClose}>
                                        Cancel
                                    </Button>

                                    <Button type="submit" size="sm">
                                        Upload
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default SiteGalleryAddDrawerForm;
