import { PermissionEnum } from '@api/enum/permission.enum';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface AuthGuardProps {
    page: ReactNode;
    permissions: PermissionEnum[];
}

const PermissionGuard = (props: AuthGuardProps) => {
    const { hasPermissions } = useAuthPermission();

    if (!hasPermissions(props.permissions)) {
        return <Navigate to="/404" replace />;
    }

    return props.page;
};

export default PermissionGuard;
