import { createSlice } from '@reduxjs/toolkit';
import { IConsoleUser, IProject } from '@api/models';
import { ConsoleUserRoleEnum, UserRoleEnum, UserStatusEnum } from '@api/enum';

interface IProfile {
    info: null | IConsoleUser;
}

const getInitialState = (): IProfile => {
    // return {
    //     _id: '',
    //     designation: '',
    //     email: '',
    //     name: '',
    //     password: '',
    //     reseller: '',
    //     role: ConsoleUserRoleEnum.VIEWER,
    //     status: UserStatusEnum.ENABLED,
    //     username: '',
    //     dp: '',
    //     dpUrl: '',
    // };
    return {
        info: null,
    };
};

const profileSlice = createSlice({
    name: 'profile',
    initialState: getInitialState(),
    reducers: {
        setProfile(state, action) {
            const data = action.payload;
            state.info = { ...data };
        },
        removeProfile() {
            return getInitialState();
        },
    },
});

export const profileAction = profileSlice.actions;

export default profileSlice;
