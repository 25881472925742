import dayjs from 'dayjs';
import Picker from '@library/datepicker/components/Picker';
import { Popover } from '@library/popover';
import { FC } from 'react';
import { useField } from 'formik';
import { Input } from '@library/forms';
import moment from 'moment';
import { CalendarIcon } from '../../icons';

interface IFormikDatePicker {
    // value?: Date;
    // onChange?: (date: Date) => void;
    format?: string;
    minDate?: Date;
    maxDate?: Date;
    name: string;
    disabled?: boolean;
}

const FormikDatePicker: FC<IFormikDatePicker> = (props) => {
    const { format, maxDate, minDate, name, disabled } = props;
    const [field, , helpers] = useField(name);


    return (
        <Popover
            placement="bottom-start"
            contentClassName="z-[82] p-0"
            content={
                <Picker
                    value={field.value}
                    onChange={(cusDate) => helpers.setValue(moment(cusDate.format('YYYYMMDD'), 'YYYYMMDD'))}
                    minDate={minDate ? dayjs(minDate) : null}
                    maxDate={maxDate ? dayjs(maxDate) : null}
                />
            }
        >
            <div className="relative flex items-center">
                <Input
                    name={name}
                    readOnly
                    value={dayjs(field.value).format(format)}
                    className=""
                    disabled={disabled}
                />
                <div className="absolute right-3 pointer-events-none">
                    <CalendarIcon />
                </div>
            </div>
        </Popover>
    );
};

FormikDatePicker.defaultProps = {
    format: 'DD MMM, YYYY',
    disabled: false,
    // placeholder: 'Select from calendar',
};

export default FormikDatePicker;
