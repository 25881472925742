import React, { FC, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface IllustrationViewProps {
  illustration: ReactNode;
  title: ReactNode;
  message: ReactNode;
  className?: string;
}

const IllustrationView: FC<IllustrationViewProps> = (props) => {
  const { illustration, title, message, className } = props;

  return (
    <div
      className={twMerge("flex flex-col items-center text-center", className)}
    >
      {illustration}
      <h3 className="mt-10">{title}</h3>
      <div className="text-grayscale-500 mt-4">{message}</div>
    </div>
  );
};

export default IllustrationView;
