import { getDroneFootageById } from '@api/services/drone-footage.service';
import VideoPlayer from 'modules/shared/components/VideoPlayer/VideoPlayer';
import useBoundingClientRect from 'modules/shared/hooks/use-bounding-client-rect';
import useNavName from 'modules/shared/hooks/useNavName';
import { Breadcrumb } from '@library/breadcrumb';
import { CircularProgress } from '@library/loaders/components';
import { Skelton } from '@library/skeltons';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const DroneDetails = () => {
    const { clientId, projectId, droneId } = useParams();
    const { clientName, projectName } = useNavName(clientId, projectId);

    const [headerRefSetter, headerBoundingClientRect] = useBoundingClientRect();

    const navigate = useNavigate();

    const { data, isLoading } = useQuery([], async () => getDroneFootageById(clientId, projectId, droneId));

    // const navArray = [
    //     { label: 'Clients', onClick: () => navigate('/client') },
    //     { label: clientName, onClick: () => navigate(`/client?query=${clientId}`) },
    //     {
    //         label: 'Projects',
    //         onClick: () => navigate(`/client/${clientId}/project`),
    //     },
    //     {
    //         label: projectName,
    //         onClick: () => navigate(`/client/${clientId}/project?query=${projectId}`),
    //     },
    //     {
    //         label: 'Drone Footage',
    //         onClick: () => navigate(`/client/${clientId}/project/${projectId}/drone`),
    //     },
    //     { label: droneId, onClick: () => {} },
    // ];

    return (
        <div className="h-screen">
            {/* <Navbar /> */}
            {/* <div className="pt-[var(--navbar-height)]"> */}
            <div className="py-4" ref={headerRefSetter}>
                <div className="px-4">
                    {/* <Breadcrumb separator="/" items={navArray} /> */}
                    <div className="mt-6">
                        <h3>Site Gallery</h3>
                    </div>
                </div>
            </div>

            {isLoading ? (
                <div
                    className="flex  justify-center items-centers relative "
                    style={{
                        height: window.innerHeight - headerBoundingClientRect.height,
                    }}
                >
                    <CircularProgress className="absolute  w-full h-full " />
                    <Skelton variant="rectangular" className="w-full h-full" animation="wave" />
                </div>
            ) : (
                <div
                    className="bg-black"
                    style={{
                        height: window.innerHeight - headerBoundingClientRect.height,
                    }}
                >
                    <div>
                        <VideoPlayer
                            src={data.url}
                            poster={data.coverImageUrl}
                            provider={data.details.provider}
                            keyboard={true}
                            className="aspect-video "
                            style={{
                                maxHeight: window.innerHeight - headerBoundingClientRect.height,
                            }}
                        />
                    </div>
                </div>
            )}
            {/* </div> */}
        </div>
    );
};

export default DroneDetails;
