import * as Yup from 'yup';
import useUniqueMutation from './useUniqueMutation';
import moment from 'moment';
import { useMutation } from '@tanstack/react-query';
import { addUser } from '@api/services/user.service';
import { IUser } from '@api/models';
const useUserAddForm = (clientId: string) => {
    const { uniqueEmailMutation, uniqueUsernameMutation } = useUniqueMutation();

    const schema = Yup.object().shape({
        name: Yup.string().trim().required('This field is required'),
        username: Yup.string()
            .trim()
            .required('This field is required')
            .test(
                'Unique Username',
                'Username already in use', // <- key, message
                function (value) {
                    return new Promise(async (resolve) => {
                        try {
                            let res = await uniqueUsernameMutation.mutateAsync({
                                username: value,
                            });
                            if (res.status === 200) {
                                resolve(true);
                            }
                        } catch (e) {
                            resolve(false);
                        }
                    });
                }
            ),
        phone: Yup.object().shape({
            dialCode: Yup.string().trim(),
            countryCode: Yup.string().trim(),
            number: Yup.string().trim(),
            fullNumber: Yup.string(),
        }),
        email: Yup.string()
            .email('Please enter a valid email address.')
            .required('This field is required')
            .test(
                'Unique Email',
                'Email already in use', // <- key, message
                function (value) {
                    return new Promise(async (resolve) => {
                        try {
                            let res = await uniqueEmailMutation.mutateAsync({ email: value });
                            if (res.status === 200) {
                                resolve(true);
                            }
                        } catch (e) {
                            resolve(false);
                        }
                    });
                }
            ),
        // role: Yup.string().oneOf(Object.keys(Roles)).required('This field is required'),
        role: Yup.string(),
        dob: Yup.date().nullable(),
        designation: Yup.string().trim(),
        tags: Yup.array().typeError('tags must be an array').of(Yup.string()),
        preferences: Yup.object().shape({
            timezone: Yup.string().trim().required('This field is required'),
            primaryColor: Yup.string().trim(),
            theme: Yup.string().trim(),
            isEmailNotificationEnabled: Yup.boolean(),
            show4k: Yup.boolean(),
            showZoomed: Yup.boolean(),
            showTimestamp: Yup.boolean(),
        }),
        projects: Yup.array().typeError('tags must be an array').of(Yup.string()),
    });
    const initialValues: any = {
        name: '',
        username: '',
        phone: {
            dialCode: '+966',
            countryCode: 'sa',
            number: '',
        },
        email: '',
        role: 'VIEWER',
        dob: moment().toDate(),
        file: '',
        designation: '',
        tags: [],
        preferences: {
            timezone: '',
            primaryColor: '#0052CC',
            theme: 'LIGHT',
            isEmailNotificationEnabled: true,
            show4k: false,
            showZoomed: true,
            showTimestamp: false,
        },
        projects: [],
    };

    const addUserMutation = useMutation((payload: any) => addUser(clientId, payload));
    const serializeData = (values: any) => {
        const temp = { ...values };

        // convert dob
        temp.dob = moment(temp.dob).format('YYYYMMDD');

        // handle phone number
        const hasPhoneNumber = values.phone && values.phone.number && values.phone.dialCode && values.phone.countryCode;
        if (hasPhoneNumber) {
            temp.phone.fullNumber = temp.phone.dialCode + values.phone.number;
        } else {
            temp.phone = null;
        }

        return temp;
    };

    const submit = async (values: any) => {
        const formattedValues = serializeData(values);
        console.log('values on add', formattedValues);
        const formData = new FormData();
        formData.append('name', formattedValues.name);
        formData.append('username', formattedValues.username);
        formData.append('email', formattedValues.email);
        formData.append('role', formattedValues.role);
        formData.append('dob', formattedValues.dob);

        formData.append('preferences[timezone]', formattedValues.preferences.timezone);
        formData.append('preferences[primaryColor]', formattedValues.preferences.primaryColor);
        formData.append('preferences[theme]', formattedValues.preferences.theme);
        formData.append(
            'preferences[isEmailNotificationEnabled]',
            formattedValues.preferences.isEmailNotificationEnabled
        );
        formData.append('preferences[show4k]', formattedValues.preferences.show4k);
        formData.append('preferences[showZoomed]', formattedValues.preferences.showZoomed);
        formData.append('preferences[showTimestamp]', formattedValues.preferences.showTimestamp);

        if (values.file) {
            formData.append('file', values.file);
        }
        if (formattedValues.projects.length > 0) {
            formData.append('projects', formattedValues.projects);
        }
        const { data } = await addUserMutation.mutateAsync(formData);
        return data;
    };
    return {
        schema,
        initialValues,
        submit,
        isSubmitting: addUserMutation.isLoading,
    };
};
export default useUserAddForm;
