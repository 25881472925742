import * as Yup from 'yup';

import { PluginIndentifierEnum } from '@api/enum/plugin-indentifier.enum';
import { useMutation } from '@tanstack/react-query';

import { addClient } from '@api/services/client.service';
import { IPluginForm } from '../helpers/clients.types';

const useClientAddForm = () => {
    const schema = Yup.object({
        name: Yup.string().trim().required('This field is required'),
        preferences: Yup.object({
            primaryColor: Yup.string().trim().required('This field is required'),
            hasCdnEnabled: Yup.boolean(),
        }),
        domain: Yup.string().trim(),
        logo: Yup.mixed().nullable(),
        emailHeaderLogo: Yup.mixed().nullable(),
        storage: Yup.string().required('This field is required'),
    });

    const initialValues: any = {
        name: '',

        preset: {
            maxImageViewableDays: '',
        },
        preferences: {
            primaryColor: '#0052CC',
            hasCdnEnabled: true,
            mapZoom: '',
            mapCenter: {
                latitude: '',
                longitude: '',
            },
        },
        domain: '',
        logo: '',
        emailHeaderLogo: '',
        plugins: Object.values(PluginIndentifierEnum).map((plugin: string) => ({
            name: plugin,
            isEnabled: false,
        })),
        storage: '',
    };

    // TODO - move api call to services

    const addClientMutation = useMutation((payload: any) => addClient(payload));
    // const addClientMutation = useMutation(async (payload: any) => {
    //     const { data, status } = await http.post(
    //         `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients`,
    //         payload
    //     );

    //     return { data, status };
    // });

    const submit = async (values: any) => {
        const activePlugins = values.plugins
            .filter((plugin: IPluginForm) => plugin.isEnabled === true)
            .map((plugin: IPluginForm) => plugin.name);

        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('preferences[primaryColor]', values.preferences.primaryColor.trim());
        formData.append('preferences[hasCdnEnabled]', values.preferences.hasCdnEnabled);
        formData.append('preferences[mapZoom]', values.preferences.mapZoom);
        formData.append('domain', values.domain);
        formData.append('preferences[mapCenter][latitude]', values.preferences.mapCenter.latitude);
        formData.append('preferences[mapCenter][longitude]', values.preferences.mapCenter.longitude);
        formData.append('preset[maxImageViewableDays]', values.preset.maxImageViewableDays);

        activePlugins.forEach((val: string, index: number) => {
            formData.append(`plugins[${index}]`, val);
        });

        formData.append('storage', values.storage);

        if (values.logo) {
            formData.append('logo', values.logo);
        }

        if (values.emailHeaderLogo) {
            formData.append('emailHeaderLogo', values.emailHeaderLogo);
        }

        const { data } = await addClientMutation.mutateAsync(formData);

        return data;
    };

    return {
        initialValues,
        schema,
        isSubmitting: addClientMutation.isLoading,
        submit,
    };
};

export default useClientAddForm;
