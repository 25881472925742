import { ComponentPropsWithRef, ForwardedRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface PageTemplateProps extends ComponentPropsWithRef<'div'> {}

const PageTemplate = forwardRef((props: PageTemplateProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, className, ...rest } = props;

    return (
        <div {...rest} ref={ref} className={twMerge('p-5', className)}>
            {children}
        </div>
    );
});

export default PageTemplate;
