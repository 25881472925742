import { ClientStatusEnum } from '@api/enum/client-status.enum';
import { IClient } from '@api/models/client.model';
import { CircularProgress } from '@library/loaders/components';
import { Form, Formik } from 'formik';
import upperCase from 'lodash.uppercase';
import { useMemo } from 'react';
import { ColorResult } from 'react-color';
import { toast } from 'react-toastify';

// import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import EmailHeaderForm from '../EmailHeaderForm/EmailHeaderForm';
import LogoForm from '../LogoForm/LogoForm';

import { PermissionEnum } from '@api/enum/permission.enum';
import { Button, Label } from '@hyperflake/react-ui-library';
import useClientUpdateBasicForm from 'modules/clients/hooks/useClientUpdateBasicForm';
import ColorPicker from 'modules/shared/components/ColorPicker/ColorPicker';
import { FormikInput, FormikSelect, FormikSwitch } from 'modules/shared/features/formik';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';

interface ClientEditDrawerFormProps {
    client: IClient;
    onClientUpdate: (updatedClient: IClient) => void;
}
const ClientEditFormBasic = (props: ClientEditDrawerFormProps) => {
    const { client, onClientUpdate } = props;

    const { hasPermission } = useAuthPermission();

    const updateClientForm = useClientUpdateBasicForm({ client });

    const handleClientUpdate = (updatedClient: IClient) => {
        onClientUpdate(updatedClient);
    };

    const handleSubmit = async (values: any) => {
        try {
            const updatedClient = await updateClientForm.submit(values);

            handleClientUpdate(updatedClient);

            toast.success('Client updated successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    const clientStatusOptions = useMemo(() => {
        return Object.values(ClientStatusEnum).map((status) => ({
            value: status,
            label: upperCase(status),
        }));
    }, []);

    const handleLogoUrlUpdate = (logoUrl: string) => {
        handleClientUpdate({ ...client, logoUrl });
    };

    const handleEmailHeaderLogoUrlUpdate = (emailHeaderLogoUrl: string) => {
        handleClientUpdate({ ...client, emailHeaderLogoUrl });
    };

    return (
        <div>
            {/* Client logo's */}
            <div className="flex justify-around mt-2">
                <LogoForm client={client} onLogoUrlUpdate={handleLogoUrlUpdate} />
                <EmailHeaderForm client={client} onEmailHeaderLogoUrlUpdate={handleEmailHeaderLogoUrlUpdate} />
            </div>

            <Formik
                initialValues={updateClientForm.initialValues}
                enableReinitialize
                validationSchema={updateClientForm.schema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <fieldset disabled={!hasPermission(PermissionEnum.CLIENT_UPDATE)}>
                            <div className="mt-4">
                                {/* name */}
                                <div className="mt-4">
                                    <Label>Name</Label>
                                    <FormikInput type="text" name="name" />
                                    {/* <FormHelperText variant={'error'}>
                                        <ErrorMessage name="name" />
                                    </FormHelperText> */}
                                </div>
                                {/* domain */}
                                <div className="mt-4">
                                    <Label>Domain</Label>
                                    <FormikInput type="text" name="domain" />
                                    {/* <FormHelperText variant={'error'}>
                                        <ErrorMessage name="domain" />
                                    </FormHelperText> */}
                                </div>

                                <div className="mt-4">
                                    <Label>Status</Label>
                                    <FormikSelect name="status" options={clientStatusOptions} />
                                    {/* <FormHelperText variant={'error'}>
                                        <ErrorMessage name="status" />
                                    </FormHelperText> */}
                                </div>
                                {/* Storage */}
                                <div className="mt-4">
                                    <Label>Storage</Label>
                                    <FormikInput type="text" name="storageName" disabled={true} />
                                    {/* <FormHelperText variant={'error'}>
                                        <ErrorMessage name="storageName" />
                                    </FormHelperText> */}
                                </div>

                                {/* preferences */}
                                <div className="mt-8">
                                    <h5>Preferences</h5>
                                    {/* maxImageViewableDays */}
                                    <div className="grid grid-cols-2 gap-4 mt-4">
                                        <div>
                                            <Label>Max ImageViewable Days</Label>
                                            <FormikInput type="number" name="preset.maxImageViewableDays" />
                                        </div>
                                        {/* primary color */}
                                        <div>
                                            <Label>Primary Color</Label>
                                            <ColorPicker
                                                value={values.preferences.primaryColor}
                                                setColor={(event: ColorResult) => {
                                                    setFieldValue('preferences.primaryColor', event.hex.toUpperCase());
                                                }}
                                            />
                                        </div>
                                        {/* cdn enabled */}
                                        <div className="flex items-center gap-2 mt-4">
                                            <Label
                                                htmlFor="preferences.hasCdnEnabled"
                                                className="font-medium text-grayscale-900 mb-0"
                                            >
                                                Enable CDN Delivery
                                            </Label>

                                            <FormikSwitch color="success" name="preferences.hasCdnEnabled" />
                                        </div>
                                    </div>
                                </div>
                                {/* mapCenter */}
                                <div className="mt-8">
                                    <h5>Map</h5>
                                    <div className="grid grid-cols-2 gap-4 mt-4">
                                        <div>
                                            <Label>Latitude</Label>
                                            <FormikInput type="number" name="preferences.mapCenter.latitude" />
                                        </div>
                                        <div>
                                            <Label>Longitude</Label>
                                            <FormikInput type="number" name="preferences.mapCenter.longitude" />
                                        </div>
                                        {/* mapZoom */}
                                        <div>
                                            <Label>Map Zoom</Label>
                                            <FormikInput type="number" name="preferences.mapZoom" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Button
                                type="submit"
                                size={'lg'}
                                className="mt-10"
                                disabled={updateClientForm.isSubmitting}
                            >
                                {updateClientForm.isSubmitting ? (
                                    <CircularProgress color={'inherit'} size={18} />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </fieldset>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ClientEditFormBasic;
