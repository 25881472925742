import { IConsoleUser, ISupportTicketReply, IUser } from '@api/models';
import { Divider } from '@library/divider';
import { TRootState } from '@store/index';
import groupBy from 'lodash.groupby';
import moment from 'moment';
import { FC, useLayoutEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import ConsoleUserReply from '../ConsoleUserReply/ConsoleUserReply';
import UserReply from '../UserReply/UserReply';

interface TicketRepliesCardProps {
    replies: ISupportTicketReply[];
}
const TicketRepliesCard: FC<TicketRepliesCardProps> = (props) => {
    const { replies } = props;

    const { info: user } = useSelector((state: TRootState) => state.profile);

    const chatBoxRef = useRef(null);

    useLayoutEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [replies]);

    const groupedReplies = useMemo(() => {
        const repliesMap = groupBy(replies, (item) => {
            const date = moment(item.createdAt);

            return date.calendar(null, {
                lastDay: '[Yesterday · ' + date.format('DD MMM') + ']',
                sameDay: '[Today · ' + date.format('DD MMM') + ']',
                lastWeek: () => {
                    return '[' + date.format('DD MMM, YYYY') + ']';
                },
                sameElse: () => {
                    return '[' + date.format('DD MMM, YYYY') + ']';
                },
            });
        });

        return Object.keys(repliesMap).map((key) => ({ date: key, replies: repliesMap[key] }));
    }, [replies, user]);

    return (
        <div ref={chatBoxRef} className="p-6 max-h-[68vh] overflow-y-auto toolbar-scrollbar">
            <div className="space-y-3">
                {groupedReplies.map((row) => (
                    <div key={row.date} className="space-y-3">
                        <div className="relative flex items-center justify-center py-2">
                            <Divider className="absolute w-[200px]" />
                            <div className="relative inline-flex justify-center items-center text-center bg-grayscale-100 text-grayscale-500 text-xs h-6 mx-auto px-[10px] rounded-full">
                                {row.date}
                            </div>
                        </div>
                        {row.replies.map((reply) => (
                            <div key={reply._id}>
                                {reply.userType === 'ConsoleUser' ? (
                                    <ConsoleUserReply
                                        user={reply.user as IConsoleUser}
                                        message={reply.message}
                                        createdAt={reply.createdAt}
                                    />
                                ) : (
                                    <UserReply
                                        user={reply.user as IUser}
                                        message={reply.message}
                                        createdAt={reply.createdAt}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {groupedReplies.length === 0 && (
                <p className="text-grayscale-400 text-sm font-medium text-center py-6">- No Conversation yet -</p>
            )}
        </div>
    );
};

export default TicketRepliesCard;
