import { FileTypeEnum } from '@api/enum/file-type.enum';
import { getSiteGalleryItemById } from '@api/services/site-gallery.service';
import Img from 'modules/shared/components/Img/Img';
import VideoPlayer from 'modules/shared/components/VideoPlayer/VideoPlayer';
import useBoundingClientRect from 'modules/shared/hooks/use-bounding-client-rect';
import useNavName from 'modules/shared/hooks/useNavName';
import { Breadcrumb } from '@library/breadcrumb';
import { CircularProgress } from '@library/loaders/components';
import { Skelton } from '@library/skeltons';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

const SiteGalleryDetails = () => {
    const { clientId, projectId, siteItem } = useParams();
    const { clientName, projectName } = useNavName(clientId, projectId);

    const [headerRefSetter, headerBoundingClientRect] = useBoundingClientRect();

    const navigate = useNavigate();

    const { data, isLoading } = useQuery([], async () => getSiteGalleryItemById(clientId, projectId, siteItem));

    // const navArray = [
    //     { label: 'Clients', onClick: () => navigate('/client') },
    //     { label: clientName, onClick: () => navigate(`/client?query=${clientId}`) },
    //     {
    //         label: 'Projects',
    //         onClick: () => navigate(`/client/${clientId}/project`),
    //     },
    //     {
    //         label: projectName,
    //         onClick: () => navigate(`/client/${clientId}/project?query=${projectId}`),
    //     },
    //     {
    //         label: 'Site Gallery',
    //         onClick: () => navigate(`/client/${clientId}/project/${projectId}/site-gallery`),
    //     },
    //     { label: siteItem, onClick: () => {} },
    // ];

    return (
        <div className="h-screen">
            {/* <Navbar /> */}
            {/* <div className="pt-[var(--navbar-height)]"> */}
            <div className="py-4" ref={headerRefSetter}>
                <div className="px-4">
                    {/* <Breadcrumb separator="/" items={navArray} /> */}
                    <div className="mt-6">
                        <h3>Site Gallery</h3>
                    </div>
                </div>
            </div>

            {isLoading ? (
                <div
                    className="flex  justify-center items-centers relative "
                    style={{
                        height: window.innerHeight - headerBoundingClientRect.height,
                    }}
                >
                    <CircularProgress className="absolute  w-full h-full " />
                    <Skelton variant="rectangular" className="w-full h-full" animation="wave" />
                </div>
            ) : (
                <div
                    className="bg-black"
                    style={{
                        height: window.innerHeight - headerBoundingClientRect.height,
                    }}
                >
                    <div>
                        {data.type === FileTypeEnum.IMAGE && (
                            <Img
                                src={data.url}
                                className="mx-auto"
                                style={{
                                    maxHeight: window.innerHeight - headerBoundingClientRect.height,
                                }}
                            />
                        )}
                        {data.type === FileTypeEnum.VIDEO && (
                            <VideoPlayer
                                controls
                                src={data.url}
                                keyboard={true}
                                className="aspect-video"
                                style={{
                                    maxHeight: window.innerHeight - headerBoundingClientRect.height,
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
            {/* </div> */}
        </div>
    );
};

export default SiteGalleryDetails;
