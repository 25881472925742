import { PublicRequestStatusEnum } from '@api/enum';
import { PermissionEnum } from '@api/enum/permission.enum';
import { IPublicRequest } from '@api/models/public-request.model';
import { givePublicApiAccess } from '@api/services/public-request.service';
import { Button, Chip, Drawer } from '@hyperflake/react-ui-library';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import { CopyIcon } from 'modules/shared/icons';
import { toast } from 'react-toastify';

interface PublicApiRequestDetailDrawerProps {
    open: boolean;
    onClose: () => void;
    publicApiData: IPublicRequest;
    onUpdate: (updatedPublicApiData: IPublicRequest) => void;
}

const PublicApiRequestDetailDrawer = (props: PublicApiRequestDetailDrawerProps) => {
    const queryClient = useQueryClient();

    const { open, onClose, publicApiData, onUpdate } = props;
    const { hasPermission } = useAuthPermission();
    const handleCopyApiKey = () => {
        navigator.clipboard.writeText(publicApiData?.accessKeys?.X_API_KEY);

        toast.success('Copied Successfully.');
    };
    const handleCopyApiSecret = () => {
        navigator.clipboard.writeText(publicApiData?.accessKeys?.X_API_SECRET);

        toast.success('Copied Successfully.');
    };
    const activatePublicApiMutation = useMutation(() => givePublicApiAccess(publicApiData._id));

    const handleGiveAccess = async () => {
        try {
            const updatedPublicApi = await activatePublicApiMutation.mutateAsync();
            queryClient.prefetchQuery(['requestApi-counter']);
            onUpdate({ ...publicApiData, ...updatedPublicApi });
            toast.success('Access Granted successfully.');
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured, please try again');
        }
    };

    return (
        <Drawer open={open} onClose={onClose} title="View Public API Request">
            <div className="mt-8 grid grid-cols-2 gap-4">
                <div className="col-span-12 sm:col-span-6 lg:col-span-1">
                    <div className="text-grayscale-500 text-sm mb-[6px]">Name:</div>
                    <h6>{publicApiData?.user.email}</h6>
                </div>
                <div className="col-span-12 sm:col-span-6 lg:col-span-1">
                    <div className="text-grayscale-500 text-sm mb-[6px]">Client:</div>
                    <h6>{publicApiData?.client.name}</h6>
                </div>
                <div className="col-span-12 sm:col-span-6 lg:col-span-1">
                    <div className="text-grayscale-500 text-sm mb-[6px]">Status:</div>
                    {publicApiData?.status === PublicRequestStatusEnum.COMPELETED ? (
                        <h6 className="text-success">{publicApiData?.status}</h6>
                    ) : (
                        <h6 className="text-warning">{publicApiData?.status}</h6>
                    )}
                </div>

                {/* <div className="font-bold">
                    Status:{' '}
                    <span className="font-normal">
                        {
                            <Chip
                                label={publicApiData?.status}
                                color={
                                    publicApiData?.status === PublicRequestStatusEnum.COMPELETED ? 'success' : 'danger'
                                }
                                variant="shaded"
                            />
                        }
                    </span>
                </div> */}
            </div>
            <div className="mt-8">
                {publicApiData?.status === PublicRequestStatusEnum.COMPELETED ? (
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <div className="text-grayscale-500 text-sm">Public Api Key:</div>
                            <h6 className="mt-4">
                                {publicApiData?.accessKeys?.X_API_KEY}
                                <span>
                                    <button onClick={handleCopyApiKey} className="text-primary px-2">
                                        <CopyIcon />
                                    </button>
                                </span>
                            </h6>
                        </div>
                        <div>
                            <div className="text-grayscale-500 text-sm">Public Api Secret:</div>
                            <h6 className="mt-4">
                                {publicApiData?.accessKeys?.X_API_SECRET}
                                <span>
                                    <button onClick={handleCopyApiSecret} className="text-primary px-2">
                                        <CopyIcon />
                                    </button>
                                </span>
                            </h6>
                        </div>
                    </div>
                ) : (
                    <div>
                        <Button
                            onClick={handleGiveAccess}
                            disabled={!hasPermission(PermissionEnum.PUBLIC_API_REQUEST_UPDATE)}
                        >
                            Give Access
                        </Button>
                    </div>
                )}
            </div>
        </Drawer>
    );
};

export default PublicApiRequestDetailDrawer;
