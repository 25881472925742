import { getProjectsByClientId, updateProjectAccess } from '@api/services/project.service';
import useDataQuery from 'modules/shared/hooks/use-data-query';
import useUserData from './useUserData';
import { useMutation } from '@tanstack/react-query';

const useProjectAccessForm = (clientId: string, userId: string) => {
    const projectQuery = useDataQuery(['projects', clientId, userId], () => getProjectsByClientId(clientId));
    const userQuery = useUserData(clientId, userId);

    const updateProjectAccessMutation = useMutation((payload: any) => updateProjectAccess(clientId, userId, payload));

    const submit = async (payload: any) => {
        const data = await updateProjectAccessMutation.mutateAsync(payload);
        return data;
    };

    return {
        data: projectQuery.data,
        isLoading: projectQuery.isLoading,
        user: userQuery.data,
        userLoading: userQuery.isLoading,
        submit,
        isSubmitting: updateProjectAccessMutation.isLoading,
    };
};

export default useProjectAccessForm;
