import { VariantProps, cva } from 'class-variance-authority';
import { ChangeEvent, DetailedHTMLProps, FC, ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import styles from './Checkbox.module.scss';

const checkboxVariants = cva(
    [styles['checkbox'], 'bg-transparent', 'border border-grayscale-300', 'align-top cursor-pointer'],
    {
        variants: {
            size: {
                sm: ['w-4 h-4 rounded'],
                normal: ['w-5 h-5 rounded'],
                lg: ['w-6 h-6 rounded-[0.3rem]'],
            },
            color: {
                primary: [
                    'checked:bg-primary checked:border-primary checked:outline checked:outline-primary/10 checked:focus:border-primary checked:focus:outline-primary/10',
                ],
                secondary: [
                    'checked:bg-secondary checked:border-secondary checked:outline checked:outline-secondary/10 checked:focus:border-secondary checked:focus:outline-secondary/10',
                ],
                success: [
                    'checked:bg-success checked:border-success checked:outline checked:outline-success/10 checked:focus:border-success checked:focus:outline-success/10',
                ],
                danger: [
                    'checked:bg-danger checked:border-danger checked:outline checked:outline-danger/10 checked:focus:border-danger checked:focus:outline-danger/10',
                ],
                warning: [
                    'checked:bg-warning checked:border-warning checked:outline checked:outline-warning/10 checked:focus:border-warning checked:focus:outline-warning/10',
                ],
            },
            disabled: {
                true: ['bg-grayscale-100 opacity-60'],
                false: [],
            },
        },
        defaultVariants: {
            size: 'normal',
            disabled: false,
            color: 'primary',
        },
    }
);

interface CheckboxProps
    extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size' | 'color'>,
        VariantProps<typeof checkboxVariants> {
    ref?: ForwardedRef<HTMLInputElement>;
    onCheckedChange?: (checked: boolean) => void;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    (props: CheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
        const { size, color, disabled, className, onCheckedChange, onChange, ...rest } = props;

        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            onChange?.(e);

            onCheckedChange?.(e.target.checked);
        };

        return (
            <input
                {...rest}
                ref={ref}
                type="checkbox"
                disabled={disabled}
                onChange={handleChange}
                className={twMerge(checkboxVariants({ size, color, disabled, className }))}
            />
        );
    }
);

export default Checkbox;
