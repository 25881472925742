import { VariantProps, cva } from 'class-variance-authority';
import { DetailedHTMLProps, FC, ForwardedRef, HTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import styles from './Skelton.module.scss';

const skeltonVariants = cva([styles['skelton-base'], 'block max-w-full'], {
    variants: {
        variant: {
            text: [styles['skelton-text']],
            rectangular: ['w-full'],
            circular: ['rounded-full'],
            rounded: ['w-full rounded-lg'],
        },
        animation: {
            pulse: [styles['skelton-pulse-animate']],
            wave: [styles['skelton-wave-animate']],
        },
    },
    defaultVariants: {
        animation: 'pulse',
        variant: 'text',
    },
});

interface SkeltonProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>,
        VariantProps<typeof skeltonVariants> {
    width?: string | number;
    height?: string | number;
    ref?: ForwardedRef<HTMLSpanElement>;
}

const Skelton = forwardRef<HTMLSpanElement, SkeltonProps>((props: SkeltonProps, ref: ForwardedRef<HTMLSpanElement>) => {
    const { variant, animation, width, height, className, style, ...rest } = props;

    return (
        <span
            {...rest}
            ref={ref}
            className={twMerge(skeltonVariants({ variant, animation, className }))}
            style={{ width, height, ...style }}
        />
    );
});

export default Skelton;
