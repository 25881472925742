import { ICameraImage } from '@api/models';
import useLoading from '@hooks/use-loading';
import { Backdrop } from '@hyperflake/react-ui-library';
import { formatDatetime } from '@utils/date-utils';
import { AnimatePresence, motion } from 'framer-motion';
import OverlayLoader from 'modules/reseller-cameras/components/ui/OverlayLoader';
import useAuthSelector from 'modules/shared/hooks/use-auth-selector';
import useBoxDimensions from 'modules/shared/hooks/use-box-dimensions';
import { CrossIcon } from 'modules/shared/icons';
import { useMemo } from 'react';
import ScrollLock from 'react-scrolllock';

const modalAnimation = {
    variants: {
        hidden: {
            scale: 0.15,
            opacity: 0,
        },
        visible: {
            scale: 1,
            opacity: 1,
        },
    },
    transition: { duration: 0.35 },
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
};

/************************************************** components **************************************************/

interface CameraSneakPeekModalProps {
    show: boolean;
    onClose: () => void;
    cameraImage: ICameraImage;
}

const CameraSneakPeekModal = (props: CameraSneakPeekModalProps) => {
    const { show, onClose, cameraImage } = props;

    // const { user } = useAuthSelector();

    const { aspectRatio, headerHeight, containerWidth, containerHeight } = useMemo(() => {
        const headerHeight = 60;

        return {
            aspectRatio: cameraImage?.resolution.width / cameraImage?.resolution?.height || 1.49,
            headerHeight: 60,
            containerWidth: window.innerWidth - 96,
            containerHeight: window.innerHeight - 96 - headerHeight,
        };
    }, [cameraImage]);

    const { refs, dimensions } = useBoxDimensions({ aspectRatio, containerWidth, containerHeight });

    const [imgLoading, setImgLoading] = useLoading(cameraImage);

    // const imageUrl = getPreferedImageUrl(user, camera?.latestImage);

    return (
        <AnimatePresence initial={false} mode="wait">
            {show && (
                <>
                    <Backdrop ref={refs.setReference} show={show} onClick={onClose} className="z-[30]" />

                    <ScrollLock>
                        <div
                            className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-[31] mt-5"
                            style={{ height: dimensions.height + headerHeight, width: dimensions.width }}
                        >
                            <motion.div {...modalAnimation} onClick={(e) => e.stopPropagation()}>
                                <div
                                    className={`flex items-center bg-card-color px-6 rounded-t-2xl`}
                                    style={{ height: headerHeight }}
                                >
                                    <div className="flex items-center w-full gap-6">
                                        <h5 className="font-bold text-grayscale-900">
                                            {cameraImage?.name} ·{' '}
                                            {cameraImage?.datetime
                                                ? formatDatetime(cameraImage?.datetime, 'h:mm a [·] DD MMM, YYYY')
                                                : '-'}
                                        </h5>

                                        <button
                                            className="ml-auto grid place-items-center w-7 h-7 rounded-full bg-background-color hover-transition hover:bg-primary/10"
                                            onClick={onClose}
                                        >
                                            <CrossIcon className="text-grayscale-500" width="10" height="10" />
                                        </button>
                                    </div>
                                </div>

                                <div className="relative">
                                    <img
                                        src={cameraImage?.urlPreview}
                                        className="rounded-b-2xl object-cover object-center"
                                        onLoad={() => setImgLoading(false)}
                                        style={{
                                            height: dimensions.height,
                                            width: dimensions.width,
                                            aspectRatio: aspectRatio,
                                        }}
                                        alt=""
                                    />

                                    <OverlayLoader show={imgLoading} className="rounded-b-2xl" />

                                    {!cameraImage?.urlPreview && (
                                        <div className="absolute top-0 left-0 w-full h-full bg-grayscale-100 flex items-center justify-center rounded-b-2xl z-[3]">
                                            <svg
                                                width={'20%'}
                                                viewBox="0 0 114 70"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M113.959 2.548v64.838a1.632 1.632 0 01-1.631 1.632H2.261A1.63 1.63 0 01.63 67.386V2.548A1.632 1.632 0 012.26.916h110.067a1.63 1.63 0 011.632 1.631v.001z"
                                                    fill="#C8DDFA"
                                                />
                                                <path
                                                    d="M28.372 39.467c7.909 0 14.32-6.412 14.32-14.32 0-7.91-6.411-14.322-14.32-14.322-7.91 0-14.321 6.412-14.321 14.321 0 7.91 6.411 14.321 14.32 14.321z"
                                                    fill="#000"
                                                />
                                                <path
                                                    d="M73.282 69.278H3.028a1.435 1.435 0 01-.262-.02l33.227-57.555a2.327 2.327 0 014.05 0l22.299 38.624 1.068 1.848 9.873 17.103z"
                                                    fill="#8EBBFE"
                                                />
                                                <path
                                                    opacity={0.2}
                                                    d="M73.28 69.278H49.292l11.775-17.104.847-1.231.424-.617 1.068 1.849 9.872 17.103z"
                                                    fill="#000"
                                                />
                                                <path
                                                    d="M112.397 69.278H51.633l11.775-17.104.847-1.232L79.6 28.653c1.006-1.46 3.426-1.552 4.608-.275.08.087.154.179.221.275l27.969 40.625z"
                                                    fill="#8EBBFE"
                                                />
                                                <path
                                                    d="M31.104 46.699c.7 0 1.268-.564 1.268-1.26 0-.695-.568-1.26-1.268-1.26-.7 0-1.268.565-1.268 1.26 0 .696.568 1.26 1.268 1.26z"
                                                    fill="#000"
                                                />
                                                <path
                                                    d="M33.182 45.3a2.075 2.075 0 00-2.075-1.93 2.075 2.075 0 00-2.076 1.93h4.151zM44.327 46.965c.7 0 1.268-.564 1.268-1.26 0-.695-.568-1.259-1.268-1.259-.7 0-1.268.564-1.268 1.26 0 .695.567 1.26 1.268 1.26z"
                                                    fill="#000"
                                                />
                                                <path
                                                    d="M46.401 45.568a2.075 2.075 0 00-2.076-1.93 2.075 2.075 0 00-2.075 1.93h4.151z"
                                                    fill="#000"
                                                />
                                                <path
                                                    d="M34.621 52.995s4.262-1.6 7.389 1.721M42.615 54.39s-.753-.352-.744 1.151M42.613 40.149s2.087-.721 3.583 1.01M29 41.035s1.509-1.602 3.644-.762"
                                                    stroke="#000"
                                                    strokeWidth={1.17275}
                                                    strokeMiterlimit={10}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M75.104 53.699c.7 0 1.268-.564 1.268-1.26 0-.695-.568-1.26-1.268-1.26-.7 0-1.268.565-1.268 1.26 0 .696.568 1.26 1.268 1.26z"
                                                    fill="#000"
                                                />
                                                <path
                                                    d="M77.182 52.3a2.075 2.075 0 00-2.075-1.93 2.075 2.075 0 00-2.076 1.93h4.151zM88.327 53.965c.7 0 1.268-.564 1.268-1.26 0-.695-.568-1.259-1.268-1.259-.7 0-1.268.564-1.268 1.26 0 .695.567 1.26 1.268 1.26z"
                                                    fill="#000"
                                                />
                                                <path
                                                    d="M90.401 52.568a2.075 2.075 0 00-2.075-1.93 2.075 2.075 0 00-2.076 1.93h4.151z"
                                                    fill="#000"
                                                />
                                                <path
                                                    d="M78.621 59.995s4.262-1.6 7.389 1.721M86.615 61.39s-.753-.352-.744 1.151M86.613 47.149s2.087-.721 3.583 1.01M73 48.035s1.509-1.602 3.644-.762"
                                                    stroke="#000"
                                                    strokeWidth={1.17275}
                                                    strokeMiterlimit={10}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    )}
                                </div>
                            </motion.div>
                        </div>
                    </ScrollLock>
                </>
            )}
        </AnimatePresence>
    );
};

export default CameraSneakPeekModal;
