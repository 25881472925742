const Icon = (props: any) => {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M19 13v4a2 2 0 01-2 2H3a2 2 0 01-2-2v-4M5 8l5 5 5-5M10 13V1"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
