import moment from 'moment';
import store from '@store/index';
import { useMemo, useState } from 'react';
// import { Table } from '@library/tables';
import { CalendarIcon } from '@icon/index';
import { profileAction } from '@store/profile';
import { Breadcrumb } from '@library/breadcrumb';
import { DatePicker } from '@library/datepicker';
import { useQuery } from '@tanstack/react-query';
import { ConsoleUserRoleEnum, UserStatusEnum } from '@api/enum';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { CountryDialCodeList } from 'modules/shared/helpers/shared.constants';
import { getAllUserSigninLogs } from '@api/services/sign-in.service';
import TableDataLoading from 'modules/shared/components/TableDataLoading/TableDataLoading';
import EmptyTableTemplate from 'modules/shared/components/EmptyTableTemplate/EmptyTableTemplate';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { Table } from '@hyperflake/react-ui-library';
import { PermissionEnum } from '@api/enum/permission.enum';

const SigninLogs = () => {
    const navigate = useNavigate();

    const [logDate, setLogDate] = useState(moment().toDate());

    const { data, isLoading } = useQuery(['users', 'sing-in', 'logs', logDate], () =>
        getAllUserSigninLogs(moment(logDate).format('YYYYMMDD'))
    );

    const getCountry = (countryCode: string = '') => {
        let selectedCountry = CountryDialCodeList.find(
            (country) => countryCode.toLowerCase() === country?.countryCode?.toLowerCase()
        );
        return selectedCountry?.countryName || '';
    };

    const logs = useMemo(() => {
        if (!data) return [];

        return data;
    }, [data]);

    return (
        <PageTemplate>
            {/* <Navbar /> */}
            {/* <div className="pt-[var(--navbar-height)]"> */}

            {/* <Breadcrumb separator="/" items={[]} /> */}
            {/* <div className="mt-6">
                    <h3>Sign In Logs</h3>
                </div> */}
            <PageHeading>Sign In Logs</PageHeading>
            <div className="flex justify-end">
                <div className="  font-normal text-lg relative flex items-center">
                    <DatePicker
                        className="w-full  rounded-lg px-4 py-2 pl-12 font-medium text-sm bg-transparent border border-grayscale-300 
                            placeholder:text-grayscale-400 focus:border-primary focus:outline focus:outline-2 focus:outline-primary/10"
                        value={logDate}
                        onChange={(e) => setLogDate(e)}
                    />
                    <CalendarIcon className="absolute left-4 pointer-events-none " />
                </div>
            </div>

            <div className="mt-2">
                <div className="flex justify-between py-3">
                    <div>
                        {/* <Input
                                placeholder="Search Client"
                                className="w-96 bg-white"
                                onChange={(e) => handleSearch(e.target.value)}
                                value={query}
                            /> */}
                    </div>

                    {/* <Button onClick={() => setAddDrawer({ open: true, anchor: 'right' })}>Add New User</Button> */}
                </div>

                <Table
                    className="mt-6"
                    borderStyle="divider"
                    hover
                    isLoading={isLoading}
                    data={logs}
                    columns={[
                        {
                            header: 'ID',
                        },
                        {
                            header: 'Date & Time',
                        },
                        {
                            header: 'Name',
                        },
                        {
                            header: 'Username',
                        },
                        {
                            header: 'Client',
                        },
                        {
                            header: 'Ip Address',
                        },
                        {
                            header: 'Country',
                        },
                    ]}
                    renderRow={(row) => (
                        <Table.Row key={row._id} onClick={() => {}}>
                            <Table.Cell>{row._id}</Table.Cell>
                            <Table.Cell>
                                <span>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('HH:mm A - DD MMM YYYY')}</span>
                            </Table.Cell>
                            <Table.Cell>{row?.user?.name} </Table.Cell>
                            <Table.Cell>
                                <span
                                    className="text-primary cursor-pointer"
                                    onClick={() => {
                                        navigate({
                                            pathname: `/clients/${row.user.client._id}/users`,
                                            search: createSearchParams({
                                                showDialog: 'true',
                                                userid: row.user._id,
                                            }).toString(),
                                        });
                                    }}
                                >
                                    {row?.user.username}
                                </span>
                            </Table.Cell>
                            <Table.Cell>
                                <span
                                    className="text-primary cursor-pointer"
                                    onClick={() => navigate(`/clients/${row.user.client._id}/users`)}
                                >
                                    {row.user.client.name}
                                </span>
                            </Table.Cell>
                            <Table.Cell>{row?.userIp}</Table.Cell>
                            <Table.Cell>
                                <div className="flex  items-center gap-2">
                                    <img
                                        className="rounded-full w-6 h-6"
                                        src={`/flags/${row?.country?.toLowerCase() || ''}.svg`}
                                    />
                                    <div>{getCountry(row.country)}</div>
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    )}
                />
            </div>
        </PageTemplate>
    );
};

export default SigninLogs;
