import { Backdrop } from '@library/backdrop';
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { FC, ForwardedRef, ReactNode, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';
import { drawerAnimation } from '../helpers/drawer.animations';
import { VariantProps, cva } from 'class-variance-authority';

const drawerVariants = cva(['fixed z-[81]', 'overflow-x-hidden overflow-y-auto', 'p-6 bg-floating-color'], {
    variants: {
        anchor: {
            right: 'right-0 top-0 bottom-0 w-full md:w-[var(--drawer-width)]',
            left: 'left-0 top-0 bottom-0 w-full md:w-[var(--drawer-width)]',
            top: 'left-0 top-0 right-0 h-[var(--drawer-width)]',
            bottom: 'left-0 bottom-0 right-0 h-[var(--drawer-width)]',
        },
    },
    defaultVariants: {
        anchor: 'right',
    },
});

export interface DrawerProps extends HTMLMotionProps<'div'>, VariantProps<typeof drawerVariants> {
    open: boolean;
    onClose: () => void;
    children: ReactNode;
    crossBtnClassName?: string;
    ref?: ForwardedRef<HTMLDivElement>;
}
const Drawer = forwardRef<HTMLDivElement, DrawerProps>((props: DrawerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { open, onClose, children, anchor, className, crossBtnClassName, ...rest } = props;

    return createPortal(
        <AnimatePresence initial={false} mode="wait">
            {open && (
                <>
                    <Backdrop open={open} onClick={onClose} lockScroll className="z-[80]" />

                    <motion.div
                        {...rest}
                        {...drawerAnimation[anchor]}
                        ref={ref}
                        className={twMerge(drawerVariants({ anchor, className }))}
                    >
                        <button
                            type="button"
                            className={twMerge(
                                'absolute top-[26px] right-6 z-[82] text-grayscale-500',
                                crossBtnClassName
                            )}
                            onClick={onClose}
                        >
                            <svg
                                width="1.2em"
                                height="1.2em"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z"
                                    fill="currentColor"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>

                        {children}
                    </motion.div>
                </>
            )}
        </AnimatePresence>,
        document.body
    );
});

Drawer.defaultProps = {
    anchor: 'right',
};

export default Drawer;
