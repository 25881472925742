import { IUser } from '@api/models';
import { getUsersByClientId } from '@api/services/user.service';
import { UUID } from 'crypto';
import useDataQuery from 'modules/shared/hooks/use-data-query';
import { produce } from 'immer';

const useUserListData = (clientId: string) => {
    const usersListQuery = useDataQuery(['usersByClient', clientId], () => getUsersByClientId(clientId));
    const addUser = async (user: IUser) => {
        usersListQuery.setData((prevList) => {
            return produce(prevList, (draftList) => {
                draftList.unshift(user);

                return draftList;
            });
        });
    };

    const updateUser = async (userId: string, updates: IUser) => {
        usersListQuery.setData((prevList) => {
            return produce(prevList, (draftList) => {
                const index = draftList?.findIndex((row) => row._id === userId);

                draftList[index] = updates;

                return draftList;
            });
        });
    };

    return {
        data: usersListQuery.data,
        isLoading: usersListQuery.isLoading,
        addUser,
        updateUser,
    };
};
export default useUserListData;
