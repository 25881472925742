// export const UserRoleEnum = Object.freeze({
//     ADMIN:'ADMIN',
//     EDITOR:'EDITOR',
//     VIEWER:'VIEWER'
// });

export enum UserRoleEnum {
    ADMIN='ADMIN',
    EDITOR='EDITOR',
    VIEWER='VIEWER'
}

