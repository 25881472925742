const NoImagesIllustration = (props: any) => {
    return (
        <svg width={341} height={187} viewBox="0 0 341 187" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M174.2 147c20.339-.018 39.955 7.605 55.022 21.382a.384.384 0 01.026.538.375.375 0 01-.533.027c-14.928-13.65-34.363-21.203-54.515-21.185-28.284 0-54.095 14.481-69.042 38.735a.39.39 0 01-.235.171.364.364 0 01-.286-.048.385.385 0 01-.169-.237.39.39 0 01.048-.288 82.223 82.223 0 0129.807-28.673A81.4 81.4 0 01174.2 147z"
                fill="#000"
            />
            <path
                d="M108.795 185.833l-.193-.738-3.601.959-.686-3.693-.744.142.837 4.497 4.387-1.167zM225.036 169.34l-.02-.763 3.725-.095-.367-3.739.753-.074.445 4.554-4.536.117z"
                fill="#000"
            />
            <path
                d="M123.618 95.042v40.967a1.034 1.034 0 01-.636.953 1.025 1.025 0 01-.394.078H53.043a1.03 1.03 0 01-1.031-1.031V95.042a1.03 1.03 0 011.03-1.031h69.545a1.038 1.038 0 01.729.302 1.03 1.03 0 01.302.728v.001z"
                fill="#C8DDFA"
            />
            <path d="M69.54 118.369a9.05 9.05 0 100-18.098 9.05 9.05 0 000 18.098z" fill="#000" />
            <path
                d="M97.917 137.204h-44.39a.839.839 0 01-.166-.013l20.995-36.365a1.473 1.473 0 012.02-.546c.224.131.41.32.538.546l14.09 24.404.675 1.168 6.238 10.806z"
                fill="#8EBBFE"
            />
            <path
                opacity={0.2}
                d="M97.917 137.205H82.762l7.44-10.807.535-.778.268-.39.675 1.168 6.237 10.807z"
                fill="#000"
            />
            <path
                d="M122.632 137.205H84.238l7.44-10.807.536-.778 9.694-14.084c.636-.923 2.165-.98 2.912-.174.05.055.097.113.14.174l17.672 25.669z"
                fill="#8EBBFE"
            />
            <path
                d="M127.531 92.455a3.31 3.31 0 00-3.104-2.183h-73.13A3.296 3.296 0 0048 93.57v57.805a3.306 3.306 0 003.298 3.298h73.129a3.3 3.3 0 003.298-3.298V93.57c0-.38-.066-.758-.194-1.115zm-.194 58.92a2.913 2.913 0 01-2.91 2.91h-73.13a2.915 2.915 0 01-2.91-2.91V93.57a2.913 2.913 0 012.91-2.91h73.13a2.912 2.912 0 012.91 2.91v57.805z"
                fill="#000"
            />
            <path
                d="M287.959 42.548v64.838a1.63 1.63 0 01-1.631 1.632H176.261a1.634 1.634 0 01-1.632-1.632V42.548a1.632 1.632 0 011.631-1.632h110.067a1.63 1.63 0 011.632 1.631v.001z"
                fill="#C8DDFA"
            />
            <path
                d="M202.372 79.467c7.909 0 14.321-6.412 14.321-14.32 0-7.91-6.412-14.322-14.321-14.322s-14.321 6.412-14.321 14.321c0 7.91 6.412 14.321 14.321 14.321z"
                fill="#000"
            />
            <path
                d="M247.282 109.278h-70.254a1.433 1.433 0 01-.262-.021l33.227-57.554a2.32 2.32 0 012.025-1.18 2.33 2.33 0 012.024 1.18l22.3 38.624 1.068 1.848 9.872 17.103z"
                fill="#8EBBFE"
            />
            <path
                d="M75.063 130c.696 0 1.26-.56 1.26-1.251 0-.692-.564-1.252-1.26-1.252-.696 0-1.26.56-1.26 1.252 0 .691.564 1.251 1.26 1.251z"
                fill="#000"
            />
            <path
                d="M70.361 122.459c.041.612.552 1.096 1.18 1.096.626 0 1.137-.484 1.178-1.096M78.89 122.459c.041.612.553 1.096 1.18 1.096.626 0 1.137-.484 1.178-1.096M69 120.76s1.49-1.358 3.38-.443M82.392 120.76s-1.49-1.358-3.38-.443"
                stroke="#000"
                strokeWidth={0.844758}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M104.243 132a.88.88 0 00.883-.876.88.88 0 00-.883-.876.879.879 0 00-.882.876c0 .484.395.876.882.876z"
                fill="#000"
            />
            <path
                d="M100.953 126.722a.826.826 0 00.825.767.824.824 0 00.825-.767M106.924 126.722a.824.824 0 00.825.767.824.824 0 00.825-.767M100 125.532s1.043-.951 2.366-.31M109.374 125.532s-1.043-.951-2.366-.31"
                stroke="#000"
                strokeWidth={0.591331}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                opacity={0.2}
                d="M247.281 109.278h-23.986l11.775-17.104.847-1.232.424-.616 1.068 1.849 9.872 17.103z"
                fill="#000"
            />
            <path
                d="M286.399 109.278h-60.764l11.775-17.104.847-1.232 15.344-22.289c1.006-1.46 3.426-1.552 4.608-.275.08.087.154.179.221.275l27.969 40.625z"
                fill="#8EBBFE"
            />
            <path
                d="M294.15 38.454A5.23 5.23 0 00289.238 35h-115.74a5.214 5.214 0 00-4.822 3.221 5.226 5.226 0 00-.397 1.998v91.487a5.228 5.228 0 005.219 5.219h115.74a5.223 5.223 0 005.219-5.219V40.219a5.158 5.158 0 00-.307-1.765zm-.307 93.252a4.611 4.611 0 01-4.605 4.605h-115.74a4.611 4.611 0 01-4.605-4.605V40.219a4.61 4.61 0 014.605-4.605h115.74a4.61 4.61 0 014.605 4.605v91.487z"
                fill="#000"
            />
            <path
                d="M205.106 86.699c.7 0 1.268-.564 1.268-1.26 0-.695-.568-1.26-1.268-1.26-.7 0-1.268.565-1.268 1.26 0 .696.568 1.26 1.268 1.26z"
                fill="#000"
            />
            <path
                d="M207.18 85.3a2.074 2.074 0 00-2.075-1.93 2.075 2.075 0 00-2.076 1.93h4.151zM218.327 86.965c.7 0 1.268-.564 1.268-1.26 0-.695-.568-1.259-1.268-1.259-.701 0-1.268.564-1.268 1.26 0 .695.567 1.26 1.268 1.26z"
                fill="#000"
            />
            <path d="M220.401 85.568a2.075 2.075 0 00-2.075-1.93 2.075 2.075 0 00-2.076 1.93h4.151z" fill="#000" />
            <path
                d="M208.621 92.995s4.262-1.6 7.389 1.721M216.613 94.39s-.753-.352-.744 1.151M216.613 80.149s2.087-.721 3.583 1.01M203 81.035s1.509-1.602 3.644-.762"
                stroke="#000"
                strokeWidth={1.17275}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M249.106 93.699c.7 0 1.268-.564 1.268-1.26 0-.695-.568-1.26-1.268-1.26-.7 0-1.268.565-1.268 1.26 0 .696.568 1.26 1.268 1.26z"
                fill="#000"
            />
            <path
                d="M251.18 92.3a2.074 2.074 0 00-2.075-1.93 2.075 2.075 0 00-2.076 1.93h4.151zM262.327 93.965c.7 0 1.268-.564 1.268-1.26 0-.695-.568-1.259-1.268-1.259-.701 0-1.268.564-1.268 1.26 0 .695.567 1.26 1.268 1.26z"
                fill="#000"
            />
            <path d="M264.401 92.568a2.075 2.075 0 00-2.075-1.93 2.075 2.075 0 00-2.076 1.93h4.151z" fill="#000" />
            <path
                d="M252.621 99.995s4.262-1.6 7.389 1.721M260.613 101.389s-.753-.351-.744 1.152M260.613 87.149s2.087-.721 3.583 1.01M247 88.035s1.509-1.602 3.644-.762"
                stroke="#000"
                strokeWidth={1.17275}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M0 40.956h44.508s-1.56-4.316-8.235-2.986c-4.678-9.572-15.354-12.436-21.586-3.883C3.561 32.627 0 40.957 0 40.957zM268 19.382h72.152s-2.528-6.997-13.349-4.84c-7.584-15.517-24.891-20.16-34.993-6.295C273.773 5.879 268 19.382 268 19.382z"
                fill="#C8DDFA"
            />
            <path
                opacity={0.6}
                d="M119 18.988h55.815s-1.953-5.414-10.326-3.745c-5.861-12.005-19.265-15.58-27.07-4.87C123.466 8.54 119 18.988 119 18.988z"
                fill="#C8DDFA"
            />
        </svg>
    );
};

export default NoImagesIllustration;
