import { ISigninLog } from "@api/types/sign-in.type";
import http from "httpConfig/http";
import { getResellerIdFromRedux } from "modules/shared/helpers/shared.utils";

export const getAllUserSigninLogs = async (date: string) => {
  const { data } = await http.get(
    `${
      import.meta.env.VITE_APP_API_URL
    }/${getResellerIdFromRedux()}/users/logs?date=${date}`
  );
  return data as ISigninLog[];
};
