import { ICamera, IProject, IUser } from '@api/models';
import http from 'httpConfig/http';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';

export const getProjectsByClientId = async (clientId: string) => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/projects`
    );

    return data as IProject[];
};

export const getProjectByIds = async (clientId: string, projectId: string) => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}`
    );
    return data as IProject;
};

export const addProject = async (payload: any, clientId: string) => {
    const { data, status } = await http.post(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/projects`,
        payload
    );
    return { data, status };
};

export const updateProject = async (clientId: string, projectId: string, payload: IProject) => {
    const { data, status } = await http.put(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}`,
        payload
    );
    return { data, status };
};

export const updateProjectAccess = async (clientId: string, userId: string, payload: IProject) => {
    const { data } = await http.put(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/users/${userId}/projects`,
        payload
    );
    return data as IUser;
};

export const getProjectHierarchy = async (clientId: string, projectId: string) => {
    const { data } = await http.get(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/hierarchy`
    );

    return data as Pick<IProject, '_id' | 'name'>[];
};

export const getSubProjectsByProjectId = async (clientId: string, projectId: string) => {
    const { data } = await http.get(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/children`
    );

    return data as IProject[];
};

export const getProjectOnSearch = async (clientId: string, projectId: string, q: any) => {
    const { data } = await http.get(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras/search-project`,
        {
            params: { q },
        }
    );

    return data as IProject[];
};

export const moveProjectOfCamera = async (clientId: string, projectId: string, cameraId: string, payload: string) => {
    const { data } = await http.put(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras/${cameraId}/update-project`,
        { newProjectId: payload }
    );
    return data as ICamera;
};
