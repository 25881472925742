import { Field, FieldAttributes, FieldProps } from 'formik';
import { Input, InputProps } from '@hyperflake/react-ui-library/forms';
import { ForwardedRef, forwardRef, useMemo } from 'react';

interface FormikInputProps extends InputProps {
    fieldProps?: FieldAttributes<InputProps>;
}

const FormikInput = forwardRef((props: FormikInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { name, id, fieldProps, invalid, ...rest } = props;

    const idOrName = useMemo(() => {
        return id || name;
    }, [id, name]);

    return (
        <Field name={name} {...fieldProps}>
            {({ field, meta }: FieldProps) => (
                <Input
                    {...rest}
                    {...field}
                    ref={ref}
                    id={idOrName}
                    name={name}
                    invalid={(meta.touched && Boolean(meta.error)) || invalid}
                />
            )}
        </Field>
    );
});

export default FormikInput;
