import { IProjectAsset } from '@api/models';
import { addProject, getProjectsByClientId } from '@api/services/project.service';
import { useMutation } from '@tanstack/react-query';
import useDataQuery from 'modules/shared/hooks/use-data-query';
import * as Yup from 'yup';
import { IAddProjectForm } from '../helpers/project.types';
import { ProjectStatusEnum } from '@api/enum';

const useProjectAddForm = (clientId: string) => {
    const Schema = Yup.object().shape({
        name: Yup.string().required('This field is required'),
        location: Yup.object().shape({
            name: Yup.string().required('This field is required'),
        }),
        assets: Yup.array()
            .typeError('asset names must be array')
            .of(Yup.string().trim().required('This field is required'))
            .min(1, 'Add atleast one asset'),
        hasCameras: Yup.boolean(),
        parentId: Yup.string(),
        files: Yup.array().of(Yup.mixed()),
    });
    const initialValues = {
        name: '',
        location: {
            name: '',
            latitude: '',
            longitude: '',
        },
        assets: [] as any,
        hasCameras: false,
        parentId: '',
        files: [] as any,
    };

    const projectQuery = useDataQuery(
        ['projects', clientId],
        () => {
            return getProjectsByClientId(clientId);
        },
        {
            enabled: Boolean(clientId),
        }
    );
    const addProjectMutation = useMutation((payload: any) => addProject(payload, clientId));
    const submit = async (values: IAddProjectForm) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('location[name]', values.location.name);
        formData.append('location[latitude]', values.location.latitude);
        formData.append('location[longitude]', values?.location?.longitude);
        values.assets.forEach((val, index) => {
            formData.append(`assets[${index}]`, val);
        });
        formData.append('hasCameras', JSON.stringify(values.hasCameras));
        formData.append('parentId', values.parentId ? values.parentId : JSON.stringify(null));
        formData.append('status', ProjectStatusEnum.ACTIVE);
        values.files.forEach((val) => {
            formData.append(`files`, val);
        });
        const { data } = await addProjectMutation.mutateAsync(formData);
        return data;
    };

    return {
        initialValues,
        Schema,
        data: projectQuery.data,
        isLoading: projectQuery.isLoading,
        submit,
        isSubmitting: addProjectMutation.isLoading,
    };
};
export default useProjectAddForm;
