import moment from "moment";
import { ICameraImage } from "@api/models";
import { IconButton } from "@library/buttons";
import ImageSliderCard from "modules/shared/components/ImageSliderCard/ImageSliderCard";
import { DatePicker } from "@library/datepicker";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Keyboard } from "swiper/modules";
import { formatDate, formatTime } from "modules/shared/helpers/shared.utils";
import {
  ComponentPropsWithRef,
  FC,
  ForwardedRef,
  forwardRef,
  useRef,
} from "react";
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from "@icon/index";

interface ImageSliderProps extends ComponentPropsWithRef<"div"> {
  images: ICameraImage[];
  selectedImage: ICameraImage;
  onSelectedImageChange: (image: ICameraImage) => void;
  selectedDate: string;
  onSelectedDateChange: (date: string) => void;
  startDate: string;
  endDate: string;
  isLoading: boolean;
  className?: string;
}
const ImageSlider = forwardRef(
  (props: ImageSliderProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      images,
      selectedImage,
      onSelectedImageChange,
      selectedDate,
      onSelectedDateChange,
      startDate,
      endDate,
      isLoading,
      className,
    } = props;

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    // const isDesktop = useDesktopMediaQuery();

    return (
      <div
        ref={ref}
        // style={{ backgroundColor: 'red' }}
        className={[
          "flex flex-col gap-4 pt-5 h-[var(--image-slider-height)]",
          className,
        ].join(" ")}
      >
        <div className="flex justify-between items-center px-6">
          <div className="relative flex items-center gap-2 cursor-pointer font-semibold text-grayscale-900 h-8">
            <div className="absolute flex items-center gap-2 left-0  pointer-events-none">
              <IconButton
                color="secondary"
                size="xs"
                className="bg-white text-grayscale-600"
              >
                <CalendarIcon
                  className="text-grayscale-600"
                  width="16"
                  height="18"
                />
              </IconButton>
            </div>
            <DatePicker
              className="bg-transparent outline-none pl-11 w-full"
              value={moment(selectedDate, "YYYYMMDD").toDate()}
              onChange={(date) =>
                onSelectedDateChange(moment(date).format("YYYYMMDD"))
              }
              maxDate={moment(endDate, "YYYYMMDD").toDate()}
              minDate={moment(startDate, "YYYYMMDD").toDate()}
              format="DD MMM, YYYY"
            />
          </div>
          <div className="flex items-center gap-3">
            <button
              ref={navigationPrevRef}
              className="flex justify-center items-center w-6 h-6 rounded-full bg-card-color btn-secondary-shadow btn-secondary-border btn-secondary-hover"
            >
              <ChevronLeftIcon
                className="text-grayscale-600"
                width="6"
                height="10"
              />
            </button>
            <button
              ref={navigationNextRef}
              className="flex justify-center items-center w-6 h-6 rounded-full bg-card-color btn-secondary-shadow btn-secondary-border btn-secondary-hover"
            >
              <ChevronRightIcon
                className="text-grayscale-600"
                width="6"
                height="10"
              />
            </button>
          </div>
        </div>

        <div className="px-6 overflow-hidden">
          {isLoading ? (
            // <ImageSliderSkelton count={16} />
            <h3> Loading...</h3>
          ) : images.length > 0 ? (
            <Swiper
              direction="horizontal"
              spaceBetween={20}
              slidesPerView="auto"
              className="overflow-visible"
              modules={[Navigation, Keyboard]}
              // allowTouchMove={!isDesktop  }
              allowTouchMove={false}
              keyboard={{
                enabled: true,
              }}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper: any) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
            >
              {images.map((img, i) => (
                <SwiperSlide key={img._id} className="!w-auto">
                  <ImageSliderCard
                    url={img.urlThumb}
                    time={formatTime(img.time, "h:mm a") as string}
                    isActive={img._id === selectedImage._id}
                    onClick={() => onSelectedImageChange(img)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="text-center font-medium mt-6">
              No images on {formatDate(selectedDate, "DD MMM, YYYY")}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ImageSlider;
