import { CSSProperties, FC, ForwardedRef, ReactNode, forwardRef, useImperativeHandle } from 'react';
import useZoomable from './use-zoomable';
import { IZoomableOptions, IZoomableRef } from './zoomable.types';

interface ZoomableProps extends IZoomableOptions {
    children: ReactNode;
    containerStyle?: CSSProperties;
    contentStyle?: CSSProperties;
    containerClassName?: string;
    contentClassName?: string;
    ref?: ForwardedRef<IZoomableRef>;
}

const Zoomable = forwardRef<IZoomableRef, ZoomableProps>((props: ZoomableProps, ref: ForwardedRef<IZoomableRef>) => {
    const { children, containerStyle, contentStyle, containerClassName, contentClassName, ...rest } = props;

    const { getContainerProps, getContentProps, zoomIn, zoomOut, zoomTo } = useZoomable({
        ...rest,
    });

    useImperativeHandle(ref, () => {
        return {
            zoomIn,
            zoomOut,
            zoomTo,
        };
    });

    return (
        <div {...getContainerProps({ style: containerStyle, className: containerClassName })}>
            <div {...getContentProps({ style: contentStyle, className: contentClassName })}>{children}</div>
        </div>
    );
});

export default Zoomable;
