import { Field, FieldAttributes, FieldProps } from 'formik';
import { Select, SelectProps } from '@hyperflake/react-ui-library/forms';
import { ForwardedRef, forwardRef, useMemo } from 'react';

interface FormikSelectProps extends SelectProps {
    fieldProps?: FieldAttributes<SelectProps>;
}

const FormikSelect = forwardRef((props: FormikSelectProps, ref: ForwardedRef<HTMLSelectElement>) => {
    const { name, id, fieldProps, invalid, ...rest } = props;

    const idOrName = useMemo(() => {
        return id || name;
    }, [id, name]);

    return (
        <Field name={name} {...fieldProps}>
            {({ field, meta }: FieldProps) => (
                <Select
                    {...rest}
                    {...field}
                    ref={ref}
                    id={idOrName}
                    name={name}
                    invalid={(meta.touched && Boolean(meta.error)) || invalid}
                />
            )}
        </Field>
    );
});

export default FormikSelect;
