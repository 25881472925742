import http from 'httpConfig/http';
import { useEffect, useMemo, useRef } from 'react';
import { ICamera } from '@api/models/camera.model';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { CircularProgress } from '@library/loaders/components';
import CameraCardSkelton from '../CameraCardSkelton/CameraCardSkelton';
// import CameraCard from '../CameraCard/CameraCard';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import CameraCard from '../CameraCard/CameraCard';
import { useNavigate } from 'react-router-dom';

interface IPaginatedCameres {
    cameras: ICamera[];
    pageCount: number;
}

const ResellerCameraList = () => {
    const navigate = useNavigate();
    const size = 50;
    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    const topBarRef = useRef<HTMLDivElement>(null);

    const { isLoading, data, isFetching, fetchNextPage } = useInfiniteQuery<IPaginatedCameres>(
        ['reseller', 'all cameras'],
        async ({ pageParam = 1 }) => {
            const { data } = await http.get(
                `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/cameras?size=${size}&page=${pageParam}`
            );
            return data;
        },
        {
            getNextPageParam: (lastPage, pages) => {
                return pages.length < lastPage.pageCount ? pages.length + 1 : undefined;
            },
        }
    );

    useEffect(() => {
        console.log('running', inView);
        if (inView) {
            fetchNextPage();
        }
    }, [inView]);

    const cameras = useMemo(() => {
        if (isLoading) return [];

        if (data) {
            let mergedData: ICamera[] = [];
            const { pages } = data;
            pages.forEach((pagedCamera: IPaginatedCameres) => {
                mergedData = [...mergedData, ...pagedCamera.cameras];
            });
            return mergedData;
        }
        return [];
    }, [data, isLoading, isFetching]);

    return (
        <PageTemplate ref={topBarRef}>
            {/* //     <Breadcrumb separator='/' items={navArray} /> */}

            <PageHeading>Reseller Camera</PageHeading>

            <div
                className="overflow-auto px-4"
                style={{
                    height: `${window.innerHeight - (+topBarRef?.current?.offsetHeight || 0)}px`,
                }}
            >
                <div className="mt-4 ">
                    {isLoading ? (
                        <div className="grid grid-cols-4 2xl:grid-cols-5 gap-6">
                            {Array(20)
                                .fill(1)
                                .map((_, index) => (
                                    <CameraCardSkelton unique={index.toString()} key={index} />
                                ))}
                        </div>
                    ) : (
                        // <div className="grid grid-cols-4 2xl:grid-cols-5 gap-6">
                        //     {cameras.map((camera, index, allCameras) => {
                        //         return (
                        //             <CameraCard
                        //                 camera={camera}
                        //                 unique={index.toString()}
                        //                 isActivator={allCameras.length - 30 === index}
                        //                 ref={ref}
                        //                 key={camera._id}
                        //             />
                        //         );
                        //     })}
                        // </div>

                        <div className="grid grid-cols-4 gap-6 mt-6 empty:hidden">
                            {cameras.map((camera, index) => (
                                <div className="col-span-1" key={camera._id}>
                                    <CameraCard
                                        camera={camera}
                                        onClick={() => navigate(`/cameras/${camera._id}`)}
                                        isActivator={cameras.length - 30 === index}
                                        ref={ref}
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                    {isFetching && (
                        <div className="flex justify-center py-36">
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </div>
        </PageTemplate>
    );
};

export default ResellerCameraList;
