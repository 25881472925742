import { FC, ReactNode, forwardRef, ForwardedRef } from 'react';
import Drawer, { DrawerProps } from './Drawer';
import { LoadingButton } from '@library/buttons';

export interface ConfirmationDrawerProps extends Omit<DrawerProps, 'title' | 'children'> {
    title: ReactNode;
    message: ReactNode;
    onConfirm?: () => void;
    isConfirming?: boolean;
    confirmText?: string;
    confirmBtnColor?: 'primary' | 'danger';
    confirmLoadingText?: string;
    cancelText?: string;
    onCancel?: () => void;
    isCancelling?: boolean;
    cancelLoadingText?: string;
}

const ConfirmationDrawer = forwardRef((props: ConfirmationDrawerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        open,
        onClose,
        title,
        message,
        onConfirm,
        isConfirming,
        confirmText,
        confirmBtnColor,
        confirmLoadingText,
        onCancel,
        isCancelling,
        cancelText,
        cancelLoadingText,
        ...rest
    } = props;

    const handleCancelClick = () => {
        onClose();

        onCancel?.();
    };

    return (
        <Drawer ref={ref} open={open} onClose={onClose} {...rest}>
            <h4>{title}</h4>

            <div className="text-sm leading-6 text-grayscale-500 font-medium mt-4">{message}</div>

            <div className="flex gap-4 mt-4">
                <LoadingButton
                    type="button"
                    color="secondary"
                    className="font-medium text-white "
                    // className="font-medium text-grayscale-500 "
                    size="sm"
                    onClick={handleCancelClick}
                    isLoading={isCancelling}
                    loadingText={cancelLoadingText}
                >
                    {cancelText}
                </LoadingButton>

                <LoadingButton
                    type="button"
                    color={confirmBtnColor}
                    size="sm"
                    onClick={onConfirm}
                    isLoading={isConfirming}
                    loadingText={confirmLoadingText}
                >
                    {confirmText}
                </LoadingButton>
            </div>
        </Drawer>
    );
});

ConfirmationDrawer.defaultProps = {
    isConfirming: false,
    confirmText: 'Confirm',
    confirmBtnColor: 'danger',
    isCancelling: false,
    cancelText: 'Cancel',
};

export default ConfirmationDrawer;
