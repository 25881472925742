import { FC, ReactNode, useCallback, useReducer } from 'react';
import ProgressTabContext from '../context/progress-tab-context';
import { IAddProgressTabItem, IProgressTabItem } from '../helpers/progress-tab.types';

const initialState: IProgressTabItem[] = [];

// Define the actions that can be dispatched
type Action =
    | { type: 'ADD'; payload: IProgressTabItem }
    | { type: 'UPDATE'; payload: { itemId: string; updates: Partial<IProgressTabItem> } }
    | { type: 'REMOVE'; payload: string }
    | { type: 'RESET' };

// Define the reducer function
const reducer = (state: IProgressTabItem[], action: Action): IProgressTabItem[] => {
    switch (action.type) {
        case 'ADD':
            return [...state, action.payload];
        case 'UPDATE':
            return state.map((item) =>
                item._id === action.payload.itemId ? { ...item, ...action.payload.updates } : item
            );
        case 'REMOVE':
            return state.filter((item) => item._id !== action.payload);
        case 'RESET':
            return initialState;
        default:
            return state;
    }
};

interface ProgressTabProviderProps {
    children: ReactNode;
}
const ProgressTabProvider: FC<ProgressTabProviderProps> = (props) => {
    const { children } = props;

    const [items, dispatch] = useReducer(reducer, initialState);

    const addItem = useCallback((values: IAddProgressTabItem) => {
        const item: IProgressTabItem = {
            ...values,
            progress: 0,
            isCompleted: false,
            isErrored: false,
            isCancellable: values.onCancel ? true : false,
        };

        dispatch({ type: 'ADD', payload: item });

        return item;
    }, []);

    const updateItem = useCallback((itemId: string, updates: Partial<IProgressTabItem>) => {
        dispatch({ type: 'UPDATE', payload: { itemId, updates } });
    }, []);

    const removeItem = useCallback((itemId: string) => {
        dispatch({ type: 'REMOVE', payload: itemId });
    }, []);

    const reset = useCallback(() => {
        dispatch({ type: 'RESET' });
    }, []);

    return (
        <ProgressTabContext.Provider
            value={{
                items,
                addItem,
                updateItem,
                removeItem,
                reset,
            }}
        >
            {children}
        </ProgressTabContext.Provider>
    );
};

export default ProgressTabProvider;
