import { PermissionEnum } from '@api/enum/permission.enum';
import { Sidebar } from '@hyperflake/react-ui-library';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import { useSupportCounterData } from 'modules/shared/hooks/use-badge-count';
import { useLocation, useNavigate } from 'react-router-dom';

interface NotificationBadgeProps {
    count: number;
}
const NotificationBadge = (props: NotificationBadgeProps) => {
    const { count } = props;

    return (
        <div className="text-[10px] bg-warning w-5 h-5 ml-[0.25rem] -mt-[0.8rem] rounded-full grid place-items-center text-warning-contrast font-semibold">
            {count}
        </div>
    );
};

const AppSidebar = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { supportCounter, publicApiCounter } = useSupportCounterData();
    const { hasPermission } = useAuthPermission();

    const navItems = [
        {
            content: 'Clients',
            isActive: pathname.startsWith('/clients'),
            onClick: () => navigate('/clients'),
            permission: hasPermission(PermissionEnum.CLIENT_READ),
        },
        {
            content: 'All Cameras',
            isActive: pathname.startsWith('/cameras'),
            onClick: () => navigate('/cameras'),
            permission: hasPermission(PermissionEnum.RESELLER_CAMERA_READ),
        },
        {
            content: 'All Users',
            isActive: pathname.startsWith('/all-users'),
            onClick: () => navigate('/all-users'),
            permission: hasPermission(PermissionEnum.ALL_USERS_READ),
        },
        {
            content: 'Sign In Logs',
            isActive: pathname.startsWith('/sign-in-logs'),
            onClick: () => navigate('/sign-in-logs'),
            permission: hasPermission(PermissionEnum.SIGN_IN_LOGS_READ),
        },
        // {
        //     content: 'Console User',
        //     isActive: pathname.startsWith('/console-users'),
        //     onClick: () => navigate('/console-users'),
        //     permission: hasPermission(PermissionEnum.CONSOLE_USER_READ),
        // },
        {
            content: (
                <div className="relative flex items-center">
                    <div>Support</div>
                    {supportCounter?.count !== 0 && <NotificationBadge count={supportCounter?.count} />}
                </div>
            ),
            isActive: pathname.startsWith('/support-tickets'),
            onClick: () => navigate('/support-tickets'),
            permission: hasPermission(PermissionEnum.SUPPORT_READ),
        },
        {
            content: (
                <div className="relative flex items-center">
                    <div>Public Api Request</div>
                    {publicApiCounter?.count !== 0 && <NotificationBadge count={publicApiCounter?.count} />}
                </div>
            ),
            isActive: pathname.startsWith('/public-requests'),
            onClick: () => navigate('/public-requests'),
            permission: hasPermission(PermissionEnum.PUBLIC_API_REQUEST_READ),
        },
        {
            content: 'Internal Applications',
            isActive: pathname.startsWith('/internal-applications'),
            onClick: () => navigate('/internal-applications'),
            permission: hasPermission(PermissionEnum.TIMELAPSE_CAMERA_READ),
        },
    ];

    return (
        <Sidebar className="">
            <Sidebar.Nav>
                {navItems.map(
                    (row, i) =>
                        row.permission && (
                            <Sidebar.NavItem key={i} isActive={row.isActive} onClick={row.onClick}>
                                {row.content}
                            </Sidebar.NavItem>
                        )
                )}
            </Sidebar.Nav>
        </Sidebar>
    );
};

export default AppSidebar;
