import * as Yup from 'yup';
import useClientData from './useClientData';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateClient } from '@api/services/client.service';
import { IClient } from '@api/models';
import { IPluginForm } from '../helpers/clients.types';
import { produce } from 'immer';
import { useMemo } from 'react';
import { ClientStatusEnum, PluginIndentifierEnum } from '@api/enum';

interface UpdateClientFormOptions {
    client: IClient;
    // onUpdate: (updatedClient: IClient) => void;
}

const useClientUpdatePluginForm = (options: UpdateClientFormOptions) => {
    const { client } = options;

    // const schema = Yup.object({
    //     name: Yup.string().trim().required('This field is required'),
    //     preferences: Yup.object({
    //         primaryColor: Yup.string().trim().required('This field is required'),
    //         hasCdnEnabled: Yup.boolean(),
    //     }),
    //     domain: Yup.string().trim(),
    //     logo: Yup.mixed().nullable(),
    //     emailHeaderLogo: Yup.mixed().nullable(),
    // });

    const availablePlugins = Object.values(PluginIndentifierEnum);

    const getPluginData = (client: IClient) => {
        const activePlugins = Object.keys(client.availablePlugins);

        return availablePlugins.map((plugin: string) => ({
            name: plugin,
            isEnabled: activePlugins.findIndex((val: string) => val === plugin) < 0 ? false : true,
        }));
    };

    const initialValues = useMemo(() => {
        if (!client) {
            return {
                // name: '',
                // preferences: {
                //     primaryColor: '',
                //     hasCdnEnabled: false,
                // },

                domain: '',
                status: ClientStatusEnum.DISBALED,
                plugins: availablePlugins.map((plugin: string) => ({
                    name: plugin,
                    isEnabled: false,
                })),
            };
        }

        return {
            name: client?.name,
            preferences: {
                primaryColor: (client?.preferences?.primaryColor as string).trim(),
                hasCdnEnabled: client?.preferences?.hasCdnEnabled,
                mapZoom: client?.preferences?.mapZoom,
                mapCenter: {
                    latitude: client?.preferences?.mapCenter.latitude,
                    longitude: client?.preferences?.mapCenter.longitude,
                },
            },
            preset: {
                maxImageViewableDays: client?.preset?.maxImageViewableDays,
            },
            domain: client?.domain || '',
            status: client?.status,
            plugins: getPluginData(client),
        };
    }, [client]);

    const updateClientMutation = useMutation((payload: IClient) => updateClient(client._id, payload));

    const submit = async (values: any) => {
        const serializeData = {
            ...values,
            plugins: values.plugins
                .filter((plugin: IPluginForm) => plugin.isEnabled === true)
                .map((plugin: IPluginForm) => plugin.name),
        };

        const data = await updateClientMutation.mutateAsync(serializeData);

        return data;
    };

    return {
        // schema,
        initialValues,
        isSubmitting: updateClientMutation.isLoading,
        submit,
    };
};
export default useClientUpdatePluginForm;
