import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { FileIcon, defaultStyles } from "react-file-icon";

const fileStyles: any = defaultStyles;

interface DocFileIconProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  extension: string;
}

const DocFileIcon: FC<DocFileIconProps> = (props) => {
  const { extension, ...rest } = props;

  return (
    <div {...rest}>
      <FileIcon extension={extension} {...fileStyles[extension]} />
    </div>
  );
};

export default DocFileIcon;
