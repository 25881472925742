import { ISupportTicket, ISupportTicketReply } from '@api/models';
import http from 'httpConfig/http';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';

export const getAllSupportTicket = async (clientName: string) => {
    const { data } = await http.get(`${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/support-tickets`, {
        params: { clientName: clientName ? clientName : undefined },
    });
    return data as ISupportTicket[];
};

export const getSupportTicketById = async (ticketId: string) => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/support-tickets/${ticketId}`
    );

    return data as ISupportTicket;
};

export const updateSupporticketStatus = async (ticketId: string, status: string) => {
    await http.put(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/support-tickets/${ticketId}/status`,
        { status }
    );
};

export const createSupportTicketReply = async (ticketId: string, message: string) => {
    const { data } = await http.post(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/support-tickets/${ticketId}/reply`,
        { message }
    );

    return data as ISupportTicketReply;
};

export const deleteSupportTicket = async (ticketId: string) => {
    await http.delete(`${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/support-tickets/${ticketId}`);
};

export const supportOpenedCounter = async () => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/support-tickets/count`
    );

    return data;
};
