import { HTMLMotionProps, motion } from 'framer-motion';
import { FC, ForwardedRef, forwardRef } from 'react';
import ScrollLock from 'react-scrolllock';
import { twMerge } from 'tailwind-merge';

import { backdropAnimation } from '../helpers/backdrop.animations';

interface BackdropProps extends HTMLMotionProps<'div'> {
    open: boolean;
    lockScroll?: boolean;
    ref?: ForwardedRef<HTMLDivElement>;
}

const Backdrop = forwardRef<HTMLDivElement, BackdropProps>(
    (props: BackdropProps, ref: ForwardedRef<HTMLDivElement>) => {
        const { open, lockScroll, className, ...rest } = props;

        if (!open) return null;

        return (
            <ScrollLock isActive={lockScroll}>
                <motion.div
                    {...rest}
                    {...backdropAnimation}
                    ref={ref}
                    className={twMerge(
                        'fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/30',
                        className
                    )}
                />
            </ScrollLock>
        );
    }
);

Backdrop.defaultProps = {
    lockScroll: true,
};

export default Backdrop;
