import { FC, ForwardedRef, ReactNode, forwardRef } from 'react';
import { clsx } from 'clsx';
import Header from './Header';
import OverflowTooltip from '@components/OverflowTooltip/OverflowTooltip';

interface AppHeaderProps {
    title: ReactNode;
    headerComponent?: ReactNode;
    headerActions?: ReactNode;
    ref?: ForwardedRef<HTMLElement>;
    lean?: boolean;
}

const AppHeader = forwardRef<HTMLElement, AppHeaderProps>((props: AppHeaderProps, ref: ForwardedRef<HTMLElement>) => {
    const { title, headerComponent, headerActions, lean } = props;

    return (
        <Header
            ref={ref}
            className={clsx(
                'flex items-center gap-6 px-6 pt-6',
                lean ? 'pb-0 h-[calc(var(--header-height)-16px)]' : 'pb-4 h-[var(--header-height)]'
            )}
        >
            <div className="overflow-hidden">
                {headerComponent}

                <h3 className="mt-2">
                    <OverflowTooltip>{title}</OverflowTooltip>
                </h3>
            </div>

            {headerActions && <div className="ml-auto flex items-center gap-4">{headerActions}</div>}
        </Header>
    );
});

AppHeader.defaultProps = {
    lean: false,
};

export default AppHeader;
