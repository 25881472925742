import http from "httpConfig/http";
import { IDroneFootage } from "@api/models/drone-footage.model";
import { getResellerIdFromRedux } from "modules/shared/helpers/shared.utils";

/* -------------------------------------------------------------------------------------------------
 * Get all
 * -----------------------------------------------------------------------------------------------*/

export const getAllDroneFootages = async (
  clientId: string,
  projectId: string
) => {
  const { data } = await http.get(
    `${
      import.meta.env.VITE_APP_API_URL
    }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/drone-footages`
  );

  return data as IDroneFootage[];
};

/* -------------------------------------------------------------------------------------------------
 * Get by Id
 * -----------------------------------------------------------------------------------------------*/

export const getDroneFootageById = async (
  clientId: string,
  projectId: string,
  droneId: string
) => {
  const { data } = await http.get(
    `${
      import.meta.env.VITE_APP_API_URL
    }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/drone-footages/${droneId}`
  );

  return data as IDroneFootage;
};

/* -------------------------------------------------------------------------------------------------
 * Create (file upload)
 * -----------------------------------------------------------------------------------------------*/

export const createDroneFootageWithFile = async (
  clientId: string,
  projectId: string,
  payload: FormData,
  config: {
    signal?: AbortSignal;
    onUploadProgress?: (progress: number) => void;
  }
) => {
  const { data } = await http.post(
    `${
      import.meta.env.VITE_APP_API_URL
    }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/drone-footages/progress-center`,
    payload,
    {
      signal: config.signal,
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        config?.onUploadProgress?.(Math.trunc(progress));
      },
    }
  );

  return data as IDroneFootage;
};

/* -------------------------------------------------------------------------------------------------
 * Create (pass youtube/vimeo url)
 * -----------------------------------------------------------------------------------------------*/

export const createDroneFootageWithUrl = async (
  clientId: string,
  projectId: string,
  payload: Pick<IDroneFootage, "name" | "location" | "url" | "details">
) => {
  const { data } = await http.post(
    `${
      import.meta.env.VITE_APP_API_URL
    }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/drone-footages`,
    payload
  );

  return data as IDroneFootage;
};

/* -------------------------------------------------------------------------------------------------
 * Update
 * -----------------------------------------------------------------------------------------------*/

export const updateDroneFootage = async (
  clientId: string,
  projectId: string,
  droneId: string,
  payload: Partial<IDroneFootage>
) => {
  const { data } = await http.put(
    `${
      import.meta.env.VITE_APP_API_URL
    }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/drone-footages/${droneId}`,
    payload
  );
  return data as IDroneFootage;
};

/* -------------------------------------------------------------------------------------------------
 * Delete
 * -----------------------------------------------------------------------------------------------*/

export const deleteDroneFootage = async (
  clientId: string,
  projectId: string,
  droneId: string
) => {
  await http.delete(
    `${
      import.meta.env.VITE_APP_API_URL
    }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/drone-footages/${droneId}`
  );
};

/* -------------------------------------------------------------------------------------------------
 * Share on social media
 * -----------------------------------------------------------------------------------------------*/

// export const shareDroneFootage = async (projectId: string, droneId: string) => {
//     const { data } = await http.post(`${getApiUrl()}/projects/${projectId}/drone-footages/${droneId}/share`);

//     return data as { url: string };
// };
