// import http from 'app/config/http';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => {
    // const error: any = useRouteError();
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/');
    };

    return (
        <div className="flex flex-col items-center justify-center h-[calc(100vh-var(--navbar-height))]">
            <img className="h-10" src="/icons/logo.svg" alt="" />
            <h3 className="mt-8">Page not found</h3>
            <button className="text-primary font-medium mt-8" onClick={goBack}>
                Go Back Home
            </button>
        </div>
    );
};

export default NotFound;
