import { ISupportTicket } from '@api/models';
import { deleteSupportTicket } from '@api/services/support.service';
import DeleteConfirmationDrawer from '@library/drawer/components/DeleteConfirmationDrawer';
import { useMutation } from '@tanstack/react-query';
import { FC } from 'react';
import { toast } from 'react-toastify';

interface DeleteSupportTicketDrawerProps {
    show: boolean;
    onClose: () => void;
    supportTicket: ISupportTicket;
    onDelete: (ticketId: string) => void;
}

const DeleteSupportTicketDrawer: FC<DeleteSupportTicketDrawerProps> = (props) => {
    const { show, onClose, supportTicket, onDelete } = props;

    const deleteSupportTicketMutation = useMutation(() => deleteSupportTicket(supportTicket._id));

    const handleDelete = async () => {
        try {
            await deleteSupportTicketMutation.mutateAsync();

            toast.success('Support ticket deleted successfully.');

            onDelete(supportTicket._id);

            onClose();
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured, please try again');
        }
    };

    return (
        <DeleteConfirmationDrawer
            open={show}
            onClose={onClose}
            message={<>Are you sure you want to delete this ticket?</>}
            onConfirm={handleDelete}
            isConfirming={deleteSupportTicketMutation.isLoading}
        />
    );
};

export default DeleteSupportTicketDrawer;
