import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';

const fileStyles: any = defaultStyles;

interface ProgressFileIconProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    extension: string;
}

const ProgressFileIcon: FC<ProgressFileIconProps> = (props) => {
    const { extension, ...rest } = props;

    return (
        <div {...rest}>
            <FileIcon extension={extension} {...fileStyles[extension]} />
        </div>
    );
};

export default ProgressFileIcon;
