import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import Dropzone, { Accept } from 'react-dropzone';

interface DragAndDropFileInputProps {}

interface DragAndDropFileUploadProps extends DragAndDropFileInputProps {
    text?: ReactNode;
    onChange?: (files: File[]) => void;
    accept?: Accept;
    multiple?: boolean;
    maxFiles?: number;
}

const DragAndDropFileUpload: FC<DragAndDropFileUploadProps> = (props) => {
    const { text, onChange, accept, multiple, maxFiles } = props;

    const fileRef = useRef(null);
    const [value, setValue] = useState(Date.now());

    useEffect(() => {
        if (!fileRef.current) return;

        fileRef.current.value = '';
    }, [value]);

    const handleChange = async (files: File[]) => {
        if (files && files.length > 0) {
            onChange?.([...files]);
            setValue(Date.now());
        }
    };

    return (
        <Dropzone
            onDrop={(files) => handleChange(files)}
            accept={accept}
            multiple={multiple}
            maxFiles={maxFiles}
            useFsAccessApi={false}
        >
            {({ getRootProps, getInputProps }) => (
                <div
                    {...getRootProps({
                        className:
                            'relative bg-background-color border border-dashed border-grayscale-300 rounded-[10px] overflow-hidden h-full cursor-pointer',
                    })}
                >
                    <input {...getInputProps()} />

                    <div className="text-center p-6">
                        <div className="text-grayscale-400 font-medium text-sm">
                            <span className="text-primary">Upload a file</span> or drag and drop
                        </div>
                        <div className="text-grayscale-400 text-xs mt-2">{text}</div>
                    </div>
                </div>
            )}
        </Dropzone>
    );
};

DragAndDropFileUpload.defaultProps = {
    multiple: false,
};

export default DragAndDropFileUpload;
