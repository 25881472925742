import useResizeObserver from '@react-hook/resize-observer';
import { select } from 'd3-selection';
import { transition } from 'd3-transition';
import { ZoomBehavior, zoom } from 'd3-zoom';
import { DetailedHTMLProps, HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { IZoomableOptions, IZoomableState } from './zoomable.types';

select.prototype.transition = transition;

type HTMLElementProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

const useZoomable = (options: IZoomableOptions) => {
    const { bounds, minZoom = 1, maxZoom = 14, zoomedToContent = false } = options;

    const [transform, setTransform] = useState<IZoomableState>({ x: 0, y: 0, k: 1 });

    const containerRef = useRef(null);
    const contentRef = useRef(null);

    const zoomFnRef = useRef<ZoomBehavior<Element, unknown>>(null);

    const zoomed = useCallback((e: any) => {
        const { transform } = e;

        const { x, y, k } = transform;

        setTransform({ x, y, k });
    }, []);

    const centerView = useCallback(() => {
        if (!zoomFnRef.current) return;

        const container = select(containerRef.current);
        const content = select(contentRef.current);

        const { width: containerWidth, height: containerHeight } = container.node().getBoundingClientRect();
        const { width: contentWidth, height: contentHeight } = content.node().getBoundingClientRect();

        const xOffset = containerWidth / 2 - contentWidth / 2;
        const yOffset = contentHeight / 2 - containerHeight / 2;

        // setTransform((prev) => ({ ...prev, x: xOffset, y: yOffset }));

        container.call(zoomFnRef.current.translateTo, xOffset, yOffset);
    }, []);

    const getScalingFactor = useCallback(() => {
        if (!zoomFnRef.current) return 1;

        const container = select(containerRef.current);

        const { width: containerWidth, height: containerHeight } = container.node().getBoundingClientRect();

        return Math.max(containerWidth / bounds.width, containerHeight / bounds.height) || 1;
    }, [bounds.height, bounds.width]);

    const zoomToView = useCallback(
        (withTransition: boolean = false) => {
            if (!zoomFnRef.current) return;

            const container = withTransition
                ? select(containerRef.current).transition().duration(250)
                : select(containerRef.current);

            if (zoomedToContent) {
                const scalingFactor = getScalingFactor();

                container.call(zoomFnRef.current.scaleTo, scalingFactor);
            } else {
                container.call(zoomFnRef.current.scaleTo, 1);
            }
        },
        [getScalingFactor, zoomedToContent]
    );

    useEffect(() => {
        zoomFnRef.current = zoom()
            .scaleExtent([minZoom, maxZoom])
            .translateExtent([
                [0, 0],
                [bounds.width, bounds.height],
            ])
            .on('zoom', zoomed);

        const container = select(containerRef.current);

        container.call(zoomFnRef.current);
    }, [bounds.width, bounds.height, zoomed, centerView, minZoom, maxZoom]);

    useResizeObserver(containerRef.current, () => {
        centerView();

        zoomToView();
    });

    useEffect(() => {
        zoomToView(true);
    }, [zoomToView]);

    const zoomIn = useCallback(() => {
        if (!zoomFnRef.current) return;

        const container = select(containerRef.current);

        container
            .transition()
            .duration(100)
            .call(zoomFnRef.current.scaleBy, 1 + 0.1);
    }, []);

    const zoomOut = useCallback(() => {
        if (!zoomFnRef.current) return;

        const container = select(containerRef.current);

        container
            .transition()
            .duration(100)
            .call(zoomFnRef.current.scaleBy, 1 - 0.1);
    }, []);

    const zoomTo = useCallback((k: number) => {
        if (!zoomFnRef.current) return;

        const container = select(containerRef.current);

        container.call(zoomFnRef.current.scaleTo, k);
    }, []);

    const getContainerProps = useCallback((props: HTMLElementProps = {}) => {
        const { style, ...rest } = props;

        return {
            ...rest,
            ref: containerRef,
            style: {
                ...style,
                overflow: 'hidden',
            },
        };
    }, []);

    const getContentProps = useCallback(
        (props: HTMLElementProps = {}) => {
            const { style, ...rest } = props;

            return {
                ...rest,
                ref: contentRef,
                style: {
                    ...style,
                    height: bounds.height,
                    width: bounds.width,
                    transformOrigin: '0 0',
                    transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.k})`,
                },
            };
        },
        [bounds.width, bounds.height, transform.k, transform.x, transform.y]
    );

    return {
        getContainerProps,
        getContentProps,
        state: transform,
        zoomIn,
        zoomOut,
        zoomTo,
        // refs: {
        //     container: containerRef,
        //     content: contentRef,
        // },
    };
};

export default useZoomable;
