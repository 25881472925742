import { DetailedHTMLProps, FC, ForwardedRef, HTMLAttributes, ImgHTMLAttributes, forwardRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { AvatarOptions } from '../helpers/avatar.types';
import { getFontSize } from '../helpers/avatar.utils';
import Avatar from './Avatar';

interface AvatarGroupProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
        Pick<AvatarOptions, 'size' | 'fontSize' | 'initialsOnly'> {
    avatars: Pick<AvatarOptions, 'src' | 'alt' | 'bgColor'>[];
    max?: number;
    ref?: ForwardedRef<HTMLDivElement>;
    imgProps?: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
}

const AvatarGroup = forwardRef<HTMLDivElement, AvatarGroupProps>(
    (props: AvatarGroupProps, ref: ForwardedRef<HTMLDivElement>) => {
        const { avatars, max, size, fontSize, initialsOnly, className, imgProps, ...rest } = props;

        const altFontSize = useMemo(() => (fontSize ? fontSize : getFontSize(size)), [size, fontSize]);

        return (
            <div {...rest} ref={ref} className={twMerge('flex items-center', className)}>
                {avatars.slice(0, max).map((row, i) => (
                    <Avatar
                        key={i}
                        src={row.src}
                        alt={row.alt}
                        bgColor={row.bgColor}
                        size={size}
                        initialsOnly={initialsOnly}
                        className="border-2 border-card-color -ml-2 first:ml-0"
                        imgProps={imgProps}
                    />
                ))}

                {avatars.length > max && (
                    <Avatar
                        alt={`+${avatars.length - max}`}
                        size={size}
                        className="border-2 border-card-color -ml-2"
                        initialsOnly={false}
                        fontSize={altFontSize - altFontSize / 6}
                    />
                )}
            </div>
        );
    }
);

AvatarGroup.defaultProps = {
    max: 4,
};

export default AvatarGroup;
