import http from 'httpConfig/http';
import { IUser } from '@api/models';
import { Label } from '@library/forms';
import { toast } from 'react-toastify';
import { Avatar } from '@library/avatar';
import { useMutation } from '@tanstack/react-query';
// import DisplayImageView from '../ui/DisplayImageView';
// import UploadImageButton from '../ui/UploadImageButton';
import useUploadProgress from 'modules/shared/hooks/use-upload-progress';

import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import UploadImageButton from 'modules/clients/components/ui/UploadImageButton';
import DisplayImageView from 'modules/clients/components/ui/DisplayImageView';

interface ILogoForm {
    // onLogoUrlUpdate: <K extends keyof IClient>(field: K, value: IClient[K]) => void;
    user: IUser;
    clientId: string;
    // logoUrl: string;
    onDpUrlUpdate: (updatedUser: IUser) => void;
}

const UserUpdateDp = (props: ILogoForm) => {
    const { user, clientId, onDpUrlUpdate } = props;
    // const [urlLogo, setUrlLogo] = useState(client.logoUrl);

    const userDpUpdateMutation = async (
        payload: FormData,
        config: { onUploadProgress?: (progress: number) => void }
    ) => {
        const { data } = await http.put(
            `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/users/${user._id}/dp`,
            payload,
            {
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;

                    config?.onUploadProgress?.(Math.trunc(progress));
                },
            }
        );

        return data as Pick<IUser, 'dp'>;
    };
    const updateUserDpMutation = useMutation((payload: FormData) =>
        userDpUpdateMutation(payload, {
            onUploadProgress: (progress) => {
                setUploadProgress(progress);
            },
        })
    );
    const removeUserDpMutation = useMutation(async () => {
        await http.delete(
            `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/users/${user._id}/dp`
        );
    });

    const { uploadProgress, setUploadProgress } = useUploadProgress(user.dpUrl);

    const handleUserDpUpdate = async (file: File) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const { dp } = await updateUserDpMutation.mutateAsync(formData);
            onDpUrlUpdate({ ...user, dpUrl: dp });
            toast.success('Dp updated successfully');
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured');
            console.log(err);
        }
    };

    const handleUserDpRemove = async () => {
        try {
            await removeUserDpMutation.mutateAsync();
            toast.success('Dp removed successfully');
            onDpUrlUpdate({ ...user, dpUrl: null });
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured');

            console.log(err);
        }
    };

    return (
        <div>
            <Label>Display Image</Label>

            <div className="flex   items-center gap-2">
                {/* TODO Show confirmation on remove */}
                <DisplayImageView
                    showRemove={Boolean(user.dpUrl)}
                    onRemove={handleUserDpRemove}
                    isUploading={updateUserDpMutation.isLoading || removeUserDpMutation.isLoading}
                    uploadProgress={uploadProgress}
                >
                    <Avatar
                        src={user.dpUrl}
                        alt={user.name}
                        // bgColor={user.preferences.primaryColor}
                        size={88}
                    />
                </DisplayImageView>

                <div className="flex flex-col items-center">
                    <UploadImageButton onFileChange={handleUserDpUpdate} />
                    <div className="text-xs font-medium text-grayscale-400 mt-2">Max size up to 10MB</div>
                </div>
            </div>
        </div>
    );
};

export default UserUpdateDp;
