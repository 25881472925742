import { VariantProps, cva } from 'class-variance-authority';
import {
    ChangeEvent,
    DetailedHTMLProps,
    FC,
    ForwardedRef,
    InputHTMLAttributes,
    forwardRef,
    useCallback,
    useImperativeHandle,
    useRef,
} from 'react';
import { twMerge } from 'tailwind-merge';
import styles from './Range.module.scss';

const rangeVariants = cva([styles['range'], 'w-full', 'bg-transparent'], {
    variants: {
        color: {
            primary: [styles['range-primary']],
            secondary: [styles['range-secondary']],
            success: [styles['range-success']],
            danger: [styles['range-danger']],
            warning: [styles['range-warning']],
        },
        disabled: {
            true: ['bg-grayscale-100 opacity-60'],
            false: [],
        },
    },
    defaultVariants: {
        disabled: false,
        color: 'primary',
    },
});

const setColor = (node: HTMLInputElement) => {
    const value = +node.value;

    const percentage = ((value - +node.min) / (+node.max - +node.min)) * 100;

    node.style.backgroundImage = `linear-gradient(
    to right,
    rgb(var(--range-color)) 0%,
    rgb(var(--range-color)) ${percentage}%,
    var(--range-color-unfilled) ${percentage}%,
    var(--range-color-unfilled) 100%
)`;
};

interface RangeProps
    extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'color'>,
        VariantProps<typeof rangeVariants> {
    ref?: ForwardedRef<HTMLInputElement>;
    onValueChange?: (val: number) => void;
}

const Range = forwardRef<HTMLInputElement, RangeProps>((props: RangeProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { color, disabled, className, onValueChange, onChange, ...rest } = props;

    const internalRef = useRef<HTMLInputElement>(null);
    const internalRefSetter = useCallback((node: HTMLInputElement) => {
        internalRef.current = node;

        if (node) {
            setColor(node);
        }
    }, []);

    useImperativeHandle(ref, () => internalRef.current, [internalRef]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);

        const value = +e.target.value;

        onValueChange?.(value);

        setColor(e.target);
    };

    return (
        <input
            {...rest}
            ref={internalRefSetter}
            type="range"
            disabled={disabled}
            onChange={handleChange}
            className={twMerge(rangeVariants({ color, disabled, className }))}
        />
    );
});

Range.defaultProps = {
    min: 0,
    max: 100,
};

export default Range;
