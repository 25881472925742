import { SupportTicketStatusEnum } from '@api/enum/support-ticket-status.enum';
import store from '@store/index';

export const getResellerIdFromRedux = () => {
    const {
        auth: { resellerId },
    } = store.getState();

    return resellerId;
    // return resellerId ? resellerId : "61caf1dccb3a8396b119a117";
};

export const getSupportTicketStatusLabel = (role: SupportTicketStatusEnum) => {
    const map = {
        OPEN: 'Open',
        CLOSED: 'Closed',
    };

    return map[role];
};
