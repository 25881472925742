import { ChevronLeftIcon } from '@icon/index';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface BackButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    to?: string;
}

const BackButton: FC<BackButtonProps> = (props) => {
    const { to, className, ...rest } = props;

    const navigate = useNavigate();

    const handlebackClick = () => {
        if (to) {
            navigate(to, { replace: true });
        } else {
            navigate(-1);
        }
    };

    return (
        <button
            {...rest}
            className={twMerge('flex items-center gap-[6px] text-grayscale-700 text-sm font-[Inter]', className)}
            onClick={handlebackClick}
        >
            <ChevronLeftIcon /> Back
        </button>
    );
};

export default BackButton;
