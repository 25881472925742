import { FloatingElement, useFloatingElement, FloatingElementOptions } from '@library/floating';
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface TooltipProps
    extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'content'>,
        Pick<FloatingElementOptions, 'open' | 'setOpen' | 'placement' | 'triggers' | 'interactive'> {
    content: ReactNode;
    contentClassName?: string;
}

const Tooltip: FC<TooltipProps> = (props) => {
    const { children, content, contentClassName, open, setOpen, placement, triggers, interactive, className, ...rest } =
        props;

    const { context, refs, getReferenceProps, getFloatingProps } = useFloatingElement({
        open,
        setOpen,
        placement,
        triggers,
        interactive,
        offset: 12,
        showArrow: true,
    });

    return (
        <>
            <div
                {...rest}
                {...getReferenceProps()}
                ref={refs.setReference}
                className={twMerge(content ? 'cursor-pointer' : '', className)}
            >
                {children}
            </div>

            {Boolean(content) && (
                <FloatingElement
                    context={context}
                    refs={refs}
                    {...getFloatingProps()}
                    className={twMerge('text-sm font-medium px-4 py-2', contentClassName)}
                >
                    {content}
                </FloatingElement>
            )}
        </>
    );
};

Tooltip.defaultProps = {
    placement: 'top',
    triggers: ['hover'],
    interactive: false,
};

export default Tooltip;
