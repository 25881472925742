import { FC, ForwardedRef, forwardRef } from 'react';
import Button, { ButtonProps } from './Button';
import { CircularProgress } from '@library/loaders/components';

export interface LoadingButtonProps extends ButtonProps {
    isLoading?: boolean;
    loadingText?: string;
}

const LoadingButton = forwardRef((props: LoadingButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { isLoading, loadingText, children, disabled, ...rest } = props;

    return (
        <Button {...rest} ref={ref} disabled={isLoading || disabled}>
            {!isLoading ? (
                children
            ) : (
                <div className="flex items-center gap-3 justify-center">
                    {<CircularProgress size={10} />} {loadingText ? loadingText : children}
                    ...
                </div>
            )}
        </Button>
    );
});

LoadingButton.defaultProps = {
    isLoading: false,
};

export default LoadingButton;
