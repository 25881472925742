import { IClient } from '@api/models';
import { getAllClients } from '@api/services/client.service';
import useDataQuery from '@hooks/use-data-query';
import { produce } from 'immer';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useClientListData = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    let searchQueryString = searchParams.get('search') || '';

    const [searchValue, setSearchValue] = useState(searchQueryString);

    const clientListQuery = useDataQuery(['clients'], () => getAllClients());

    const filteredClients = useMemo(() => {
        if (clientListQuery.isLoading) return [];

        let _clients = clientListQuery.data;

        const val = searchValue.toLowerCase();

        if (val) {
            _clients = _clients.filter((row) => {
                let flag = row.name.toLowerCase().includes(val);

                flag = flag || row._id.toLowerCase().includes(val);

                return flag;
            });
        }

        return _clients;
    }, [clientListQuery.isLoading, clientListQuery.data, searchValue]);

    const setSearchQuery = (value: string) => {
        setSearchValue(value);

        if (value) {
            searchParams.set('search', value);
        } else {
            searchParams.delete('search');
        }

        setSearchParams(searchParams);
    };

    const addClient = async (client: IClient) => {
        clientListQuery.setData((prevList: IClient[]) => {
            return produce(prevList, (draftList) => {
                draftList.unshift(client);

                return draftList;
            });
        });
    };

    const updateClient = async (clientId: string, updates: IClient) => {
        clientListQuery.setData((prevList: IClient[]) => {
            return produce(prevList, (draftList) => {
                const index = draftList.findIndex((row) => row._id === clientId);

                draftList[index] = updates;

                return draftList;
            });
        });
    };

    return {
        isLoading: clientListQuery.isLoading,
        clients: clientListQuery.data,
        filteredClients,
        searchQuery: searchValue,
        setSearchQuery: setSearchQuery,
        addClient,
        updateClient,
    };
};

export default useClientListData;
