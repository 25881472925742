import moment from 'moment';
import { produce } from 'immer';
import groupBy from 'lodash.groupby';
import { PlusIcon } from '@icon/index';
import { Drawer } from '@library/drawer';
import { useMemo, useState } from 'react';
import useNavName from '@hooks/useNavName';
import { IconButton } from '@library/buttons';
import { Breadcrumb } from '@library/breadcrumb';
import DroneGrid from '../ui/DroneGrid';
import { useNavigate, useParams } from 'react-router-dom';
import IllustrationView from 'modules/shared/components/IllustrationView/IllustrationView';
import { IDroneFootage } from '@api/models/drone-footage.model';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getAllDroneFootages } from '@api/services/drone-footage.service';
import NoImagesIllustration from 'modules/shared/components/Illustrations/NoImagesIllustration';
import DroneFootageAddDrawerForm from '../DroneFootageAddDrawerForm/DroneFootageAddDrawerForm';
import DroneFootageDeleteDrawerForm from '../DroneFootageDeleteDrawerForm/DroneFootageDeleteDrawerForm';
import DroneFootageEditDrawerForm from '../DroneFootageEditDrawerForm/DroneFootageEditDrawerForm';
import { CircularProgress } from '@library/loaders/components';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import { PermissionEnum } from '@api/enum/permission.enum';

interface IDroneDrawer {
    open: boolean;
    anchor: string;
    droneFootage: null | IDroneFootage;
}

const DroneList = () => {
    const queryClient = useQueryClient();
    const { clientId, projectId } = useParams();
    const { clientName, projectName } = useNavName(clientId, projectId);
    const { hasPermission } = useAuthPermission();

    const [addDrawer, setAddDrawer] = useState({ open: false, anchor: 'right' });
    const [editDrawer, setEditDrawer] = useState<IDroneDrawer>({
        open: false,
        anchor: 'right',
        droneFootage: null,
    });
    const [confirmationDrawer, setConfirmationDrawer] = useState<IDroneDrawer>({
        open: false,
        anchor: 'right',
        droneFootage: null,
    });

    const navigate = useNavigate();

    // const navArray = [
    //     { label: 'Clients', onClick: () => navigate('/client') },
    //     { label: clientName, onClick: () => navigate(`/client?query=${clientId}`) },
    //     {
    //         label: 'Projects',
    //         onClick: () => navigate(`/client/${clientId}/project`),
    //     },
    //     {
    //         label: projectName,
    //         onClick: () => navigate(`/client/${clientId}/project?query=${projectId}`),
    //     },
    //     { label: 'Drone Footage', onClick: () => {} },
    // ];

    const { data, isLoading } = useQuery(['clients', clientId, 'projects', projectId, 'drone'], async () =>
        getAllDroneFootages(clientId, projectId)
    );

    const droneFootagesGroup = useMemo(() => {
        if (isLoading) return [];

        const droneFootageList = data;

        droneFootageList.sort((r1, r2) => {
            return new Date(r2.createdAt).getTime() - new Date(r1.createdAt).getTime();
        });

        const droneFootagesMap = groupBy(droneFootageList, (item) => {
            return moment(item.createdAt).format('YYYYMMDD');
        });

        return Object.keys(droneFootagesMap)
            .map((key) => ({ key, list: droneFootagesMap[key] }))
            .sort((r1, r2) => +r2.key - +r1.key);
    }, [isLoading, data]);

    const handleOnUpload = (data: IDroneFootage) => {
        queryClient.setQueryData<IDroneFootage[]>(['clients', clientId, 'projects', projectId, 'drone'], (prev) => {
            if (!prev) return;

            return produce(prev, (draft) => {
                return [data, ...prev];
            });
        });
    };

    const handleOnUpdate = (droneId: string, droneFootage: Partial<IDroneFootage>) => {
        queryClient.setQueryData<IDroneFootage[]>(['clients', clientId, 'projects', projectId, 'drone'], (prev) => {
            if (!prev) return;
            const index = prev.findIndex((df) => df._id === droneId);
            return produce(prev, (draft) => {
                draft[index] = droneFootage as IDroneFootage;
                return draft;
                // return [data, ...prev];
            });
        });
    };

    if (isLoading)
        return (
            <PageTemplate>
                {/* <Navbar /> */}
                {/* <div className="pt-[var(--navbar-height)]"> */}
                <PageHeading>Drone Footage</PageHeading>
                <div className="mt-6 flex justify-center items-center min-h-[600px]">
                    <CircularProgress />
                </div>

                {/* </div> */}
            </PageTemplate>
        );

    return (
        <PageTemplate>
            {/* <Navbar /> */}
            {/* <div className="pt-[var(--navbar-height)]"> */}
            <PageHeading>Drone Footage</PageHeading>
            {/* <Breadcrumb separator="/" items={navArray} /> */}
            {hasPermission(PermissionEnum.DRONE_CREATE) && (
                <IconButton
                    className="fixed right-8 bottom-10 z-30"
                    onClick={() => setAddDrawer({ open: true, anchor: 'right' })}
                >
                    <PlusIcon />
                </IconButton>
            )}

            <div>
                <div>
                    <div className="p-4">
                        {droneFootagesGroup.length > 0 ? (
                            <section className="space-y-6">
                                {droneFootagesGroup.map((row) => (
                                    <div key={row.key}>
                                        <div className="flex items-center gap-[10px]">
                                            <div className="text-lg font-medium pl-2">
                                                {moment(row.key, 'YYYYMMDD').format('DD MMM, YYYY')}
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            <DroneGrid
                                                droneFootages={row.list}
                                                onDroneDelete={(item: IDroneFootage) => {
                                                    setConfirmationDrawer({
                                                        anchor: 'right',
                                                        open: true,
                                                        droneFootage: item,
                                                    });
                                                }}
                                                onDroneEdit={(item: IDroneFootage) =>
                                                    setEditDrawer((prev) => ({
                                                        ...prev,
                                                        droneFootage: item,
                                                        open: true,
                                                    }))
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </section>
                        ) : (
                            <IllustrationView
                                className="mt-[10vh]"
                                illustration={<NoImagesIllustration />}
                                title="No Site Gallery yet"
                                message="No results found"
                            />
                        )}
                    </div>
                </div>
            </div>
            {/* </div> */}

            {/* Add drone footage Drawer */}
            <Drawer
                open={addDrawer.open}
                onClose={() => setAddDrawer({ open: false, anchor: 'right' })}
                anchor={addDrawer.anchor as any}
            >
                <DroneFootageAddDrawerForm
                    onClose={() => setAddDrawer({ open: false, anchor: 'right' })}
                    clientId={clientId}
                    projectId={projectId}
                    onAdd={handleOnUpload}
                    show={addDrawer.open}
                />
            </Drawer>

            {/* Edit drone footage Drawer */}
            <Drawer
                open={editDrawer.open}
                onClose={() => setEditDrawer({ open: false, anchor: 'right', droneFootage: null })}
                anchor={editDrawer.anchor as any}
            >
                <DroneFootageEditDrawerForm
                    clientId={clientId}
                    projectId={projectId}
                    show={editDrawer.open}
                    droneFootage={editDrawer.droneFootage}
                    onClose={() => setEditDrawer({ open: false, anchor: 'right', droneFootage: null })}
                    onUpdate={handleOnUpdate}
                />
            </Drawer>

            {/* Delete drone footage  Drawer */}
            <Drawer
                open={confirmationDrawer.open}
                onClose={() =>
                    setConfirmationDrawer({
                        open: false,
                        anchor: 'right',
                        droneFootage: null,
                    })
                }
                anchor={confirmationDrawer.anchor as any}
            >
                <DroneFootageDeleteDrawerForm
                    onClose={() =>
                        setConfirmationDrawer({
                            open: false,
                            anchor: 'right',
                            droneFootage: null,
                        })
                    }
                    droneFootage={confirmationDrawer.droneFootage}
                    projectId={projectId}
                    clientId={clientId}
                />
            </Drawer>
        </PageTemplate>
    );
};

export default DroneList;
