const Icon = (props: any) => {
    return (
        <svg width={8} height={5} viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1 1l3 3 3-3"
                stroke="currentColor"
                strokeWidth={props.strokeWidth || 1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
