import { IClient } from '@api/models/client.model';
import { useState } from 'react';

import { Drawer, LinearProgress, NavTab } from '@hyperflake/react-ui-library';
import useClientData from 'modules/clients/hooks/useClientData';
import ClientEditFormBasic from '../ClientEditFormBasic/ClientEditFormBasic';
import ClientEditFormPlugin from '../ClientEditFormPlugin/ClientEditFormPlugin';
import ClientLoginImagesForm from '../ClientLoginImagesForm/ClientLoginImagesForm';

enum TabEnum {
    BASIC_DETAILS,
    LOGIN_IMAGES,
    PULGINS,
}

interface EditClientDrawerProps {
    open: boolean;
    onClose: () => void;
    clientId: string;
    onClientUpdate: (updatedClient: IClient) => void;
}
const EditClientDrawer = (props: EditClientDrawerProps) => {
    const { clientId, onClose, open, onClientUpdate } = props;

    const [activeTab, setActiveTab] = useState(TabEnum.BASIC_DETAILS);

    const clientData = useClientData(clientId);

    const handleClientUpdate = (client: IClient) => {
        clientData.setData(client);
        onClientUpdate(client);
    };

    const client = clientData.client;

    return (
        <Drawer open={open} onClose={onClose} title="Edit Client">
            <NavTab
                items={[
                    {
                        content: 'Basic',
                        isActive: TabEnum.BASIC_DETAILS === activeTab,
                        onClick: () => setActiveTab(TabEnum.BASIC_DETAILS),
                    },
                    {
                        content: 'Login Images',
                        isActive: TabEnum.LOGIN_IMAGES === activeTab,
                        onClick: () => setActiveTab(TabEnum.LOGIN_IMAGES),
                    },
                    {
                        content: 'Plugins',
                        isActive: TabEnum.PULGINS === activeTab,
                        onClick: () => setActiveTab(TabEnum.PULGINS),
                    },
                ]}
            />

            {clientData.isLoading ? (
                <div className="h-[50vh] flex items-center">
                    <div className="w-full text-center">
                        <LinearProgress color="primary" className="max-w-[220px] mx-auto" />
                        <div className="text-sm font-medium text-grayscale-500 mt-3">Loading...</div>
                    </div>
                </div>
            ) : (
                <div className="mt-6">
                    {activeTab === TabEnum.BASIC_DETAILS && (
                        <ClientEditFormBasic client={client} onClientUpdate={handleClientUpdate} />
                    )}

                    {activeTab === TabEnum.LOGIN_IMAGES && (
                        <ClientLoginImagesForm client={client} onClientUpdate={handleClientUpdate} />
                    )}

                    {activeTab === TabEnum.PULGINS && (
                        <ClientEditFormPlugin onClientUpdate={handleClientUpdate} onClose={onClose} client={client} />
                    )}
                </div>
            )}
        </Drawer>
    );
};

export default EditClientDrawer;
