import { VariantProps, cva } from 'class-variance-authority';
import { DetailedHTMLProps, FC, ForwardedRef, HTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const listVariants = cva(['bg-card-color rounded-lg py-2'], {
    variants: {
        divider: {
            true: 'divide-y divide-separator-color-light',
            false: '',
        },
    },
    defaultVariants: {
        divider: false,
    },
});

interface ListProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement>,
        VariantProps<typeof listVariants> {
    ref?: ForwardedRef<any>;
}

const List = forwardRef((props: ListProps, ref: ForwardedRef<any>) => {
    const { children, divider, className, ...rest } = props;

    return (
        <ul {...rest} ref={ref} className={twMerge(listVariants({ divider, className }), className)}>
            {children}
        </ul>
    );
});

export default List;
