import { IPublicRequest } from '@api/models/public-request.model';
import { deletePublicApiRequest } from '@api/services/public-request.service';
import DeleteConfirmationDrawer from '@library/drawer/components/DeleteConfirmationDrawer';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

interface DeletePublicApiRequestDrawerProps {
    open: boolean;
    onClose: () => void;
    publicApiRequest: IPublicRequest;
    onDelete: (publicApiId: string) => void;
    // onUpdate: (updatedPublicApiData: IPublicRequest) => void;
}
const DeletePublicApiRequestDrawer = (props: DeletePublicApiRequestDrawerProps) => {
    const { open, onClose, publicApiRequest, onDelete } = props;
    const deleteMutation = useMutation(() => deletePublicApiRequest(publicApiRequest._id));

    const handleDelete = async () => {
        try {
            await deleteMutation.mutateAsync();
            onDelete(publicApiRequest._id);
            onClose();
            toast.success('Request Deleted');
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured, please try again');
        }
    };

    return (
        <div>
            <DeleteConfirmationDrawer
                open={open}
                onClose={onClose}
                message={
                    <>
                        <div>
                            We will remove this Api Request of <strong>{publicApiRequest?.user.username}</strong>{' '}
                        </div>
                        <div className="mt-4">
                            <span className="text-grayscale-900 font-semibold">Attention: </span> This action is
                            irreversible.
                        </div>
                    </>
                }
                onConfirm={handleDelete}
                isConfirming={deleteMutation.isLoading}
            />
        </div>
    );
};

export default DeletePublicApiRequestDrawer;
