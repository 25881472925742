import { FC } from 'react';
import * as Yup from 'yup';
import http from 'httpConfig/http';
import { useSelector } from 'react-redux';
import store, { TRootState } from '@store/index';
import capitalize from 'lodash.capitalize';
import { ConsoleUserRoleEnum } from '@api/enum';
import { ErrorMessage, Form, Formik } from 'formik';
import { FormHelperText, Label } from '@library/forms';
import RequiredMark from '@components/FormFields/RequiredMark';
import { CircularProgress } from '@library/loaders/components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { profileAction } from '@store/profile';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { Button } from '@hyperflake/react-ui-library';
import { FormikInput, FormikSelect } from 'modules/shared/features/formik';

// interface IProfile {
//     onClose: () => void;
// }

const profileValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required('This field is required'),
});

const Profile = () => {
    const { info } = useSelector((state: TRootState) => state.profile);
    const initialValues = {
        name: info.name,
        email: info.email,
        username: info.username,
        designation: info.designation,
        role: info.role,
    };

    const roleOption = Object.keys(ConsoleUserRoleEnum).map((val) => ({
        value: val,
        label: capitalize(val),
    }));

    const updateProfileMutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/console-users/profile`,
            payload
        );
        return data;
    });

    const handleSubmit = async (values: any) => {
        try {
            const data = await updateProfileMutation.mutateAsync(values);
            toast.success('Profile Updated Successfully');
            store.dispatch(profileAction.setProfile(data));
        } catch (e) {
            toast.error('Something went wrong');
        }
    };

    return (
        <PageTemplate>
            <div>
                <PageHeading>Edit Your Profile</PageHeading>
                <div>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={profileValidationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({}) => (
                            <Form>
                                <div className="mt-4 ">
                                    {/* name */}
                                    <div className="">
                                        <Label>
                                            Name <RequiredMark />
                                        </Label>
                                        <FormikInput type="text" name="name" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="name" />
                                        </FormHelperText>
                                    </div>
                                    {/* designation */}
                                    <div className="">
                                        <Label>Designation</Label>
                                        <FormikInput type="text" name="designation" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="designation" />
                                        </FormHelperText>
                                    </div>

                                    <fieldset disabled>
                                        {/* Username */}
                                        <div className="">
                                            <Label>
                                                Username <RequiredMark />
                                            </Label>
                                            <FormikInput type="text" name="username" />
                                            <FormHelperText variant={'error'}>
                                                <ErrorMessage name="username" />
                                            </FormHelperText>
                                        </div>

                                        {/* email */}
                                        <div className="">
                                            <Label>
                                                Email <RequiredMark />{' '}
                                            </Label>
                                            <FormikInput type="text" name="email" />
                                            <FormHelperText variant={'error'}>
                                                <ErrorMessage name="email" />
                                            </FormHelperText>
                                        </div>

                                        <div>
                                            <Label>
                                                Role <RequiredMark />
                                            </Label>
                                            <div className="flex gap-2">
                                                <FormikSelect name="role" options={roleOption} />
                                            </div>
                                            <FormHelperText variant={'error'}>
                                                <ErrorMessage name="role" />
                                            </FormHelperText>
                                        </div>
                                    </fieldset>
                                </div>

                                <Button
                                    type="submit"
                                    size={'lg'}
                                    className="mt-8 w-28"
                                    disabled={updateProfileMutation.isLoading}
                                >
                                    {updateProfileMutation.isLoading ? (
                                        <CircularProgress size={20} color={'inherit'} />
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </PageTemplate>
    );
};

export default Profile;
