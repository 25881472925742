import { VariantProps, cva } from 'class-variance-authority';
import { DetailedHTMLProps, FC, HTMLAttributes, useMemo } from 'react';
import styles from './LinearProgress.module.scss';
import { twJoin, twMerge } from 'tailwind-merge';

const progressVariants = cva(['relative flex items-center gap-2'], {
    variants: {
        variant: {
            indeterminate: [styles['progress-indeterminate']],
            determinate: [styles['progress-determinate']],
        },
        color: {
            inherit: [styles['progress-color-inherit']],
            primary: [styles['progress-color-primary']],
            secondary: [styles['progress-color-secondary']],
            success: [styles['progress-color-success']],
            danger: [styles['progress-color-danger']],
            warning: [styles['progress-color-warning']],
        },
    },
    defaultVariants: {
        variant: 'indeterminate',
        color: 'primary',
    },
});

interface LinearProgressProps
    extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>, 'color'>,
        VariantProps<typeof progressVariants> {
    value?: number;
    showLabel?: boolean;
}

const LinearProgress: FC<LinearProgressProps> = (props) => {
    const { value, showLabel, variant, color, className, ...rest } = props;

    const offset = useMemo(() => {
        let _value = Math.max(0, value);
        _value = Math.min(_value, 100);

        return _value;
    }, [value]);

    return (
        <span role="progressbar" {...rest} className={twMerge(progressVariants({ variant, color, className }))}>
            <span className={twJoin('flex h-1 relative overflow-hidden flex-1 w-full', styles['progress-bar'])}>
                <span className={styles['line-1']} style={{ width: `${offset}%` }}></span>
                {variant === 'indeterminate' && <span className={styles['line-2']}></span>}
            </span>

            {showLabel && variant === 'determinate' && (
                <span className="text-[0.8rem] leading-[1] font-medium text-grayscale-500 min-w-[35px]">{offset}%</span>
            )}
        </span>
    );
};

LinearProgress.defaultProps = {
    variant: 'indeterminate',
};

export default LinearProgress;
