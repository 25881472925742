import RequiredMark from '@components/FormFields/RequiredMark';

import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import VimeoFormFields from './VimeoFormFields';
import { FC, useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import YouTubeFormFields from './YouTubeFormFields';
import { useMutation } from '@tanstack/react-query';
import DocFileIcon from 'modules/shared/components/DocFileIcon/DocFileIcon';
import VideoUploadFormFields from './VideoUploadFormFields';
import { FormHelperText, Label, Radio } from '@library/forms';
import { useProgressTabContext } from '@library/progress-tab';
import { CircularProgress } from '@library/loaders/components';
import { IDroneFootage } from '@api/models/drone-footage.model';
import { VideoProviderEnum } from '@api/enum/video-provider.enum';
import { convertFileSize, getFileExtension } from 'modules/shared/helpers/shared.utils';
import { createDroneFootageWithFile, createDroneFootageWithUrl } from '@api/services/drone-footage.service';

import { DroneTypeEnum } from '../../helpers/drone-list.enums';
import { IDroneFootageForm } from '../../helpers/drone-list.types';
import { Button } from '@hyperflake/react-ui-library';
import { FormikInput } from 'modules/shared/features/formik';

const isValidVimeoUrl = (url: string) => {
    return url.match(
        /http(?:s?):\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/
    );
};

const isValidYouTubeUrl = (url: string) => {
    return url.match(/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/);
};

const FormSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    location: Yup.object().shape({
        name: Yup.string().required('This field is required'),
    }),
    file: Yup.string()
        .nullable()
        .test('validate-file', function (file: any) {
            const { type } = this.parent;

            if (!file && type === DroneTypeEnum.VIDEO) {
                return this.createError({ message: 'This field is required' });
            }

            return true;
        }),
    url: Yup.string().test('validate-url', function (url: string) {
        const { type } = this.parent;

        if (!url && [DroneTypeEnum.VIMEO, DroneTypeEnum.YOUTUBE].includes(type)) {
            return this.createError({ message: 'This field is required' });
        }

        if (type === DroneTypeEnum.VIMEO && !isValidVimeoUrl(url)) {
            return this.createError({ message: 'Please enter a valid vimeo URL' });
        }

        if (type === DroneTypeEnum.YOUTUBE && !isValidYouTubeUrl(url)) {
            return this.createError({ message: 'Please enter a valid youtube URL' });
        }

        return true;
    }),
});

const DroneTypeList = [
    {
        type: DroneTypeEnum.VIDEO,
        label: 'Upload Video',
        description: 'Use this to upload an MP4 file directly',
        icon: <DocFileIcon className="w-6" extension={'mp4'} />,
    },
    {
        type: DroneTypeEnum.VIMEO,
        label: 'From Vimeo',
        description: 'Use this to add a video from Vimeo',
        icon: <img className="w-6" src="/icons/vimeo.svg" alt="" />,
    },
    {
        type: DroneTypeEnum.YOUTUBE,
        label: 'From YouTube',
        description: 'Use this to add a video from YouTube',
        icon: <img className="w-7" src="/icons/youtube.svg" alt="" />,
    },
];

interface IDroneFootageAddDrawerForm {
    show: boolean;
    onClose: () => void;
    projectId: string;
    clientId: string;
    onAdd: (droneFootage: IDroneFootage) => void;
}

const DroneFootageAddDrawerForm: FC<IDroneFootageAddDrawerForm> = (props) => {
    const [step, setStep] = useState(1);
    const progressTabCtx = useProgressTabContext();
    const { clientId, projectId, show, onAdd, onClose } = props;

    useEffect(() => {
        setStep(1);
    }, [show]);

    const addDroneFootageWithVideoMutation = useMutation(
        (values: { progressTabItemId: string; abortController: AbortController; payload: FormData }) => {
            const { progressTabItemId, abortController, payload } = values;

            return createDroneFootageWithFile(clientId, projectId, payload, {
                signal: abortController.signal,
                onUploadProgress: (progress) => {
                    progressTabCtx.updateItem(progressTabItemId, { progress });
                },
            });
        }
    );

    const addDroneFootageWithUrlMutation = useMutation(
        (payload: Pick<IDroneFootage, 'name' | 'location' | 'url' | 'details'>) =>
            createDroneFootageWithUrl(clientId, projectId, payload)
    );

    const handleAddDroneFootageWithFile = async (values: IDroneFootageForm) => {
        const formData = new FormData();

        formData.append('name', values.name);
        formData.append('location[name]', values.location.name);
        formData.append('type', values.type.toString());
        formData.append('file', values.file);

        const abortController = new AbortController();

        const itemData = progressTabCtx.addItem({
            _id: uuidv4(),
            title: values.name,
            text: convertFileSize(values.file.size),
            extension: getFileExtension(values.file.name),
            onCancel: () => {
                abortController.abort();
            },
        });

        onClose();

        try {
            const droneFootage = await addDroneFootageWithVideoMutation.mutateAsync({
                progressTabItemId: itemData._id,
                abortController: abortController,
                payload: formData,
            });

            progressTabCtx.updateItem(itemData._id, {
                progress: 100,
                isCompleted: true,
            });

            onAdd(droneFootage);
        } catch (err: any) {
            if (err?.message !== 'canceled') {
                toast.error(err.data?.message || 'Some error occured, please try again.');

                progressTabCtx.updateItem(itemData._id, {
                    progress: 100,
                    isErrored: true,
                });
            }
        }
    };

    const handleAddDroneFootageWithUrl = async (values: IDroneFootageForm) => {
        let provider: VideoProviderEnum;

        if (values.type === DroneTypeEnum.VIMEO) {
            provider = VideoProviderEnum.VIMEO;
        } else if (values.type === DroneTypeEnum.YOUTUBE) {
            provider = VideoProviderEnum.YOUTUBE;
        }

        const payload = {
            name: values.name,
            location: values.location,
            details: {
                provider: provider,
            },
            url: values.url,
        };

        try {
            const droneFootage = await addDroneFootageWithUrlMutation.mutateAsync(payload);

            onAdd(droneFootage);

            onClose();
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured');
        }
    };

    const handleSubmit = async (values: IDroneFootageForm) => {
        if (values.type === DroneTypeEnum.VIDEO) {
            await handleAddDroneFootageWithFile(values);
        } else {
            await handleAddDroneFootageWithUrl(values);
        }
    };

    const initialValues: IDroneFootageForm = {
        name: '',
        location: {
            name: '',
        },
        url: '',
        file: null,
        type: DroneTypeEnum.VIDEO,
    };

    return (
        <div>
            <div>
                <h4>Add Drone Footage</h4>
            </div>
            <div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={FormSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, setErrors, setTouched }) => (
                        <Form className="flex flex-col flex-1">
                            {step === 1 && (
                                <>
                                    <div className="space-y-6 mt-6">
                                        {DroneTypeList.map((row) => (
                                            <label
                                                key={row.type}
                                                className={[
                                                    'relative block p-4 border rounded-[10px] overflow-hidden cursor-pointer select-none',
                                                    row.type === values.type
                                                        ? 'border-primary outline outline-2 outline-primary/10'
                                                        : 'border-grayscale-100',
                                                ].join(' ')}
                                            >
                                                <div className="flex justify-between">
                                                    <div>
                                                        <div>{row.icon}</div>
                                                        <div className="font-semibold text-sm mt-3">{row.label}</div>
                                                        <div className="text-xs text-grayscale-500 mt-1">
                                                            {row.description}
                                                        </div>
                                                    </div>
                                                    <Radio
                                                        checked={row.type === values.type}
                                                        onChange={() => setFieldValue('type', row.type)}
                                                    />
                                                </div>
                                            </label>
                                        ))}
                                    </div>

                                    <div className="mt-auto">
                                        <div className="flex gap-4 mt-8">
                                            <Button type="button" size="sm" className="font-medium " onClick={onClose}>
                                                Cancel
                                            </Button>

                                            <Button type="button" size="sm" onClick={() => setStep((prev) => prev + 1)}>
                                                Next
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )}

                            {step === 2 && (
                                <>
                                    <div className="mt-6">
                                        <Label htmlFor="name">Name</Label>
                                        <FormikInput name="name" placeholder="Enter text here" />

                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="name" />
                                        </FormHelperText>
                                    </div>

                                    <div className="mt-6">
                                        <Label htmlFor="location.name">Location</Label>

                                        <FormikInput name="location.name" placeholder="Enter text here" />

                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="location.name" />
                                        </FormHelperText>
                                    </div>

                                    <div className="mt-6">
                                        {values.type === DroneTypeEnum.VIDEO && <VideoUploadFormFields />}

                                        {values.type === DroneTypeEnum.VIMEO && <VimeoFormFields />}

                                        {values.type === DroneTypeEnum.YOUTUBE && <YouTubeFormFields />}
                                    </div>

                                    <div className="mt-auto">
                                        <div className="flex gap-4 mt-8">
                                            <Button
                                                type="button"
                                                size="sm"
                                                className="font-medium text-white"
                                                onClick={() => {
                                                    setTouched({}, false);
                                                    setErrors({});
                                                    setStep((prev) => prev - 1);
                                                }}
                                            >
                                                Back
                                            </Button>

                                            <Button
                                                type="submit"
                                                size="sm"
                                                // isLoading={addDroneFootageWithUrlMutation.isLoading}
                                                // loadingText='Uploading'
                                            >
                                                {addDroneFootageWithUrlMutation.isLoading ? (
                                                    <CircularProgress />
                                                ) : (
                                                    'Upload'
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default DroneFootageAddDrawerForm;
