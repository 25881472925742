import { FormHelperText, Label } from '@library/forms';
import { Form, Formik } from 'formik';
import CustomSwitch from 'modules/shared/components/FormFields/CustomSwitch';

import { ICamera } from '@api/models';
import useCameraPulginUpdate from 'modules/cameras/hooks/useCameraPluginUpdate';
import { toast } from 'react-toastify';
import { Button, CircularProgress, LinearProgress } from '@hyperflake/react-ui-library';
import { FormikSwitch } from 'modules/shared/features/formik';
import ErrorMessageInfo from '@components/ErrorMessageInfo/ErrorMessageInfo';
interface ICameraPulginFormProps {
    camera: ICamera;
    clientId: string;
    projectId: string;
    onCameraUpdate: (camera: ICamera) => void;
    isLoading: boolean;
}

const CameraPluginForm = (props: ICameraPulginFormProps) => {
    const { camera, clientId, projectId, onCameraUpdate, isLoading } = props;

    const updatePulginForm = useCameraPulginUpdate(camera, clientId, projectId);

    const handleSubmit = async (values: any) => {
        try {
            const data = await updatePulginForm.submit(values);
            onCameraUpdate(data);
            toast.success('AI Activated');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <>
            {isLoading ? (
                <div>
                    <LinearProgress color="primary" />
                </div>
            ) : (
                <div>
                    <Formik
                        initialValues={updatePulginForm.initialValues}
                        enableReinitialize
                        // validationSchema={updateClientForm.schema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                {/* <fieldset disabled={!hasAdminPermission}> */}
                                <div className="">
                                    {/* plugins */}
                                    <Label className=" font-bold mt-2">Plugins</Label>
                                    <div className="mt-4">
                                        {values.plugins.map((plugin, index) => {
                                            return (
                                                <>
                                                    {/* <FormikSwitch
                                                color="success"
                                                name={`plugins[${index}].isEnabled`}
                                                disabled={!camera?.location.latitude || !camera?.location.longitude}
                                            /> */}

                                                    <CustomSwitch
                                                        key={index}
                                                        name={`plugins[${index}].isEnabled`}
                                                        label={plugin.name}
                                                        disabled={
                                                            !camera?.location.latitude || !camera?.location.longitude
                                                        }
                                                    />
                                                    {(!camera?.location.latitude || !camera?.location.longitude) && (
                                                        <ErrorMessageInfo message="Add Latitude and Longitude first." />
                                                        // <FormHelperText variant={'error'}>
                                                        //     <div>Set Latitude and Longitude First</div>
                                                        // </FormHelperText>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>

                                <Button
                                    type="submit"
                                    size={'lg'}
                                    className="mt-10"
                                    disabled={
                                        updatePulginForm.isSubmitting ||
                                        !camera?.location.latitude ||
                                        !camera?.location.longitude
                                    }
                                >
                                    {updatePulginForm.isSubmitting ? (
                                        <CircularProgress color={'inherit'} size={18} />
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                                {/* </fieldset> */}
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </>
    );
};
export default CameraPluginForm;
