import { LinearProgress } from '@hyperflake/react-ui-library';
import { ComponentPropsWithoutRef, FC } from 'react';

interface BlockLoaderProps extends ComponentPropsWithoutRef<'div'> {}

const BlockLoader: FC<BlockLoaderProps> = (props) => {
    const { className, ...rest } = props;

    return (
        <div {...rest} className={['flex justify-center items-center mt-[30vh]', className].join(' ')}>
            <div>
                <div className="w-[220px]">
                    <LinearProgress />
                </div>
                <div className="text-center text-grayscale-500 text-sm font-medium mt-4">Loading...</div>
            </div>
        </div>
    );
};

export default BlockLoader;
