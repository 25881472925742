import { getClientStorages } from '@api/services/client.service';
import useDataQuery from '@hooks/use-data-query';

const useClientStorages = () => {
    const clientStorageQuery = useDataQuery(['clients', 'storage_names'], () => getClientStorages());

    return {
        isLoading: clientStorageQuery.isLoading,
        clientStorage: clientStorageQuery.data,
    };
};

export default useClientStorages;
