import { useEffect, useState } from "react";

const useUploadProgress = (dependencyUrl: string) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  /**
   * Reset upload progress when the dependency changes
   * */
  useEffect(() => {
    setUploadProgress(0);
  }, [dependencyUrl]);

  return {
    uploadProgress,
    setUploadProgress,
  };
};

export default useUploadProgress;
