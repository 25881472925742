import { Card } from '@library/cards';

const EmptyTableTemplate = () => {
    return (
        <Card>
            <div className="flex justify-center items-center h-36">
                <div className="text-secondary font-medium text-base">- No data available -</div>
            </div>
        </Card>
    );
};

export default EmptyTableTemplate;
