import CardSkelton from '@components/CardSkelton/CardSkelton';
import useInternalApplicationListData from 'modules/internalApplications/hooks/useInternalApplicationListData';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import InternalApplicationCard from '../InternalApplicationCard/InternalApplicationCard';
import { CircularProgress } from '@hyperflake/react-ui-library';

const InternalApplicationsList = () => {
    const internalApplicationsList = useInternalApplicationListData();

    return (
        <>
            <PageTemplate>
                <PageHeading>Internal Applications</PageHeading>
                {internalApplicationsList.loading ? (
                    <div className="flex justify-center items-center h-[70vh]">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="grid grid-cols-3 gap-6 mt-6 empty:hidden">
                        {internalApplicationsList.data.map((internalApplication, i) => (
                            <div className="col-span-1">
                                <InternalApplicationCard data={internalApplication} />
                            </div>
                        ))}
                    </div>
                )}
            </PageTemplate>
        </>
    );
};

export default InternalApplicationsList;

// <div
// key={row._id}
// className="card col-span-4 sm:col-span-2 xl:col-span-1 px-5 pt-4 pb-6 hover:cursor-pointer "
// onClick={() => handleNavigate(row)}
// >
// <div className="flex justify-end">
//     <div
//         className={`inline-flex text-xs px-3 py-1 rounded-full font-semibold  ${
//             row.isEnabled ? 'bg-green-500 text-white' : 'bg-gray-100 text-gray-900'
//         }`}
//     >
//         {row.isActive ? (row.isEnabled ? 'ACTIVE' : 'NOT ACTIVE') : 'COMING SOON'}
//     </div>
// </div>
// <img className="max-h-10 max-w-[140px] mt-6" src={row.logoUrl} alt="" />
// <div className="font-bold mt-4">{row.name}</div>
// <p className="mt-2">{row.description}</p>
// </div>
