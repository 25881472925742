import {
    DetailedHTMLProps,
    FC,
    ForwardedRef,
    HTMLAttributes,
    ImgHTMLAttributes,
    forwardRef,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { getFontSize, getInitials } from '../helpers/avatar.utils';
import { AvatarOptions } from '../helpers/avatar.types';

interface AvatarProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, AvatarOptions {
    ref?: ForwardedRef<HTMLDivElement>;
    imgProps?: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
}

const Avatar = forwardRef<HTMLDivElement, AvatarProps>((props: AvatarProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { src, alt, bgColor, size, fontSize, initialsOnly, className, style, imgProps, ...rest } = props;

    const { className: imgClassName, ...restProps } = imgProps;

    const [isError, setIsError] = useState(false);

    const altFontSize = useMemo(() => (fontSize ? fontSize : getFontSize(size)), [size, fontSize]);

    const showImage = useMemo(() => src && !isError, [isError, src]);

    const renderAlt = useCallback(() => {
        if (initialsOnly) return getInitials(alt);

        return alt;
    }, [alt, initialsOnly]);

    return (
        <div
            {...rest}
            ref={ref}
            className={twMerge(
                'relative flex items-center justify-center flex-shrink-0 select-none rounded-full overflow-hidden text-white text-center font-medium',
                className
            )}
            style={{ height: size, width: size, fontSize: altFontSize, backgroundColor: bgColor, ...style }}
        >
            {showImage ? (
                <img
                    {...restProps}
                    className={twMerge(
                        'w-full h-full object-cover object-center rounded-full text-transparent',

                        imgClassName
                    )}
                    src={src}
                    alt={alt}
                    onError={() => setIsError(true)}
                />
            ) : (
                renderAlt()
            )}
        </div>
    );
});

Avatar.defaultProps = {
    bgColor: '#bdbdbd',
    size: 32,
    initialsOnly: true,
    imgProps: {},
};

export default Avatar;
