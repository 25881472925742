const Icon = (props: any) => {
    return (
        <svg width={14} height={16} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 3.885h12M11.666 3.885v9.6c0 .364-.14.713-.39.97s-.59.402-.943.402H3.666c-.353 0-.692-.145-.942-.402a1.392 1.392 0 01-.391-.97v-9.6m2 0V2.514c0-.364.14-.713.39-.97s.59-.401.943-.401h2.667c.354 0 .693.144.943.401s.39.606.39.97v1.371M5.667 7.313v4.115M8.334 7.313v4.115"
                stroke="currentColor"
                strokeWidth={1.28571}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
