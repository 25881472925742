import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface PickerHeaderProps {
    isPrevDisabled: boolean;
    isNextDisabled: boolean;
    onPrevClick: () => void;
    onNextClick: () => void;
    content: ReactNode;
}

const PickerHeader: FC<PickerHeaderProps> = (props) => {
    const { isPrevDisabled, isNextDisabled, onPrevClick, onNextClick, content } = props;

    return (
        <div className="flex items-center justify-center gap-4">
            <div
                role="button"
                aria-label="Previous"
                className={twMerge(
                    'grid place-items-center w-6 h-6 rounded-md text-grayscale-300',
                    !isPrevDisabled && 'text-grayscale-700 cursor-pointer bg-floating-color hover:bg-primary/5'
                )}
                onClick={onPrevClick}
            >
                <svg width={16} height={16} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.854 3.146a.5.5 0 010 .708L3.707 7H12.5a.5.5 0 010 1H3.707l3.147 3.146a.5.5 0 01-.708.708l-4-4a.5.5 0 010-.708l4-4a.5.5 0 01.708 0z"
                        fill="currentColor"
                        fillRule="evenodd"
                        clipRule="evenodd"
                    />
                </svg>
            </div>

            <div className="flex items-center justify-center text-grayscale-900 text-sm font-medium text-center w-[152px]">
                {content}
            </div>

            <div
                role="button"
                aria-label="Next"
                className={twMerge(
                    'grid place-items-center w-6 h-6 rounded-md text-grayscale-300',
                    !isNextDisabled && 'text-grayscale-700 cursor-pointer bg-floating-color hover:bg-primary/5'
                )}
                onClick={onNextClick}
            >
                <svg width={16} height={16} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.146 3.146a.5.5 0 01.708 0l4 4a.5.5 0 010 .708l-4 4a.5.5 0 01-.708-.708L11.293 8H2.5a.5.5 0 010-1h8.793L8.146 3.854a.5.5 0 010-.708z"
                        fill="currentColor"
                        fillRule="evenodd"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
        </div>
    );
};

export default PickerHeader;
