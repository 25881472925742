import { IProject } from '@api/models/project.model';
import { useMemo } from 'react';

interface ProjectHierarchyOptions {
    projects: IProject[];
    selectedIds?: string[];
    onSelectedIdsChange?: (ids: string[]) => void;
}
const useProjectHierarchySelection = (options: ProjectHierarchyOptions) => {
    const { projects, selectedIds = [], onSelectedIdsChange } = options;

    const projectChildrenMap: { [key: string]: IProject[] } = useMemo(() => {
        return projects.reduce((acc: any, cur) => {
            if (cur.parentId === null) {
                acc['root'] = acc['root'] || [];

                acc['root'].push(cur);
            } else {
                acc[cur.parentId] = acc[cur.parentId] || [];

                acc[cur.parentId].push(cur);
            }

            return acc;
        }, {});
    }, [projects]);

    const isSelected = (projectId: string) => selectedIds.includes(projectId);

    const hasChildren = (projectId: string) => projectChildrenMap[projectId] !== undefined;

    const getChildren = (projectId: string) => projectChildrenMap[projectId];

    const addParentId = (ids: Set<string>, parentId: string) => {
        const parent = projects.find((p) => p._id === parentId);

        ids.add(parent._id);

        if (parent.parentId) {
            addParentId(ids, parent.parentId);
        }
    };

    const removeParentId = (ids: Set<string>, parentId: string) => {
        const parent = projects.find((p) => p._id === parentId);

        const children = getChildren(parent._id);
        const childrenIds = children.map((child) => child._id);

        const isAnotherChildSelected = Array.from(ids).some((id) => childrenIds.includes(id));

        if (!isAnotherChildSelected) {
            ids.delete(parent._id);

            if (parent.parentId) {
                removeParentId(ids, parent.parentId);
            }
        }
    };

    const addChildrenIds = (ids: Set<string>, children: IProject[]) => {
        children.forEach((child) => {
            ids.add(child._id);

            if (hasChildren(child._id)) {
                addChildrenIds(ids, getChildren(child._id));
            }
        });
    };

    const removeChildrenIds = (ids: Set<string>, children: IProject[]) => {
        children.forEach((child) => {
            ids.delete(child._id);

            if (hasChildren(child._id)) {
                removeChildrenIds(ids, getChildren(child._id));
            }
        });
    };

    const changeSelected = (project: IProject, isChecked: boolean) => {
        const _selectedProjectIds = new Set<string>(selectedIds);

        const projectId = project._id;

        if (isChecked) {
            // Add project id to the selected list
            _selectedProjectIds.add(projectId);

            // Check if project has parentId, add parent id to the selected list (recursively)
            if (project.parentId) {
                addParentId(_selectedProjectIds, project.parentId);
            }

            // Check if project has children, add all children ids to the selected list (recursively)
            if (hasChildren(project._id)) {
                addChildrenIds(_selectedProjectIds, getChildren(project._id));
            }
        } else {
            // Delete project id from the selected list
            _selectedProjectIds.delete(projectId);

            // Check if project has parentId, remove parent id from the selected list (recursively & conditionally)
            if (project.parentId) {
                removeParentId(_selectedProjectIds, project.parentId);
            }

            // Check if project has children, remove all children ids from the selected list (recursively)
            if (hasChildren(project._id)) {
                removeChildrenIds(_selectedProjectIds, getChildren(project._id));
            }
        }

        onSelectedIdsChange?.(Array.from(_selectedProjectIds));

        return Array.from(_selectedProjectIds);
    };

    const hasAllChildrenSelected = (projectId: string) => {
        const allDescendants = new Set<string>();

        if (hasChildren(projectId)) {
            addChildrenIds(allDescendants, getChildren(projectId));
        }

        const hasAllChildrens = Array.from(allDescendants).every((id) => selectedIds.includes(id));

        return hasAllChildrens;
    };

    return {
        changeSelected,
        isSelected,
        hasChildren,
        getChildren,
        hasAllChildrenSelected,
    };
};

export default useProjectHierarchySelection;
