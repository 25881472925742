const Icon = (props: any) => {
    return (
        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x={4.16675} width={1.66667} height={10} rx={0.833333} fill="currentColor" />
            <rect
                x={10}
                y={4.16602}
                width={1.66667}
                height={10}
                rx={0.833333}
                transform="rotate(90 10 4.166)"
                fill="currentColor"
            />
        </svg>
    );
};

export default Icon;
