import { PermissionEnum } from '@api/enum/permission.enum';
import { Avatar, Breadcrumb, ContentLayout, NavPills, Select, Sidebar } from '@hyperflake/react-ui-library';
import useClientData from 'modules/clients/hooks/useClientData';
import { BackButton } from 'modules/shared/components/buttons';
import { AppHeaderWithNav } from 'modules/shared/components/header';
import ProjectHierarchyBreadcrumb from 'modules/shared/components/ProjectHierarchyBreadcrumb/ProjectHierarchyBreadcrumb';
import { NavTab, useTab } from 'modules/shared/components/tabs';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import { ReactNode } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

export interface ITabItem {
    content: ReactNode;
    value: string;
}
enum NavTypeEnum {
    PROJECTS = 'projects',
    USERS = 'users',
}
const tabItems: ITabItem[] = [
    {
        content: 'projects',
        value: NavTypeEnum.PROJECTS,
    },
    {
        content: 'Users',
        value: NavTypeEnum.USERS,
    },
];

const ClientLayout = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { clientId } = useParams();
    const { hasPermission } = useAuthPermission();
    const navItems = [
        {
            content: 'Client Details',
            isActive: pathname.startsWith(`/clients/${clientId}/client-details`),
            onClick: () => navigate(`${clientId}/client-details`),
            permission: hasPermission(PermissionEnum.CLIENT_READ),
        },
        {
            content: 'Projects',
            isActive: pathname.startsWith(`/clients/${clientId}/projects`),
            onClick: () => navigate(`${clientId}/projects`),
            permission: hasPermission(PermissionEnum.PROJECT_READ),
        },
        {
            content: 'Users',
            isActive: pathname.startsWith(`/clients/${clientId}/users`),
            onClick: () => navigate(`${clientId}/users`),
            permission: hasPermission(PermissionEnum.USER_READ),
        },
    ];
    const [activeTab, setActiveTab] = useTab(NavTypeEnum.PROJECTS);

    // TODO - fetch client details
    const { client } = useClientData(clientId);

    return (
        <>
            {/* <Sidebar>
                <Sidebar.Content>
                    <div className="flex items-center gap-2">
                        <Avatar src={client?.logoUrl} alt={client?.name} bgColor={client?.preferences?.primaryColor} />
                        <div className="font-semibold">{client?.name}</div>
                    </div>
                </Sidebar.Content>
                <Sidebar.Nav className="pt-0">
                    <div></div>
                    {navItems.map(
                        (row, i) =>
                            row.permission && (
                                <Sidebar.NavItem key={i} isActive={row.isActive} onClick={row.onClick}>
                                    {row.content}
                                </Sidebar.NavItem>
                            )
                    )}
                </Sidebar.Nav>
            </Sidebar> */}
            {/* <AppHeaderWithNav
                headerComponent={<BackButton />}
                title={client?.name}
                navComponent={
                    <NavTab
                        value={activeTab}
                        onChange={(val) => {
                            console.log('value: ' + val);
                            setActiveTab(val);
                            if (val === 'projects') {
                                navigate(`${clientId}/projects`);
                            } else if (val === 'users') {
                                navigate(`${clientId}/users`);
                            }
                        }}
                        items={tabItems}
                    />
                }
            /> */}
            {/* <AppHeaderWithNav
                headerComponent={<BackButton />}
                title={client?.name}
                navComponent={
                    <NavPills
                        items={navItems.map((item) => ({
                            content: item.content,
                            isActive: item.isActive,
                            onClick: () => item.onClick(),
                        }))}
                    />
                }

            /> */}

            <div className="p-3">
                <ProjectHierarchyBreadcrumb
                    appendItems={[
                        {
                            label: 'Client',
                            to: '/',
                        },
                        {
                            label: client?.name,
                            to: `/clients/${client?._id}/client-details`,
                        },
                    ]}
                />

                <NavPills
                    className="mt-4"
                    items={navItems.map((item) => ({
                        content: item.content,
                        isActive: item.isActive,
                        onClick: () => item.onClick(),
                    }))}
                />
            </div>

            <Outlet />
        </>
    );
};

export default ClientLayout;
