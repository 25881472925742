import { CameraStatusEnum } from '@api/enum/camera-status.enum';
import { PermissionEnum } from '@api/enum/permission.enum';
import { UnitTypeEnum } from '@api/enum/unit-type.enum';
import { ICamera } from '@api/models/camera.model';
import { getAvailableCameraStorageList } from '@api/services/camera.service';
import { Button, LinearProgress } from '@hyperflake/react-ui-library';
import { DatePicker } from '@library/datepicker';
import { FormHelperText, Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { useQuery } from '@tanstack/react-query';
import { ErrorMessage, Form, Formik } from 'formik';
import capitalize from 'lodash.capitalize';
import upperCase from 'lodash.uppercase';
import useCameraUpdateForm from 'modules/cameras/hooks/useCameraUpdateForm';
import RequiredMark from 'modules/shared/components/FormFields/RequiredMark';
import { FormikInput, FormikSelect } from 'modules/shared/features/formik';
import { resolutionOption, timezoneList } from 'modules/shared/helpers/shared.constants';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import moment from 'moment';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface ICameraEditFormProps {
    onClose: () => void;
    cameraId: string;
    onCameraUpdate: (camera: ICamera) => void;
    camera: ICamera;
    isLoading: boolean;
}

const CameraEditForm = (props: ICameraEditFormProps) => {
    const { cameraId, onCameraUpdate, onClose, camera, isLoading } = props;
    const { clientId, projectId } = useParams();
    const cameraUpdateForm = useCameraUpdateForm({ clientId, projectId, cameraId, camera });

    const { data: storages, isLoading: storageIsLoading } = useQuery<string[]>(
        ['clients', clientId, 'projects', projectId, 'cameras', 'storage_names'],
        () => getAvailableCameraStorageList(clientId as string, projectId as string)
    );

    const resOption = useMemo(() => {
        return resolutionOption.map((val) => ({
            label: JSON.stringify(val),
            value: JSON.stringify(val),
        }));
    }, []);

    // option for drop downs
    const unitTypeOption = useMemo(() => {
        const temp = Object.values(UnitTypeEnum);
        return temp.map((val) => ({
            label: capitalize(val),
            value: val,
        }));
    }, []);

    const timezoneOption = useMemo(() => {
        return [
            {
                label: 'Select Timezone',
                value: '',
            },
            ...timezoneList.map((tz) => ({
                label: tz.label,
                value: tz.value,
            })),
        ];
    }, []);

    const storageOption = useMemo(() => {
        if (!storages) return [];
        return [{ label: 'Select Storage', value: '' }, ...storages.map((val: string) => ({ label: val, value: val }))];
    }, [storages]);

    const statusOption = useMemo(() => {
        return Object.values(CameraStatusEnum).map((status) => ({
            label: upperCase(status),
            value: status,
        }));
    }, []);

    // form submit
    const handleDataTransformation = (values: any) => {
        const temp = { ...values };
        temp.folder = temp.folder.trim() === '' ? null : temp.folder.trim();
        temp.installationDate = moment(temp.installationDate).format('YYYYMMDD');
        temp.latestImage = temp.latestImage.name === '' ? null : temp.latestImage;
        return temp;
    };

    const handleCameraEdit = async (payload: any) => {
        try {
            const updatedCamera = await cameraUpdateForm.submit(payload);
            onCameraUpdate(updatedCamera);

            toast.success('TimeLapse Camera Updated Successfully.');

            onClose();
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    const handleSubmit = (values: any) => {
        console.log({ values });
        const formattedData = handleDataTransformation(values);
        handleCameraEdit(formattedData);
    };

    const { hasPermission } = useAuthPermission();

    return (
        <div>
            {isLoading ? (
                // <LinearProgress variant="determinate" showLabel value={{isLoading:clientData.isLoading}} color="danger" />
                <LinearProgress color="primary" />
            ) : (
                <div>
                    <div>
                        <Formik
                            initialValues={cameraUpdateForm.initialValues}
                            enableReinitialize
                            validationSchema={cameraUpdateForm.schema}
                            onSubmit={handleSubmit}
                        >
                            {(formik) => (
                                <Form>
                                    <fieldset disabled={!hasPermission(PermissionEnum.TIMELAPSE_CAMERA_UPDATE)}>
                                        <div className="">
                                            {/* id */}
                                            <div>
                                                <Label>ID</Label>
                                                <div className="flex gap-2">
                                                    <FormikInput name="_id" disabled />
                                                </div>
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="_id" />
                                                </FormHelperText>
                                            </div>
                                            {/* name */}
                                            <div className="">
                                                <Label>
                                                    Name <RequiredMark />
                                                </Label>
                                                <FormikInput type="text" name="name" />
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="name" />
                                                </FormHelperText>
                                            </div>
                                            {/* installation Date */}
                                            <div className="">
                                                <Label>
                                                    Installation Date <RequiredMark />
                                                </Label>
                                                <DatePicker
                                                    className="flex items-center w-full font-medium bg-transparent border border-grayscale-300 placeholder:text-grayscale-400 focus:border-primary focus:outline focus:outline-2 focus:outline-primary/10 text-sm px-4 h-10 rounded-md"
                                                    // disabled={true}
                                                    onChange={(val) => formik.setFieldValue('installationDate', val)}
                                                    value={formik.values.installationDate}
                                                    maxDate={new Date()}
                                                />
                                                {/* <FormikDatePicker name="installationDate" disabled={true} /> */}
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="installationDate" />
                                                </FormHelperText>
                                            </div>
                                            {/* camera/unit type */}
                                            <div>
                                                <Label>Folder</Label>
                                                <div className="flex gap-2">
                                                    <FormikInput name="folder" disabled />
                                                </div>
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="folder" />
                                                </FormHelperText>
                                            </div>

                                            {/* camera/unit type */}
                                            <div>
                                                <Label>
                                                    Type <RequiredMark />
                                                </Label>
                                                <div className="flex gap-2">
                                                    <FormikSelect
                                                        name="details.type"
                                                        options={unitTypeOption}
                                                        disabled
                                                    />
                                                </div>
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="details.type" />
                                                </FormHelperText>
                                            </div>
                                            {/* camera/unit version */}
                                            <div className="">
                                                <Label>Version</Label>
                                                <FormikInput type="text" name="details.version" disabled />
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="details.version" />
                                                </FormHelperText>
                                            </div>
                                            {/* timezone */}
                                            <div>
                                                <Label>Timezone</Label>
                                                <div className="flex gap-2">
                                                    <FormikSelect name="timezone" options={timezoneOption} disabled />
                                                </div>
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="timezone" />
                                                </FormHelperText>
                                            </div>
                                            {/* orientation */}
                                            <div className="">
                                                <Label>Orientation</Label>
                                                <FormikInput type="text" name="orientation" disabled />
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="orientation" />
                                                </FormHelperText>
                                            </div>
                                            {/* storage */}
                                            <div>
                                                <Label>
                                                    Storage <RequiredMark />
                                                </Label>
                                                <div className="flex gap-2">
                                                    <FormikSelect
                                                        name="storage"
                                                        options={storageOption}
                                                        // disabled={true}
                                                    />
                                                    {storageIsLoading && <CircularProgress size={14} />}
                                                </div>
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="storage" />
                                                </FormHelperText>
                                            </div>
                                            {/* status */}
                                            <div>
                                                <Label>
                                                    Status <RequiredMark />
                                                </Label>
                                                <FormikSelect name="status" options={statusOption} />
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="status" />
                                                </FormHelperText>
                                            </div>
                                            <div>
                                                <Label>Image Name</Label>
                                                <FormikInput type="text" name="latestImage.name" />
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="latestImage.name" />
                                                </FormHelperText>
                                            </div>

                                            <div>
                                                <Label>Resolution</Label>
                                                <div className="flex gap-2">
                                                    <FormikSelect name="latestImage.resolution" options={resOption} />
                                                </div>
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="latestImage.resolution" />
                                                </FormHelperText>
                                            </div>
                                            <div className="mt-4">
                                                <h5>Location</h5>
                                                <div className="mt-4">
                                                    <Label>Latitude</Label>
                                                    <FormikInput type="text" name="location.latitude" />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="location.latitude" />
                                                    </FormHelperText>
                                                </div>
                                                <div className="">
                                                    <Label>Longitude</Label>
                                                    <FormikInput type="text" name="location.longitude" />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="location.longitude" />
                                                    </FormHelperText>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            type="submit"
                                            size={'lg'}
                                            className="mt-8 w-28"
                                            disabled={cameraUpdateForm.isSubmitting}
                                        >
                                            {cameraUpdateForm.isSubmitting ? (
                                                <CircularProgress size={20} color={'inherit'} />
                                            ) : (
                                                'Save'
                                            )}
                                        </Button>
                                    </fieldset>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            )}
        </div>
    );
};
export default CameraEditForm;
