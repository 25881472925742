import http from 'httpConfig/http';
import { IConsoleUser, IProject, IUser } from '@api/models';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';

export const getCurrentAccount = async () => {
    const { data } = await http.get(`${import.meta.env.VITE_APP_AUTH_URL}/profile`);

    return data as IUser;
};
export const getAllUsers = async () => {
    const { data } = await http.get(`${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/users`);

    return data as IUser[];
};

export const getUsersByClientId = async (clientId: string) => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/users`
    );
    return data as IUser[];
};

export const getUserByClientIdUserId = async (clientId: string, userId: string) => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/users/${userId}`
    );
    return data as IUser;
};

export const addUser = async (clientId: string, payload: any) => {
    const { data } = await http.post(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/users`,
        payload
    );
    return { data, status };
};
export const updateUser = async (clientId: string, userId: string, payload: any) => {
    const { data } = await http.put(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/users/${userId}`,
        payload
    );
    return data as IUser;
};
export const updateProjectAccess = async (clientId: string, userId: string, payload: any) => {
    const { data } = await http.put(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${clientId}/users/${userId}/projects`,
        payload
    );
    return data as IUser;
};

export const getExternalOnSearch = async (clientId: string, userId: string, q: any) => {
    const { data } = await http.get(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/users/${userId}/search-external-projects`,
        {
            params: { q },
        }
    );

    return data as IProject[];
};

export const updateExternalProjects = async (clientId: string, userId: string, payload: any) => {
    const { data } = await http.put(
        `${
            import.meta.env.VITE_APP_API_URL
        }/${getResellerIdFromRedux()}/clients/${clientId}/users/${userId}/external-projects`,
        payload
    );
    // return data as IUser;s
    return data;
};
