import { ICameraImage } from '@api/models';
import { cameraNameValidation, deleteMultipleImages, getImagesByCameraId } from '@api/services/camera.service';
import useDataQuery from '@hooks/use-data-query';
import { useMutation } from '@tanstack/react-query';

const useCameraImagesListData = (clientId: string, projectId: string, cameraId: string, date: string) => {
    const cameraImageListQuery = useDataQuery([cameraId, clientId, projectId, date, 'images'], () =>
        getImagesByCameraId(clientId as string, projectId as string, cameraId as string, date as string)
    );
    const deleteImageMutation = useMutation((payload: any) =>
        deleteMultipleImages(clientId, projectId, cameraId, payload)
    );
    const cameraNameValidationMutation = useMutation((payload: string) =>
        cameraNameValidation(clientId, projectId, cameraId, payload)
    );

    const onDelete = (selectedIds: string[]) => {
        cameraImageListQuery.setData((prevList: ICameraImage[]) => {
            return prevList.filter((item) => !selectedIds.includes(item._id));
        });
    };
    const handleDeleteImage = async (values: string[]) => {
        const data = await deleteImageMutation.mutateAsync({ cameraImageIds: values });
        onDelete(values);
    };
    const handleValidation = async (values: string) => {
        await cameraNameValidationMutation.mutateAsync(values);
    };

    return {
        images: cameraImageListQuery.data as ICameraImage[],
        isLoading: cameraImageListQuery.isLoading,
        handleDeleteImage,
        handleValidation,
        isDeleting: deleteImageMutation.isLoading,
        isValidating: cameraNameValidationMutation.isLoading,
        onDelete,
    };
};

export default useCameraImagesListData;
