export const modalAnimation = {
    variants: {
        hidden: {
            y: '100vh',
        },
        visible: {
            y: 0,
        },
    },
    transition: { type: 'tween', duration: 0.35, ease: 'linear' },
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
};

export const collapseAnimation = {
    variants: {
        collapsed: {
            opacity: 0,
            height: 0,
        },
        expanded: {
            opacity: 1,
            height: 'auto',
        },
    },
    transition: { duration: 0.3 },
    initial: 'collapsed',
    animate: 'expanded',
    exit: 'collapsed',
};
